import React, { memo, useEffect, useState } from "react";
// Chakra imports
import {
  Button,
  Text,
  Input,
  Flex,
  Box,
  Icon,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  FormControl,
  FormLabel,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { BsTrash3Fill } from "react-icons/bs";
import { post, get } from "redux/services/api";
import Confirmation from "./DeleteStationConfirmation";

// import { GoSettings } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { Table, Pagination, Input as InputAntd } from "antd";
import { CiEdit } from "react-icons/ci";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { getAllUsers, getAllLocations } from "redux/slices/Auth";

import { eventRootUrl } from "utilities/constants";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
const { Search } = InputAntd;
let alphabats = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
function EventSettings(props: any) {
  const toast = useToast();
  const textColor = useColorModeValue("black.50", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const dispatch: AppDispatch = useDispatch();
  const { activeId, onChangeNavigation, getEventDetailInside } = props;
  const [SelectedStation, SetSelectedStation] = useState({
    station_Name: "",
    station_ID: 0,
    isInvalid: false,
  });
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenConfirmation,
    onClose: onCloseConfirmation,
  } = useDisclosure();
  const [stationsList, setStationList] = useState([]);

  const [No_Of_stations, Set_No_Of_stations] = useState(0);
  const [stations, setstations] = useState([]);

  const { allUsers } = useSelector((state: RootState) => state.Auth);
  const { usersListloading, usersList, userRolesList } = useSelector(
    (state: any) => state.AdminsTableLists
  );
  const { challonge_Tournament_Id, id } = props.state;
  const [isLoading, setisLoading] = useState(false);

  const [settingType, setSettingType] = useState("moderators");

  useEffect(() => {
    // dispatch(getAllUsers());
  }, []);
  useEffect(() => {
    getStations();
    if (usersList) {
      console.log({ allUsers });
    }
  }, [usersList]);
  const [invalidState, setInvalidState] = useState({
    station_Name: false,
    FirstNameInvalid: false,
    EmailAddressInvalid: false,
    UserNameInvalid: false,
  });

  const AddStations = async () => {
    let arr = stationsList.filter((x: any) => x.station_Name === "")[0];
    if (arr) {
      let obj = { ...stationsList[arr.i] };
      obj.isInvalid = true;
      stationsList[arr.i] = obj;
      // stationsList=arr
      setStationList([...stationsList]);
      // stationsList=arr
      setStationList([...stationsList]);
      return;
    }
    let stations = stationsList.map((x) => {
      return { station_Name: x.station_Name };
    });
    setisLoading(true);
    let payload = {
      url: `${eventRootUrl}api/v1/leagueprofile/save-tournament-station`,
      data: {
        tournamentId: id,
        stations: stations,
      },
    };
    await post(payload);
    setisLoading(false);
    getStations();
    onCloseCraetGroup();
    toast({
      title: "Success",
      description: "Stations Added",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };
  const {
    isOpen: isOpenCraetGroup,
    onOpen: onOpenCraetGroup,
    onClose: onCloseCraetGroup,
  } = useDisclosure();
  const {
    isOpen: isOpenUpdateStation,
    onOpen: onOpenUpdateStation,
    onClose: onCloseUpdateStation,
  } = useDisclosure();
  const getStations = async () => {
    console.log({ challonge_Tournament_Id });
    let url = `${eventRootUrl}api/v1/leagueprofile/tournament-station?tournamentID=${id}`;
    const response: any = await get(url);
    if (response.data.status === true) {
      setstations(response?.data?.response ?? []);
    }
    console.log({ response: response.data.response });
  };

  const genrateStations = (num: number) => {
    let arr = [];

    for (let i = 0; i < num; i++) {
      let obj = { i: i, station_Name: "", isInvalid: false };
      arr.push(obj);
    }
    setStationList(arr);
  };
  const updateStationName = (e: any, i: number) => {
    let obj = { ...stationsList[i] };
    obj.station_Name = e.target.value;
    stationsList[i] = obj;
    // stationsList=arr
    setStationList([...stationsList]);
  };
  const updateStation = async () => {
    if (SelectedStation.station_Name === "") {
      SetSelectedStation({ ...SelectedStation, isInvalid: true });
      return;
    }

    setisLoading(true);
    let payload = {
      url: `${eventRootUrl}api/v1/leagueprofile/save-tournament-station`,
      data: {
        tournamentId: id,
        stations: [{ ...SelectedStation }],
      },
    };
    await post(payload);
    setisLoading(false);
    getStations();
    onCloseUpdateStation();
    toast({
      title: "Success",
      description: "Stations updated",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };
  const deletStation = async() => {
    setisLoading(true);
    let payload = {
      url: `${eventRootUrl}api/v1/leagueprofile/Delete-tournament-station`,
      data: {
        tournamentId: id,
        stationID:SelectedStation.station_ID,
      },
    };
    await post(payload);
    setisLoading(false);
    getStations();
    onCloseConfirmation();
    toast({
      title: "Success",
      description: "Stations deleted",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };
  return (
    <Box>
       <Confirmation
        isLoading={isLoading}
        isOpen={isOpenDelete}
        onOpen={onOpenConfirmation}
        onClose={onCloseConfirmation}
        confirm={deletStation}
      />
      <Flex w="250px" alignItems={"center"} justifyContent="space-between">
        <Icon
          cursor={"pointer"}
          onClick={() => onChangeNavigation(1)}
          as={MdOutlineKeyboardBackspace}
          color={"blue.200"}
          boxSize={"30px"}
        />

        <Text fontSize={"24px"} color="blue.200" fontWeight={"700"}>
          Set Stations
        </Text>
      </Flex>
      <Flex
        p="10px"
        borderRadius={"12px"}
        // direction="column"
        // border="1px solid #EAECF0"
        justifyContent={"space-between"}
      >
        <Text fontSize={"20px"} color="blue.200" fontWeight={"600"}>
          Available Stations
        </Text>
        <Flex>
          <Button
            //   disabled={isRegistrationClosed}
            onClick={onOpenCraetGroup}
            leftIcon={<FiPlus />}
            variant="brand"
            color="blue.200"
            fontSize="14px"
            fontWeight="500"
            borderRadius="100px"
            mt="0px"
            px="14px"
            py="8px"
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
            bg="transparent"
            maxH="147px"
            border="1px"
            borderColor="blue.200"
            borderStyle={"dashed"}

            // w="100%"
          >
            Add New Station
          </Button>
        </Flex>
      </Flex>
      <Flex>
        {stations.map((x: any) => {
          return (
            <Flex
              key={x.station_ID}
              ml="5px"
              justifyContent={"flex-start"}
              alignItems="center"
              bg="#ECF1F4"
              py="15px"
              px="20px"
              w="343px"
              borderRadius={"12px"}
            >
              <Flex
                justifyContent={"space-between"}
                w="full"
                alignItems="center"
              >
                <Text color={"#1C2A5B"} fontSize="14px" fontWeight={"500"}>
                  {x.station_Name}
                </Text>
                <HStack justifyContent={"flex-end"}>
                  <Box
                    cursor={"pointer"}
                    onClick={() => {
                      SetSelectedStation(x);
                      onOpenUpdateStation();
                    }}
                  >
                    {" "}
                    <CiEdit color="#298BE2" size={"20px"} />
                  </Box>
                 
                  <Box
                    cursor={"pointer"}
                    onClick={() => {
                     onOpenConfirmation()
                      SetSelectedStation(x);
                    }}
                  >
                    {" "}
                    <BsTrash3Fill color="#FF5247" size={"20px"} />
                  </Box>
                </HStack>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      <Modal isCentered isOpen={isOpenCraetGroup} onClose={onCloseCraetGroup}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Stations</ModalHeader>

          <ModalCloseButton />
          <ModalBody
            maxH="60vh"
            overflowY={"scroll"}
            css={{
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#1C2A5B",
                borderRadius: "24px",
              },
            }}
          >
            <FormControl isInvalid={invalidState.station_Name}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Stations<Text color={brandStars}>*</Text>
              </FormLabel>
              <Flex justifyContent={"space-between"} alignItems="center">
                <Text color={"#9299B0"} fontSize="13px" fontWeight={"500"}>
                  How many stations will there be? (Max 26)
                </Text>

                <Input
                  borderColor={invalidState.station_Name ? "red" : null}
                  onChange={(e) =>
                    // updateState(e.target.value, "LastName", "LastNameInvalid")
                    {
                      if (Number(e.target.value) > 26) {
                        Set_No_Of_stations(26);
                        genrateStations(26);
                      } else {
                        Set_No_Of_stations(Number(e.target.value));
                        genrateStations(Number(e.target.value));
                      }
                    }
                  }
                  value={No_Of_stations}
                  isRequired={true}
                  type="number"
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  placeholder="3"
                  borderRadius={8}
                  w="50px"
                  fontWeight="500"
                  size="lg"
                  h={"46px"}
                />
              </Flex>
            </FormControl>
            {stationsList.map((x: any, i: number) => {
              return (
                <FormControl key={i} mt="5px" isInvalid={x.isInvalid}>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Station {alphabats[i]} Name<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    borderColor={x.isInvalid ? "red" : null}
                    onChange={(e) => {
                      updateStationName(e, i);
                    }}
                    value={x.station_Name}
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    placeholder="Enter Station Name"
                    type="text"
                    borderRadius={8}
                    fontWeight="500"
                    size="lg"
                    h={"46px"}
                  />
                  {x.isInvalid && (
                    <FormErrorMessage>
                      Station Name is required.
                    </FormErrorMessage>
                  )}
                </FormControl>
              );
            })}
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              loadingText="Adding Station..."
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              my="10px"
              borderRadius={8}
              bg={"blue.200"}
              onClick={AddStations}
            >
              Add Stations
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isCentered
        isOpen={isOpenUpdateStation}
        onClose={onCloseUpdateStation}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Stations</ModalHeader>

          <ModalCloseButton />
          <ModalBody
            maxH="60vh"
            overflowY={"scroll"}
            css={{
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#1C2A5B",
                borderRadius: "24px",
              },
            }}
          >
            <FormControl
              key={SelectedStation.station_ID}
              mt="5px"
              isInvalid={SelectedStation.isInvalid}
            >
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Station Name<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                borderColor={SelectedStation.isInvalid ? "red" : null}
                onChange={(e) => {
                  SetSelectedStation({
                    ...SelectedStation,
                    station_Name: e.target.value,
                  });
                }}
                value={SelectedStation.station_Name}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                placeholder="Enter Station Name"
                type="text"
                borderRadius={8}
                fontWeight="500"
                size="lg"
                h={"46px"}
              />
              {SelectedStation.isInvalid && (
                <FormErrorMessage>Station Name is required.</FormErrorMessage>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              loadingText="Adding Station..."
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              my="10px"
              borderRadius={8}
              bg={"blue.200"}
              onClick={updateStation}
            >
              Update Stations
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default memo(EventSettings);
