/* eslint-disable */

import React, { memo, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Image,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  useToast,
  Text,
  useColorModeValue,
  HStack,
  Avatar,
} from "@chakra-ui/react";
// Custom components
import { rootUrl } from "utilities/constants";
import { post } from "redux/services/api";

import { useDispatch, useSelector } from "react-redux";

function index(props: { next: () => void }) {
  const toast = useToast();
  const dispatch: any = useDispatch();
  const { loading } = useSelector((state: any) => state.Auth);
  const { next } = props;
  // Chakra color mode
  const textColor = useColorModeValue("black.50", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("blue.100", "blue.100");
  const buttonColorBrand = useColorModeValue("blue.200", "blue.200");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [state, setState] = useState({
    HubProfilePicName: "",
    HubProfileCover: "",
    HubWebsite: "",
    UserId: 145,
    HubBio: "",
    HubProfilePic: "",
  });
  const [preview, setPreview] = useState<any>(null);
  const [previewCover, setPreviewCover] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const hiddenFileInput = React.useRef(null);
  const hiddenFileInputBanner = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(fileUploaded);
    reader.onloadend = function (e) {
      setPreview([reader.result]);
    };

    setState((prvState) => ({
      ...prvState,
      HubProfilePicName: fileUploaded.name,
      HubProfilePic: fileUploaded,
    }));
  };

  const handleClickBanner = () => {
    hiddenFileInputBanner.current.click();
  };
  const handleChangeBanner = (event: any) => {
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(fileUploaded);
    reader.onloadend = function (e) {
      setPreviewCover([reader.result]);
    };
    setState((prvState) => ({
      ...prvState,
      HubProfileCover: fileUploaded,
    }));
  };
  const updateState = (val: string, prop: string) => {
    setState((prvState) => ({ ...prvState, [prop]: val }));
  };
  const checks = (): boolean => {
    if (state.HubProfilePic === "") {
      toast({
        title: "Profile picture is required",
        description: "Please upload profile picture",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };
  const finish = async () => {
    if (checks()) {
      setIsLoading(true);
      let payload: any = { ...state };
      var myHeaders = new Headers();
      var formdata = new FormData();

      myHeaders.append("accept", "*/*");
      for (var key in payload) {
        formdata.append(key, payload[key]);
      }

      var requestOptions: any = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      let obj = {
        url: `${rootUrl}api/v1/user-profile/update-hubuser-profile`,
        data: formdata,
      };

      try {
        let res = await post(obj);
        if (res.data.status === false) {
          toast.closeAll()
          toast({
            title: "Error",
            description: res.data.errorMessage,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } else {
          next();
          toast.closeAll()
          toast({
            title: "Profile Saved",
            description: res.data.message,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    }
    // next()
  };
  return (
    <Flex
      maxW={{ base: "100%", md: "max-content" }}
      w="100%"
      mx={{ base: "auto", lg: "0px" }}
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      //   mb={{ base: "30px", md: "60px" }}
      //   px={{ base: "25px", md: "0px" }}
      mt={{ base: "40px", md: "10vh" }}
      flexDirection="column"
      bg={"white"}
      p="50px"
    >
      <Box me="auto">
        <Heading color={textColor} fontSize="36px" w={450} mb="10px">
          Customize your hub profile
        </Heading>
        <Text
          fontSize="md"
          fontWeight="normal"
          color={textColorSecondary}
          mb="8px"
        >
          lets get gamers to recognize why you are so amazing!
        </Text>
      </Box>
      <HStack mt="20px">
        {preview ? (
          <Avatar src={preview} size="lg" />
        ) : (
          <Box borderRadius={50} h={20} w={20} bg={"gray.200"} />
        )}
        <Box ml="20px">
          <Text fontSize="md" fontWeight="normal" color={textColor}>
            Select your hub profile picture
          </Text>

          <Button
            onClick={handleClick}
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="60%"
            h="40px"
            mt={"10px"}
            borderRadius={8}
          >
            Chose
          </Button>
          <input
            accept="image/*"
            type="file"
            value={""}
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
          />
        </Box>
      </HStack>
      <HStack mt="40px">
        {previewCover ? (
          <Image src={previewCover} borderRadius={8} h={120} w={200} />
        ) : (
          <Box borderRadius={8} h={120} w={200} bg={"gray.200"} />
        )}
        <Box>
          <Text fontSize="md" fontWeight="normal" color={textColor} ml="20px">
            Select your hub profile picture cover image {"(optional)"}
          </Text>
          <Button
            onClick={handleClickBanner}
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="50%"
            h="40px"
            ml="20px"
            mt={"20px"}
            borderRadius={8}
          >
            Chose
          </Button>
          <input
            accept="image/*"
            type="file"
            value={""}
            ref={hiddenFileInputBanner}
            onChange={handleChangeBanner}
            style={{ display: "none" }}
          />
        </Box>
      </HStack>
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "420px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <FormControl mt={10}>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Your hubs bio
          </FormLabel>
          <Input
            onChange={(e) => updateState(e.target.value, "HubBio")}
            value={state.HubBio}
            isRequired={true}
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            borderRadius={8}
            mb="24px"
            fontWeight="500"
            size="lg"
          />
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Hub website
          </FormLabel>
          <Input
            onChange={(e) => updateState(e.target.value, "HubWebsite")}
            value={state.HubWebsite}
            isRequired={true}
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            borderRadius={8}
            mb="24px"
            fontWeight="500"
            size="lg"
          />

          <HStack mt="20px">
            <Button
              isLoading={isLoading || loading}
              loadingText="Finisging..."
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="30%"
              h="50"
              borderRadius={8}
              bg={buttonColorBrand}
              onClick={finish}
            >
              Update
            </Button>
          </HStack>
        </FormControl>
      </Flex>
    </Flex>
  );
}

export default index;
