// Chakra imports
import react, { useEffect, useState } from "react";
import { Portal, Box, useDisclosure, Flex, Grid } from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin";
// Layout components
import Navbar from "components/navbar/NavbarAdmin";
import Sidebar from "components/sidebar/Sidebar";
import { SidebarContext } from "contexts/SidebarContext";
import { Redirect, Route, Switch } from "react-router-dom";
import AppRoutes from "routes";
import { motion, AnimatePresence } from "framer-motion";
import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdSettingsApplications,
} from "react-icons/md";
import SidebarMenu from "components/SidebarMenu";
import { useDispatch, useSelector } from "react-redux";
// import { getRoleWiseMenu } from "redux/slices/AdminsTableLists";
import OneSignal from "react-onesignal";
import { useHistory, useLocation } from "react-router-dom";

import async from "react-select/dist/declarations/src/async/index";
import {
  useToast,
  Alert,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { CloseButton } from "@chakra-ui/close-button";

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
  const dispatch: any = useDispatch();

  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [routes, setRoutes] = useState([]);
  const toast = useToast();
  const history = useHistory();
  const { menuList } = useSelector((state: any) => state.AdminsTableLists);
  const { role } = useSelector((state: any) => state.Auth);

  useEffect(() => {
    OneSignal.Notifications.addEventListener("click", eventListener);
    OneSignal.Notifications.addEventListener(
      "foregroundWillDisplay",
      foregroundWillDisplayListener
    );
  }, []);
  useEffect(() => {
    if (menuList) {
      setRoutes(menuList);
    }
  }, [menuList]);

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeNavbar;
  };
  // const getRoutes = (routes: RoutesType[]): any => {
  //   return routes.map((route: RoutesType, key: any) => {
  //     if (route.layout === "/admin") {
  //       return (
  //         <Route
  //           path={route.layout + route.path}
  //           component={route.component}
  //           key={key}
  //         />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };
  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  const [navSize, changeNavSize] = useState("large");

  const eventListener = (event: any) => {
    console.log({ event });
  };
  const navigate = (data: any) => {
    console.log({ data });
    toast.closeAll();
    if (
      data?.additionalData?.type === "chat" &&
      data?.additionalData?.groupName === "Grid Customor Service"
    ) {
      history.push({
        pathname: "/admin/Ticketingsystem",
      });
    } else if (
      data?.additionalData?.type === "chat" &&
      data?.additionalData?.eventId
    ) {
      history.push({
        pathname: "/admin/BracketPortal",
        state: { eventDetail: {} },
        search: `?eventId=${data?.additionalData?.eventId}`,
      });
    } else if (data?.additionalData?.type === "participantRegistered") {
      history.push({
        pathname: "/admin/BracketPortal",
        state: { eventDetail: {} },
        search: `?eventId=${data?.additionalData?.tournamentId}`,
      });
    }
  };
  const foregroundWillDisplayListener = (data: any) => {
    toast.closeAll();
    toast({
      position: "bottom-left",
      status: "info",
      duration: 9000,
      isClosable: true,
      render: () => (
        <>
          <Alert
            cursor={"pointer"}
            variant="left-accent"
            p="10px"
            borderRadius={"8px"}
            onClick={() => navigate(data?.notification)}
            status="info"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            height="90px"
          >
            <AlertTitle> {data?.notification?.title}</AlertTitle>
            <AlertDescription>{data?.notification?.body}</AlertDescription>
          </Alert>
          <CloseButton
            size="sm"
            onClick={() => toast.closeAll()}
            position="absolute"
            zIndex={10}
            insetEnd={1}
            top={2}
            right={2}
          />
        </>
      ),
    });
  };

  return (
    <Box
      w="full"
      position={"fixed"}
      bg="#F0F4F8"
      h='100vh'
      overflow="-moz-hidden-unscrollable"
    >
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Box>
          <Navbar
            onOpen={onOpen}
            logoText={"Grid Gamers"}
            brandText={getActiveRoute(routes)}
            secondary={getActiveNavbar(routes)}
            message={getActiveNavbarText(routes)}
            fixed={fixed}
            navSize={navSize}
            changeNavSize={changeNavSize}
            {...rest}
          />
        </Box>
        <Flex>
          <Box  position={{sm:"absolute", xl:"relative"}} zIndex={1}>
            <SidebarMenu
              routes={routes}
              navSize={navSize}
              changeNavSize={changeNavSize}
            />
          </Box>
          <Box w="full">
            <Box
              // bg="red"
              overflowX={"hidden"}
              transitionTimingFunction="linear, linear, ease"
              transition="all 0s cubic-bezier(0.685, 0.0473, 0.346, 1)"
              transitionDuration=".2s, .2s, .35s"
              transitionProperty="top, bottom, width"
              height="100%"
              w={{sm:"calc(95%)", xl:navSize == "small" ? "calc(95%)" : "calc(95%)"}}
              maxWidth={{
                '2xl':navSize == "small" ? "calc(95%)" : "calc(85%)", 
                xl:navSize == "small" ? "calc(95%)" : "calc(82%)",
                sm:navSize == "small" ? "calc(95%)" : "calc(78%)",
              }}
              p={{ base: "20px", md: "30px" }}
              pe="20px"
              minH="100vh"
              pt="50px"
              overflowY="auto"
              position="fixed"
              right={0}
            >
              <Box>
                <AppRoutes />
              </Box>
            </Box>
            <Box>
              <Footer />
            </Box>
          </Box>
        </Flex>
      </SidebarContext.Provider>
    </Box>
  );
}
