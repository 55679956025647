import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
export function SearchBar(props: {
  variant?: string;
  background?: string;
  children?: JSX.Element;
  placeholder?: string;
  borderRadius?: string | number;
  onChange?: any;
  value?: string | number;
  onSearch?: Function;
  isLoading?: boolean;
  showClear?: boolean;
  [x: string]: any;
}) {
  // Pass the computed styles into the `__css` prop
  const {
    variant,
    background,
    children,
    placeholder,
    borderRadius,
    onChange,
    onSearch,
    value,
    isLoading,
    showClear,
    ...rest
  } = props;
  // Chakra Color Mode
  const searchIconColor = useColorModeValue("gray.700", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  return (
    <InputGroup w={{ base: "100%", md: "200px" }} {...rest}>
      <InputRightElement
        children={
          <>
            {isLoading === true ? (
              <Spinner
                thickness="2px"
                speed="1s"
                emptyColor="gray.200"
                color="blue.500"
                size="md"
              />
            ) : (
              <IconButton
                onClick={() => onSearch()}
                aria-label="search"
                bg="inherit"
                borderRadius="inherit"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
                icon={
                  showClear ? (
                    <CloseIcon color={searchIconColor} w="15px" h="15px" />
                  ) : (
                    <SearchIcon color={searchIconColor} w="15px" h="15px" />
                  )
                }
              />
            )}
          </>
        }
      />
      <Input
        value={value}
        onChange={onChange}
        variant="search"
        fontSize="sm"
        bg={background ? background : inputBg}
        color={inputText}
        fontWeight="500"
        _placeholder={{ color: "gray.400", fontSize: "14px" }}
        borderRadius={borderRadius ? borderRadius : "30px"}
        placeholder={placeholder ? placeholder : "Search..."}
      />
    </InputGroup>
  );
}
