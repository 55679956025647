import AdminsTableLists from "./slices/AdminsTableLists";
import AuthReducer from "./slices/Auth";
import EventReduces from "./slices/Events";
import TicketingReduces from "./slices/Ticketing";


import { AnyAction, Reducer } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { RootState } from "./store";
const appReducer = combineReducers({
  AdminsTableLists: AdminsTableLists,
  Auth: AuthReducer,
  Events: EventReduces,
  Ticketing:TicketingReduces
});

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "AuthSlice/clearResults") {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem("persist:root");

    state = {} as RootState;
  }
  return appReducer(state, action);
};
