import React, { memo } from "react";
// Chakra imports
import { Flex, Image, Text, HStack, Avatar } from "@chakra-ui/react";
import Card from "components/card/Card";
import avatar from "assets/img/global/Ellipse117.png";
import leaderBoardLoader from "assets/img/global/leaderBoard.png";

interface Props {
  leaderBoard: Array<any>;
  isgroupLeaderBoard?: boolean;
}
function Leaderboard(props: Props) {
  const { leaderBoard,isgroupLeaderBoard } = props;
  return (
    <Card px="20px" mt="16px" minH="160px" boxShadow={"none"} p="16px" border="1px solid #ECF1F4">
      <Text color={"black"} fontWeight="600" fontSize={"20px"}>
        {isgroupLeaderBoard===true? "Group Leaderboard":"Final Leaderboard"}
      </Text>
      <Flex
        mt="20px"
        alignItems="flex-start"
        direction={"column"}
        justifyContent="flex-start"
        h="140px"
        flexWrap="wrap"
        overflow={"hidden"}
      >
        {[...leaderBoard, ...[0, 0, 0, 0, 0, 0, 0, 0, 0]].map((x) => {
          if (x === 0) {
            return <Image m="5px" src={leaderBoardLoader} />;
          }
          return (
            <HStack
              key={x.playerId}
              // bg="#31D0AA"
              maxWidth={"200px"}
              m="5px"
              justifyContent={"flex-start"}
              alignItems="flex-start"
            >
              <Flex
                bg="#31D0AA"
                w="22px"
                h="22px"
                justifyContent={"center"}
                alignItems="center"
                borderRadius={"20px"}
              >
                <Text fontSize={"12px"} color={"white"}>
                  {x.winsCount}
                </Text>
              </Flex>
              <Avatar src={avatar} w="27px" h="27px" />
              <Text noOfLines={1} fontSize={"14px"} color={"blue.200"}>
                {x.playerName}
              </Text>
            </HStack>
          );
        })}
      </Flex>
    </Card>
  );
}

export default memo(Leaderboard);
