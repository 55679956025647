// Chakra imports
import react, { useEffect, useRef, useState } from "react";
import fileDownload from "js-file-download";
import { Icon } from "@chakra-ui/react";
import { AiFillEye } from "react-icons/ai";

import {
  Flex,
  Text,
  Input,
  FormLabel,
  useColorModeValue,
  Button,
  FormErrorMessage,
  FormControl,
  useToast,
  Box,
} from "@chakra-ui/react";
import {
  AsyncCreatableSelect,
  AsyncSelect,
  CreatableSelect,
  Select,
} from "chakra-react-select";
import moment from "moment";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import { post, get } from "redux/services/api";
import { hubRootUrl } from "utilities/constants";
import { gethubDocList } from "redux/slices/AdminsTableLists";
import DocumentTable from "./DocumentTable";
const documentDropDown = [
  {
    label: "Business Document EIN",
    value: "Business Document EIN",
  },
  {
    label: "Retail Address Confirmation",
    value: "Retail Address Confirmation",
  },
  {
    label: "License/Passport",
    value: "License/Passport",
  },
  {
    label: "Retail Safety Document",
    value: "Retail Safety Document",
  },
  {
    label: "Social Pages",
    value: "Social Pages",
  },
];
export default function Information() {
  const dispatch: any = useDispatch();
  const toast = useToast();
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("black.50", "white");
  const { userId } = useSelector((state: any) => state.Auth);
  const { hubDocsList } = useSelector((state: any) => state.AdminsTableLists);

  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const [isLoading, setisLoading] = useState(false);
  const [hubDocs, setHubDocs] = useState([]);

  const [selectedDoc, setSelectedDoc] = useState<any>(null);
  const [invalidState, setInvalidState] = useState({
    docTypeInvalid: false,
    expirationInvalid: false,
    docInvalid: false,
  });
  const [state, setState] = useState({
    HubId: userId,
    DocumentType: "",
    File: null,
    Expiration: null,
  });

  useEffect(() => {
    dispatch(gethubDocList({ userId }));
  }, []);
  useEffect(() => {
    if (hubDocsList?.documents) {
      setHubDocs(hubDocsList.documents);
    }
  }, [hubDocsList]);

  const updateState = (val: string, prop: string) => {
    setState((prvState: any) => ({ ...prvState, [prop]: val }));
  };
  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    // console.log({ fileUploaded });
    setState((prvState) => ({
      ...prvState,
      File: fileUploaded,
    }));
  };
  const checks = () => {
    console.log({ state });
    if (selectedDoc === null) {
      setInvalidState((prvState) => ({
        ...prvState,
        docTypeInvalid: true,
      }));

      return false;
    } else if (state.Expiration === null) {
      setInvalidState((prvState) => ({
        ...prvState,
        expirationInvalid: true,
      }));
      return false;
    } else if (state.File === null) {
      setInvalidState((prvState) => ({
        ...prvState,
        docInvalid: true,
      }));
      return false;
    }
    return true;
  };
  const save = async () => {
    if (checks()) {
      setisLoading(true);
      let data: any = {
        ...state,
        DocumentType: selectedDoc.value,
        Expiration: state.Expiration,
      };
      let formdata = new FormData();
      for (var key in data) {
        console.log({ key, data: data[key] });
        formdata.append(key, data[key]);
      }
      console.log({ formdata });

      let payload = {
        url: `${hubRootUrl}api/v1/hub/upload-hub-document`,
        data: formdata,
      };
      let response = await post(payload);
      console.log({ response });
      setisLoading(false);
      dispatch(gethubDocList({ userId }));

      if (response.data.statusCode === 200) {
        setState({
          HubId: userId,
          DocumentType: "",
          File: null,
          Expiration: new Date(),
        });
        toast.closeAll()
        toast({
          title: response.data.message,
          description: response.data.errorMessage,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast.closeAll()
        toast({
          title: response.data.message,
          description: response.data.errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
   
  return (
    <>
      <Card shadow={"lg"} borderRadius={"8px"} p="40px">
        <Text fontSize={"36px"} fontWeight="bold" color="#1C2A5B">
          Documents
        </Text>
        <Flex justifyContent={"space-between"}>
          <FormControl width={"30%"} isInvalid={invalidState.docTypeInvalid}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Document Type<Text color={brandStars}>*</Text>
            </FormLabel>
            <Select
              colorScheme="purple"
              options={documentDropDown}
              onChange={setSelectedDoc}
              value={selectedDoc}
            />
            {invalidState.docTypeInvalid && (
              <FormErrorMessage>Please select Document Type.</FormErrorMessage>
            )}
          </FormControl>

          <FormControl width={"30%"} isInvalid={invalidState.expirationInvalid}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Expiration Date<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              onChange={(e) => updateState(e.target.value, "Expiration")}
              value={state.Expiration}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="date"
              borderRadius={8}
              placeholder="Name"
              fontWeight="500"
              size="lg"
            />
            {invalidState.expirationInvalid && (
              <FormErrorMessage>
                Please select expiration date.
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl width={"30%"} isInvalid={invalidState.docInvalid}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Attached Document<Text color={brandStars}>*</Text>
            </FormLabel>
            <Button
              onClick={handleClick}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="40px"
              ms={{ base: "0px", md: "0px" }}
              borderRadius={8}
            >
              <Text noOfLines={1}>
                {state.File ? state.File.name : "Chose"}
              </Text>
            </Button>
            {invalidState.docInvalid && (
              <FormErrorMessage>Please upload document.</FormErrorMessage>
            )}
            <input
              accept="*"
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
          </FormControl>
        </Flex>
        <Flex w="full" justifyContent={"flex-end"} alignItems="flex-end">
          <Button
            isLoading={isLoading}
            onClick={save}
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="30%"
            h="40px"
            float={"left"}
            mt="24px"
            ms={{ base: "0px", md: "0px" }}
            borderRadius={8}
          >
            Save
          </Button>
        </Flex>

        <Flex mt="48px" px="19px" justifyContent={"space-between"}>
          <Box w="20%">
            <Text color={"#38456F"} fontSize="12px" fontWeight={"500"}>
              Document Title
            </Text>
          </Box>
          <Box w="10%">
            <Text color={"#38456F"} fontSize="12px" fontWeight={"500"}>
              Approval
            </Text>
          </Box>
          <Box w="10%">
            <Text color={"#38456F"} fontSize="12px" fontWeight={"500"}>
              Expiration
            </Text>
          </Box>
          <Box w="10%">
            <Text color={"#38456F"} fontSize="12px" fontWeight={"500"}>
              View
            </Text>
          </Box>
          <Box w="50%">
            <Text color={"#38456F"} fontSize="12px" fontWeight={"500"}>
              Feedback Comments
            </Text>
          </Box>
        </Flex>
        <Box
          maxH="50vh"
          overflowY={"scroll"}
          css={{
            "&::-webkit-scrollbar": {
              width: "10px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#D2D2D2",
              borderRadius: "24px",
            },
          }}
        >
          {hubDocs.map((doc: any) => {
            // console.log({ doc });
            return (
              <Flex
                key={doc.docId}
                mt="15px"
                bg="#F0F4F8"
                p="19px"
                borderRadius={"4px"}
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Box w="20%">
                  <Text color={"#38456F"} fontSize="14px" fontWeight={"500"}>
                    {doc.documentType}
                  </Text>
                </Box>
                <Box w="10%">
                  <Text color={"#38456F"} fontSize="14px" fontWeight={"500"}>
                    {doc.status}
                  </Text>
                </Box>
                <Box w="10%">
                  <Text color={"#38456F"} fontSize="14px" fontWeight={"500"}>
                    {moment(doc.expiration).format("DD/MM/yy")}
                  </Text>
                </Box>

                <Box w="10%">
                  
                  <Flex
                    cursor={"pointer"}
                    // onClick={() => DownloadFile(doc)}
                    onClick={() =>
                      window.open(doc.documentPath, "_blank", "noopener,noreferrer")
                    }
                    bg="#19BAEE4D"
                    p="10px"
                    w="50px"
                    borderRadius={"50px"}
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    <Icon
                      as={AiFillEye}
                      width="20px"
                      height="20px"
                      color="#298BE2"
                    />
                  </Flex>
                </Box>
                <Box w="50%">
                  <Text color={"#38456F"} fontSize="14px" fontWeight={"500"}>
                    {doc.feedBackComment}
                  </Text>
                </Box>
              </Flex>
            );
          })}
        </Box>
      </Card>
    </>
  );
}
