/* eslint-disable */

import React, { useCallback, useEffect, useState } from "react";
import { rootUrl } from "utilities/constants";
import { useToast, Box, Text, Flex, HStack, Image } from "@chakra-ui/react";
// Custom components
import { Authenticate } from "redux/slices/Auth";
import { useDispatch, useSelector } from "react-redux";
import Card from "components/card/Card";
import {
  getPrivacyPolicy,
  getTermsConditions,
} from "redux/slices/AdminsTableLists";
import logo from "assets/img/global/logo.png";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";

import Profile from "./Profile";
import { get } from "redux/services/api";

function SignUp(props:any) {
  console.log({props})
  const { userName, password,userId } = (props.location && props.location.state) || {};

  const dispatch: any = useDispatch();
  // const { userId, userName, email, phoneNumer, token } = useSelector(
  //   (state: any) => state.Auth
  // );

  const toast = useToast();
  const [steps, setStep] = useState(4);
  const [erorrMessage, SetErorrMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    LastName: "",
    CompanyName: "",
    MobileNumber: "",
    IsHub: true,
    BusinessEINNumber: "",
    UserName: "",
    SSNNumber: "",
    EmailAddress: "",
    Documents: "",
    FirstName: "",
    ConfirmPassword: "",
    Password: "",
  });
  useEffect(() => {
    dispatch(getPrivacyPolicy());
    dispatch(getTermsConditions());
  }, []);
  const handleNext: any = useCallback(
    (updatedState: any) => {
      setState((prvState) => ({ ...prvState, ...updatedState }));
      if (steps === 1) {
        // console.log({updated:{ ...state,...updatedState,}});
        createAccount({ ...state, ...updatedState });
      } else if (steps === 4) {
        getAccessToken();
      } else {
        setStep(steps + 1);
      }
    },
    [steps]
  );

  const getAccessToken = () => {
    let payload = {
      userName: state.UserName,
      password: state.Password,
      fcmToken: "",
      isSocialLogin: false,
      isFacebook: true,
      isGoogle: true,
      isAppleLogin: true,
      isFacialLoginAllowed: true,
    };
    dispatch(Authenticate(payload));
  };
  const createAccount = (payload: any) => {
    setIsLoading(true);
    var myHeaders = new Headers();
    var formdata = new FormData();
    myHeaders.append("accept", "*/*");
    for (var key in payload) {
      if (key != "Documents") {
        formdata.append(key, payload[key]);
      }
    }

    let files = payload?.Documents?.file;
    if (files) {
      for (const file of files) {
        formdata.append("Documents", file);
      }
    }

    // console.log({ type: typeof payload.Documents.file });
    var requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${rootUrl}api/v1/account/hub-signup`, requestOptions)
      .then((response) => response.text())
      .then((result: any) => {
        setIsLoading(false);
        result = JSON.parse(result);
        if (result.status === false) {
          SetErorrMessage(result.errorMessage);
        } else {
          toast.closeAll()
          toast({
            title: "Account created.",
            description: "We've created your account for you.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setStep(3);
          sendVarificationCode(payload);
        }
        console.log({ result });
      })
      .catch((error) => console.log("error", error));
  };
  const sendVarificationCode = async (payload: any) => {
    let url = `${rootUrl}api/v1/account/send-accesscode-for-signup?username=${payload.EmailAddress}&IsSms=true&IsEmail=true&IsAppleLogin=false&accountType=hub`;
    await get(url);
  };
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Box>
        <Box w={"full"} height="80px">
          <Box px={{ "2xl": "120px" }} maxW="1440px" m="auto">
            <Image src={logo} alt="logo " w={"107px"} h={"50px"} />
          </Box>
        </Box>
        <Flex justifyContent="center" alignItems={"center"} m="auto" w="100%">
          <Profile next={handleNext} userName={userName}  userId={userId} password ={password} />
        </Flex>
      </Box>
    </DefaultAuth>
  );
}

export default SignUp;
