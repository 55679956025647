import { memo, useState, useEffect } from "react";
import "../../../../../assets/css/App.css";
import {
  ModalBody,
  Heading,
  useColorModeValue,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Button,
  Input,
  Textarea,
  RadioGroup,
  Stack,
  ModalFooter,
  HStack,
  FormErrorMessage,
  Select,
  Checkbox,
  Switch,
} from "@chakra-ui/react";

interface Props {
  next: Function;
  parantState: any;
  handleBack: () => void;
}

function Step_3(props: Props) {
  const { next, handleBack, parantState } = props;
  const textColor = useColorModeValue("black.50", "white");
  const textColorSecondary = "gray.400";
  const buttonColorBrand = "blue.200";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [state, setState] = useState({
    tournament_Name: "",
    tournament_Description: "",
    number_of_participants: "",

    require_team_registration: "false",
  });
  const [invalidState, setInvalidState] = useState({
    tournament_NameInvalid: false,
    tournament_DescriptionInvalid: false,
    number_of_participantsInvalid: false,
  });
  const [togglePlayer, setTogglePlayer] = useState(false)
  const maxPlayers = (data: any) =>{
    setTogglePlayer(data?.target?.checked)
  };

  useEffect(()=>{
      if(parantState.number_of_participants){
        setTogglePlayer(!togglePlayer)
      }
  },[])

  const updateState = (val: any, prop: string, invalidProp?: string) => {
    setState((prvState: any) => ({ ...prvState, [prop]: val }));
    if (invalidProp) {
      setInvalidState((prvState: any) => ({
        ...prvState,
        [invalidProp]: false,
      }));
    }
  };
  useEffect(() => {
    if (parantState.tournament_Name) {
      setState((prvState) => ({ ...parantState }));
    }
  }, [parantState]);
  const checks = () => {
    if (state.tournament_Name === "") {
      setInvalidState((prvState) => ({
        ...prvState,
        tournament_NameInvalid: true,
      }));
      return false;
    } 
    
    // else if (
    //   state.number_of_participants === "0" ||
    //   state.number_of_participants === ""
    // ) {
    //   setInvalidState((prvState) => ({
    //     ...prvState,
    //     number_of_participantsInvalid: true,
    //   }));
    //   return false;
    // }
    
    else if (state.tournament_Description.length < 30) {
      setInvalidState((prvState) => ({
        ...prvState,
        tournament_DescriptionInvalid: true,
      }));
      return false;
    }
    return true;
  };
  const handleNext = () => {
    if (checks()) {
      next(state);
    }
  };
  return (
    <>
      <ModalBody
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
            
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#1C2A5B",
            borderRadius: "24px",
          },
        }}
        position={"relative"}
        px="50px"
      >
        <Heading color={textColor} fontSize="36px" w={400} mb="10px">
          Information
        </Heading>
        <HStack>
          <FormControl
            h="100px"
            isInvalid={invalidState.tournament_NameInvalid}
          >
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              Enter Event Name<Text color={brandStars}> *</Text>
            </FormLabel>
            <Input
              borderColor={invalidState.tournament_NameInvalid ? "red" : null}
              onChange={(e) =>
                updateState(
                  e.target.value,
                  "tournament_Name",
                  "tournament_NameInvalid"
                )
              }
              value={state.tournament_Name}
              isRequired={true}
              variant="auth"
              placeholder="e.g Friendly Match"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              borderRadius={8}
              // mb="10px"
              fontWeight="500"
              size="lg"
            />
            {invalidState.tournament_NameInvalid && (
              <FormErrorMessage> Event name is required.</FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            isInvalid={invalidState.number_of_participantsInvalid}
            h="100px"
          >
            <div style={{display:"flex"}}>
            <FormLabel
              display="flex"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              Max # of Participants
            </FormLabel>
            <Switch
                onChange={(e:any) => maxPlayers(e)}
                isChecked={togglePlayer}
                colorScheme={"brand"}
                w="50px"
                id=""
              />
            </div>
            { togglePlayer  && (
                <Input
                  minH="48px"
                  onChange={(e) =>
                    updateState(e.target.value, "number_of_participants")
                  }
                  borderColor={
                    invalidState.number_of_participantsInvalid ? "red" : null
                  }
                  value={state.number_of_participants}
                  isRequired={true}
                  variant="auth"
                  placeholder="e.g 5"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="number"
                  borderRadius={8}
                  // mb="10px"
                  fontWeight="500"
                  size="lg"
                />)
              }
            {invalidState.number_of_participantsInvalid && (
              <FormErrorMessage>
                Number of participants must be greater 0
              </FormErrorMessage>
            )}
          </FormControl>
        </HStack>
        <FormControl
          mt="10px"
          isInvalid={invalidState.tournament_DescriptionInvalid}
        >
          <FormLabel
            display="flex"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
          >
            Description<Text color={brandStars}>*</Text>
          </FormLabel>
          <Textarea
            minH="150px"
            borderColor={
              invalidState.tournament_DescriptionInvalid ? "red" : null
            }
            onChange={(e) =>
              updateState(
                e.target.value,
                "tournament_Description",
                "tournament_DescriptionInvalid"
              )
            }
            value={state.tournament_Description}
            isRequired={true}
            placeholder="Type Here..."
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            borderRadius={8}
            fontWeight="500"
            size="lg"
          />

          {invalidState.tournament_DescriptionInvalid && (
            <FormErrorMessage>
              {" "}
              Description is required (minimum 30 words).
            </FormErrorMessage>
          )}
          <Flex justifyContent={"flex-end"}>
            <Text fontSize={"15px"} color={textColorSecondary}>
              {state.tournament_Description.length}/500 characters
            </Text>
          </Flex>
        </FormControl>

        <FormControl maxW={"60%"}>
          <Checkbox
            isChecked={state.require_team_registration==="true"}
            onChange={(e) =>
              // setState([e.target.checked, e.target.checked])
              updateState(e.target.checked===true?"true":"false", "require_team_registration")
            }
            py={"10px"}
            size="md"
          >
            Team(s) Event Only
          </Checkbox>
        </FormControl>
      </ModalBody>

      <ModalFooter px="50px">
        <Flex w="full" justifyContent={"space-between"}>
          <Button
            onClick={handleBack}
            fontSize="sm"
            variant="brand"
            fontWeight="700"
            w="276px"
            h="50"
            bg="transparent"
            borderRadius={8}
            border={`1px solid `}
            color={"black"}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
            borderColor={"black"}
          >
            Back
          </Button>

          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="276px"
            h="50"
            onClick={() => handleNext()}
            borderRadius={8}
            bg={buttonColorBrand}
          >
            Continue
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
}
export default memo(Step_3);
