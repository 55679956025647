import React, { useState, memo, useEffect } from "react";
// Chakra imports
import {
  Input,
  Grid,
  Flex,
  Image,
  Text,
  GridItem,
  Checkbox,
  Box,
  useDisclosure,
  HStack,
  Button,
  Avatar,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  FormControl,
  FormLabel,
  useColorModeValue,
  useToast,
  Select,
} from "@chakra-ui/react";
import { Table, Pagination, Input as InputAntd } from "antd";
import { useUser } from "../../UsersList/hooks";
import { CiEdit } from "react-icons/ci";
import { BsTrash3Fill } from "react-icons/bs";
import { mode } from "@chakra-ui/theme-tools";
import { HiUserGroup } from "react-icons/hi2";
import { FiPlus } from "react-icons/fi";
import { MdOutlineFileUpload } from "react-icons/md";
import { RxFile } from "react-icons/rx";
import { BsEmojiSmile } from "react-icons/bs";
import { HiOutlineGlobe } from "react-icons/hi";
import EventBracketConfirmation from "views/admin/bracketPortal/components/EventBracketConfirmation";
import { Spin } from "antd";
import Card from "components/card/Card";
import EventConfiguration from "views/admin/bracketPortal/components/EventConfiguration";
import { eventRootUrl } from "utilities/constants";
import { get, post } from "redux/services/api";
import { Skeleton } from "@chakra-ui/react";
const { Search } = InputAntd;
interface Props {
  tabIndex: number;
  handleTabsChange: any;
}

function EventCheckIn(props: any) {
  const toast = useToast();
  const hiddenFileInput = React.useRef(null);

  const textColor = useColorModeValue("black.50", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { activeId, onChangeNavigation } = props;
  const { getEventDetail } = props;
  console.log({getEventDetail, props})
  const {
    registerPlayers,
    id,
    challonge_Tournament_Id,
    isRegistrationClosed,
    eventGames,
    require_team_registration,
  } = props.state;
  const [checkedInArr, SetCheckedInArr] = useState([]);
  const [bulkPlayerArr, SetbulkPlayerArr] = useState([]);
  console.log("🚀 ~ EventCheckIn ~ bulkPlayerArrasdfasdfadfddfasdf:", bulkPlayerArr)
  const [isEditPlayerIndex, SetisEditPlayerIndex] = useState(-1);
  const [isTeamsEvent, SetisTeamsEvent] = useState(true);
  const [teamsArr, SetteamsArr] = useState([]);
  console.log("🚀 ~ EventCheckIn ~ teamsArr:", teamsArr)
  const [AddteamsSteps, SetAddteamsSteps] = useState(0);
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(-1);

  const [file, setFile] = useState(null);
  const [registerPlayersArr, SetRegisterPlayersArr] = useState([]);
  const [CheckInloadingIndex, SetCheckInloadingIndex] = useState(null);
  const [CheckOutloadingIndex, SetCheckOutloadingIndex] = useState(null);
  const [waitlistPlayer, SetwaitlistPlayer] = useState([]);
  const [selectetdwaitlistPlayer, SetselectetdwaitlistPlayer] = useState([]);

  const [teamName, setTeamName] = useState<any>(null);
  const [state, setState] = useState<any>({
    LastName: "",
    UserName: "",
    EmailAddress: "",
    FirstName: "",
    groupName: "",
    captainId: null,
  });
  const totalParticipants = checkedInArr.length + bulkPlayerArr.length + registerPlayersArr.length;
  const totalTeams = checkedInArr.length + teamsArr.length + registerPlayersArr.length;
  const [invalidState, setInvalidState] = useState({
    LastNameInvalid: false,
    FirstNameInvalid: false,
    EmailAddressInvalid: false,
    UserNameInvalid: false,
    teamNameInvalid: false,
    GroupNameInvalid: false,
    selectedCaptinInvalid: false,
  });
  const [isLoading, setIsloading] = useState(false);
  const [addPlayer, setAddPlayer] = useState(true);

  const [closeRegis, setCloseRegistration] = useState(false);
  const [activeTab, setActiveTab] = useState(1)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenAddPlayer,
    onOpen: onOpenAddPlayer,
    onClose: onCloseAddPlayer,
  } = useDisclosure();
  const {
    isOpen: isOpenAddTeams,
    onOpen: onOpenAddTeams,
    onClose: onCloseAddTeams,
  } = useDisclosure();
  const {
    isOpen: isOpenCraetGroup,
    onOpen: onOpenCraetGroup,
    onClose: onCloseCraetGroup,
  } = useDisclosure();
  const [search, setSearch] = useState({
    page: 1,
    per_page: 100,
    search: "",
  });
  const {
    data,
    isLoading: isLoadingUsers,
    isSuccess,
    isError,
    error,
    isFetching,
  } = useUser({
    PageNumber: 1,
    PageSize: 50,
    search: "",
  });
  const { data: searchData, isLoading: searching } = useUser({
    PageNumber: search.page,
    PageSize: search.per_page,
    search: search.search,
  });
  const onSearch = (value: string) => {
    const name = value?.toString() || "";
    console.log({ value });
    setSearch({
      ...search,
      search: name,
      page: 1,
    });
  };
  const userTransactions = data?.response?.userList || [];
  const searchDataTransactions = searchData?.response?.userList || [];
  const updateState = (val: string, prop: string, invalidProp?: string) => {
    setState((prvState: any) => ({ ...prvState, [prop]: val }));
    setInvalidState((prvState: any) => ({ ...prvState, [invalidProp]: false }));
  };
  useEffect(() => {
    // SetRegisterPlayersArr(registerPlayers);
    getCheckedInUser();
    getCheckedOutUser();
    getPlayerWaitlist();
  }, [registerPlayers]);

  const checkInUser = async (user: any, index: number, flag: boolean) => {
    if (flag) {
      SetCheckInloadingIndex(index);
    } else {
      SetCheckOutloadingIndex(index);
    }

    let url = `${eventRootUrl}api/v1/challonge/tournament-participants-checkin?TournamentId=${id}&UserId=${user.userId}&IsCheckIn=${flag}`;
    const response: any = await get(url);

    if (response.data.status === true) {
      getCheckedInUser();
      getCheckedOutUser();

      SetCheckInloadingIndex(null);
      SetCheckOutloadingIndex(null);
    }
  };
  const deleteBulkPlayer = (i: number) => {
    let arr = [...bulkPlayerArr];
    arr.splice(i, 1);
    SetbulkPlayerArr(arr);
  };
  const editBulkPlayer = (data: any, i: number) => {
    SetisEditPlayerIndex(i);
    setState({
      UserName: data.userName,
      FirstName: data.firstName,
      LastName: data.lastName,
      EmailAddress: data.emailAddress,
    });
  };
  const editTeamsPlayer = (data: any, i: number) => {
    setSelectedTeamIndex(i);
    let arr = [...teamsArr];
    let teamName = arr[i].guestTeam.teamName;
    let usersRequest = arr[i].usersRequest;
    setTeamName(teamName);
    SetbulkPlayerArr(usersRequest);
    SetAddteamsSteps(0);
  };
  const deleteTeams = (i: number) => {
    let arr = [...teamsArr];
    arr.splice(i, 1);
    SetteamsArr(arr);
  };
  const addBulkPlayer = () => {
    if (state.UserName === "") {
      setInvalidState((prvState: any) => ({
        ...prvState,
        UserNameInvalid: true,
      }));
      return;
    }
    let arr = [...bulkPlayerArr];
    if (isEditPlayerIndex > -1) {
      SetisEditPlayerIndex(-1);
      arr[isEditPlayerIndex] = {
        userName: state.UserName,
        firstName: state.FirstName,
        lastName: state.LastName,
        emailAddress: state.EmailAddress,
      };
    } else {
      arr.push({
        userName: state.UserName,
        firstName: state.FirstName,
        lastName: state.LastName,
        emailAddress: state.EmailAddress,
      });
    }

    SetbulkPlayerArr(arr);

    setState({
      UserName: "",
      FirstName: "",
      LastName: "",
      EmailAddress: "",
    });
  };
  const getCheckedInUser = async () => {
    setIsloading(true);
    // /challonge/tournament-participants?TournamentId=149&IsCheckedIn=false
    let url = `${eventRootUrl}api/v1/challonge/tournament-participants?TournamentId=${id}&IsCheckedIn=true`;
    const response: any = await get(url);
    setIsloading(false);
    console.log({ response: response.data.response.participantList });
    SetCheckedInArr(response.data.response.participantList);
  };
  const getCheckedOutUser = async () => {
    setIsloading(true);
    // /challonge/tournament-participants?TournamentId=149&IsCheckedIn=false
    let url = `${eventRootUrl}api/v1/challonge/tournament-participants?TournamentId=${id}&IsCheckedIn=false`;
    const response: any = await get(url);
    setIsloading(false);
    SetRegisterPlayersArr(response.data.response.participantList);
    console.log({ response: response.data.response.participantList });
  };
  const closeRegistration = async () => {
    onOpen();
  };

  const SaveParticipants = async () => {
    if(props?.state?.number_of_participants != "" && (Number(props?.state?.number_of_participants) < totalParticipants)){
        setIsloading(false)
        toast({
          title: "Max number of players",
          description: "The provided limit of maximum participants is exceeding.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    setActiveTab(1)
    setIsloading(true);

    let payload: any = {
      url: `${eventRootUrl}api/v1/challonge/publish-tournament-bulk-players`,
      data: {
        tournamentId: id,
        guestTeamPlayersRequests: [
          {
            gameId: eventGames[0].gameId,
            guestTeam: {
              teamName: null,
              emailAddress: null,
            },
            usersRequest: bulkPlayerArr,
          },
        ],
      },
    };
    try {
      let response = await post(payload);
      // setSelectedEvent(null);

      if (response.data.statusCode === 200) {
        onCloseAddPlayer();
        setIsloading(false);
        getCheckedInUser();
        getCheckedOutUser();
        toast.closeAll();
        toast({
          title: response.data.message,
          description: response.data.errorMessage,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        SetbulkPlayerArr([])
      } else {
        setIsloading(false);
        toast.closeAll();
        toast({
          title: response.data.message,
          description: response.data.errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (err) {
      console.log({ err });
      setIsloading(false);
      toast.closeAll();
      toast({
        title: "Server error",
        description: "Server Error",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    console.log({ event: fileUploaded });
    setFile(fileUploaded);
  };
  const handleClickDocuments = () => {
    hiddenFileInput.current.click();
  };
  const UploadFile = async () => {
    let formdata = new FormData();
    formdata.append("file", file);
    let payload: any = {
      url: `${eventRootUrl}api/v1/challonge/upload`,
      data: formdata,
    };
    try {
      let response = await post(payload);
      // setSelectedEvent(null);

      if (response.data.statusCode === 200) {
        SetbulkPlayerArr(response.data.response[0].usersRequest);
        SetteamsArr(response.data.response);
        SetAddteamsSteps(2);
        setAddPlayer(!addPlayer);
        // onCloseAddPlayer();
        setIsloading(false);
        toast.closeAll();
        toast({
          title: response.data.message,
          description: response.data.errorMessage,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setIsloading(false);
        toast.closeAll();
        toast({
          title: response.data.message,
          description: response.data.errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (err) {
      console.log({ err });
      setIsloading(false);
      toast.closeAll();
      toast({
        title: "Server error",
        description: "Server Error",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const updateTeamsArr = (step: number) => {
    if (step === 0) {
      if (teamName === "") {
        setInvalidState((prvState) => ({ ...prvState, teamNameInvalid: true }));
      }
      if (selectedTeamIndex > -1) {
        let arr = [...teamsArr];
        arr[selectedTeamIndex].guestTeam.teamName = teamName;
        SetteamsArr(arr);
      }

      SetAddteamsSteps(step + 1);
    } else if (step === 1) {
      let arr = [...teamsArr];
      if (selectedTeamIndex > -1) {
        arr[selectedTeamIndex].usersRequest = bulkPlayerArr;
        SetteamsArr(arr);
        SetAddteamsSteps(step + 1);
        return;
      }

      let obj: any = {
        gameId: eventGames[0].gameId,
        guestTeam: {
          teamName: teamName,
          emailAddress: null,
        },
        usersRequest: bulkPlayerArr,
      };
      arr.push(obj);
      SetteamsArr(arr);

      SetAddteamsSteps(step + 1);
    }
  };
  const uploadTeams = async () => {
    if(props?.state?.number_of_participants != "" && (Number(props?.state?.number_of_participants) < totalTeams)){
      setIsloading(false)
      toast({
        title: "Max number of teams",
        description: "The provided limit of maximum participants is exceeding.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setActiveTab(1)
    setIsloading(true);

    let payload: any = {
      url: `${eventRootUrl}api/v1/challonge/publish-tournament-bulk-players`,
      data: {
        tournamentId: id,
        guestTeamPlayersRequests: teamsArr,
      },
    };
    try {
      let response = await post(payload);
      // setSelectedEvent(null);

      if (response.data.statusCode === 200) {
        onCloseAddTeams();
        setIsloading(false);
        toast.closeAll();
        getCheckedInUser();
        getCheckedOutUser();
        toast({
          title: response.data.message,
          description: response.data.errorMessage,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        SetteamsArr([])
      } else {
        setIsloading(false);
        toast.closeAll();
        toast({
          title: response.data.message,
          description: response.data.errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (err) {
      console.log({ err });
      setIsloading(false);
      toast.closeAll();
      toast({
        title: "Server error",
        description: "Server Error",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const getPlayerWaitlist = async () => {
    let url = `${eventRootUrl}api/v1/challonge/get-waitlist-players?tournamentId=${id}`;
    const response: any = await get(url);

    if (response.data.status === true) {
      let arr = response.data.response;
      SetwaitlistPlayer(arr);
    }
  };
  const updateWaitlistPlayer = (userId: number) => {
    let arr = [...selectetdwaitlistPlayer];
    if (arr.includes(userId)) {
      arr.splice(arr.indexOf(userId, 1));
    } else {
      arr.push(userId);
    }
    SetselectetdwaitlistPlayer(arr);
  };

  const createGroup = async () => {
    if (state.groupName === "") {
      setInvalidState((prev) => ({ ...prev, GroupNameInvalid: true }));
      return;
    } else if (state.captainId === null) {
      setInvalidState((prev) => ({ ...prev, selectedCaptinInvalid: true }));
      return;
    }

    setIsloading(true);

    let payload: any = {
      url: `${eventRootUrl}api/v1/challonge/add-group-players`,
      data: {
        tournamentId: id,
        captainId: state.captainId,
        userIds: selectetdwaitlistPlayer,
        groupName: state.groupName,
        // groupId: 65,
      },
    };
    try {
      let response = await post(payload);
      // setSelectedEvent(null);

      if (response.data.statusCode === 200) {
        onCloseCraetGroup();
        setIsloading(false);
        toast.closeAll();
        toast({
          title: response.data.message,
          description: response.data.errorMessage,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setIsloading(false);
        toast.closeAll();
        toast({
          title: response.data.message,
          description: response.data.errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (err) {
      console.log({ err });
      setIsloading(false);
      toast.closeAll();
      toast({
        title: "Server error",
        description: "Server Error",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const checkDisabledFun = () => {
    if(isRegistrationClosed) {
      return true
    }else if(registerPlayersArr.length != 0){
      return true
    }else if(checkedInArr.length == 0){
      return true
    }
    return false
  }
  return (
    <Box minH="90vh" p="24PX">
      <EventBracketConfirmation
        state={props.state}
        getEventDetail={getEventDetail}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onChangeNavigation={onChangeNavigation}
        number_of_participants={checkedInArr.length}
      />

      <Spin spinning={isLoading} tip="Loading...">
        <Flex justifyContent={"space-between"} w="full">
          <Tabs borderBottom={"0px"} w="full" index={activeTab} onChange={(e)=>setActiveTab(e)}>
            <TabList borderBottom={"0px"}>
              <Tab>Checked-In</Tab>
              <Tab>Remaining</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Flex flexWrap={"wrap"} w="full" mt="20px">
                  {checkedInArr.map((data: any, i: number) => {
                    return (
                      <Flex
                        w="370px"
                        mt="10px"
                        key={data.userId}
                        alignItems={"center"}
                        border={"1px solid #ECF1F4"}
                        borderRadius="12px"
                        p="10px"
                        ml="10px"
                      >
                        <HStack w="70%">
                          <Box>
                            <Avatar src={data.profilePicPath} size="md" />
                          </Box>
                          <Box ml="10px">
                            <Text fontWeight={"medium"} fontSize={"16px"}>
                              {data.userName}
                            </Text>
                            <Text fontSize={"12px"}>{data.title}</Text>
                          </Box>
                        </HStack>
                        <HStack justifyContent={"flex-end"} w="30%">
                          <Button
                            disabled={isRegistrationClosed}
                            isLoading={i === CheckOutloadingIndex}
                            loadingText={"Checking-in ..."}
                            onClick={() => checkInUser(data, i, false)}
                            variant="brand"
                            color="blue.200"
                            textDecorationLine={"underline"}
                            fontSize="12px"
                            fontWeight="500"
                            borderRadius="12px"
                            bg="transparent"
                            _hover={{ bg: "transparent" }}
                            px="18px"
                            _focus={{ bg: "transparent" }}
                            _active={{ bg: "transparent" }}
                            py="2px"
                          >
                            Remove
                          </Button>
                          {/* <MainMenu /> */}
                        </HStack>
                      </Flex>
                    );
                  })}
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex flexWrap={"wrap"} w="full" mt="20px">
                  {registerPlayersArr.map((data: any, i: number) => {
                    return (
                      <Flex
                        w="370px"
                        mt="10px"
                        key={data.userId}
                        alignItems={"center"}
                        border={"1px solid #ECF1F4"}
                        borderRadius="12px"
                        p="10px"
                        ml="10px"
                      >
                        <HStack w="70%">
                          <Box>
                            <Avatar src={data.profilePicPath} size="md" />
                          </Box>
                          <Box ml="10px">
                            <Text fontWeight={"medium"} fontSize={"16px"}>
                              {data.userName}
                            </Text>
                            <Text fontSize={"12px"}>{data.title}</Text>
                          </Box>
                        </HStack>
                        <HStack justifyContent={"flex-end"} w="30%">
                          <Button
                            disabled={isRegistrationClosed}
                            isLoading={i === CheckInloadingIndex}
                            loadingText={"Checking-in ..."}
                            onClick={() => checkInUser(data, i, true)}
                            variant="brand"
                            color="blue.200"
                            textDecorationLine={"underline"}
                            fontSize="12px"
                            fontWeight="500"
                            borderRadius="12px"
                            bg="transparent"
                            _hover={{ bg: "transparent" }}
                            _focus={{ bg: "transparent" }}
                            _active={{ bg: "transparent" }}
                            px="18px"
                            py="2px"
                          >
                            Check-In
                          </Button>
                          {/* <MainMenu /> */}
                        </HStack>
                      </Flex>
                    );
                  })}
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Flex justifyContent={"flex-end"}>
            <Button
              disabled={isRegistrationClosed}
              onClick={onOpenCraetGroup}
              leftIcon={<HiUserGroup />}
              variant="brand"
              color="blue.200"
              fontSize="14px"
              fontWeight="500"
              borderRadius="10px"
              mt="0px"
              px="12px"
              py="8px"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
              bg="transparent"
              maxH="147px"
              border="1px"
              borderColor="blue.200"
              borderStyle={"dashed"}

              // w="100%"
            >
              Create Groups
            </Button>
            <Button
              ml="10px"
              disabled={isRegistrationClosed}
              onClick={() => {
                SetisEditPlayerIndex(-1);
                setSelectedTeamIndex(-1);
                require_team_registration === "true"
                  ?  onOpenAddTeams()
                  : onOpenAddPlayer();
                
              }}
              leftIcon={<FiPlus />}
              variant="brand"
              color="blue.200"
              fontSize="14px"
              fontWeight="500"
              borderRadius="10px"
              mt="0px"
              px="12px"
              py="8px"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
              bg="transparent"
              maxH="147px"
              border="1px"
              borderColor="blue.200"
              borderStyle={"dashed"}

              // w="100%"
            >
              {require_team_registration === "true"
                ? "Add Teams"
                : "Add Participants"}
            </Button>
            <Button
              ml="10px"
              disabled={checkDisabledFun()}
              onClick={closeRegistration}
              variant="brand"
              color="white"
              fontSize="14px"
              fontWeight="500"
              borderRadius="10px"
              mt="0px"
              px="12px"
              py="8px"
              _hover={{ bg: "#F15B40" }}
              maxH="147px"
              bg="#F15B40"
              // w="100%"
            >
              Close Registration
            </Button>
          </Flex>
        </Flex>
      </Spin>
      <Modal
        isCentered
        isOpen={isOpenAddPlayer}
        size={"3xl"}
        onClose={onCloseAddPlayer}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {addPlayer === true ? "Add  Participants" : "Upload CSV"}

            <Flex w="full" mt="20px" bg="#F0F4F8" borderRadius={"100px"}>
              <Flex
                cursor={"pointer"}
                onClick={() => setAddPlayer(!addPlayer)}
                w="50%"
                justifyContent={"center"}
                borderRadius={"100px"}
                alignItems="center"
                padding={"10px"}
                bg={addPlayer === true ? "blue.200" : null}
              >
                <Text
                  color={addPlayer === true ? "white" : "#979C9E"}
                  fontSize="15px"
                  fontWeight={"500"}
                >
                  Add Manually
                </Text>
              </Flex>
              <Flex
                cursor={"pointer"}
                onClick={() => setAddPlayer(!addPlayer)}
                w="50%"
                justifyContent={"center"}
                borderRadius={"100px"}
                bg={addPlayer === false ? "blue.200" : null}
                alignItems="center"
                padding={"10px"}
              >
                <Text
                  color={addPlayer === false ? "white" : "#979C9E"}
                  fontSize="15px"
                  fontWeight={"500"}
                >
                  Upload CSV
                </Text>
              </Flex>
            </Flex>
          </ModalHeader>

          <ModalCloseButton />
          {addPlayer === true && (
            <>
              <ModalBody>
                <Flex w={"full"} justifyContent={"space-between"}>
                  <Box w="40%" position={"relative"}>
                    <FormControl
                      mt="30px"
                      isInvalid={invalidState.UserNameInvalid}
                    >
                      <Text fontSize={"20px"} fontWeight="500">
                        Add Participants
                      </Text>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                      >
                        User Name<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Input
                        borderColor={
                          invalidState.FirstNameInvalid ? "red" : null
                        }
                        onChange={(e) =>
                          updateState(
                            e.target.value,
                            "UserName",
                            "UserNameInvalid"
                          )
                        }
                        value={state.UserName}
                        isRequired={true}
                        placeholder="Enter Your First Name"
                        variant="auth"
                        fontSize="sm"
                        ms={{ base: "0px", md: "0px" }}
                        type="text"
                        borderRadius={8}
                        fontWeight="500"
                        // w="258px"
                        h={"46px"}
                        size="lg"
                      />
                      {invalidState.UserNameInvalid && (
                        <FormErrorMessage>
                          User name is required.
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl mt="10px">
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                      >
                        First Name (Optional)
                      </FormLabel>
                      <Input
                        onChange={(e) =>
                          updateState(e.target.value, "FirstName")
                        }
                        value={state.FirstName}
                        isRequired={true}
                        placeholder="Enter Your First Name"
                        variant="auth"
                        fontSize="sm"
                        ms={{ base: "0px", md: "0px" }}
                        type="text"
                        borderRadius={8}
                        fontWeight="500"
                        // w="258px"
                        h={"46px"}
                        size="lg"
                      />
                    </FormControl>
                    <FormControl mt="10px">
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                      >
                        Last Name (Optional)
                      </FormLabel>
                      <Input
                        onChange={(e) =>
                          updateState(e.target.value, "LastName")
                        }
                        value={state.LastName}
                        isRequired={true}
                        variant="auth"
                        fontSize="sm"
                        ms={{ base: "0px", md: "0px" }}
                        placeholder="Enter Your Last Name"
                        type="text"
                        borderRadius={8}
                        fontWeight="500"
                        size="lg"
                        h={"46px"}
                      />
                    </FormControl>

                    <FormControl mt="10px">
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                      >
                        Email (Optional)
                      </FormLabel>
                      <Input
                        onChange={(e) =>
                          updateState(e.target.value, "EmailAddress")
                        }
                        placeholder="Enter E-mail Address"
                        value={state.EmailAddress}
                        isRequired={true}
                        variant="auth"
                        fontSize="sm"
                        ms={{ base: "0px", md: "0px" }}
                        type="email"
                        borderRadius={8}
                        fontWeight="500"
                        size="lg"
                        h={"46px"}
                      />
                    </FormControl>
                    <Button
                      isLoading={false}
                      loadingText="Creating Account.."
                      fontSize="sm"
                      variant="brand"
                      fontWeight="500"
                      w="100%"
                      h="50"
                      my="10px"
                      borderRadius={8}
                      bg={"blue.200"}
                      onClick={addBulkPlayer}
                    >
                      {isEditPlayerIndex > -1
                        ? "Update  Participant"
                        : "Add  Participant"}
                    </Button>
                    <Box
                      w="1px"
                      bg="#9299B0"
                      h="100%"
                      top={0}
                      position={"absolute"}
                      right={"-10%"}
                    />
                  </Box>

                  <Box w="50%">
                    <Box
                      maxH="400px"
                      overflowX={"hidden"}
                      css={{
                        "&::-webkit-scrollbar": {
                          width: "4px",
                        },
                        "&::-webkit-scrollbar-track": {
                          width: "6px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "#1C2A5B",
                          borderRadius: "24px",
                        },
                      }}
                      overflowY={"auto"}
                    >
                      {bulkPlayerArr.map((data: any, i: number) => {
                        return (
                          <Flex
                            w="370px"
                            mt="10px"
                            key={data.userId}
                            alignItems={"center"}
                            borderBottom={"1px solid #ECF1F4"}
                            p="10px"
                          >
                            <HStack w="70%">
                              <Box>
                                <Avatar size="md" />
                              </Box>
                              <Box ml="10px">
                                <Text fontWeight={"medium"} fontSize={"16px"}>
                                  {data.userName}
                                </Text>
                                <Text fontSize={"12px"}>
                                  {" "}
                                  {data.firstName + " " + data.lastName}
                                </Text>
                                <Text fontSize={"12px"}>
                                  {data.emailAddress}
                                </Text>
                              </Box>
                            </HStack>
                            <HStack justifyContent={"flex-end"}>
                              <Flex
                                justifyContent={"center"}
                                bg="#298BE21A"
                                alignItems="center"
                                borderRadius={"30px"}
                                cursor={"pointer"}
                                w="30px"
                                p="8px"
                                height={"30px"}
                                onClick={() => editBulkPlayer(data, i)}
                              >
                                {" "}
                                <CiEdit color="#298BE2" size={"20px"} />
                              </Flex>

                              <Flex
                                p="8px"
                                justifyContent={"center"}
                                bg="#FF52471A"
                                alignItems="center"
                                borderRadius={"30px"}
                                cursor={"pointer"}
                                w="30px"
                                height={"30px"}
                                onClick={() => deleteBulkPlayer(i)}
                              >
                                {" "}
                                <BsTrash3Fill color="#FF5247" size={"16px"} />
                              </Flex>
                            </HStack>
                          </Flex>
                        );
                      })}
                    </Box>
                    {bulkPlayerArr.length > 0 && (
                      <Button
                        isLoading={isLoading}
                        loadingText="Saving Participants..."
                        fontSize="sm"
                        variant="brand"
                        fontWeight="500"
                        w="100%"
                        h="50"
                        my="10px"
                        borderRadius={8}
                        bg={"blue.200"}
                        onClick={SaveParticipants}
                      >
                        Save Participants
                      </Button>
                    )}
                  </Box>
                </Flex>
              </ModalBody>

              <ModalFooter>
                {/* <Button
                  isLoading={false}
                  loadingText="Creating Account.."
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  my="10px"
                  borderRadius={8}
                  bg={"blue.200"}
                  // onClick={createAccount}
                >
                  Save Players
                </Button> */}
              </ModalFooter>
            </>
          )}
          {addPlayer === false && (
            <>
              <ModalBody>
                <Flex
                  w="full"
                  direction={"column"}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    textAlign={"center"}
                  >
                    Browser / Drag & Drop File
                  </FormLabel>

                  <Flex
                    onClick={handleClickDocuments}
                    fontSize="sm"
                    fontWeight="500"
                    w="50%"
                    padding={"10px"}
                    borderRadius={"100px"}
                    bg={"transparent"}
                    border="1px solid"
                    color={"blue.200"}
                    borderColor={"blue.200"}
                    justifyContent="center"
                    alignItems={"center"}
                    // onClick={createAccount}
                    cursor="pointer"
                  >
                    <MdOutlineFileUpload color="#298BE2" />
                    <Text ml="10px">{file ? file.name : "Upload File"}</Text>
                    <input
                      accept="*"
                      type="file"
                      value={""}
                      maxLength={1}
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: "none" }}
                    />
                  </Flex>
                  <Text
                    textAlign={"center"}
                    mt="10px"
                    fontSize="14px"
                    fontWeight="400"
                    color={"#9299B0"}
                  >
                    Please make sure that your file includes firstname, lastname
                    and email address as columns
                  </Text>
                </Flex>
              </ModalBody>

              <ModalFooter>
                <Button
                  isLoading={isLoading}
                  loadingText="Uploading File.."
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  my="10px"
                  borderRadius={8}
                  bg={"blue.200"}
                  onClick={UploadFile}
                >
                  Upload File
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Modal
        isCentered
        isOpen={isOpenAddTeams}
        size={"3xl"}
        onClose={onCloseAddTeams}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {addPlayer === true ? "Add  Team" : "Upload CSV"}

            <Flex w="full" mt="20px" bg="#F0F4F8" borderRadius={"100px"}>
              <Flex
                cursor={"pointer"}
                onClick={() => setAddPlayer(!addPlayer)}
                w="50%"
                justifyContent={"center"}
                borderRadius={"100px"}
                alignItems="center"
                padding={"10px"}
                bg={addPlayer === true ? "blue.200" : null}
              >
                <Text
                  color={addPlayer === true ? "white" : "#979C9E"}
                  fontSize="15px"
                  fontWeight={"500"}
                >
                  Add Manually
                </Text>
              </Flex>
              <Flex
                cursor={"pointer"}
                onClick={() => setAddPlayer(!addPlayer)}
                w="50%"
                justifyContent={"center"}
                borderRadius={"100px"}
                bg={addPlayer === false ? "blue.200" : null}
                alignItems="center"
                padding={"10px"}
              >
                <Text
                  color={addPlayer === false ? "white" : "#979C9E"}
                  fontSize="15px"
                  fontWeight={"500"}
                >
                  Upload CSV
                </Text>
              </Flex>
            </Flex>
          </ModalHeader>

          <ModalCloseButton />
          {addPlayer === true && (
            <>
              <ModalBody>
                <Box position={"relative"}>
                  {AddteamsSteps === 0 && (
                    <>
                      <FormControl isInvalid={invalidState.teamNameInvalid}>
                        <FormLabel
                          display="flex"
                          ms="4px"
                          fontSize="sm"
                          fontWeight="500"
                          color={textColor}
                          mb="8px"
                        >
                          Team Name<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                          borderColor={
                            invalidState.teamNameInvalid ? "red" : null
                          }
                          onChange={(e) => {
                            setTeamName(e.target.value);
                            setInvalidState((prvState) => ({
                              ...prvState,
                              teamNameInvalid: false,
                            }));
                          }}
                          value={teamName}
                          isRequired={true}
                          placeholder="Enter Your First Name"
                          variant="auth"
                          fontSize="sm"
                          ms={{ base: "0px", md: "0px" }}
                          type="text"
                          borderRadius={8}
                          fontWeight="500"
                          // w="258px"
                          h={"46px"}
                          size="lg"
                        />
                        {invalidState.teamNameInvalid && (
                          <FormErrorMessage>
                            Team Name is required.
                          </FormErrorMessage>
                        )}
                      </FormControl>

                      <Button
                        isLoading={false}
                        loadingText="Creating Account.."
                        fontSize="sm"
                        variant="brand"
                        fontWeight="500"
                        w="100%"
                        h="50"
                        my="10px"
                        borderRadius={8}
                        bg={"blue.200"}
                        onClick={() => updateTeamsArr(0)}
                      >
                        {selectedTeamIndex > -1 ? "Update  Team" : "Add  Team"}
                      </Button>
                    </>
                  )}
                  {AddteamsSteps === 1 && (
                    <Flex w={"full"} justifyContent={"space-between"}>
                      <Box w="40%" position={"relative"}>
                        <FormControl
                          mt="30px"
                          isInvalid={invalidState.UserNameInvalid}
                        >
                          <Text fontSize={"20px"} fontWeight="500">
                            Add Team Participants
                          </Text>
                          <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="8px"
                          >
                            User Name<Text color={brandStars}>*</Text>
                          </FormLabel>
                          <Input
                            borderColor={
                              invalidState.FirstNameInvalid ? "red" : null
                            }
                            onChange={(e) =>
                              updateState(
                                e.target.value,
                                "UserName",
                                "UserNameInvalid"
                              )
                            }
                            value={state.UserName}
                            isRequired={true}
                            placeholder="Enter Your First Name"
                            variant="auth"
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="text"
                            borderRadius={8}
                            fontWeight="500"
                            // w="258px"
                            h={"46px"}
                            size="lg"
                          />
                          {invalidState.UserNameInvalid && (
                            <FormErrorMessage>
                              User Name is required.
                            </FormErrorMessage>
                          )}
                        </FormControl>
                        <FormControl mt="10px">
                          <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="8px"
                          >
                            First Name (Optional)
                          </FormLabel>
                          <Input
                            onChange={(e) =>
                              updateState(e.target.value, "FirstName")
                            }
                            value={state.FirstName}
                            isRequired={true}
                            placeholder="Enter Your First Name"
                            variant="auth"
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="text"
                            borderRadius={8}
                            fontWeight="500"
                            // w="258px"
                            h={"46px"}
                            size="lg"
                          />
                        </FormControl>
                        <FormControl mt="10px">
                          <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="8px"
                          >
                            Last Name (Optional)
                          </FormLabel>
                          <Input
                            onChange={(e) =>
                              updateState(e.target.value, "LastName")
                            }
                            value={state.LastName}
                            isRequired={true}
                            variant="auth"
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            placeholder="Enter Your Last Name"
                            type="text"
                            borderRadius={8}
                            fontWeight="500"
                            size="lg"
                            h={"46px"}
                          />
                        </FormControl>

                        <FormControl mt="10px">
                          <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="8px"
                          >
                            Email (Optional)
                          </FormLabel>
                          <Input
                            onChange={(e) =>
                              updateState(e.target.value, "EmailAddress")
                            }
                            placeholder="Enter E-mail Address"
                            value={state.EmailAddress}
                            isRequired={true}
                            variant="auth"
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="email"
                            borderRadius={8}
                            fontWeight="500"
                            size="lg"
                            h={"46px"}
                          />
                        </FormControl>
                        <Button
                          isLoading={false}
                          loadingText="Creating Account.."
                          fontSize="sm"
                          variant="brand"
                          fontWeight="500"
                          w="100%"
                          h="50"
                          my="10px"
                          borderRadius={8}
                          bg={"blue.200"}
                          onClick={addBulkPlayer}
                        >
                          {isEditPlayerIndex > -1
                            ? "Update  Participant"
                            : "Add  Participant"}
                        </Button>
                        <Box
                          w="1px"
                          bg="#9299B0"
                          h="100%"
                          top={0}
                          position={"absolute"}
                          right={"-10%"}
                        />
                      </Box>

                      <Box w="50%">
                        <Box
                          maxH="400px"
                          overflowX={"hidden"}
                          css={{
                            "&::-webkit-scrollbar": {
                              width: "4px",
                            },
                            "&::-webkit-scrollbar-track": {
                              width: "6px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              background: "#1C2A5B",
                              borderRadius: "24px",
                            },
                          }}
                          overflowY={"auto"}
                        >
                          {bulkPlayerArr.map((data: any, i: number) => {
                            return (
                              <Flex
                                w="370px"
                                mt="10px"
                                key={data.userId}
                                alignItems={"center"}
                                borderBottom={"1px solid #ECF1F4"}
                                p="10px"
                              >
                                <HStack w="70%">
                                  <Box>
                                    <Avatar size="md" />
                                  </Box>
                                  <Box ml="10px">
                                    <Text
                                      fontWeight={"medium"}
                                      fontSize={"16px"}
                                    >
                                      {data.userName}
                                    </Text>
                                    <Text fontSize={"12px"}>
                                      {" "}
                                      {data.firstName + " " + data.lastName}
                                    </Text>
                                    <Text fontSize={"12px"}>
                                      {data.emailAddress}
                                    </Text>
                                  </Box>
                                </HStack>
                                <HStack justifyContent={"flex-end"}>
                                  <Flex
                                    justifyContent={"center"}
                                    bg="#298BE21A"
                                    alignItems="center"
                                    borderRadius={"30px"}
                                    cursor={"pointer"}
                                    w="30px"
                                    p="8px"
                                    height={"30px"}
                                    onClick={() => editBulkPlayer(data, i)}
                                  >
                                    {" "}
                                    <CiEdit color="#298BE2" size={"20px"} />
                                  </Flex>

                                  <Flex
                                    p="8px"
                                    justifyContent={"center"}
                                    bg="#FF52471A"
                                    alignItems="center"
                                    borderRadius={"30px"}
                                    cursor={"pointer"}
                                    w="30px"
                                    height={"30px"}
                                    onClick={() => deleteBulkPlayer(i)}
                                  >
                                    {" "}
                                    <BsTrash3Fill
                                      color="#FF5247"
                                      size={"16px"}
                                    />
                                  </Flex>
                                </HStack>
                              </Flex>
                            );
                          })}
                        </Box>
                        {bulkPlayerArr.length > 0 && (
                          <Button
                            isLoading={isLoading}
                            loadingText="Saving Participants..."
                            fontSize="sm"
                            variant="brand"
                            fontWeight="500"
                            w="100%"
                            h="50"
                            my="10px"
                            borderRadius={8}
                            bg={"blue.200"}
                            // onClick={SaveParticipants}
                            onClick={() => updateTeamsArr(1)}
                          >
                            Save Participants
                          </Button>
                        )}
                      </Box>
                    </Flex>
                  )}
                  {AddteamsSteps === 2 && (
                    <>
                      <Box w="100%">
                        <Box
                          maxH="400px"
                          overflowX={"hidden"}
                          css={{
                            "&::-webkit-scrollbar": {
                              width: "4px",
                            },
                            "&::-webkit-scrollbar-track": {
                              width: "6px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              background: "#1C2A5B",
                              borderRadius: "24px",
                            },
                          }}
                          overflowY={"auto"}
                        >
                          {teamsArr.map((data: any, i: number) => {
                            return (
                              <Flex
                                w="370px"
                                mt="10px"
                                key={data.userId}
                                alignItems={"center"}
                                borderBottom={"1px solid #ECF1F4"}
                                p="10px"
                              >
                                <HStack w="70%">
                                  <Box>
                                    <Avatar size="md" />
                                  </Box>
                                  <Box ml="10px">
                                    <Text
                                      fontWeight={"medium"}
                                      fontSize={"16px"}
                                    >
                                      {data.guestTeam.teamName}
                                    </Text>
                                    <Text fontSize={"12px"}>
                                      {" "}
                                      {data.usersRequest.length} Players
                                    </Text>
                                  </Box>
                                </HStack>
                                <HStack justifyContent={"flex-end"}>
                                  <Flex
                                    justifyContent={"center"}
                                    bg="#298BE21A"
                                    alignItems="center"
                                    borderRadius={"30px"}
                                    cursor={"pointer"}
                                    w="30px"
                                    p="8px"
                                    height={"30px"}
                                    onClick={() => editTeamsPlayer(data, i)}
                                  >
                                    {" "}
                                    <CiEdit color="#298BE2" size={"20px"} />
                                  </Flex>

                                  <Flex
                                    p="8px"
                                    justifyContent={"center"}
                                    bg="#FF52471A"
                                    alignItems="center"
                                    borderRadius={"30px"}
                                    cursor={"pointer"}
                                    w="30px"
                                    height={"30px"}
                                    onClick={() => deleteTeams(i)}
                                  >
                                    {" "}
                                    <BsTrash3Fill
                                      color="#FF5247"
                                      size={"16px"}
                                    />
                                  </Flex>
                                </HStack>
                              </Flex>
                            );
                          })}
                          <Button
                            isLoading={isLoading}
                            loadingText="Saving Participants..."
                            fontSize="sm"
                            variant="outline"
                            fontWeight="500"
                            w="370px"
                            h="50"
                            my="10px"
                            borderRadius={8}
                            bg={"transparent"}
                            onClick={() => {
                              SetAddteamsSteps(0);
                              setTeamName("");
                              SetbulkPlayerArr([]);
                            }}
                          >
                            Add More Team
                          </Button>
                        </Box>
                        {teamsArr.length > 0 && (
                          <Button
                            isLoading={isLoading}
                            loadingText="Saving Participants..."
                            fontSize="sm"
                            variant="brand"
                            fontWeight="500"
                            w="100%"
                            h="50"
                            my="10px"
                            borderRadius={8}//////////////////////////////////////////
                            bg={"blue.200"}
                            onClick={uploadTeams}
                          >
                            Upload Teams
                          </Button>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              </ModalBody>

              <ModalFooter></ModalFooter>
            </>
          )}
          {addPlayer === false && (
            <>
              <ModalBody>
                <Flex
                  w="full"
                  direction={"column"}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    textAlign={"center"}
                  >
                    Browser / Drag & Drop File
                  </FormLabel>

                  <Flex
                    onClick={handleClickDocuments}
                    fontSize="sm"
                    fontWeight="500"
                    w="50%"
                    padding={"10px"}
                    borderRadius={"100px"}
                    bg={"transparent"}
                    border="1px solid"
                    color={"blue.200"}
                    borderColor={"blue.200"}
                    justifyContent="center"
                    alignItems={"center"}
                    // onClick={createAccount}
                    cursor="pointer"
                  >
                    <MdOutlineFileUpload color="#298BE2" />
                    <Text ml="10px">{file ? file.name : "Upload File"}</Text>
                    <input
                      accept="*"
                      type="file"
                      value={""}
                      maxLength={1}
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: "none" }}
                    />
                  </Flex>
                  <Text
                    textAlign={"center"}
                    mt="10px"
                    fontSize="14px"
                    fontWeight="400"
                    color={"#9299B0"}
                  >
                    Please make sure that your file includes firstname, lastname
                    and email address as columns
                  </Text>
                </Flex>
              </ModalBody>

              <ModalFooter>
                <Button
                  isLoading={isLoading}
                  loadingText="Uploading File.."
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  my="10px"
                  borderRadius={8}
                  bg={"blue.200"}
                  onClick={UploadFile}
                >
                  Upload File
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Modal isCentered isOpen={isOpenCraetGroup} onClose={onCloseCraetGroup}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Groups</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <FormControl isInvalid={invalidState.GroupNameInvalid}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Create Group Title<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                borderColor={invalidState.GroupNameInvalid ? "red" : null}
                onChange={(e) =>
                  updateState(e.target.value, "groupName", "GroupNameInvalid")
                }
                value={state.groupName}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                placeholder="Group Title"
                type="text"
                borderRadius={8}
                fontWeight="500"
                size="lg"
                h={"46px"}
              />
              {invalidState.GroupNameInvalid && (
                <FormErrorMessage>Group Name is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              mt="10px"
              isInvalid={invalidState.selectedCaptinInvalid}
            >
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Select Group Captin<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select
                borderColor={invalidState.selectedCaptinInvalid ? "red" : null}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                placeholder="Select Group Captin"
                borderRadius={8}
                fontWeight="500"
                size="lg"
                h={"46px"}
                onChange={(e) =>
                  updateState(
                    e.target.value,
                    "captainId",
                    "selectedCaptinInvalid"
                  )
                }
                value={state.captainId}
              >
                {waitlistPlayer.map((x: any, i: number) => {
                  return (
                    <option key={x.userId} value={x.userId}>
                      {x.userName}
                    </option>
                  );
                })}
              </Select>
              {invalidState.selectedCaptinInvalid && (
                <FormErrorMessage>Group Captin is required.</FormErrorMessage>
              )}
            </FormControl>
            <Box w={"full"} bg="#E1E1E1" h="1px" my="22px" />

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Select Participants<Text color={brandStars}>*</Text>
            </FormLabel>
            <Flex direction="column" w="full" mt="20px">
              <Spin spinning={isLoadingUsers || searching} tip="Loading...">
                <Flex
                  maxH="30vh"
                  overflowY={"scroll"}
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#1C2A5B",
                      borderRadius: "24px",
                    },
                  }}
                  direction="column"
                  w="full"
                  mt="20px"
                >
                  {waitlistPlayer.map((data: any, i: number) => {
                    return (
                      <Flex
                        w="370px"
                        mt="5px"
                        key={data.userId}
                        alignItems={"center"}
                        // border={"1px solid #ECF1F4"}
                        borderRadius="12px"
                        p="10px"
                        // ml="10px"
                      >
                        <HStack w="70%">
                          <Box>
                            <Avatar src={data.profilePic} size="sm" />
                          </Box>
                          <Box ml="10px">
                            <Text fontWeight={"500"} fontSize={"14px"}>
                              {data.userName}
                            </Text>
                          </Box>
                        </HStack>
                        <HStack justifyContent={"flex-end"} w="30%">
                          <Checkbox
                            onChange={() => updateWaitlistPlayer(data.userId)}
                            isChecked={selectetdwaitlistPlayer.includes(
                              data.userId
                            )}
                          ></Checkbox>
                        </HStack>
                      </Flex>
                    );
                  })}
                </Flex>
              </Spin>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={false}
              loadingText="Creating Account.."
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              my="10px"
              borderRadius={8}
              bg={"blue.200"}
              onClick={createGroup}
            >
              Create Group
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default memo(EventCheckIn);
