import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  Image,
  useToast,
  Text,
  chakra,
  Box,
  ModalFooter,
  ModalBody,
  Heading,
  useColorModeValue,
  HStack,
  Icon,
  Button,
  Avatar,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Textarea,
  ModalCloseButton,
} from "@chakra-ui/react";
import { HiOutlineCamera } from "react-icons/hi";
import { rootUrl } from "utilities/constants";
 
import { post } from "redux/services/api";
import { eventRootUrl } from "utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import pubg_banner2 from "assets/img/global/pubg_banner2.png";

import logoWite from "assets/img/global/logoWite.png";
import EventStepper_1 from "assets/img/global/EventStepper_1.png";
// import EventStepper_1 from "assets/img/global/EventStepper_1.png";
import { getProfile } from "redux/slices/Auth";

import { AppDispatch } from "redux/store";
import { getEventList } from "redux/slices/Events";

interface Props {
  onOpen: Function;
  isOpen: boolean;
  onClose: Function;
  [x: string]: any;
}

export default function EditProfile(props: Props) {
  const {
    userId,
    userName,
    location,
    description,
    website,
    games,
    logo,
    coverImage,
    title,
  } = useSelector((state: any) => state.Auth);
  const [isLoading, setIsLoading] = useState(false);

  const { next, handleSteps, parantState } = props;
  const textColor = useColorModeValue("black.50", "white");
  const textColorBrand = useColorModeValue("blue.100", "white");
  const buttonColorBrand = "blue.200";
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const toast = useToast();
  const dispatch: AppDispatch = useDispatch();
  const [preview, setPreview] = useState<any>(null);
  const [isReview, setIsReview] = React.useState(false);
  const [loaing, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose, editState, ...rest } = props;
  const [steps, setStep] = React.useState(5);
  const hiddenFileInputBanner = React.useRef(null);
  const [previewCover, setPreviewCover] = useState<any>(null);

  let initialState: any = {};
  const [state, setState] = useState({
    HubProfilePicName: "",
    HubProfileCover: "",
    HubWebsite: website,
    UserId: userId,
    HubBio: description,
    HubProfilePic: "",
  });
  const handleChangeBanner = (event: any) => {
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(fileUploaded);
    reader.onloadend = function (e) {
      setPreviewCover([reader.result]);
    };
    setState((prvState) => ({
      ...prvState,
      HubProfileCover: fileUploaded,
    }));
  };
  const handleClickBanner = () => {
    hiddenFileInputBanner.current.click();
  };
  const hiddenFileInput = React.useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(fileUploaded);
    reader.onloadend = function (e) {
      setPreview([reader.result]);
    };

    setState((prvState: any) => ({
      ...prvState,
      HubProfilePicName: fileUploaded.name,
      HubProfilePic: fileUploaded,
    }));
  };
  useEffect(() => {
    console.log({ coverImage });
    setPreviewCover(coverImage?.replace("\\", "/") ?? null);
    setPreview(logo);
    setState((prvState: any) => ({
      ...prvState,
      HubWebsite: website,
      HubBio: description,
    }));
  }, [logo, coverImage, website, description]);

  const updateState = (val: string, prop: string) => {
    setState((prvState) => ({ ...prvState, [prop]: val }));
  };
  function isValidHttpUrl(string:string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }
  const checks = (): boolean => {
    if (state.HubProfilePic === "" && preview === null) {
      toast({
        title: "Profile picture is required",
        description: "Please upload profile picture",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      return false;
    }else if(state.HubWebsite.length>0){
      const thirdCheck = isValidHttpUrl(state.HubWebsite);
      if(!thirdCheck){
        toast({
          title: "Invalid website",
          description: "Please write valid website address with http ot https protocol",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
        return false;
        
      }
    }

    return true;
  };
  const finish = async() => {
    if (checks()) {
      setIsLoading(true);
      let payload: any = { ...state };
      var myHeaders = new Headers();
      var formdata = new FormData();

      myHeaders.append("accept", "*/*");
      for (var key in payload) {
        if (payload[key] != "") {
          formdata.append(key, payload[key]);
        }
      }

      var requestOptions: any = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
  
      let obj = {
        url: `${rootUrl}api/v1/account/hub-user-profile`,
        data: formdata,
      };

      try {
        let res = await post(obj);
        if (res.data.status === false) {
          toast({
            title: "Error",
            description: res.data.errorMessage,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } else {
         
          dispatch(getProfile());
          onClose();
          toast({
            title: "Profile Saved",
            description: res.data.message,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
      // fetch(`${rootUrl}api/v1/account/hub-user-profile`, requestOptions)
      //   .then((response) => response.text())
      //   .then((result: any) => {
      //     setIsLoading(false);
      //     result = JSON.parse(result);
      //     if (result.status === false) {
      //       toast({
      //         title: "Error",
      //         description: result.errorMessage,
      //         status: "error",
      //         duration: 9000,
      //         isClosable: true,
      //       });
      //     } else {
      //       dispatch(getProfile());
      //       toast({
      //         title: "Profile Updated",
      //         description: result.message,
      //         status: "success",
      //         duration: 9000,
      //         isClosable: true,
      //       });
      //     }
      //     console.log({ result });
      //   })
      //   .catch((error) => console.log("error", error));
    }
    // next()
  };
  return (
    <Modal
    isCentered
      {...rest}
      closeOnOverlayClick={false}
      scrollBehavior="inside"
      size={"4xl"}
      isOpen={isOpen}
      onClose={() => {
        setState(initialState);
        onClose();
       
      }}
    >
      <ModalOverlay />
      <ModalContent borderRadius="14px" position={"relative"} p={0}>
        <ModalHeader
          borderTopRadius="14px"
          // borderBottomRadius="16px"
          justifyContent={"space-between"}
          bg="#1C2A5B"
          alignItems="center"
          py="20px"
          px="50px"
          minH={"80px"}
          p="35px"
          // borderRadius="14px"
        >
          <Box>
            <Text color={"white"} fontSize="24px" fontWeight={"600"} mt="10px">
              Edit Profile
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton color={"white"} />
        <ModalBody
          overflowY="auto"
          css={{
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#1C2A5B",
              borderRadius: "24px",
            },
          }}
          position={"relative"}
          p="35px"
        >
          <Flex
            bgImage={previewCover ?? pubg_banner2}
            bgSize="cover"
            position="relative"
            h={"187px"}
            borderRadius="8px"
            alignItems={"center"}
            justifyContent="center"
          >
            <Button
              isLoading={loaing}
              loadingText="Loading..."
              onClick={handleClickBanner}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              bg="#17172580"
              borderRadius={8}
              border={`1px solid  white`}
              color={"white"}
              _hover={{ bg: "#17172580" }}
              _active={{ bg: "#17172580" }}
              _focus={{ bg: "#17172580" }}
              borderColor={"#E2E2E2"}
            >
              Change Cover Image
            </Button>
            <input
              accept="image/*"
              type="file"
              value={""}
              ref={hiddenFileInputBanner}
              onChange={handleChangeBanner}
              style={{ display: "none" }}
            />
          </Flex>
          <Flex
            // justifyContent="flex-start"
            alignItems={"center"}
            // flexDirection="column"
            // bg="red"
            mt="20px"
          >
            {preview ? (
              <Avatar
                onClick={handleClick}
                cursor="pointer"
                src={preview}
                size="2xl"
              />
            ) : (
              <Flex
                onClick={handleClick}
                cursor="pointer"
                borderRadius={50}
                h={"100px"}
                w={"100px"}
                bg={"#ECF1F4"}
                justifyContent="center"
                alignItems={"center"}
              >
                <Icon
                  transition="0.2s linear"
                  w="30px"
                  h="30px"
                  as={HiOutlineCamera}
                  //   color="brand.500"
                />
              </Flex>
            )}

            <Flex
              cursor={"pointer"}
              justifyContent={"center"}
              alignItems="center"
              onClick={handleClick}
              fontSize="sm"
              fontWeight="500"
              w="147px"
              h="40px"
              borderRadius={"0px"}
              color="#298BE2"
              bg={"transparent"}
              ml="16px"
            >
              <Text
                fontWeight={"700"}
                fontSize="14px"
                textDecorationLine={"underline"}
              >
                Change Profile Image
              </Text>
            </Flex>
          </Flex>
          <input
            accept="image/*"
            type="file"
            value={""}
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
          />
          <Flex
            zIndex="2"
            direction="column"
            w="100%"
            background="transparent"
            borderRadius="15px"
          >
            <FormControl mt={10}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={"black"}
                mb="8px"
              >
                Hub Website
              </FormLabel>
              <Input
                onChange={(e) => updateState(e.target.value, "HubWebsite")}
                value={state.HubWebsite}
                isRequired={true}
                placeholder="Website link"
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                borderRadius={8}
                mb="24px"
                fontWeight="500"
                size="lg"
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={"black"}
                mb="8px"
              >
                About My Hub
              </FormLabel>
              <Textarea
                onChange={(e) => updateState(e.target.value, "HubBio")}
                placeholder="This is the bio to my Hub, and its super awesome that no one could compare to this!"
                value={state.HubBio}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                borderRadius={8}
                mb="24px"
                fontWeight="500"
                size="lg"
                minHeight={"200px"}
                border={`1px solid `}
                borderColor={"gray.400"}
              />
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter px="50px">
          <Flex w="full" justifyContent={"space-between"} alignItems="center">
            <Button
              isLoading={loaing}
              loadingText="Loading..."
              onClick={() => onClose()}
              fontSize="16px"
              variant="brand"
              fontWeight="700"
              w="276px"
              h="50"
              color={"black"}
              bg="transparent"
              border={`1px solid  black`}
              borderRadius={8}
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
            >
              Cancel
            </Button>

            <Button
              fontSize="16px"
              variant="brand"
              fontWeight="700"
              isLoading={isLoading}
              loadingText="Updating..."
              onClick={finish}
              w="276px"
              h="50"
              borderRadius={8}
              bg={buttonColorBrand}
            >
              Update
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
