// Chakra imports
import { Grid, Text, HStack, Icon } from "@chakra-ui/react";
import Card from "components/card/Card";
import { HSeparator } from "components/separator/Separator";
import { BsThreeDots, BsGlobe , BsCalendarDate} from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { TbHeartHandshake } from "react-icons/tb";


export default function AboutMyHub({state}:any) {
  // Chakra Color Mode
  const textColorSecondary = "gray.200";
  const textColorSecondary_2 = "gray.300";

  return (
    <Card p={0} borderRadius={"10px"} mt="20px">
      <HStack justifyContent={"space-between"} py="10px" px="20px">
        <Text  fontSize={"14px"} fontWeight="500">About Hub</Text>
        <Icon
          as={BsThreeDots}
          color={textColorSecondary_2}
          cursor="pointer"
          w="24px"
          h="24px"
          mt="4px"
        />
      </HStack>
      <HSeparator />
      <Grid p="20px">
        <Text lineHeight={'24px'} color='#44444F' fontSize={"13px"}>
        {state?.hubBio}
        </Text>
      </Grid>
      <HSeparator />
      <Grid p="20px">
        {/* <HStack justifyContent={"flex-start"}>
          <Icon
            as={HiOutlineLocationMarker}
            color={textColorSecondary_2}
            cursor="pointer"
            w="24px"
            h="24px"
          
          />
          <Text fontSize={"14px"}>1076 McCullen Ave. Dallas, TX</Text>
        </HStack> */}
        <HStack justifyContent={"flex-start"} mt='25px'>
          <Icon
            as={BsGlobe}
            color={textColorSecondary_2}
            cursor="pointer"
            w="24px"
            h="24px"
         
          />
          <Text fontSize={"14px"}>{state?.hubWebSite}</Text>
        </HStack>
        <HStack justifyContent={"flex-start"} mt='25px'>
          <Icon
            as={BsCalendarDate}
            color={textColorSecondary_2}
            cursor="pointer"
            w="24px"
            h="24px"
           
          />
          <Text fontSize={"14px"}>{state?.hostedCount} events hosted</Text>
        </HStack>
        {/* <HStack justifyContent={"flex-start"} mt='25px'>
          <Icon
            as={TbHeartHandshake}
            color={textColorSecondary_2}
            cursor="pointer"
            w="24px"
            h="24px"
            
          />
          <Text fontSize={"14px"}>4.6 / 5 rating</Text>
        </HStack> */}
      </Grid>
    </Card>
  );
}
