// Chakra imports
import React, { useState, useCallback, useEffect } from "react";

import {
  Box,
  Text,
  Input,
  FormLabel,
  useColorModeValue,
  Button,
  FormErrorMessage,
  FormControl,
  useToast,
  HStack,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { hubRootUrl } from "utilities/constants";
import { put, get } from "redux/services/api";
import { useDispatch, useSelector } from "react-redux";

export default function Business() {
  const toast = useToast();
  const { userId } = useSelector((state: any) => state.Auth);
  const [disablebtn, setDisablebtn] = useState( true);
  const textColor = useColorModeValue("black.50", "white");
  const buttonColorBrand = useColorModeValue("blue.200", "white");
  const [loading, setloading] = useState(false);
  const [invalidState, setInvalidState] = useState({
    EmailAddressInvalid: false,
  });

  const [state, setState] = useState({
    hubId: userId,
    name: "",
    phoneNo: "",
    email: "",
    physicalAddress: "",
    website: "",
    einNo: "",
  });

  useEffect(() => {
    getBusinessInfo();
  }, []);
  const updateState = (val: string, prop: string) => {
    setDisablebtn(false)
    setState((prvState: any) => ({ ...prvState, [prop]: val }));
  };
  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const checks = () => {
    if (state.email.length) {
      if (!validateEmail(state.email)) {
        console.log("EmailAddressInvalid");
        setInvalidState((prvState) => ({
          ...prvState,
          EmailAddressInvalid: true,
        }));
        return false;
      }
    }
    return true;
  };
  const save = async () => {
    console.log({ userId });
    if (checks()) {
      setloading(true);
      let data = {
        ...state,
        hubId: userId,
      };

      let payload = {
        url: `${hubRootUrl}api/v1/hub/save-hub-business-info`,
        data: data,
      };
      try {
        let response = await put(payload);
        setloading(false);
        if (response.data.statusCode === 200) {
          setDisablebtn(true)
          getBusinessInfo();
          toast.closeAll()
          toast({
            title: "Success",
            description: "Business Settings Saved",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast.closeAll()
          toast({
            title: response.data.message,
            description: response.data.errorMessage,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (err) {
        setloading(false);
        toast.closeAll()
        toast({
          title: "Server Err",
          description: "Server Err",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  const getBusinessInfo = async () => {
    let url = `${hubRootUrl}api/v1/hub/get-hub-business-info?hubId=${userId}`;
    const response: any = await get(url);
    if (response.data.status === true) {
      console.log({ response: response.data });

      setState(response.data.response);
    }
  };
  return (
    <Card shadow={"lg"} borderRadius={"8px"} p="40px">
      <Text fontSize={"36px"} fontWeight="bold" color="#1C2A5B">
        Business Settings
      </Text>
      <Box>
        <HStack>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              mt="24px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              Company Name
            </FormLabel>
            <Input
              onChange={(e) => updateState(e.target.value, "name")}
              value={state.name}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              borderRadius={8}
              placeholder="Please Enter Your Full Name"
              fontWeight="500"
              size="lg"
            />
          </FormControl>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              mt="24px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              Phone Number
            </FormLabel>
            <Input
              onChange={(e) => updateState(e.target.value, "phoneNo")}
              value={state.phoneNo}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="number"
              borderRadius={8}
              placeholder="Phone Number"
              fontWeight="500"
              size="lg"
            />
          </FormControl>
        </HStack>
        <HStack>
          <FormControl isInvalid={invalidState.EmailAddressInvalid}>
            <FormLabel
              display="flex"
              ms="4px"
              mt="24px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              E-mail Address
            </FormLabel>
            <Input
              borderColor={invalidState.EmailAddressInvalid ? "red" : null}
              isRequired={true}
              onChange={(e) => updateState(e.target.value, "email")}
              value={state.email}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              borderRadius={8}
              placeholder="E-mail Address"
              fontWeight="500"
              size="lg"
            />
            {invalidState.EmailAddressInvalid && (
              <FormErrorMessage>Invalid Email</FormErrorMessage>
            )}
          </FormControl>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              mt="24px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              Physical Address
            </FormLabel>
            <Input
              isRequired={true}
              onChange={(e) => updateState(e.target.value, "physicalAddress")}
              value={state.physicalAddress}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              borderRadius={8}
              placeholder="Physical Address"
              fontWeight="500"
              size="lg"
            />
          </FormControl>
        </HStack>
        <HStack>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              mt="24px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              Website
            </FormLabel>
            <Input
              onChange={(e) => updateState(e.target.value, "website")}
              value={state.website}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              borderRadius={8}
              placeholder="Website"
              fontWeight="500"
              size="lg"
            />
          </FormControl>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              mt="24px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              EIN Number
            </FormLabel>
            <Input
              onChange={(e) => updateState(e.target.value, "einNo")}
              value={state.einNo}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="number"
              borderRadius={8}
              placeholder="EIN Number"
              fontWeight="500"
              size="lg"
            />
          </FormControl>
        </HStack>
        <Button
        disabled={disablebtn}
          float={"right"}
          mt="24px"
          onClick={save}
          isLoading={loading}
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="30%"
          h="50"
          borderRadius={8}
          bg={buttonColorBrand}
        >
          Save
        </Button>
      </Box>
    </Card>
  );
}
