import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, post } from "../services/api";
// import { ProductInterface } from "../../utilities/Interface";
import { hubAdminUrl, hubRootUrl } from "../../utilities/constants";

interface ProductsState {
  usersListloading: boolean;
  usersList: Array<any>;
  adminsListloading: boolean;
  adminsList: Array<any>;
  ticketAdminsList: Array<any>;
  hubListloading: boolean;
  hubList: Array<any>;
  userRolesListloading: boolean;
  userRolesList: Array<any>;
  menuList: Array<any>;
  eventList: Array<any>;
  eventListloading: boolean;
  hubDocsList: Array<any>;
  privacyPolicy: any;
  termsConditions: any;
  loadingMenu:boolean
}
export const getUserList = createAsyncThunk(
  "admin-get-users-list",
  async (payload: any) => {
    const { PageNumber, PageSize, Flag } = payload;
    try {
      const response = await get(
        `${hubAdminUrl}/api/v1/user/admin-get-users-list?PageNumber=${PageNumber}&PageSize=${PageSize}&Flag=${Flag}`
      );
      return response.data.response;
    } catch (err: any) {
      console.log({ errorz: err.message });
      throw err;
    }
  }
);
export const getAdminsList = createAsyncThunk(
  "get-admin-users-list",
  async (payload: any) => {
    const { PageNumber, PageSize, Flag } = payload;
    try {
      const response = await get(
        `${hubAdminUrl}/api/v1/user/get-admin-users-list?PageNumber=${PageNumber}&PageSize=${PageSize}&Flag=${Flag}`
      );
      return response.data.response;
    } catch (err: any) {
      console.log({ errorz: err.message });
      throw err;
    }
  }
);
export const getTicketAdminsList = createAsyncThunk(
  "get-userlist-can-ticket-assign",
  async () => {
    try {
      const response = await get(
        `${hubAdminUrl}/api/v1/user/get-userlist-can-ticket-assign`
      );
      return response.data.response;
    } catch (err: any) {
      console.log({ errorz: err.message });
      throw err;
    }
  }
);
//
export const getHubList = createAsyncThunk(
  "get-hub-users-list",
  async (payload: any) => {
    const { PageNumber, PageSize, Flag } = payload;
    try {
      const response = await get(
        `${hubAdminUrl}/api/v1/user/get-hub-users-list?PageNumber=${PageNumber}&PageSize=${PageSize}&Flag=${Flag}`
      );
      return response.data.response;
    } catch (err: any) {
      console.log({ errorz: err.message });
      throw err;
    }
  }
);

export const getUserRolesList = createAsyncThunk(
  "get-user-roles-list",
  async (payload: any) => {
    const { PageNumber, PageSize, Flag } = payload;
    try {
      const response = await get(
        `${hubAdminUrl}/api/v1/user/get-user-roles-list?PageNumber=${PageNumber}&PageSize=${PageSize}&Flag=${Flag}`
      );
      return response.data.response;
    } catch (err: any) {
      console.log({ errorz: err.message });
      throw err;
    }
  }
);
export const getRolePermssionList = createAsyncThunk(
  "get-role-permssion-list",
  async (payload: any) => {
    const { PageNumber, PageSize, Flag } = payload;
    console.log({});
    try {
      const response = await get(
        `${hubAdminUrl}/api/v1/user/get-role-permssion-list?PageNumber=${PageNumber}&PageSize=${PageSize}&Flag=${Flag}`
      );
      return response.data.response;
    } catch (err: any) {
      console.log({ errorz: err.message });
      throw err;
    }
  }
);

export const getRoleWiseMenu = createAsyncThunk(
  "get-role-wise-menu",
  async (payload: any) => {
    const { RoleId } = payload;
    try {
      const response = await get(
        `${hubAdminUrl}/api/v1/user/get-role-wise-menu?RoleId=${RoleId}`
      );
      return response.data.response;
    } catch (err: any) {
      console.log({ errorz: err.message });
      throw err;
    }
  }
);

export const getEventList = createAsyncThunk(
  "get-admin-events-list",
  async (payload: any) => {
    const { PageNumber, PageSize, Flag } = payload;
    try {
      const response = await get(
        `${hubAdminUrl}/api/v1/user/get-admin-events-list?PageNumber=${PageNumber}&PageSize=${PageSize}&Flag=${Flag}`
      );
      return response.data.response;
    } catch (err: any) {
      console.log({ errorz: err.message });
      throw err;
    }
  }
);
export const gethubDocList = createAsyncThunk(
  "get-all-documents",
  async (payload: any) => {
    const { userId } = payload;
    try {
      const response = await post({
        url: `${hubRootUrl}api/v1/hub/get-all-documents?hubId=${userId}`,
      });
      return response.data.response;
    } catch (err: any) {
      console.log({ errorz: err.message });
      throw err;
    }
  }
);
export const getPrivacyPolicy = createAsyncThunk(
  " getPrivacyPolicy",
  async () => {
    try {
      const response = await get(
        `${hubRootUrl}api/v1/setting/privacypolicy-termscondition?SubCatagoryId=12`
      );
      return response.data.response;
    } catch (err: any) {
      console.log({ errorz: err.message });
      throw err;
    }
  }
);
export const getTermsConditions = createAsyncThunk(
  "getTermsConditions",
  async () => {
    try {
      const response = await get(
        `${hubRootUrl}api/v1/setting/privacypolicy-termscondition?SubCatagoryId=13`
      );
      return response.data.response;
    } catch (err: any) {
      console.log({ errorz: err.message });
      throw err;
    }
  }
);

const initialState: ProductsState = {
  usersListloading: false,
  usersList: [],
  ticketAdminsList: [],
  adminsListloading: false,
  adminsList: [],
  hubListloading: false,
  hubList: [],
  userRolesListloading: false,
  userRolesList: [],
  menuList: [],
  eventList: [],
  eventListloading: false,
  hubDocsList: [],
  privacyPolicy: null,
  termsConditions: null,
  loadingMenu:false
};

const AdminsTableLists = createSlice({
  name: "AdminsTableLists",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTermsConditions.fulfilled, (state, action: any) => {
      state.termsConditions = action.payload.subCategoryDescription;
    });
    builder.addCase(getPrivacyPolicy.fulfilled, (state, action: any) => {
      state.privacyPolicy = action.payload.subCategoryDescription;
    });
    builder.addCase(gethubDocList.fulfilled, (state, action: any) => {
      state.hubDocsList = action.payload;
    });

    builder.addCase(getEventList.pending, (state) => {
      state.eventListloading = true;
    });
    builder.addCase(getEventList.fulfilled, (state, action: any) => {
      state.eventListloading = false;
      state.eventList = action.payload;
    });
    builder.addCase(getEventList.rejected, (state, error: any) => {
      state.eventListloading = false;
    });

    builder.addCase(getUserList.pending, (state) => {
      state.usersListloading = true;
    });
    builder.addCase(getUserList.fulfilled, (state, action: any) => {
      state.usersListloading = false;
      state.usersList = action.payload.userList;
    });
    builder.addCase(getUserList.rejected, (state, error: any) => {
      state.usersListloading = false;
    });

    builder.addCase(getAdminsList.pending, (state) => {
      state.adminsListloading = true;
    });
    builder.addCase(getAdminsList.fulfilled, (state, action: any) => {
      state.adminsListloading = false;
      state.adminsList = action.payload.userList;
    });
    builder.addCase(getAdminsList.rejected, (state, error: any) => {
      state.adminsListloading = false;
    });

    builder.addCase(getTicketAdminsList.pending, (state) => {
      state.adminsListloading = true;
    });
    builder.addCase(getTicketAdminsList.fulfilled, (state, action: any) => {
      state.adminsListloading = false;
      state.ticketAdminsList = action.payload;
    });
    builder.addCase(getTicketAdminsList.rejected, (state, error: any) => {
      state.adminsListloading = false;
    });
    builder.addCase(getHubList.pending, (state) => {
      state.hubListloading = true;
    });
    builder.addCase(getHubList.fulfilled, (state, action: any) => {
      state.hubListloading = false;
      state.hubList = action.payload.userList;
    });
    builder.addCase(getHubList.rejected, (state, error: any) => {
      state.hubListloading = false;
    });

    builder.addCase(getUserRolesList.pending, (state) => {
      state.userRolesListloading = true;
    });
    builder.addCase(getUserRolesList.fulfilled, (state, action: any) => {
      state.userRolesListloading = false;
      state.userRolesList = action.payload.roleList;
    });
    builder.addCase(getUserRolesList.rejected, (state, error: any) => {
      state.userRolesListloading = false;
    });

    builder.addCase(getRoleWiseMenu.pending, (state) => {
      state.loadingMenu=true
    });
    builder.addCase(getRoleWiseMenu.fulfilled, (state, action: any) => {
      state.loadingMenu=false
      let arr = action.payload.map((x: any) => {
        return x.formId;
      });
      state.menuList = arr;
    });
    builder.addCase(getRoleWiseMenu.rejected, (state, error: any) => {
      state.loadingMenu=false
    });
  },
});

export default AdminsTableLists.reducer;
