import { useQuery, useMutation, useQueryClient } from "react-query";
import { post, put, get } from "../../../redux/services/api";
import { hubAdminUrl_table } from "../../../utilities/constants";
// const queryClient = useQueryClient()

interface payload {
  PageSize: number;
  PageNumber: number;
  search: string;
}
const fetchEventList = async (payload: payload) => {
  const { PageNumber, PageSize, search } = payload;
  const { data } = await get(
    // `${hubAdminUrl_table}/v1/Complain?PageSize=${PageSize}&PageNumber=${PageNumber}&LocationId=${locationId}`
    `${hubAdminUrl_table}api/v1/user/get-admin-events-list?PageNumber=${PageNumber}&PageSize=${PageSize}&Flag=${0}&SearchString=${search}`
  );
  return data;
};

const fetchDashboard = async () => {
  const { data } = await get(
    // `${hubAdminUrl_table}/v1/Complain?PageSize=${PageSize}&PageNumber=${PageNumber}&LocationId=${locationId}`
    `${hubAdminUrl_table}api/v1/user/get-hub-dashboard-details`
  );
  return data;
};

export const useEvent = (payload: payload) => {
  const { PageNumber, PageSize, search } = payload;
  return useQuery({
    queryKey: ["fetchEventList", PageNumber, PageSize, search],
    queryFn: () => fetchEventList(payload),
    refetchOnWindowFocus: false,
  });
};
export const useDashboard = () => {
  return useQuery({
    queryKey: ["fetchDashboard"],
    queryFn: fetchDashboard,
    refetchOnWindowFocus: false,
  });
};

// export default useComplain
