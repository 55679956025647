// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
import firebase from 'firebase'
const env = process.env; 

// import { getAnalytics } from "firebase/analytics";
// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: env.REACT_APP_apiKey,
  authDomain: env.REACT_APP_authDomain,
  projectId: env.REACT_APP_projectId,
  storageBucket: env.REACT_APP_storageBucket,
  messagingSenderId: env.REACT_APP_messagingSenderId,
  appId: env.REACT_APP_appId,
  measurementId: env.REACT_APP_measurementId
};
console.log({firebaseConfig})

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const db = app.firestore();
export const auth = app.auth();