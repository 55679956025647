// Chakra imports
import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  useToast,
  Text,
  Box,
  ModalFooter,
  ModalBody,
  Button,
  Input,
  Select,
} from "@chakra-ui/react";
import { hubAdminUrl_table } from "utilities/constants";
import { post } from "redux/services/api";
import { useMutationSubmitDocList } from "../hooks";

export default function ProfileApproved(props: any) {
  const { mutateAsync, isLoading, isSuccess } = useMutationSubmitDocList();
  const { next, handleSteps, parantState, ishub, id, refetch, hubDocuments } =
    props;
  console.log({ id });
  const buttonColorBrand = "blue.200";
  const toast = useToast();
  const { isOpen, onOpen, onClose, editState, ...rest } = props;
  let initialState: any = {};
  const [docArr, setDocArr] = useState([]);
  const [state, setState] = useState({
    feedbackComment: "",
    document: null,
    locationId: id,
    userId: id,
  });
  useEffect(() => {
    setState((prvState) => ({
      ...prvState,
      locationId: id,
      userId: id,
    }));
  }, [id]);
  useEffect(() => {
    if (hubDocuments?.length) {
      console.log({ hubDocuments });
      setDocArr(hubDocuments);
    }
  }, [hubDocuments]);

  const updateState = (val: string, i: number) => {
    let arr = [...hubDocuments];
    arr[i]["feedBackComment"] = val;
    setDocArr(arr);
  };
  const checks = (): boolean => {
    return true;
  };
  const finish = async () => {
    if (checks()) {
      let arr = docArr.map((x: any) => {
        return {
          documentId: x.docId.toString(),
          // approve: x.isApproved,
          // decline: x.isPending,
          // pending: x.isApproved,
          feedbackComment: x.feedBackComment,
          status: x.status,
        };
      });
      await mutateAsync({ updateDocument:arr, hubId: id});
      refetch()
      onClose();
    }
  };

  return (
    <Modal
      isCentered
      {...rest}
      // closeOnOverlayClick={false}
      scrollBehavior="inside"
      size={"6xl"}
      isOpen={isOpen}
      onClose={() => {
        setState(initialState);
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent borderRadius="14px" position={"relative"} p={0}>
        <ModalHeader h={"80px"} p="0px" borderRadius="14px">
          <Box
            borderTopRadius="14px"
            // borderBottomRadius="16px"
            justifyContent={"space-between"}
            bg="#1C2A5B"
            alignItems="center"
            py="20px"
            px="50px"
          >
            <Text color={"white"} fontSize="24px" fontWeight={"600"} mt="10px">
              Documents
            </Text>
          </Box>
        </ModalHeader>
        <ModalBody
          overflowY="auto"
          css={{
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#1C2A5B",
              borderRadius: "24px",
            },
          }}
          position={"relative"}
          p="35px"
        >
          <Flex px="19px" justifyContent={"space-between"}>
            <Box w="30%">
              <Text color={"#333333"} fontSize="14px" fontWeight={"600"}>
                Document Type
              </Text>
            </Box>
            <Box w="50%">
              <Text color={"#333333"} fontSize="14px" fontWeight={"600"}>
                Comment
              </Text>
            </Box>

            <Box ml="5%" w="15%">
              <Text color={"#333333"} fontSize="14px" fontWeight={"600"}>
                Status
              </Text>
            </Box>
          </Flex>
          {docArr?.map((x: any, i: number) => {
            console.log({ x });
            return (
              <Flex
                mt="20px"
                px="19px"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box w="30%">
                  <Text
                    onClick={() =>
                      window.open(
                        x.documentPath,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                    cursor={"pointer"}
                    color={"#333333"}
                    fontSize="14px"
                    fontWeight={"600"}
                    textDecorationLine="underline"
                  >
                    {x.documentType}
                  </Text>
                </Box>
                <Box w="50%">
                  <Input
                    onChange={(e) => updateState(e.target.value, i)}
                    placeholder= {x.status ==="Rejected"?"Type Here...": ""}
                    // readOnly={x.status ==="Approved"}
                    disabled={x.status!="Rejected"}
                    value={x.status ==="Rejected"?x.feedBackComment:""}
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    borderRadius={8}
                    fontWeight="500"
                    size="lg"
                    border={`1px solid `}
                    borderColor={x.status ==="Rejected"?"black": "gray.400"}
                    h={"46px"}
                  />
                </Box>

                <Box ml="5%" w="15%">
                  <Select
                    h={"46px"}

                    variant="auth"
                    borderRadius={"8px"}
                    value={x.status}
                    onChange={(e) => {
                      let arr = [...docArr];
                      arr[i]["status"] = e.target.value;
                      setDocArr(arr);
                    }}
                  >
                    <option value={"Pending"}>{"Pending"}</option>
                    <option value={"Approved"}>{"Approved"}</option>
                    <option value={"Rejected"}>{"Rejected"}</option>
                  </Select>
                </Box>
              </Flex>
            );
          })}
        </ModalBody>
        <ModalFooter px="50px">
          <Flex w="full" justifyContent={"space-between"} alignItems="center">
            <Button
              isLoading={false}
              loadingText="Loading..."
              onClick={() => onClose()}
              fontSize="16px"
              variant="brand"
              fontWeight="700"
              w="276px"
              h="50"
              color={"black"}
              bg="transparent"
              border={`1px solid  black`}
              borderRadius={8}
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
            >
              Cancel
            </Button>

            <Button
              fontSize="16px"
              variant="brand"
              fontWeight="700"
              isLoading={isLoading}
              loadingText="Updating..."
              onClick={finish}
              w="276px"
              h="50"
              borderRadius={8}
              bg={buttonColorBrand}
            >
              Submit
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
