import { useQuery, useMutation, useQueryClient } from "react-query";
import { post, put, get } from "../../../redux/services/api";
import { hubAdminUrl_table, hubRootUrl, hubAdminUrl } from "../../../utilities/constants";
const approveHub = async (hubId: string) => {
  let obj = {
    url: `${hubAdminUrl_table}api/v1/user-profile/approve-admin-hub-profile?hubId=${hubId}`,
    data: {},
  };
  const res = await post(obj);
  return res;
};

const approveLocation = async (locationId: string) => {
  let obj = {
    url: `${hubAdminUrl_table}api/v1/user-profile/approve-hub-location?locationId=${locationId}`,
    data: {},
  };
  const res = await post(obj);
  return res;
};
const submitDocList = async (list: any) => {
  let obj = {
    url: `${hubAdminUrl}/api/v1/user-profile/status-update-hub-document`,
    data: list,
  };
  const res = await post(obj);
  return res;
};
const getHubDetails = async (hubId: string) => {
  let url = `${hubRootUrl}api/v1/profile/hub-detail?hubId=${hubId}`;
  const res: any = await get(url);
  return res;
};
export const useMutationApproveLocation = () => {
  return useMutation({
    mutationFn: (locationId: string) => approveLocation(locationId),
  });
};
export const useMutationSubmitDocList = () => {
  return useMutation({
    mutationFn: (list: any) => submitDocList(list),
  });
};

export const useMutationApproveHub = () => {
  return useMutation({
    mutationFn: (hubId: string) => approveHub(hubId),
  });
};

export const useHubDetails = (hubId: string) => {
  return useQuery({
    queryKey: ["fetchHubDetails"],
    queryFn: () => getHubDetails(hubId),
    enabled: hubId ? true : false,
    refetchOnWindowFocus: false,
  });
};
