/* eslint-disable */

import React, { memo, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Image,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  useToast,
  Text,
  useColorModeValue,
  HStack,
  Avatar,
} from "@chakra-ui/react";
// Custom components
import { rootUrl } from "utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import { Authenticate } from "redux/slices/Auth";
import { HiOutlineCamera } from "react-icons/hi";
import { useHistory, useLocation } from "react-router-dom";

function Profiles(props: any) {
  const toast = useToast();
  const history = useHistory();

  const dispatch: any = useDispatch();
  const { loading } = useSelector((state: any) => state.Auth);
  const { next, userName, password,userId } = props;
  console.log({ userName, password,userId });
  // Chakra color mode
  const textColor = useColorModeValue("#298BE2", "#298BE2");
  const textColorSecondary = "#707997";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("blue.100", "blue.100");
  const buttonColorBrand = useColorModeValue("blue.200", "blue.200");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [state, setState] = useState({
    HubProfilePicName: "",
    HubProfileCover: "",
    HubWebsite: "",
    UserId: userId,
    HubBio: "",
    HubProfilePic: "",
  });
  useEffect(() => {
    console.log({userId})
    if (!userId) {
      history.push({
        pathname: "/auth/sign-in",
      });
    } else {
      setState((prvState) => ({
        ...prvState,
        UserId: userId,
      }));
    }
  }, [userId]);
  const [preview, setPreview] = useState<any>(null);
  const [previewCover, setPreviewCover] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const hiddenFileInputBanner = React.useRef(null);
  const hiddenFileInput = React.useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(fileUploaded);
    reader.onloadend = function (e) {
      setPreview([reader.result]);
    };

    setState((prvState) => ({
      ...prvState,
      HubProfilePicName: fileUploaded.name,
      HubProfilePic: fileUploaded,
    }));
  };

  const handleClickBanner = () => {
    hiddenFileInputBanner.current.click();
  };
  const handleChangeBanner = (event: any) => {
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(fileUploaded);
    reader.onloadend = function (e) {
      setPreviewCover([reader.result]);
    };
    setState((prvState) => ({
      ...prvState,
      HubProfileCover: fileUploaded,
    }));
  };
  const updateState = (val: string, prop: string) => {
    setState((prvState) => ({ ...prvState, [prop]: val }));
  };
  const checks = (): boolean => {
    if (state.HubProfilePic === "") {
      toast({
        title: "Profile picture is required",
        description: "Please upload profile picture",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };
  const finish = () => {
    if (checks()) {
      setIsLoading(true);
      let payload: any = { ...state };
      var myHeaders = new Headers();
      var formdata = new FormData();

      myHeaders.append("accept", "*/*");
      for (var key in payload) {
        formdata.append(key, payload[key]);
      }

      var requestOptions: any = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${rootUrl}api/v1/account/hub-user-profile`, requestOptions)
        .then((response) => response.text())
        .then((result: any) => {
          setIsLoading(false);
          result = JSON.parse(result);
          if (result.status === false) {
            toast.closeAll()
            toast({
              title: "Error",
              description: result.errorMessage,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            getAccessToken();
            toast.closeAll()
            toast({
              title: "Profile Saved",
              description: result.message,
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          }
          console.log({ result });
        })
        .catch((error) => console.log("error", error));
    }
    // next()
  };
  const getAccessToken = () => {
    console.log({ userName, password });
    let payload = {
      userName: userName,
      password: password,
      fcmToken: "",
      isSocialLogin: false,
      isFacebook: true,
      isGoogle: true,
      isAppleLogin: true,
      isFacialLoginAllowed: true,
    };
    dispatch(Authenticate(payload));
  };
  return (
    <Flex
      w="578px"
      mx={{ base: "auto", lg: "0px" }}
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      border="1px solid #E3E4E5"
      borderRadius={"24px"}
      mt="24px"
      p="30px"
      // overflow='hidden'
      // bg='red'
      flexDirection="column"
    >
      <Box m="auto">
        <Heading
          color={textColor}
          textAlign="center"
          fontSize="24px"
          w={450}
          mb="10px"
        >
          Customize Your Hub
        </Heading>
        <Text
          textAlign="center"
          fontSize="md"
          fontWeight="normal"
          color={textColorSecondary}
          mb="8px"
        >
          Lets get gamers to recognize why you are so amazing{" "}
        </Text>
      </Box>
      <Flex
        justifyContent="center"
        alignItems={"center"}
        flexDirection="column"
        m="auto"
        mt="20px"
      >
        {preview ? (
          <Avatar
            onClick={handleClick}
            cursor="pointer"
            src={preview}
            size="2xl"
          />
        ) : (
          <Flex
            onClick={handleClick}
            cursor="pointer"
            m="auto"
            borderRadius={50}
            h={"100px"}
            w={"100px"}
            bg={"#ECF1F4"}
            justifyContent="center"
            alignItems={"center"}
          >
            <Icon
              transition="0.2s linear"
              w="30px"
              h="30px"
              as={HiOutlineCamera}
              //   color="brand.500"
            />
          </Flex>
        )}
        <Text
          noOfLines={2}
          fontSize="md"
          fontWeight="medium"
          color={"black"}
          mt="16px"
          textAlign="center"
        >
          Select Your Hub Profile Picture
        </Text>
        <Flex
          cursor={"pointer"}
          justifyContent={"center"}
          alignItems="center"
          onClick={handleClick}
          fontSize="sm"
          fontWeight="500"
          w="90%"
          h="40px"
          ml="20px"
          mt={"20px"}
          borderRadius={"20px"}
          color="#298BE2"
          bg={"transparent"}
          border="1px solid #ECF1F4"
        >
          <Text fontWeight={"semibold"} textDecorationLine={"underline"}>
            Chose File
          </Text>
        </Flex>
      </Flex>
      <input
        accept="image/*"
        type="file"
        value={''}
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <HStack mt="40px">
        {previewCover ? (
          <Image src={previewCover} borderRadius={8} h={120} w={200} />
        ) : (
          //   <Box borderRadius={8} h={120} w={200} bg={"gray.200"} />
          <Flex
            onClick={handleClickBanner}
            cursor="pointer"
            m="auto"
            borderRadius={"8px"}
            w={"175px"}
            h={"100px"}
            bg={"#ECF1F4"}
            justifyContent="center"
            alignItems={"center"}
          >
            <Icon
              transition="0.2s linear"
              w="30px"
              h="30px"
              as={HiOutlineCamera}
              //   color="brand.500"
            />
          </Flex>
        )}
        <Box>
          <Text
            noOfLines={2}
            fontSize="md"
            fontWeight="medium"
            color={"black"}
            mt="16px"
            textAlign="center"
          >
            Select Your Hub Cover Image{"(optional)"}
          </Text>

          <Flex
            cursor={"pointer"}
            justifyContent={"center"}
            alignItems="center"
            onClick={handleClickBanner}
            fontSize="sm"
            fontWeight="500"
            w="90%"
            h="40px"
            ml="20px"
            mt={"20px"}
            borderRadius={"20px"}
            color="#298BE2"
            bg={"transparent"}
            border="1px solid #ECF1F4"
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
          >
            <Text fontWeight={"semibold"} textDecorationLine={"underline"}>
              Chose File
            </Text>
          </Flex>
          <input
            accept="image/*"
            type="file"
            value={''}
            ref={hiddenFileInputBanner}
            onChange={handleChangeBanner}
            style={{ display: "none" }}
          />
        </Box>
      </HStack>
      <Flex
        zIndex="2"
        direction="column"
        w="100%"
        background="transparent"
        borderRadius="15px"
      >
        <FormControl mt={10}>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={"black"}
            mb="8px"
          >
            Profile Bio
          </FormLabel>
          <Input
            onChange={(e) => updateState(e.target.value, "HubBio")}
            placeholder='Description'
            value={state.HubBio}
            isRequired={true}
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            borderRadius={8}
            mb="24px"
            fontWeight="500"
            size="lg"
          />
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={"black"}
            mb="8px"
          >
            Hub Website
          </FormLabel>
          <Input
            onChange={(e) => updateState(e.target.value, "HubWebsite")}
            value={state.HubWebsite}
            isRequired={true}
            placeholder='Website link'
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            borderRadius={8}
            mb="24px"
            fontWeight="500"
            size="lg"
          />

          <HStack justifyContent={"space-between"} mt="20px">
            <Button
              fontSize="sm"
              //   variant="outline"
              fontWeight="500"
              w="30%"
              h="50"
              borderRadius={8}
              onClick={getAccessToken}
              bg={"transparent"}
              border="1px solid #ECF1F4"
              _hover={{ bg: "transparent" }}
            >
              <Text fontWeight={"semibold"} color="black">
                Skip
              </Text>
            </Button>
            <Button
              isLoading={isLoading || loading}
              loadingText="Finishing..."
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="30%"
              h="50"
              borderRadius={8}
              bg={buttonColorBrand}
              onClick={finish}
            >
              Finish
            </Button>
          </HStack>
        </FormControl>
      </Flex>
    </Flex>
  );
}

export default memo(Profiles);
