import { useCallback, memo } from "react";
import { HiOutlineGlobe } from "react-icons/hi";
import "../../../../../assets/css/App.css";
import {
  ModalBody,
  Heading,
  useColorModeValue,
  Flex,
  Text,
  chakra,
} from "@chakra-ui/react";
import EventCard from "./EventCard";
import { HSeparator } from "components/separator/Separator";
import template from "assets/img/global/template.png";
import inPerson from "assets/img/global/inPerson.png";
import team from "assets/img/global/team.png";
import publics from "assets/img/global/public.png";
import Group from "assets/img/global/Group.png";
import pc from "assets/img/global/pc.png";


interface Props {
  next: Function;
  state: any;
}

function Step_1(props: Props) {
  const { next, state } = props;
  const textColor = useColorModeValue("black.50", "white");
  const textColorBrand = useColorModeValue("blue.100", "white");
  const textColorSecondary = "gray.400";
  const handleNext = useCallback((eventType?: string) => {
    if (eventType) {
      let obj = { ...state };
      obj.eventType = eventType;
      next(obj);
    }
    return;
  }, []);

  return (
    <ModalBody
    overflowY="auto"
    css={{
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        width: "6px",
        
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#1C2A5B",
        borderRadius: "24px",
      },
    }}
    position={"relative"}
    
      
      pt="24px"
      px="47px"
      py='47px'
    >
      <Text fontSize={"16px"} fontWeight="normal" color={textColorSecondary}>
        What kind of event are we planning?
      </Text>
      <Flex flexWrap={"wrap"} justifyContent={"space-between"}>
        <EventCard
          isImage={true}
          src={publics}
          handleNext={() => handleNext("Online Event")}
          cardBg="white"
          heading="Online Event"
          text="Online events are Covid free"
          iconName={HiOutlineGlobe}
        />
        <EventCard
          isImage={true}
          handleNext={() => handleNext("In-Person")}
          cardBg="white"
          heading="In-Person"
          text="Get together with people at a specific location"
          iconName={HiOutlineGlobe}
          src={inPerson}
        />
        <EventCard
          isImage={true}
          src={template}
          handleNext={() => handleNext()}
          cardBg="#ECF1F4"
          heading="Use Template"
          text="Preset templates to make life easy"
         
        />
      </Flex>
      {/* <Flex
        w="60%"
        alignItems="center"
        p="10px"
        pt="40px"
        justifyContent={"space-between"}
        m="auto"
      >
        <HSeparator w="35%" />
        <Text
          textAlign={"center"}
          w="25%"
          fontSize={"12px"}
          color={textColorSecondary}
        >
          Coming Soon
        </Text>
        <HSeparator w="35%" />
      </Flex> */}
      {/* <Flex
        className="outer-wrapper"
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <EventCard
           isImage={true}
          src={pc}
          handleNext={() => handleNext()}
          cardBg="#ECF1F4"
          heading="Multi-day"
          text="Large events, Cosplay event, other..."
          iconName={HiOutlineGlobe}
        />
        <EventCard
           isImage={true}
          src={Group}
          handleNext={() => handleNext()}
          cardBg="#ECF1F4"
          heading="PvP Battle"
          text="Play against another player for their seat on the scoreboard"
          iconName={HiOutlineGlobe}
        />
        <EventCard
           isImage={true}
          src={Group}
          handleNext={() => handleNext()}
          cardBg="#ECF1F4"
          heading="League"
          text="Use your saved templates to create a league"
          iconName={HiOutlineGlobe}
        />
        <EventCard
           isImage={true}
          src={pc}
          handleNext={() => handleNext()}
          cardBg="#ECF1F4"
          heading="Teams Battle"
          text="Play against a team for a seat on the scoreboard"
          iconName={HiOutlineGlobe}
        />
        <EventCard
           isImage={true}
          src={team}
          handleNext={() => handleNext()}
          cardBg="#ECF1F4"
          heading="Find a Scrim"
          text="Find other teams or solo players to test your new battle technique"
          iconName={HiOutlineGlobe}
        />
        <EventCard
           isImage={true}
          src={team}
          handleNext={() => handleNext()}
          cardBg="#ECF1F4"
          heading="Charity"
          text="Let's give back to the esports community!"
          iconName={HiOutlineGlobe}
        />
      </Flex> */}
    </ModalBody>
  );
}
export default memo(Step_1);
