/* eslint-disable */

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  chakra,
  FormErrorMessage,
  useToast,
  Image,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import logo from "assets/img/global/logo.png";

import { post } from "redux/services/api";
import { rootUrl } from "utilities/constants";
import { useDispatch, useSelector } from "react-redux";
function ResetPassword(props: any) {
  const { userId } = (props.location && props.location.state) || {};
  const history = useHistory();

  const toast = useToast();
  const textColor = useColorModeValue("black.50", "white");
  const textColorSecondary = "gray.400";
  const buttonColorBrand = useColorModeValue("blue.200", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [isLoading, setIsLoading] = useState(false);

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [state, setState] = useState({
    ConfirmPassword: "",
    Password: "",
  });

  const [invalidState, setInvalidState] = useState({
    ConfirmPasswordInvalid: false,
    PasswordInvalid: false,
  });
  const updateState = (val: string, prop: string) => {
    setState((prvState) => ({ ...prvState, [prop]: val }));
  };
  const checks = () => {
    if (state.Password.length < 6) {
      setInvalidState((prvState) => ({ ...prvState, PasswordInvalid: true }));
      return false;
    } else if (state.ConfirmPassword != state.Password) {
      setInvalidState((prvState) => ({
        ...prvState,
        ConfirmPasswordInvalid: true,
      }));
      return false;
    }
    return true;
  };
  const resetPassword = async () => {
    if (checks()) {
      setIsLoading(true);

      let url = `${rootUrl}api/v1/account/reset-password`;
      let payload = {
        url,
        data: {
          userId: userId,
          newPassword: state.Password,
          confirmPassword: state.ConfirmPassword,
        },
      };
      const response: any = await post(payload);
      if (response.status === 200 && response.data.statusCode === 200) {
        toast.closeAll()
        toast({
          title: "Reset",
          description: response.data.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        history.push({
          pathname: "/auth/sign-in",
        });
      } else {
        toast.closeAll()
        toast({
          title: "Failed",
          description: response.data.errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Box>
        <Box w={"full"} height="80px">
          <Box px={{ "2xl": "120px" }} maxW="1440px" m="auto">
            <Image src={logo} alt="logo" w={"107px"} h={"50px"} />
          </Box>
        </Box>
        <Flex
          w="578px"
          m="auto"
          h="100%"
          alignItems="center"
          // bg="red.700"
          justifyContent="center"
          mt="36px"
          p="20px"
          flexDirection="column"
          border={"1px solid #E3E4E5"}
          borderRadius="24px"
        >
          <Box m="auto">
            <Heading
              textAlign={"center"}
              color={textColor}
              fontSize="36px"
              w={400}
              mb="10px"
            >
              Reset your password
            </Heading>
            <Text textAlign={"center"} my="20px" color="#7A86A1">
              Please enter your new password
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <FormControl isInvalid={invalidState.PasswordInvalid}>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  borderColor={invalidState.PasswordInvalid ? "red" : null}
                  onChange={(e) => updateState(e.target.value, "Password")}
                  value={state.Password}
                  borderRadius={8}
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Min. 8 characters"
                  mb="10px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                />
                {invalidState.PasswordInvalid && (
                  <FormErrorMessage>
                    Password must contain at least 6 characters .
                  </FormErrorMessage>
                )}
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    borderColor={invalidState.PasswordInvalid ? "red" : null}
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl isInvalid={invalidState.ConfirmPasswordInvalid}>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Confirm Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  borderColor={
                    invalidState.ConfirmPasswordInvalid ? "red" : null
                  }
                  onChange={(e) =>
                    updateState(e.target.value, "ConfirmPassword")
                  }
                  value={state.ConfirmPassword}
                  borderRadius={8}
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Min. 8 characters"
                  mb="10px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              {invalidState.ConfirmPasswordInvalid && (
                <FormErrorMessage>Password does not match</FormErrorMessage>
              )}
              <Flex justifyContent="space-between" align="center" mb="10px">
                <FormControl display="flex" alignItems="center"></FormControl>
              </Flex>

              <Button
                isLoading={isLoading}
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="10px"
                borderRadius={8}
                bg={buttonColorBrand}
                onClick={resetPassword}
              >
                Confirm
              </Button>
            </FormControl>
          </Flex>
        </Flex>
      </Box>
    </DefaultAuth>
  );
}

export default ResetPassword;
