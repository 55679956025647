// Chakra imports
import { Grid, Text, HStack, Icon, useDisclosure } from "@chakra-ui/react";
import Card from "components/card/Card";
import { HSeparator } from "components/separator/Separator";
import { BsThreeDots, BsGlobe, BsCalendarDate } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { TbHeartHandshake } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
import EditProfile from "views/admin/eventPlanner/components/EditProfile";

export default function AboutMyHub(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { openEditProfile } = props;
  // Chakra Color Mode
  const {
    userId,
    userName,
    location,
    description,
    website,
    games,
    logo,
    coverImage,
    title,
    userStastics,
  } = useSelector((state: any) => state.Auth);
  const textColorSecondary_2 = "gray.300";
  const { locations, selectedLocation } = useSelector(
    (state: any) => state.Auth
  );
  return (
    <Card p={0} borderRadius={"10px"} mt="20px">
      <EditProfile onOpen={onOpen} onClose={onClose} isOpen={isOpen} />
      <HStack justifyContent={"space-between"} py="10px" px="20px">
        <Text fontSize={"14px"}>About my hub</Text>
        <Icon
          onClick={onOpen}
          as={FiEdit}
          color={textColorSecondary_2}
          cursor="pointer"
          w="20px"
          h="20px"
          mt="4px"
        />
      </HStack>
      <HSeparator />
      <Grid p="20px">
        <Text fontSize={"13px"}>{description}</Text>
      </Grid>
      <HSeparator />
      <Grid p="20px">
        {selectedLocation?.hubAdress && (
          <HStack justifyContent={"flex-start"}>
            <Icon
              as={HiOutlineLocationMarker}
              color={textColorSecondary_2}
              cursor="pointer"
              w="24px"
              h="24px"
            />
            <Text fontSize={"14px"}>{selectedLocation.hubAdress}</Text>
          </HStack>
        )}
        <HStack justifyContent={"flex-start"} mt="25px">
          <Icon
            as={BsGlobe}
            color={textColorSecondary_2}
            cursor="pointer"
            w="24px"
            h="24px"
          />
          <Text
            cursor={"pointer"}
            textDecorationLine={"underline"}
            onClick={() => window.open(website, "_blank")}
            fontSize={"14px"}
          >
            {website}
          </Text>
        </HStack>
        <HStack justifyContent={"flex-start"} mt="25px">
          <Icon
            as={BsCalendarDate}
            color={textColorSecondary_2}
            cursor="pointer"
            w="24px"
            h="24px"
          />
          <Text fontSize={"14px"}>
            {" "}
            {userStastics?.host ?? 0} events hosted
          </Text>
        </HStack>
        {/* <HStack justifyContent={"flex-start"} mt="25px">
          <Icon
            as={TbHeartHandshake}
            color={textColorSecondary_2}
            cursor="pointer"
            w="24px"
            h="24px"
          />
          <Text fontSize={"14px"}>4.6 / 5 rating</Text>
        </HStack> */}
      </Grid>
    </Card>
  );
}
