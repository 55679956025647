import React, { useState, useCallback, memo } from "react";
import { Button, Text, Flex, Box } from "@chakra-ui/react";

interface Props {
  onChangeNavigation: Function;
}

function EventConfiguration(props: any) {
  const { onChangeNavigation, activeId } = props;

  const { isRegistrationClosed } = props.state;
  const updateNavigation = (id: number) => {
    onChangeNavigation(id);
  };
  return (
    <Flex
      maxW="860px"
      // justifyContent={"space-between"}
      alignItems="center"
      gap='16px'
      flexWrap={'wrap'}
      borderRadius={"4px"}
    >
      <Text fontSize={"12px"} fontWeight="500" color="#1C2A5B">
        Bracket Portal
      </Text>

      <Button
        onClick={() => updateNavigation(0)}
        fontSize="14px"
        variant="outline"
        fontWeight="normal"
        px="12px"
        py="8p"
        bg="transparent"
        isActive={activeId === 0}
        _active={{ bg: "blue.200", color: "#fff" }}
        _hover={{ bg: "blue.200", color: "#fff" }}
        borderRadius="15px"
      >
        Event Details
      </Button>

      <Text fontSize={"12px"} fontWeight="500" color="#1C2A5B">
        Event Configuration
      </Text>
      {!isRegistrationClosed && (
        <>
          <Button
            onClick={() => updateNavigation(1)}
            isActive={activeId === 1}
            _active={{ bg: "blue.200", color: "#fff" }}
            fontSize="14px"
            variant="outline"
            fontWeight="normal"
            px="12px"
            py="8p"
            bg="transparent"
            _hover={{ bg: "blue.200", color: "#fff" }}
            borderRadius="15px"
          >
            Event Settings
          </Button>

          {/* <Button
            onClick={() => updateNavigation(2)}
            isActive={activeId === 2}
            _active={{ bg: "blue.200", color: "#fff" }}
            fontSize="14px"
            variant="outline"
            fontWeight="normal"
            px="12px"
            py="8p"
            bg="transparent"
            _hover={{ bg: "blue.200", color: "#fff" }}
            borderRadius="15px"
          >
            Check-In
          </Button> */}
        </>
      )}
      <Button
        disabled
        // onClick={() => updateNavigation(2)}
        // isActive={activeId === 2}
        _active={{ bg: "transparent", color: "#fff" }}
        fontSize="14px"
        variant="outline"
        fontWeight="normal"
        px="12px"
        py="8p"
        bg="transparent"
        _hover={{ bg: "transparent", color: "#fff" }}
        borderRadius="15px"
      >
        Start Tournament
      </Button>
      {isRegistrationClosed &&<Button
        onClick={() => updateNavigation(3)}
        isActive={activeId === 3}
        _active={{ bg: "blue.200", color: "#fff" }}
        fontSize="14px"
        variant="outline"
        fontWeight="normal"
        px="12px"
        py="8p"
        bg="transparent"
        _hover={{ bg: "blue.200", color: "#fff" }}
        borderRadius="15px"
      >
        Open Bracket
      </Button>}
    </Flex>
  );
}
export default memo(EventConfiguration);
