import React, { memo, useEffect, useState } from "react";
// Chakra imports
import {
  Button,
  Grid,
  Text,
  GridItem,
  Avatar,
  useColorModeValue,
  Flex,
  Box,
  Icon,
  HStack,
} from "@chakra-ui/react";
// import { GoSettings } from "react-icons/go";
import Card from "components/card/Card";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { getAllUsers, getAllLocations } from "redux/slices/Auth";
import EventConfiguration from "views/admin/bracketPortal/components/EventConfiguration";
import EventCard from "../../eventPlanner/components/EventSetup/EventCard";
import { FaUserCog } from "react-icons/fa";
import { HiOutlineSignal } from "react-icons/hi2";
import EventCheckIn from "./EventCheckIn";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { post, get } from "redux/services/api";
import { eventRootUrl } from "utilities/constants";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

function EventSettings(props: any) {
  const dispatch: AppDispatch = useDispatch();
  const { activeId, onChangeNavigation, state, getEventDetail } = props;

  const { allUsers } = useSelector((state: RootState) => state.Auth);
  const { usersListloading, usersList, userRolesList } = useSelector(
    (state: any) => state.AdminsTableLists
  );
  const { challonge_Tournament_Id } = props.state;
  const textColorSecondary = "gray.200";
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const [selectedGame, setselectedGame] = useState(null);
  const buttonColorBrand = useColorModeValue("blue.200", "white");
  const [isLoading, setIsLoading] = useState(false);
  const [moderators, setmoderators] = useState([]);
  const [query, setQuery] = useState("");
  const [loadingIndex, SetloadingIndex] = useState(null);

  const [settingType, setSettingType] = useState("moderators");
  const [invalidState, setInvalidState] = useState({
    selectedGame: false,
  });
  useEffect(() => {
    dispatch(getAllUsers());
  }, []);
  useEffect(() => {
    getModerator();
    if (usersList) {
      console.log({ allUsers });
    }
  }, [usersList]);

  const getModerator = async () => {
    console.log({ challonge_Tournament_Id });

    let url = `${eventRootUrl}api/v1/challonge/get-moderator?challongeTournamentId=${challonge_Tournament_Id}`;

    const response: any = await get(url);

    if (response.data.status === true) {
      setmoderators(response?.data?.response ?? []);
    }

    console.log({ response: response.data.response });
  };
  const handleSetting = (type: string) => {
    setSettingType(type);
  };

  return (
    <Box>
      <Flex w="200px" alignItems={"center"} justifyContent="space-between">
        <Icon
          cursor={"pointer"}
          onClick={() => onChangeNavigation(0)}
          as={MdOutlineKeyboardBackspace}
          color={"blue.200"}
          boxSize={"30px"}
        />

        <Text fontSize={"24px"} color="blue.200" fontWeight={"700"}>
          Event Setting
        </Text>
      </Flex>
      <Text mt="51px" fontSize={"20px"} color="blue.200" fontWeight={"600"}>
        Setup
      </Text>
      <Flex w="700px" justifyContent={"space-between"}>
        <EventCard
          isImage={false}
          // src={publics}
          handleNext={() => onChangeNavigation(4)}
          cardBg="white"
          heading="Set moderators"
          text="Lets get some help for your
          event"
          iconName={FaUserCog}
        />
        <Box w="20px" />
        <EventCard
          isImage={false}
          handleNext={() => onChangeNavigation(5)}
          cardBg="white"
          heading="Set Stations"
          text="Where are these matches being held?"
          iconName={HiOutlineSignal}
          // src={inPerson}
        />
      </Flex>
      <EventCheckIn
        activeId={activeId}
        onChangeNavigation={onChangeNavigation}
        state={state}
        // next={next}
        getEventDetail={getEventDetail}
      />
    </Box>
  );
}

export default memo(EventSettings);
