import React, { useCallback, memo } from "react";
import { HiOutlineGlobe } from "react-icons/hi";
import "../../../../../assets/css/App.css";

import {
  ModalBody,
  Heading,
  useColorModeValue,
  Flex,
  Text,
} from "@chakra-ui/react";
import EventCard2 from "./EventCard2";
interface Props {
  next: Function;
  state: any;
  handleBack: () => void;
}

function Step_2(props: Props) {
  const { next, handleBack, state } = props;
  const textColor = useColorModeValue("black.50", "white");
  const textColorSecondary = "gray.400";
  const handleNext = useCallback(next, []);

  return (
    <ModalBody
    overflowY="auto"
    css={{
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        width: "6px",
        
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#1C2A5B",
        borderRadius: "24px",
      },
    }}
      position={"relative"}
      p="20px"
    >
      <Heading color={textColor} fontSize="36px" w={400} mb="10px">
        Which template are we going with?
      </Heading>
      <Text fontSize={"16px"} fontWeight="normal" color={textColorSecondary}>
        Save time, play more!
      </Text>

      <Flex
        className="outer-wrapper"
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <EventCard2
          handleNext={() => handleNext()}
          btn_2_txt="Remove"
          btn_1_txt="Use Template"
          cardBg="white"
          heading="PUBG Championship"
          iconName={HiOutlineGlobe}
        />
        <EventCard2
          handleNext={() => handleNext()}
          btn_2_txt="Remove"
          btn_1_txt="Use Template"
          cardBg="white"
          heading="PUBG Championship"
          iconName={HiOutlineGlobe}
        />
        <EventCard2
          handleNext={() => handleNext()}
          btn_2_txt="Remove"
          btn_1_txt="Use Template"
          cardBg="white"
          heading="PUBG Championship"
          iconName={HiOutlineGlobe}
        />
        <EventCard2
          handleNext={() => handleNext()}
          btn_2_txt="Remove"
          btn_1_txt="Use Template"
          cardBg="white"
          heading="PUBG Championship"
          iconName={HiOutlineGlobe}
        />
        <EventCard2
          handleNext={() => handleNext()}
          btn_2_txt="Remove"
          btn_1_txt="Use Template"
          cardBg="white"
          heading="PUBG Championship"
          iconName={HiOutlineGlobe}
        />
        <EventCard2
          handleNext={() => handleNext()}
          btn_2_txt="Remove"
          btn_1_txt="Use Template"
          cardBg="white"
          heading="PUBG Championship"
          iconName={HiOutlineGlobe}
        />
        <EventCard2
          handleNext={() => handleNext()}
          btn_2_txt="Remove"
          btn_1_txt="Use Template"
          cardBg="white"
          heading="PUBG Championship"
          iconName={HiOutlineGlobe}
        />
        <EventCard2
          handleNext={() => handleNext()}
          btn_2_txt="Remove"
          btn_1_txt="Use Template"
          cardBg="white"
          heading="PUBG Championship"
          iconName={HiOutlineGlobe}
        />
        <EventCard2
          handleNext={() => handleNext()}
          btn_2_txt="Remove"
          btn_1_txt="Use Template"
          cardBg="white"
          heading="PUBG Championship"
          iconName={HiOutlineGlobe}
        />
        <EventCard2
          handleNext={() => handleNext()}
          btn_2_txt="Remove"
          btn_1_txt="Use Template"
          cardBg="white"
          heading="PUBG Championship"
          iconName={HiOutlineGlobe}
        />
      </Flex>
    </ModalBody>
  );
}
export default memo(Step_2);
