import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  Image,
  useToast,
  Text,
  chakra,
  Box,
  ModalFooter,
  ModalBody,
  Heading,
  useColorModeValue,
  HStack,
  Icon,
  Button,
  Avatar,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Textarea,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import { HiOutlineCamera } from "react-icons/hi";
import { rootUrl } from "utilities/constants";
import TimeChip from "components/TimeChip";
import { useMutationApproveLocation } from "../hooks";
import Reject from "views/admin/ViewHubDetails/components/ApproveHub";
import { BsFileEarmarkTextFill } from "react-icons/bs";

import { post } from "redux/services/api";
import { eventRootUrl } from "utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import pubg_banner2 from "assets/img/global/pubg_banner2.png";
import { RxCross1 } from "react-icons/rx";

import logoWite from "assets/img/global/logoWite.png";
import EventStepper_1 from "assets/img/global/EventStepper_1.png";
// import EventStepper_1 from "assets/img/global/EventStepper_1.png";
import { getProfile } from "redux/slices/Auth";
import Chip from "components/chip/Chip";

import { AppDispatch } from "redux/store";
import { getEventList } from "redux/slices/Events";

interface Props {
  onOpen: Function;
  isOpen: boolean;
  onClose: Function;
  refetch: Function;
  [x: string]: any;
}

export default function LocationView(props: Props) {
  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();
  const {
    mutateAsync,
    isLoading: loadingOnSave,
    isSuccess,
  } = useMutationApproveLocation();

  const { isOpen, onOpen, selectedLocation, onClose, refetch } = props;

  const dispatch: AppDispatch = useDispatch();

  const { userId, aminitiesList } = useSelector((state: any) => state.Auth);

  const toast = useToast();

  const textColor = useColorModeValue("black.50", "white");
  const buttonColorBrand = useColorModeValue("#19BAEE", "white");
  const textColorSecondary_2 = "#333333";
  const brandBlue = "brand.500";
  const textColorSecondary = "gray.400";
  const [aminities, setAminities] = useState([]);

  const [invalidState, setInvalidState] = useState({
    AddressInvalid: false,
  });
  const [isLoading, setisLoading] = useState(false);
  const initialState: any = {
    HubId: userId,
    LocationId: 0,
    HubCoverPhotoPath: "",
    Bio: "",
    Photos: {},
    Website: "www.test.com",
    Timings: [
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: false,
        HubDay: "Monday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: false,
        HubDay: "Tuesday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: false,
        HubDay: "Wednesday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: false,
        HubDay: "Thursday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: false,
        HubDay: "Frida",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: false,
        HubDay: "Saturday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: false,
        HubDay: "Sunday",
      },
    ],
    Amenities: [],
    CovidRules: "",
    Occupancy: 0,
    CleaningProtocol: "",
    Parking: "",
    AnyAdditionalRules: "",
    AttachmentIds: "",
    Latitude: "",
    Longitude: "",
    Address: "",
    Street: "",
    attachments:[]
  };
  const [state, setState] = useState<any>(initialState);
  useEffect(() => {
    console.log({ isSuccess });
    if (isSuccess === true) {
      refetch();
      onClose();
      toast.closeAll()
      toast({
        title: "Hub Approved",
        description: "Approved Successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [isSuccess]);
  useEffect(() => {
    console.log({ selectedLocation });
    if (selectedLocation) {
      let hubTimings = selectedLocation.hubTimings.map((x: any) => {
        return {
          HubStartTime: x.hubStartTime,
          HubEndTime: x.hubEndTime,
          IsClose: x.isClosed,
          HubDay: x.hubDay,
        };
      });
      let hubAmenities = selectedLocation.hubAmenities.map((x: any) => {
        return {
          amenitiesChoice: x.amenitiesChoice,
          AminityId: x.amenityId,
          IsSelected: x.isSelected,
        };
      });
      console.log({ selectedLocation });
      setState((prvState: any) => ({
        // ...prvState,
        // ...selectedLocation,
        attachments: selectedLocation.attachments,
        HubId: userId,
        LocationId: selectedLocation.locationId,
        locationId: selectedLocation.locationId,

        Timings: hubTimings,
        Amenities: hubAmenities,
        CovidRules: selectedLocation.hostGuidelinesDetails[0]?.covidRules,
        Occupancy: selectedLocation.hostGuidelinesDetails[0]?.occupancy,
        CleaningProtocol:
          selectedLocation.hostGuidelinesDetails[0]?.cleaningProtocol,
        Parking: selectedLocation.hostGuidelinesDetails[0]?.parking,
        AnyAdditionalRules:
          selectedLocation.hostGuidelinesDetails[0]?.otherRules,
        Address: selectedLocation.address,
      }));
    }
  }, [selectedLocation]);
  const updateState = (val: string, prop: string) => {
    setState((prvState: any) => ({ ...prvState, [prop]: val }));
  };
  const updateSwitch = (IsClose: boolean, index: number) => {
    let arr = [...state.Timings];
    arr[index].IsClose = !arr[index].IsClose;
    setState((prvState: any) => ({ ...prvState, Timings: arr }));
  };

  const Approve = async () => {
    await mutateAsync(state.locationId);
  };

  const handleStartDate = (time: any, index: number) => {
    let arr = [...state.Timings];
    arr[index].HubStartTime = time;
    setState((prvState: any) => ({
      ...prvState,
      Timings: arr,
    }));
  };
  const handleEndDate = (time: any, index: number) => {
    console.log({ time, index });
    let arr = [...state.Timings];
    arr[index].HubEndTime = time;
    setState((prvState: any) => ({
      ...prvState,
      Timings: arr,
    }));
  };
  return (
    <>
      {isOpenReject === true && (
        <Reject
          refetch={refetch}
          onOpen={onOpenReject}
          onClose={onCloseReject}
          isOpen={isOpenReject}
          ishub={false}
          id={state.locationId}
        />
      )}

      {isOpen === true && (
        <Modal
        isCentered
          // closeOnOverlayClick={false}
          scrollBehavior="inside"
          size={"4xl"}
          isOpen={isOpen}
          onClose={() => {
            setState(initialState);
            onClose();
          }}
        >
          <ModalOverlay />
          <ModalContent borderRadius="14px" position={"relative"} p={0}>
            <ModalHeader h={"80px"} p="0px" borderRadius="14px">
              <Box
                borderTopRadius="14px"
                // borderBottomRadius="16px"
                justifyContent={"space-between"}
                bg="#1C2A5B"
                alignItems="center"
                py="20px"
                px="50px"
              >
                <Text
                  color={"white"}
                  fontSize="24px"
                  fontWeight={"600"}
                  mt="10px"
                >
                  {state.Address}
                </Text>
              </Box>
            </ModalHeader>
            <ModalBody
              overflowY="auto"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#D2D2D2",
                  borderRadius: "24px",
                },
              }}
              position={"relative"}
              p="35px"
            >
              <Box justifyContent={"space-between"}>
                <Box w={"100%"}>
                  <Box>
                    <HStack justifyContent={"space-between"} py="7px">
                      <Text
                        color={textColorSecondary_2}
                        fontWeight="600"
                        fontSize={"14px"}
                      >
                        Timings
                      </Text>
                    </HStack>
                    <Flex
                      w="full"
                      justifyContent={"space-between"}
                      flexWrap={"wrap"}
                    >
                      {state.Timings.map((x: any, i: number) => {
                        return (
                          <HStack
                            key={i}
                            justifyContent={"space-between"}
                            py="7px"
                          >
                            <Switch
                              isReadOnly
                              onChange={() => updateSwitch(x.IsClose, i)}
                              isChecked={!x.IsClose}
                              colorScheme={"brand"}
                              w="50px"
                              id="email-alerts"
                            />
                            <Text
                              w="100px"
                              color={textColorSecondary_2}
                              fontSize={"14px"}
                            >
                              {x.HubDay}
                            </Text>
                            <TimeChip
                              isReadOnly={true}
                              index={i}
                              handleStartDate={handleStartDate}
                              handleEndDate={handleEndDate}
                              HubStartTime={x.HubStartTime}
                              HubEndTime={x.HubEndTime}
                              innerTextColor={brandBlue}
                              IsClose={x.IsClose}
                              label={`${x.HubStartTime} - ${x.HubEndTime}`}
                              minW="150px"
                              bg="#F4FAFF"
                            />
                          </HStack>
                        );
                      })}
                    </Flex>
                  </Box>
                </Box>
                <Box w={"100%"}>
                  <Box>
                    <HStack py="7px">
                      <Text
                        color={textColorSecondary_2}
                        fontWeight="600"
                        fontSize={"14px"}
                      >
                        Host Guidelines
                      </Text>
                    </HStack>
                    <FormLabel
                      display="flex"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                      pl="0px"
                    >
                      Occupancy
                    </FormLabel>
                    <Input
                      onChange={(e) => updateState(e.target.value, "Occupancy")}
                      value={state.Occupancy}
                      isRequired={true}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="number"
                      borderRadius={8}
                      isReadOnly
                      mb="24px"
                      fontWeight="500"
                      border="0px"
                      pl="0px"
                      placeholder="Total Occupancy"
                      size="lg"
                    />

                    <FormLabel
                      display="flex"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Health Protocols
                    </FormLabel>
                    <Input
                      onChange={(e) =>
                        updateState(e.target.value, "CovidRules")
                      }
                      value={state.CovidRules}
                      isRequired={true}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="text"
                      isReadOnly
                      borderRadius={8}
                      mb="24px"
                      fontWeight="500"
                      placeholder="Mask up!"
                      size="lg"
                      border="0px"
                      pl="0px"
                    />
                    <FormLabel
                      display="flex"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Cleaning Protocols
                    </FormLabel>
                    <Input
                      onChange={(e) =>
                        updateState(e.target.value, "CleaningProtocol")
                      }
                      value={state.CleaningProtocol}
                      isRequired={true}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="text"
                      borderRadius={8}
                      isReadOnly
                      mb="24px"
                      fontWeight="500"
                      placeholder="Any cleaning rules?"
                      size="lg"
                      border="0px"
                      pl="0px"
                    />
                    <FormLabel
                      display="flex"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Parking
                    </FormLabel>
                    <Input
                      onChange={(e) => updateState(e.target.value, "Parking")}
                      value={state.Parking}
                      isRequired={true}
                      variant="auth"
                      fontSize="sm"
                      isReadOnly
                      ms={{ base: "0px", md: "0px" }}
                      type="text"
                      borderRadius={8}
                      mb="24px"
                      fontWeight="500"
                      placeholder="Park properly..."
                      size="lg"
                      border="0px"
                      pl="0px"
                    />
                    <FormLabel
                      display="flex"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Any Additional Rules
                    </FormLabel>
                    <Input
                      onChange={(e) =>
                        updateState(e.target.value, "AnyAdditionalRules")
                      }
                      value={state.AnyAdditionalRules}
                      isRequired={true}
                      isReadOnly
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="text"
                      pl="0px"
                      borderRadius={8}
                      mb="24px"
                      fontWeight="500"
                      placeholder="Get specific!"
                      size="lg"
                      border="0px"
                    />
                  </Box>
                  <Box>
                    <HStack justifyContent={"space-between"} py="7px">
                      <Text
                        color={textColorSecondary_2}
                        fontSize={"14px"}
                        fontWeight="600"
                      >
                        Amenities
                      </Text>
                    </HStack>
                    <Flex
                      flexWrap={"wrap"}
                      justifyContent={"flex-start"}
                      alignItems="center"
                      py="7px"
                      // px="20px"
                    >
                      {state.Amenities.map((item: any, index: number) => {
                        return (
                          <Chip
                            onClick={() => {}}
                            key={index}
                            cursor="pointer"
                            innerTextColor={"white"}
                            label={item.amenitiesChoice}
                            px="10px"
                            m="2px"
                            bg={
                              item.IsSelected ? brandBlue : textColorSecondary
                            }
                            // _hover={{ bg: textColorSecondary }}
                          />
                        );
                      })}
                    </Flex>
                    <HStack justifyContent={"space-between"} py="7px">
                      <Text
                        fontWeight="600"
                        color={textColorSecondary_2}
                        fontSize={"14px"}
                      >
                        Documents
                      </Text>
                    </HStack>
                  </Box>
                  <Flex
                    py="20px"
                    flexWrap={"wrap"}
                    justifyContent={"flex-start"}
                    alignItems="center"
                  >
                    {state?.attachments?.map((key: any) => {
                      return (
                        <Box
                          key={key}
                          
                          mr="10px"
                          position={"relative"}
                          onClick={()=> window.open(key.mediaFilePath, '_blank', 'noopener,noreferrer')}
                        >
                          
                          <Icon
                            color={"#FF7763"}
                            _hover={{ cursor: "pointer" }}
                            boxSize={"50px"}
                            as={BsFileEarmarkTextFill}
                          />
                          <Text   noOfLines={1}>
                            {key.mediaFileName}
                          </Text>
                        </Box>
                      );
                    })}
                  </Flex>
                </Box>
              </Box>
            </ModalBody>
            <ModalFooter px="50px">
              <Flex
                w="full"
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Button
                  // isLoading={loaing}
                  loadingText="Loading..."
                  onClick={() => {
                    onClose();
                    onOpenReject();
                  }}
                  fontSize="16px"
                  variant="brand"
                  fontWeight="700"
                  w="276px"
                  h="50"
                  color={"white"}
                  bg="#FF5247"
                  border={`0px solid  black`}
                  borderRadius={"10px"}
                  _hover={{ bg: "#FF5247" }}
                  _active={{ bg: "#FF5247" }}
                  _focus={{ bg: "#FF5247" }}
                >
                  Reject
                </Button>

                <Button
                  fontSize="16px"
                  variant="brand"
                  fontWeight="700"
                  isLoading={loadingOnSave}
                  loadingText="Updating..."
                  onClick={Approve}
                  w="276px"
                  h="50"
                  borderRadius={"10px"}
                  bg="#31D0AA"
                  border={`0px solid  black`}
                  _hover={{ bg: "#31D0AA" }}
                  _active={{ bg: "#31D0AA" }}
                  _focus={{ bg: "#31D0AA" }}
                >
                  Approve
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
