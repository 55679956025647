import React, { useState, memo } from "react";
// Chakra imports
import { Button, Grid, Flex, Text, Box, Icon, HStack } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import {
  HiOutlineCake,
  HiOutlineLocationMarker,
  HiOutlineClock,
  HiCurrencyDollar,
  HiArrowCircleLeft,
} from "react-icons/hi";

import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";

// Assets
import banner from "assets/img/global/pubg_banner2.png";

interface Props {
  tabIndex: number;
  handleTabsChange: any;
}
function EventDetailsBanner(props: any) {
  const history = useHistory();
  
  const {
    isEventClosed,
    coverPath,
    eventLocation,
    tournament_Name,
    isPaid,
    isFree,
    entryFee,
    start_Time,
    end_Time,
    startDate,
  } = props.state;
  // console.log({coverPath})
  return (
    <Box>
      <Grid
        bgImage={coverPath ? coverPath?.replace("\\", "/") : banner}
        bgSize="cover"
        position="relative"
        h={"300px"}
        borderRadius="10px"
      >
        <Flex
          borderRadius="20px"
          w="100%"
          h="100%"
          bg="rgba(0,0,0,0.3)"
          justifyContent={"space-between"}
        >
          <Flex
            px="30px"
            py="15px"
            direction={"column"}
            justifyContent="space-between"
          >
            <Flex justifyContent={"flex-start"} alignItems="center" minW="425px">
              <Icon
                cursor={"pointer"}
                onClick={() => history.goBack()}
                as={HiArrowCircleLeft}
                color={"#ECF1F4"}
                boxSize={"30px"}
              />
              <Box ml="16px">
                <Text
                  color={"white"}
                  textAlign="center"
                  fontSize="32px"
                  fontWeight={"bold"}
                >
                  {tournament_Name ?? ""}
                </Text>
              </Box>
              <Box
                ml="16px"
                bg="#FF5247"
                px={"15px"}
                py="8px"
                borderRadius="47px"
              >
                <Text color={"#fff"} fontSize="16px" fontWeight={"500"}>
                  {isEventClosed ? "Closed" : "Open"}
                </Text>
              </Box>
            </Flex>

            <Flex minW="470px" justifyContent={"space-between"}>
              <HStack>
                <Icon as={HiOutlineCake} color={"white"} boxSize={"15px"} />
                <Text fontSize={"17px"} color="white">
                  {startDate ? moment(startDate).format("ll") : "Start date"}
                </Text>
              </HStack>
              <HStack ml="24px">
                <Icon as={HiOutlineClock} color={"white"} boxSize={"15px"} />
                <Text fontSize={"17px"} color="white">
                  {start_Time ? start_Time + " Start" : "Start time"}
                </Text>
              </HStack>
              <HStack ml="24px">
                <Icon
                  as={HiOutlineLocationMarker}
                  color={"white"}
                  boxSize={"15px"}
                />
                <Text fontSize={"17px"} color="white">
                  {eventLocation ?? "Event Location"}
                </Text>
              </HStack>
            </Flex>
          </Flex>
          <Flex
            direction={"column"}
            alignItems={"flex-end"}
            justifyContent="space-between"
          >
            {/* <HStack
              justifyContent={"center"}
              alignItems="center"
              borderTopLeftRadius={"10px"}
              bg={"#31D0AA"}
              w="145px"
              h={"45px"}
            >
              <Icon
                as={HiCurrencyDollar}
                color={"white"}
                cursor="pointer"
                boxSize={"17px"}
              />
              <Text fontSize={"17px"} color="white">
                {isPaid ? entryFee : "Free"}
              </Text>
            </HStack> */}
          </Flex>
        </Flex>
      </Grid>
    </Box>
  );
}

export default memo(EventDetailsBanner);
