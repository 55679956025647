import React, { useMemo, memo } from "react";
import { HiOutlineGlobe, HiOutlineLocationMarker,HiCalendar } from "react-icons/hi";
import "../../../../../assets/css/App.css";
//
import {
  ModalBody,
  Heading,
  useColorModeValue,
  Flex,
  Text,
  HStack,
  Icon,
  Box,
  chakra,
  Button,
  ModalFooter,
} from "@chakra-ui/react";
import { CgNotes } from "react-icons/cg";
import pubg_banner2 from "assets/img/global/pubg_banner2.png";
import EventCard from "./EventCard";
import moment from "moment";

interface Props {
  next: Function;
  handleBack: () => void;
  onClose: Function;
  handleSteps: Function;
  loaing: boolean;
  parantState: any;
}

function Step_9(props: Props) {
  const { next, handleSteps, loaing, parantState } = props;
  const textColor = useColorModeValue("black.50", "white");
  const textColorBrand = useColorModeValue("blue.100", "white");
  const buttonColorBrand = "blue.200";
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  return (
    <>
      <ModalBody
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
            
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#D2D2D2",
            borderRadius: "24px",
          },
        }}
        position={"relative"}
        p="20px"
      >
        <Heading color={textColor} fontSize="36px" w={400} mb="10px">
          Event Review
        </Heading>
        <Flex
          bgImage={parantState.previewCover ?? (parantState?.selectedGame?.background_image || pubg_banner2)}
          bgSize="cover"
          position="relative"
          h={"187px"}
          // borderTopRadius="20px"
        >
          <Flex w="100%" h="100%" bg="rgba(0,0,0,0.3)">
            <Flex
              px="20px"
              w="full"
              alignItems={"flex-end"}
              justifyContent="space-between"
            >
              <Box ml={"20px"} mb={"20px"}>
                <Text color={"white"} fontWeight='500' fontSize="20px">
                  {parantState.tournament_Name ?? "Game Name"}
                </Text>
                <Flex justifyContent={"space-between"} alignItems="center">
                  <HStack>
                    <Icon
                      as={HiCalendar}
                      color={"white"}
                      boxSize={"15px"}
                    />
                    <Text fontSize={"12px"} color="white">
                      {moment(parantState.startDate).format('LL') +
                        ". " +
                        moment(parantState.start_Time,"H:mm").format("hh:mm:ss, A") ?? "Jan 12 | 7pm"}
                    </Text>
                  </HStack>
                  <HStack ml="10px">
                    <Icon
                      as={HiOutlineLocationMarker}
                      color={"white"}
                      boxSize={"15px"}
                    />
                    <Text fontSize={"12px"} color="white">
                      {parantState.eventLocation ??
                        " 9696 Gamergig Rd. Hub, TX"}
                    </Text>
                  </HStack>
                </Flex>
              </Box>
              {/* <Box mb={"20px"}>
              <Text color={"white"} fontSize="15px">
                Initial Sponsorship
              </Text>
              <Text fontSize={"25px"} color="#31D0AA">
                $180
              </Text>
            </Box> */}
            </Flex>
            <Flex alignItems={"flex-start"} justifyContent="flex-end"></Flex>
          </Flex>
        </Flex>
        <Flex flexWrap={"wrap"} justifyContent={"space-between"}>
          <EventCard
            handleNext={() => handleSteps(3)}
            cardBg="white"
            heading="Information"
            text="Names and descriptions"
            iconName={CgNotes}
          />
          <EventCard
            handleNext={() => handleSteps(7)}
            cardBg="white"
            heading="Games"
            text="Crazy fun games"
            iconName={CgNotes}
          />
          <EventCard
            handleNext={() => handleSteps(5)}
            cardBg="white"
            heading="Location & Rules"
            text="who, what, when, where, how"
            iconName={CgNotes}
          />
          <EventCard
            handleNext={() => handleSteps(8)}
            cardBg="white"
            heading="Media & Attachments"
            text="Links and stuff"
            iconName={CgNotes}
          />

          <EventCard
            handleNext={() => handleSteps(6)}
            cardBg="white"
            heading="Date & Time"
            text="Schedules"
            iconName={CgNotes}
          />
            <EventCard
            handleNext={() => handleSteps(9)}
            cardBg="white"
            heading="Set Tournament"
            text="Single Elimination, Round Robin"
            iconName={CgNotes}
          />
        </Flex>
      </ModalBody>
      <ModalFooter px="50px">
        <Flex w="full" justifyContent={"space-between"} alignItems="center">
          <Button
            disabled
            isLoading={loaing}
            loadingText="Loading..."
            onClick={() => next("Draft")}
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="106px"
            h="50"
            bg="transparent"
            borderRadius={8}
            border={`1px solid `}
            color={"black"}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
            borderColor={"#E2E2E2"}
          >
            Draft
          </Button>
          <Text
            cursor={"pointer"}
            // onClick={() => onClose()}
            opacity='0.3'
            textAlign={"center"}
            color={textColorBrand}
            fontSize="15px"
          >
            Save event template
          </Text>
          <Button
            isLoading={loaing}
            loadingText="Loading..."
            onClick={() => next("create")}
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="276px"
            h="50"
            borderRadius={8}
            bg={buttonColorBrand}
          >
            Publish
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
}
export default memo(Step_9);
