import { useQuery, useMutation, useQueryClient } from "react-query";
import { post, put, get } from "../../../redux/services/api";
import { hubRootUrl, eventRootUrl } from "../../../utilities/constants";

interface payload {
  hubUserId: number;
  locationId: number;
}
const fetchEventList = async (payload: payload) => {
  const { locationId, hubUserId } = payload;
  const { data } = await get(
    `${eventRootUrl}api/v1/challonge/hub-tournament-summary-list?otherUserId=${hubUserId}&hubLocationId=${locationId}&isHub=true`
  );
  return data;
};

export const useEvents = (payload: payload) => {
  const { locationId, hubUserId } = payload;
  return useQuery({
    queryKey: ["fetchEventList", locationId, hubUserId],
    queryFn: () => fetchEventList(payload),
    refetchOnWindowFocus: false,
    // enabled: locationId ? true : false,
  });
};
