// Chakra imports
import { Flex, Container, Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
function AuthIllustration(props: {
  children: JSX.Element | string;
  illustrationBackground: string;
  stepImage?: string;
}) {
  const { children } = props;
  // Chakra color mode
  return (
    <Box
      mt={{ base: "40px", md: "26px" }}
      // bg="yellow.500"
    >
      {children}
    </Box>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
