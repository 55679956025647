import { useQuery, useMutation, useQueryClient } from "react-query";
import { post, put, get } from "../../../redux/services/api";
import { hubAdminUrl_table } from "../../../utilities/constants";
// const queryClient = useQueryClient()

interface payload {
  PageSize: number;
  PageNumber: number;
  search:string
}
const fetchUserList = async (payload: payload) => {
  const { PageNumber, PageSize,search } = payload;
  const { data } = await get(
    // `${hubAdminUrl_table}/v1/Complain?PageSize=${PageSize}&PageNumber=${PageNumber}&LocationId=${locationId}`
    `${hubAdminUrl_table}api/v1/user/admin-get-users-list?PageNumber=${PageNumber}&PageSize=${PageSize}&SearchString=${search}&Flag=${1}`
  );
  return data;
};

export const useUser = (payload: payload) => {
  const { PageNumber, PageSize ,search} = payload;
  return useQuery({
    queryKey: ["fetchUserList", PageNumber,PageSize,search],
    queryFn: () => fetchUserList(payload),
    refetchOnWindowFocus: false,
  });
};

// export default useComplain
