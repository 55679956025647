import { useEffect, memo, useState } from "react";

import {
  ModalBody,
  Heading,
  useColorModeValue,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Button,
  Input,
  Textarea,
  FormErrorMessage,
  HStack,
  Box,
  Radio,
  RadioGroup,
  Stack,
  ModalFooter,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import Chip from "components/chip/Chip";
import Autocomplete from "react-google-autocomplete";
interface Props {
  next: Function;
  handleBack: () => void;
  parantState: any;
}

function Step_5(props: Props) {
  const { next, handleBack, parantState } = props;
  console.log({parantState})
  const [amenitiesList, setAmenitiesList] = useState([]);
  const textColor = useColorModeValue("black.50", "white");
  const { userId, locations, aminitiesList, selectedLocation } = useSelector(
    (state: any) => state.Auth
  );
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const brandBlue = "brand.500";
  const buttonColorBrand = "blue.200";
  const [aminitiesArr, setAminitiesArr] = useState([]);
  const [state, setState] = useState({
    eventLocation: "",
    rules: "",
    
    eventType: "",
  });
  const [invalidState, setInvalidState] = useState({
    eventLocationInvalid: false,

    rulesInvalid: false,
  });
  useEffect(() => {
    if (aminitiesList.length) {
      let arr = aminitiesList.map((x: any) => {
        return {
          ...x,
          AminityId: x.amenityId,
          IsSelected: false,
        };
      });
      setAminitiesArr(arr);
      setAmenitiesList(arr)
     
    }
  }, [aminitiesList]);
  useEffect(() => {
    // if (parantState.eventLocation || parantState.rules) {
      setState((prvState) => ({
        ...parantState,
        eventLocation:
          parantState.eventType === "Online Event"
            ? parantState.eventLocation
            : selectedLocation.hubAdress,
           
      }));
      if(parantState?.amenitiesList){
        setAmenitiesList(parantState?.amenitiesList??[])
      }
    // }
  }, [parantState]);
  const updateState = (val: any, prop: string, invalidProp?: string) => {
    setState((prvState: any) => ({ ...prvState, [prop]: val }));
    if (invalidProp) {
      setInvalidState((prvState: any) => ({
        ...prvState,
        [invalidProp]: false,
      }));
    }
  };

  const checks = () => {
    if (state.eventLocation === "") {
      setInvalidState((prvState) => ({
        ...prvState,
        eventLocationInvalid: true,
      }));
      return false;
    } else if (state.rules === "" || state.rules.length < 50) {
      setInvalidState((prvState) => ({
        ...prvState,
        rulesInvalid: true,
      }));
      return false;
    }
    return true;
  };
  const handleNext = () => {
    if (checks()) {
      let obj= {...state,amenitiesList:amenitiesList }
      console.log({obj})
      next(obj);
    }
  };
  const addAminities = (index: number) => {
    let arr = [...amenitiesList];
    arr = arr.map((x, i) => {
      return {
        ...x,
        IsSelected: i === index ? !x.IsSelected : x.IsSelected,
      };
    });
    
    setAmenitiesList(arr)
   
  };
  return (
    <>
      <ModalBody
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            width: "8px",

            border: "1px solid",
            borderRadius: "24px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#1C2A5B",
            borderRadius: "24px",
          },
        }}
        position={"relative"}
        px="50px"
        py="24px"
      >
        {state.eventType != "Online Event" ?
          <Heading color={textColor} w="full" fontWeight={"600"} fontSize="24px">
            Location, Rules & Amenities
          </Heading> 
          :
          <Heading color={textColor} w="full" fontWeight={"600"} fontSize="24px">
            Location & Rules
          </Heading>
          }

        <Text fontWeight={"400"} color="#9299B0" mt="12px" fontSize="20px">
          Where are you hosting your event?
        </Text>
        {/* <RadioGroup mt="24px" onChange={() => setValue("1")} value={value}>
          <Stack direction="row">
            <Radio value="1">My place</Radio>
            <Radio value="">Hub</Radio>
          </Stack>
        </RadioGroup> */}
        <FormControl mt="24px" isInvalid={invalidState.eventLocationInvalid}>
          {/* <FormLabel
            //  textTransform={"uppercase"}
            color={textColor}
            fontSize={"14px"}
            fontWeight="semibold"
            mb="8px"
          >
            Location<Text color={brandStars}>*</Text>
          </FormLabel> */}
          <FormLabel
            mt="70px"
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="semibold"
            // textTransform={"uppercase"}
            color={textColor}
            mb="8px"
          >
            Location<Text color={brandStars}>*</Text>
          </FormLabel>
          {state.eventType === "Online Event"&&
           <Text color={"#9299B0"} fontSize={"12px"}>
         Add a web address for people to join your event
         </Text>
          }
          <Input
            borderColor={invalidState.eventLocationInvalid ? "red" : null}
            onChange={(e) =>
              updateState(
                e.target.value,
                "eventLocation",
                "eventLocationInvalid"
              )
            }
            isReadOnly={state.eventType === "Online Event" ? false : true}
            value={state.eventLocation}
            isRequired={true}
            variant="auth"
            placeholder={state.eventType === "Online Event"?"Online Address":"9696 Gamer Rd. Hub, TX"}
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            borderRadius={"16px"}
            // mb="10px"
            fontWeight="semibold"
            size="lg"
          />
          {/* <Autocomplete
          apiKey={"YOUR_GOOGLE_MAPS_API_KEY"}
          onPlaceSelected={(place) => {
            console.log(place);
          }}
        /> */}

          {invalidState.eventLocationInvalid && (
            <FormErrorMessage> Event location is required.</FormErrorMessage>
          )}
        </FormControl>

       {state.eventType != "Online Event"&& <Box mt="24px">
          <HStack justifyContent={"space-between"} py="7px">
            <Text
              // textTransform={"uppercase"}
              color={textColor}
              fontSize={"14px"}
              fontWeight="semibold"
            >
              Amenities
            </Text>
          </HStack>
          <Flex
            flexWrap={"wrap"}
            justifyContent={"flex-start"}
            alignItems="center"
            py="7px"
            // px="20px"
          >
            {   amenitiesList.map((item: any, index: number) => {
              return (
                <Chip
                  onClick={() => addAminities(index)}
                  key={item.amenityId}
                  cursor="pointer"
                  innerTextColor={"white"}
                  label={item.amenitiesChoice}
                  px="10px"
                  m="2px"
                  bg={item.IsSelected ? brandBlue : textColorSecondary}
                  _hover={{ bg: brandBlue }}
                />
              );
            })}
          </Flex>
        </Box>}
        <FormControl mt="24px" isInvalid={invalidState.rulesInvalid}>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="semibold"
            // textTransform={"uppercase"}
            color={textColor}
            mb="8px"
          >
            Rules<Text color={brandStars}>*</Text>
          </FormLabel>
          <Textarea
            borderColor={invalidState.rulesInvalid ? "red" : null}
            onChange={(e) =>
              updateState(e.target.value, "rules", "rulesInvalid")
            }
            value={state.rules}
            borderRadius={8}
            isRequired={true}
            fontSize="sm"
            placeholder="Enter your rules"
            size="lg"
            border={`1px solid `}
            minH="200px"
            // mb="10px"
            fontWeight="500"
          />
          <Flex justifyContent={"flex-end"}>
            <Text fontSize={"15px"} color={textColorSecondary}>
              {state.rules.length}/500 characters
            </Text>
          </Flex>
          {invalidState.rulesInvalid && (
            <FormErrorMessage>
              {" "}
              Rules are required (minimum 50 words).
            </FormErrorMessage>
          )}
        </FormControl>
        {/* <FormControl maxW={"60%"}>
        <HStack w="50%" py="20px" justifyContent={"space-between"}>
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="106px"
            h="50"
            borderRadius={8}
            bg={buttonColorBrand}
            onClick={() => handleNext()}
          >
            Next
          </Button>
          <Text>or</Text>
          <Button
            onClick={handleBack}
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="106px"
            h="50"
            bg="transparent"
            borderRadius={8}
            border={`1px solid `}
            color={buttonColorBrand}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
            borderColor={buttonColorBrand}
          >
            Back
          </Button>
        </HStack>
      </FormControl> */}
      </ModalBody>
      <ModalFooter px="50px">
        <Flex w="full" justifyContent={"space-between"}>
          <Button
            onClick={handleBack}
            fontSize="sm"
            variant="brand"
            fontWeight="700"
            w="276px"
            h="50"
            bg="transparent"
            borderRadius={8}
            border={`1px solid `}
            color={"black"}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
            borderColor={"black"}
          >
            Back
          </Button>

          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="276px"
            h="50"
            onClick={() => handleNext()}
            borderRadius={8}
            bg={buttonColorBrand}
          >
            Continue
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
}
export default memo(Step_5);
