// Chakra imports
import { Text, useDisclosure, chakra } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Card from "components/card/Card";
import ViewLocationRejection from "./ViewLocationRejection";
export default function ProfileApproved() {
  const { locations, selectedLocation, userId } = useSelector(
    (state: any) => state.Auth
  );
  // Chakra Color Mode
  const textColorSecondary = "gray.200";
  const textColorSecondary_2 = "gray.300";
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Card borderRadius={"10px"} mt="10px">
      <ViewLocationRejection onOpen={onOpen} onClose={onClose} isOpen={isOpen} />
      <Text fontSize={"14px"}>Location Approval Status</Text>
      <Text fontSize={"14px"} mt="10px"
      color={selectedLocation?.locationStatus==="Rejected"? "#FF5247": selectedLocation?.locationStatus==="Pending"? "#F5901A": "#31D0AA"}
     
      
      >
        {selectedLocation?.locationStatus + " "}
        {selectedLocation?.locationStatus === "Rejected" && (
          <chakra.span color={"#298BE2"} cursor="pointer" onClick={onOpen}>
            (View Detail)
          </chakra.span>
        )}{" "}
      </Text>
    </Card>
  );
}
