import { useState, useCallback, useEffect } from "react";

// Chakra imports
import { Box, Grid, GridItem, useDisclosure, Select } from "@chakra-ui/react";
import { FORMS_ID } from "utilities/constants";
import Banner from "views/admin/eventPlanner/components/Banner";
import ProfileApproved from "views/admin/eventPlanner/components/ProfileApproved";
import LocationApproved from "views/admin/eventPlanner/components/LocationApproved";

import AboutMyHub from "views/admin/eventPlanner/components/AboutMyHub";
import PhotosAndVideos from "views/admin/eventPlanner/components/PhotosAndVideos";
import FullCalendar from "views/admin/eventPlanner/components/FullCalendar";
import EventPlanner from "views/admin/eventPlanner/components/EventSetup";
import EditLocation from "views/admin/eventPlanner/components/EditLocation";

import { getProfile } from "redux/slices/Auth";
import { getAllLocations, getAminitiesList } from "redux/slices/Auth";
import { useEvents } from "./hooks";
import {
  getPrivacyPolicy,
  getTermsConditions,
} from "redux/slices/AdminsTableLists";
import { getGameList } from "redux/slices/Events";

import { onChangeSelectedLocation } from "redux/slices/Auth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
export default function Marketplace() {
  // const { data, isLoading, isSuccess, isError, error, isFetching } = useEvents({
  //   locationId: selectedLocation?.locationId,
  //   hubUserId: userId,
  // });
  const { locations, selectedLocation, userId, isHubApproved,  } = useSelector(
    (state: any) => state.Auth
  );
  const { data, isLoading, refetch,isFetching } = useEvents({
    locationId: selectedLocation?.locationId,
    hubUserId: userId,
  });
  const dispatch: any = useDispatch();

  // Chakra Color Mode
  const [tabIndex, setTabIndex] = useState(0);
  const [editState, setEditState] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();
  useEffect(() => {
    dispatch(getProfile());
    dispatch(getAllLocations());
    dispatch(getGameList());
    dispatch(getAminitiesList());
    dispatch(getPrivacyPolicy());
    dispatch(getTermsConditions());
  }, []);
  useEffect(() => {
    if (locations) {
      let index = selectedLocation
        ? locations.findIndex(
            (x: any) => x.locationId == selectedLocation?.locationId
          )
        : 0;
      let location = locations[index];
      console.log({ index });
      dispatch(onChangeSelectedLocation(location));
    }
  }, [locations]);
  const handleTabsChange = useCallback(
    (index: number) => {
      setTabIndex(index);
    },
    [tabIndex]
  );
  const onEditEvent = (state: any) => {
    console.log({ state });
    setEditState(state);
  };
  const onSelectLocation = (e: any) => {
    console.log({ e: e.target.value });
    let index = locations.findIndex((x: any) => x.locationId == e.target.value);
    let location = locations[index];
    console.log({ index });
    dispatch(onChangeSelectedLocation(location));
  };
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={12} borderRadius="10px">
          <Banner
            onOpenDrawer={onOpenDrawer}
            handleTabsChange={handleTabsChange}
            tabIndex={tabIndex}
          />

          <EditLocation
            isOpen={isOpenDrawer}
            onOpen={onOpenDrawer}
            onClose={onCloseDrawer}
          />
          <Grid templateColumns="repeat(12, 1fr)" gap={4} mt="20px">
            <GridItem colSpan={3}>
              <ProfileApproved />
              {locations.length > 0 && <LocationApproved />}
              <AboutMyHub />
              {/* <PhotosAndVideos /> */}
            </GridItem>
            <GridItem colSpan={9} borderRadius="10px">
              {isHubApproved === "Approved" &&
                selectedLocation?.locationStatus === "Approved" && (
                  <FullCalendar data={data} isFetching={isFetching} onEditEvent={onEditEvent} onOpen={onOpen} />
                )}

              <EventPlanner
              refetch={refetch}
                editState={editState}
                onOpen={onOpen}
                onClose={onClose}
                isOpen={isOpen}
              />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Box>
  );
}
