import React, { memo, useEffect, useState } from "react";
// Chakra imports
import {
  Button,
  Grid,
  Text,
  GridItem,
  Avatar,
  Input,
  Flex,
  Box,
  Icon,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  FormControl,
  FormLabel,
  useColorModeValue,
  useDisclosure,
  Checkbox,
  Tag,
  TagLabel,
  TagCloseButton,
  Spinner,
} from "@chakra-ui/react";
// import { GoSettings } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { Table, Pagination, Input as InputAntd } from "antd";
import { useUser } from "../../UsersList/hooks";
import { Spin } from "antd";
import { post, deleteReq } from "redux/services/api";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { getAllUsers, getAllLocations } from "redux/slices/Auth";

import { get } from "redux/services/api";
import { eventRootUrl } from "utilities/constants";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
const { Search } = InputAntd;
function EventSettings(props: any) {
  const [search, setSearch] = useState({
    page: 1,
    per_page: 100,
    search: "",
  });
  const {
    data,
    isLoading: isLoadingUsers,
    isSuccess,
    isError,
    error,
    isFetching,
  } = useUser({
    PageNumber: 1,
    PageSize: 50,
    search: "",
  });
  const { data: searchData, isLoading: searching } = useUser({
    PageNumber: search.page,
    PageSize: search.per_page,
    search: search.search,
  });
  const onSearch = (value: string) => {
    const name = value?.toString() || "";
    console.log({ value });
    setSearch({
      ...search,
      search: name,
      page: 1,
    });
  };
  const userTransactions = data?.response?.userList || [];
  const searchDataTransactions = searchData?.response?.userList || [];
  const textColor = useColorModeValue("black.50", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const dispatch: AppDispatch = useDispatch();
  const { activeId, onChangeNavigation, getEventDetailInside } = props;
  const [AddmoderatorsList, SetAddmoderatorsList] = useState([]);

  const { allUsers } = useSelector((state: RootState) => state.Auth);
  const { usersListloading, usersList, userRolesList } = useSelector(
    (state: any) => state.AdminsTableLists
  );
  const { challonge_Tournament_Id, id } = props.state;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingIndex, setisLoadingIndex] = useState(-1);
  const [moderators, setmoderators] = useState([]);
  const [query, setQuery] = useState("");
  const [loadingIndex, SetloadingIndex] = useState(null);

  const [settingType, setSettingType] = useState("moderators");
  const [participantList, SetparticipantListArr] = useState([]);

  useEffect(() => {
    getModerator();
    getCheckedInUser();
    if (usersList) {
      console.log({ allUsers });
    }
  }, [usersList]);
  const [invalidState, setInvalidState] = useState({
    LastNameInvalid: false,
    FirstNameInvalid: false,
    EmailAddressInvalid: false,
    UserNameInvalid: false,
  });
  const [state, setState] = useState<any>({
    LastName: "",
    CompanyName: "",
    MobileNumber: "",
    BusinessEINNumber: "",
    UserName: "",
    SSNNumber: "",
    EmailAddress: "",
    // Documents: {},
    DocumentsList: [],
    FirstName: "",
    ConfirmPassword: "",
    Password: "",
    Expiration: null,
  });

  const {
    isOpen: isOpenCraetGroup,
    onOpen: onOpenCraetGroup,
    onClose: onCloseCraetGroup,
  } = useDisclosure();
  const getModerator = async () => {
    console.log({ challonge_Tournament_Id });
    let url = `${eventRootUrl}api/v1/challonge/get-moderator?challongeTournamentId=${id.toString()}`;
    const response: any = await get(url);
    if (response.data.status === true) {
      setmoderators(response?.data?.response ?? []);
    }
    console.log({ response: response.data.response });
  };
  const getCheckedInUser = async () => {
    // /challonge/tournament-participants?TournamentId=149&IsCheckedIn=false
    let url = `${eventRootUrl}api/v1/challonge/tournament-participants?TournamentId=${id}`;
    const response: any = await get(url);
    let arr = response?.data?.response?.participantList.map(
      (x: any) => x.userId
    );
    console.log({ response: arr });

    SetparticipantListArr(arr);
  };
  const checkPlayers = (e: any, userId: number) => {
    let arr = [...AddmoderatorsList];
    if (arr.includes(userId)) {
      let index = arr.indexOf(userId);
      arr.splice(index, 1);
    } else {
      arr.push(userId);
    }
    SetAddmoderatorsList(arr);
    // console.log({e})
  };
  const setModerator = async () => {
    console.log({ challonge_Tournament_Id });
    setIsLoading(true);
    let url = `${eventRootUrl}api/v1/challonge/set-moderators`;
    let payload = {
      data: {
        challongetournamentId: id.toString(),
        userIds: AddmoderatorsList.map((x: number) => x.toString()),
      },
      url: url,
    };
    const response: any = await post(payload);

    if (response.data.status === true) {
    }
    setIsLoading(false);
    getModerator();
    console.log({ response: response.data.response });
  };
  const deleteModerator = async (userId: number, index: number) => {
    setisLoadingIndex(index);
    console.log({ challonge_Tournament_Id });

    let url = `${eventRootUrl}api/v1/challonge/remove-moderator?userId=${userId}&challongetournamentId=${id}`;
    let payload = {
      data: {
        challongetournamentId: id.toString(),
        userIds: AddmoderatorsList.map((x: number) => x.toString()),
      },
      url: url,
    };
    const response: any = await deleteReq(payload);

    if (response.data.status === true) {
    }

    setisLoadingIndex(-1);
    getModerator();
    console.log({ response: response.data.response });
  };
  return (
    <Box>
      <Flex w="250px" alignItems={"center"} justifyContent="space-between">
        <Icon
          cursor={"pointer"}
          onClick={() => onChangeNavigation(1)}
          as={MdOutlineKeyboardBackspace}
          color={"blue.200"}
          boxSize={"30px"}
        />

        <Text fontSize={"24px"} color="blue.200" fontWeight={"700"}>
          Set Moderators
        </Text>
      </Flex>
      <Flex
        p="10px"
        borderRadius={"12px"}
        direction="column"
        border="1px solid #EAECF0"
      >
        <Text fontSize={"20px"} color="black" fontWeight={"600"}>
          Moderators
        </Text>
        <Flex mt="32px">
          <Button
            //   disabled={isRegistrationClosed}
            onClick={onOpenCraetGroup}
            leftIcon={<FiPlus />}
            variant="brand"
            color="blue.200"
            fontSize="14px"
            fontWeight="500"
            borderRadius="100px"
            mt="0px"
            px="14px"
            py="8px"
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
            bg="transparent"
            maxH="147px"
            border="1px"
            borderColor="blue.200"
            borderStyle={"dashed"}

            // w="100%"
          >
            Add Moderators
          </Button>
          <HStack ml="5px" spacing={4}>
            {moderators.map((x: any, index: any) => (
              <Tag
                size={"md"}
                key={x.name}
                borderRadius="full"
                variant="outline"
                colorScheme="#000"
                border={"1px solid #043057"}
                px="14px"
                py="8px"
              >
                <TagLabel>{x.userName}</TagLabel>
                {isLoadingIndex === index ? (
                  <Spinner ml="10px" size={"xs"} />
                ) : (
                  <TagCloseButton
                    onClick={() => deleteModerator(x.userId, index)}
                  />
                )}
              </Tag>
            ))}
          </HStack>
        </Flex>
      </Flex>

      <Modal isCentered isOpen={isOpenCraetGroup} onClose={onCloseCraetGroup}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Moderators</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Box w={"full"} bg="#E1E1E1" h="1px" my="22px" />
            <Flex mb="20px" justifyContent={"flex-end"} mt="10px">
              <Search
                placeholder="Search"
                onSearch={onSearch}
                allowClear
                style={{ width: "100%" }}
              />
            </Flex>
            <Spin spinning={isLoadingUsers || searching} tip="Loading...">
              <Flex
                maxH="30vh"
                overflowY={"scroll"}
                css={{
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#1C2A5B",
                    borderRadius: "24px",
                  },
                }}
                direction="column"
                w="full"
                mt="20px"
              >
                {(searchDataTransactions.length > 0
                  ? searchDataTransactions
                  : userTransactions
                ).map((data: any, i: number) => {
                  if (participantList.includes(data.userId)) {
                    return null;
                  }
                  return (
                    <Flex
                      w="370px"
                      mt="5px"
                      key={data.userId}
                      alignItems={"center"}
                      // border={"1px solid #ECF1F4"}
                      borderRadius="12px"
                      p="10px"
                      // ml="10px"
                    >
                      <HStack w="70%">
                        <Box>
                          <Avatar src={data.profilePicPath} size="sm" />
                        </Box>
                        <Box ml="10px">
                          <Text fontWeight={"500"} fontSize={"14px"}>
                            {data.name.length > 0 ? data.name : data.gamerTag}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack justifyContent={"flex-end"} w="30%">
                        <Checkbox
                          isChecked={AddmoderatorsList.includes(data.userId)}
                          onChange={(e) => checkPlayers(e, data.userId)}
                        ></Checkbox>
                      </HStack>
                    </Flex>
                  );
                })}
              </Flex>
            </Spin>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              loadingText="Adding..."
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              my="10px"
              borderRadius={8}
              bg={"blue.200"}
              onClick={setModerator}
            >
              Add Moderator
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default memo(EventSettings);
