// Chakra imports
import {
  Flex,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components

export default function Default(props: {
  innerTextColor: string;
  label: string;
  width?: string;
  bg: string;
  px?: string;
  m?: string;
  [x: string]: any;
}) {
  const { width, innerTextColor, label, bg, px, m, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  return (
    <Flex
      {...rest}
      width={width}
      m={m}
      borderRadius={"50px"}
      padding={"5px"}
      bg={bg}
      justifyContent={"center"}
      alignItems="center"
    >
      <Text px={px} color={innerTextColor} fontSize={"14px"}>
        {label}
      </Text>
    </Flex>
  );
}
