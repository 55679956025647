import React, { useState, memo, useEffect, useCallback } from "react";

// Chakra imports
import {
  Grid,
  Flex,
  Text,
  Box,
  Avatar,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  HStack,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Select,
  useToast,
} from "@chakra-ui/react";
import { FiEdit, FiRefreshCcw } from "react-icons/fi";
import { CgSwapVertical } from "react-icons/cg";
import { BsPencilFill } from "react-icons/bs";

import MatchCard from "./MatchCard";
import {
  Bracket,
  IRoundProps,
  Seed,
  SeedItem,
  SeedTeam,
  IRenderSeedProps,
} from "react-brackets";
import MatchDetails from "views/admin/bracketPortal/components/MatchDetails";
import LoadingBracketsComponent from "views/admin/bracketPortal/components/LoadingBracketsComponent";
import EventConfiguration from "views/admin/bracketPortal/components/EventConfiguration";
import { List, arrayMove } from "react-movable";
// Assets
import avatar from "assets/img/global/avatar.png";
import { eventRootUrl } from "utilities/constants";
import _, { map, object } from "underscore";
import { Spinner } from "@chakra-ui/react";
import { put, get, post } from "redux/services/api";
import async from "react-select/dist/declarations/src/async/index";

const CustomSeed = (
  { seed, breakpoint, roundIndex, seedIndex }: IRenderSeedProps,
  openMatch: any,
  isEventClosed: boolean
) => {
  // console.log({ seed });
  // breakpoint passed to Bracket component
  // to check if mobile view is triggered or not
  // mobileBreakpoint is required to be passed down to a seed
  return (
    <Seed
      role="group"
      mobileBreakpoint={breakpoint}
      style={{ fontSize: 12, borderRadius: "4px" }}
    >
      <Box>
        <SeedItem
          style={{
            fontSize: 12,
            position: "relative",
            backgroundColor: "white",
            boxShadow: "none",
             
          }}
        >
          <Box>
            {seed.teams[0].id != null && seed.teams[1].id != null && (
              <Box
                cursor={"pointer"}
                onClick={() => openMatch(seed)}
                // _groupHover={{ display: "block", visibility: "visible" }}
                // _groupHover={{bg: 'red' }}
                display="block"
                visibility="visible"
                position={"absolute"}
                top="-10px"
                zIndex={100}
                right={"-15px"}
                w="15px"
                h="15px"
              >
                <Icon as={FiEdit} color={"#298BE2"} boxSize={"15px"} />
              </Box>
            )}
            <SeedTeam
              style={{
                borderRadius: "8px",
                color: "black",
                background: "#ECF1F4",
                padding: 0,
                height: "40px",
                width: "250px",
                marginBottom: 2,
               
              }}
            >
              <Box w="20%">
                <Avatar
                  src={
                    seed.teams[0].id === null
                      ? avatar
                      : seed.teams[0].imagePath || avatar
                  }
                  w="20px"
                  h="20px"
                />
              </Box>
              <Flex
                justifyContent={"center"}
                alignItems="center"
                w="60%"
                h="100%"
              >
                {seed.teams[0].id === null
                  ? "TBD"
                  : seed.teams[0]?.name || "TBD"}
              </Flex>

              <Flex
                justifyContent={"center"}
                alignItems="center"
                borderRightRadius={"4px"}
                w="40px"
                h="40px"
                bg={
                  seed.state === "complete"
                    ? seed.teams[0].score > seed.teams[1].score
                      ? "green"
                      : "red"
                    : "#93A8BA"
                }
              >
                <Text color={"white"}>{seed.teams[0].score || 0}</Text>
              </Flex>
            </SeedTeam>
           
            <SeedTeam
              style={{
                borderRadius: "8px",
                color: "black",
                background: "#ECF1F4",
                padding: 0,
                height: "40px",
                width: "250px",
                // borderTopWidth: 1,
                marginTop: 2,
              }}
            >
              <Box w="20%">
                <Avatar
                  src={
                    seed.teams[1].id === null
                      ? avatar
                      : seed.teams[1].imagePath || avatar
                  }
                  w="20px"
                  h="20px"
                />
              </Box>
              <Flex
                justifyContent={"center"}
                alignItems="center"
                w="60%"
                h="100%"
              >
                {seed.teams[1].id === null
                  ? "TBD"
                  : seed.teams[1]?.name || "TBD"}
              </Flex>

              <Flex
                justifyContent={"center"}
                alignItems="center"
                borderRightRadius={"4px"}
                w="40px"
                h="40px"
                bg={
                  seed.state === "complete"
                    ? seed.teams[1].score > seed.teams[0].score
                      ? "green"
                      : "red"
                    : "#93A8BA"
                }
              >
                <Text color={"white"}>{seed.teams[1].score || 0}</Text>
              </Flex>
            </SeedTeam>
            
          </Box>
        </SeedItem>
      </Box>
    </Seed>
  );
};

 
//   {
//     title: "Round 1",
//     seeds: [
//       {
//         id: 1,
//         date: new Date().toDateString(),
//         teams: [{ name: "Team 1" },  ],
//       },
//       {
//         id: 2,
//         date: new Date().toDateString(),
//         teams: [{ name: "Team 2" }],
//       },
//       {
//         id: 3,
//         date: new Date().toDateString(),
//         teams: [ { name: "Team 3" }],
//       },
//       {
//         id: 4,
//         date: new Date().toDateString(),
//         teams: [  { name: "Team 4" }],
//       },
//       {
//         id: 5,
//         date: new Date().toDateString(),
//         teams: [{ name: "Team 5" }],
//       },
//       {
//         id: 1,
//         date: new Date().toDateString(),
//         teams: [{ name: "Team 6" },  ],
//       },
//       {
//         id: 2,
//         date: new Date().toDateString(),
//         teams: [{ name: "Team 7" }],
//       },
//       {
//         id: 3,
//         date: new Date().toDateString(),
//         teams: [ { name: "Team 8" }],
//       },

//     ],
//   },
//   {
//     title: "Round 1",
//     seeds: [
//       {
//         id: 1,
//         date: new Date().toDateString(),
//         teams: [{ name: "Team 1" },  ],
//       },
//       {
//         id: 2,
//         date: new Date().toDateString(),
//         teams: [{ name: "Team 2" }],
//       },
//       {
//         id: 3,
//         date: new Date().toDateString(),
//         teams: [ { name: "Team 3" }],
//       },
//       {
//         id: 4,
//         date: new Date().toDateString(),
//         teams: [  { name: "Team 4" }],
//       },

//     ],
//   },
//   {
//     title: "Round 3",
//     seeds: [
//       {
//         id: 1,
//         date: new Date().toDateString(),
//         teams: [{ name: "Team 1" },  ],
//       },
//       {
//         id: 2,
//         date: new Date().toDateString(),
//         teams: [{ name: "Team 2" }],
//       },
//     ],
//   },
//   {
//     title: "Round 4",
//     seeds: [ {
//       id: 1,
//       date: new Date().toDateString(),
//       teams: [{ name: "Team 1" },],
//     },],
//   },
//   {
//     title: "Champion",
//     seeds: [ {
//       id: 1,
//       date: new Date().toDateString(),
//       teams: [{ name: "Team 1" },],
//     },],
//   },
// ];
const rounds: IRoundProps[] = [
  {
    title: "Round one",
    seeds: [
      {
        id: 1,
        date: new Date().toDateString(),
        teams: [{ name: "Team A" }],
      },
      {
        id: 2,
        date: new Date().toDateString(),
        teams: [{ name: "Team C" }],
      },
      {
        id: 1,
        date: new Date().toDateString(),
        teams: [{ name: "Team A" }],
      },
      {
        id: 2,
        date: new Date().toDateString(),
        teams: [{ name: "Team C" }],
      },
    ],
  },
  {
    title: "Round Two",
    seeds: [
      {
        id: 3,
        date: new Date().toDateString(),
        teams: [{ name: "Team A" }, { name: "Team C" }],
      },
    ],
  },
];
function EventBracketOrdering(props: any) {
  
  const toast = useToast();
  const { state, getEventDetail, activeId, onChangeNavigation } = props;
  const { challonge_Tournament_Id, isEventClosed } = state;

  const wrapper = React.useRef<HTMLDivElement>(null);
  const [roundCompleted, setRoundCompleted] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const [bestof, setBestof] = useState<any>(null);
  const [bestofOptions, setBestofOptions] = useState<any>([]);

  const [brackets, setBrackets] = useState([]);
  const [shuffleParticipantsList, setShuffleParticipantsList] = useState([]);
  const [shuffleLoading, setShuffleLoading] = useState(false);
  const [losersBracket, setlosersBracket] = useState([]);
  const [finalSatgelosersBracket, setfinalSatgelosersBracket] = useState([]);
  const [finalSatgeBracket, setfinalSatgeBracket] = useState([]);

  const [grandFinalsBrackets, setgrandFinalsBrackets] = useState([]);
  const [participantStandingsList, setParticipantStandingsList] = useState<any>({
    finalStandings:[],
    groupStandings:[]
  });
  const [selectedRoundMatch, setSelectedRoundMatch] = useState([]);
  const [groupsStages, setGroupsStages] = useState([]);

  const [isLoadingEndBracket, setisLoadingEndBracket] = useState(false);
  const [isGroup, setisGroup] = useState(0);

  const [loadingMatches, setLoadingMatches] = useState(false);
  const [loadingBrackets, setLoadingBrackets] = useState(true);
  const [selectedRound, setSelectedRound] = React.useState(1);
  // console.log({ getEventDetail });
  const [matches, setMatches] = useState([]);
  const [container, setContainer] = React.useState<Element | null>(null);
  React.useEffect(() => {
    console.log({ isEventClosed });
    setContainer(wrapper.current);
  }, [wrapper.current]);
  useEffect(() => {
    if (state) {
      getbestof();
      getMatches();
    }
  }, [state]);

  useEffect(() => {
    // console.log({ matches, props });
    if (matches?.length) {
      let arr = [...matches];
      let status = arr.filter((x: any) => x.match.matchStatus === "Completed");
      console.log({ status });
      setRoundCompleted(status.length === arr.length ? true : false);
    }
  }, [matches]);

  const participantStandings = async () => {
    let url = `${eventRootUrl}api/v1/challonge/participant-standings?ChallongeTournamentId=${state.challonge_Tournament_Id}`;
    const response: any = await get(url);
    if (response.data.status === true) {
      let res = response.data.response;
      setParticipantStandingsList(res);
      // console.log({ res });
    }
  };

  const generateBracket = async () => {
    setLoadingBrackets(true);
    let url = `${eventRootUrl}api/v2/bracket/generate-bracket?challongeTournamentId=${state.challonge_Tournament_Id}&tournamentType=${state?.tournament_Type}&brackettype=${state?.bracket_Type}`;
    const response: any = await get(url);
    if (response.data.status === true) {
      setLoadingBrackets(false);
      let newEmptyArr: any = [];
      let res = response.data.response;
      let winnersBracket = res.winnersBracket;
      let grandFinalsBracket = res.grandFinals;
      let losersBracket = res.losersBracket;

      let arr = winnersBracket.map((x: any) => {
        return {
          title: x,
          seeds: x.matches.map((y: any) => {
            return {
              ...y,
              date: new Date().toDateString(),
              teams: [y.player1, y.player2],
            };
          }),
        };
      });

      let arr2 = grandFinalsBracket.map((x: any) => {
        return {
          title: x,
          seeds: x.matches.map((y: any) => {
            return {
              ...y,
              date: new Date().toDateString(),
              teams: [y.player1, y.player2],
            };
          }),
        };
      });
      let arr3 = losersBracket.map((x: any) => {
        return {
          title: x,
          seeds: x.matches.map((y: any) => {
            return {
              ...y,
              date: new Date().toDateString(),
              teams: [y.player1, y.player2],
            };
          }),
        };
      });
      setlosersBracket(arr3);
      setgrandFinalsBrackets(arr2);
      setBrackets(arr);
    }
  };

  const generateBracketRoundRobin = async () => {
    setLoadingBrackets(true);
    let url = `${eventRootUrl}api/v2/bracket/generate-bracket?challongeTournamentId=${state.challonge_Tournament_Id}&tournamentType=${state?.tournament_Type}&brackettype=${state?.bracket_Type}`;
    const response: any = await get(url);
    if (response.data.status === true) {
      setLoadingBrackets(false);
      let res = response.data.response;
      let rounds = res.rounds;
      let arr = rounds.map((x: any) => {
        return {
          title: x,
          seeds: x.matches.map((y: any) => {
            return {
              ...y,
              date: new Date().toDateString(),
              teams: [y.player1, y.player2],
            };
          }),
        };
      });

      setBrackets(arr);
    }
  };
  const generateBracketSingleElimination = async () => {
    setLoadingBrackets(true);
    let url = `${eventRootUrl}api/v2/bracket/generate-bracket?challongeTournamentId=${state.challonge_Tournament_Id}&tournamentType=${state?.tournament_Type}&brackettype=${state?.bracket_Type}`;
    const response: any = await get(url);
    if (response.data.status === true) {
      setLoadingBrackets(false);
      let res = response.data.response;
      let winnersBracket = res.winnersBracket;
      let thirdPlaceMatch = res.thirdPlaceMatch;
      let arr = winnersBracket.map((x: any) => {
        return {
          title: x,
          seeds: x.matches.map((y: any) => {
            return {
              ...y,
              date: new Date().toDateString(),
              teams: [y.player1, y.player2],
            };
          }),
        };
      });

      let arr3 = thirdPlaceMatch.map((x: any) => {
        return {
          title: x,
          seeds: x.matches.map((y: any) => {
            return {
              ...y,
              date: new Date().toDateString(),
              teams: [y.player1, y.player2],
            };
          }),
        };
      });
      setlosersBracket(arr3);
      setBrackets(arr);
      setLoadingBrackets(false);
    }
  };

  const generateBracketTwoStage = async () => {
    let url = `${eventRootUrl}api/v2/bracket/generate-bracket?challongeTournamentId=${state.challonge_Tournament_Id}&tournamentType=${state?.tournament_Type}&brackettype=${state?.bracket_Type}&finalStageBracketRequired=true&groupStageBracketRequired=true&groupStageBrackettype=${state.group_stage_bracket_Type}`;
    const response: any = await get(url);
    if (response.data.status === true) {
      setLoadingBrackets(false);
      let res = response.data.response;
      if (state?.group_stage_bracket_Type === "single elimination") {
        let groupStages = res.groupStages;

        let groupArr = groupStages.map((x: any, i: number) => {
          return {
            group: x.group,
            index: i,
            thirdPlaceMatch: x.bracket.thirdPlaceMatch.map((x: any) => {
              return {
                title: x,
                seeds: x.matches.map((y: any) => {
                  return {
                    ...y,
                    date: new Date().toDateString(),
                    teams: [y.player1, y.player2],
                  };
                }),
              };
            }),
            winnersBracket: x.bracket.winnersBracket.map((x: any) => {
              return {
                title: x,
                seeds: x.matches.map((y: any) => {
                  return {
                    ...y,
                    date: new Date().toDateString(),
                    teams: [y.player1, y.player2],
                  };
                }),
              };
            }),
          };
        });
        setGroupsStages(groupArr);
      } else if (state?.group_stage_bracket_Type === "round robin") {
        let groupStages = res.groupStages;
        let groupArr = groupStages.map((x: any, i: number) => {
          return {
            group: x.group,
            index: i,
            winnersBracket: x.bracket.rounds.map((x: any) => {
              return {
                title: x,
                seeds: x.matches.map((y: any) => {
                  return {
                    ...y,
                    date: new Date().toDateString(),
                    teams: [y.player1, y.player2],
                  };
                }),
              };
            }),
          };
        });
        setGroupsStages(groupArr);
      } else if (state?.group_stage_bracket_Type === "double elimination") {
        let groupStages = res.groupStages;

        let groupArr = groupStages.map((x: any, i: number) => {
          return {
            group: x.group,
            index: i,
            thirdPlaceMatch: x.bracket.losersBracket.map((x: any) => {
              return {
                title: x,
                seeds: x.matches.map((y: any) => {
                  return {
                    ...y,
                    date: new Date().toDateString(),
                    teams: [y.player1, y.player2],
                  };
                }),
              };
            }),
            winnersBracket: x.bracket.winnersBracket.map((x: any) => {
              return {
                title: x,
                seeds: x.matches.map((y: any) => {
                  return {
                    ...y,
                    date: new Date().toDateString(),
                    teams: [y.player1, y.player2],
                  };
                }),
              };
            }),
          };
        });
        setGroupsStages(groupArr);
      }
      if (state?.bracket_Type === "single elimination") {
        let finalStage = res.finalStage;
        let winnersBracket = finalStage.winnersBracket;
        let thirdPlaceMatch = finalStage.thirdPlaceMatch;
        let arr = winnersBracket.map((x: any) => {
          return {
            title: x,
            seeds: x.matches.map((y: any) => {
              return {
                ...y,
                date: new Date().toDateString(),
                teams: [y.player1, y.player2],
              };
            }),
          };
        });

        let arr3 = thirdPlaceMatch.map((x: any) => {
          return {
            title: x,
            seeds: x.matches.map((y: any) => {
              return {
                ...y,
                date: new Date().toDateString(),
                teams: [y.player1, y.player2],
              };
            }),
          };
        });
        setfinalSatgelosersBracket(arr3);
        setfinalSatgeBracket(arr);
        if (arr?.length > 0) {
          setisGroup(0);
        } else {
          setisGroup(1);
        }
      } else if (state?.bracket_Type === "round robin") {
        let finalStage = res.finalStage;
        let arr = finalStage.rounds?.map((x: any) => {
          return {
            title: x,
            seeds: x.matches.map((y: any) => {
              return {
                ...y,
                date: new Date().toDateString(),
                teams: [y.player1, y.player2],
              };
            }),
          };
        });
        if (arr?.length > 0) {
          setisGroup(0);
        } else {
          setisGroup(1);
        }
        console.log({arr})
        setfinalSatgeBracket(arr);
      } else if (state?.bracket_Type === "double elimination") {
        let finalStage = res.finalStage;
        let winnersBracket = finalStage.winnersBracket;
        let thirdPlaceMatch = finalStage.losersBracket;
        let arr = winnersBracket.map((x: any) => {
          return {
            title: x,
            seeds: x.matches.map((y: any) => {
              return {
                ...y,
                date: new Date().toDateString(),
                teams: [y.player1, y.player2],
              };
            }),
          };
        });

        let arr3 = thirdPlaceMatch.map((x: any) => {
          return {
            title: x,
            seeds: x.matches.map((y: any) => {
              return {
                ...y,
                date: new Date().toDateString(),
                teams: [y.player1, y.player2],
              };
            }),
          };
        });
        setfinalSatgelosersBracket(arr3);
        setfinalSatgeBracket(arr);
        if (arr?.length > 0) {
          setisGroup(0);
        } else {
          setisGroup(1);
        }
      }
    }
    setLoadingBrackets(false);
  };
  const generateSwissBracket = async (state: any) => {
    setLoadingBrackets(true);
    let url = `${eventRootUrl}api/v2/bracket/generate-bracket?challongeTournamentId=${state.challonge_Tournament_Id}&tournamentType=${state?.tournament_Type}&brackettype=${state?.bracket_Type}`;
    const response: any = await get(url);
    if (response.data.status === true) {
      setLoadingBrackets(false);
      let newEmptyArr: any = [];
      let res = response.data.response;
      let winnersBracket = res.rounds;
      let arr = winnersBracket.map((x: any) => {
        return {
          title: x,
          seeds: x.matches.map((y: any) => {
            return {
              ...y,
              date: new Date().toDateString(),
              teams: [y.player1, y.player2],
            };
          }),
        };
      });

      setBrackets(arr);
    }
  };
  const getMatches = useCallback(async () => {
    setLoadingMatches(true);
    let url = `${eventRootUrl}api/v1/challonge/get-matches?challongetournamentId=${state.challonge_Tournament_Id}`;
    const response: any = await get(url);
    if (response.data.status === true) {
      let arr = response.data.response;
      setLoadingMatches(false);
      setMatches(arr);
      console.log({ selectedRoundMatch });
      if (selectedRoundMatch.length) {
        let obj = arr.filter(
          (x: any) => x.match.id === selectedRoundMatch[0].match.id
        );
        if (obj) {
          console.log({ obj });
          setSelectedRoundMatch(obj);
        }
      }

      participantStandings();
      if (state.tournament_Type === "single stage tournament") {
        if (state?.bracket_Type === "single elimination") {
          generateBracketSingleElimination();
        } else if (state?.bracket_Type === "round robin") {
          generateBracketRoundRobin();
        } else if (state?.bracket_Type === "double elimination") {
          generateBracket();
        }
        else if (state?.bracket_Type === "swiss") {
          generateSwissBracket(state);
        }
      } else if (state.tournament_Type === "two stage tournament") {
        generateBracketTwoStage();
        console.log({ tournament_Type: state.tournament_Type });
        // if (state?.group_stage_bracket_Type === "single elimination") {
        //   generateBracketTwoStageSingleElimination();
        // } else if (state?.group_stage_bracket_Type === "round robin") {
        //   generateBracketTwoStageRoundRobin();
        // } else if (state?.group_stage_bracket_Type === "double elimination") {
        //   generateBracketTwoStageDoubleElimination();
        // }
      }
    }
  }, [selectedRoundMatch]);

  const getbestof = useCallback(async () => {
    setLoadingMatches(true);
    let url = `${eventRootUrl}api/v1/lookup/bestof`;
    const response: any = await get(url);
    if (response.data.status === true) {
      setBestofOptions(response.data.response);
    }
  }, []);
  const editRound = (title: any) => {
    setSelectedRound(title.round);
    console.log({ title });
    let arr2 = [...matches];
    let resArr: any = [];
    title.matches.forEach((x: any) => {
      arr2.forEach((y: any) => {
        if (y.match.id === x.id) {
          resArr.push(y.match);
        }
      });
    });

    let selectedRoundMatches = resArr.filter((x: any) => {
      return x.matchStatus === "In Progress" || x.matchStatus === "Completed";
    });

    // let selectedRoundMatch = arr2.filter((x: any) => x.state === "complete") ;
    setBestof(resArr[0]?.bestOfMatch ?? "1");
    if (selectedRoundMatches.length > 0) {
      toast.closeAll();
      toast({
        title: "Round is in Progress or Completed",
        description: "Round can not be edited!!",
        status: "info",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    let arr = brackets.filter((x: any) => x.title.round === title.round)[0];
    console.log({ arr, resArr });
    if (resArr) {
      // let seeds = arr.seeds;
      let newSeeds: any = [];
      resArr.forEach((x: any, i: number) => {
        newSeeds.push({
          id:x.player1_id,
          imagePath:  x.player1ImagePath,
          isDeleted: x.player1_isDeleted,
          name:x.player1_name,
          score: x.player1_name,
          userId: x.player1_Userid,
          matchNo: i + 1,
        });
        newSeeds.push({
          id:x.player2_id,
          imagePath:  x.player2ImagePath,
          isDeleted: x.player2_isDeleted,
          name:x.player2_name,
          score: x.player2_name,
          userId: x.player2_Userid,
          matchNo: i + 1,
        });
      });
      // console.log({ newSeeds, arr, selectedRoundMatch });
      setShuffleParticipantsList(newSeeds);
    }

    onOpen();
  };
  const swap = useCallback(
    (oldIndex: number, newIndex: number) => {
      let arr = [...shuffleParticipantsList];
      var temp = arr[oldIndex];
      arr[oldIndex] = arr[newIndex];
      arr[newIndex] = temp;
      setShuffleParticipantsList(arr);
      swapApiCall(arr[oldIndex].userId, arr[newIndex].userId);
    },
    [shuffleParticipantsList]
  );

  const swapApiCall = async (toUserId: string, fromUserId: string) => {
    setShuffleLoading(true);

    let data = {
      tournamentId: state.id,
      toUserId: toUserId,
      fromUserId: fromUserId,
    };

    let payload = {
      url: `${eventRootUrl}api/v1/challonge/shuffle-participants`,
      data: data,
    };
    let response = await put(payload);
    setShuffleLoading(false);
    if (response.data.statusCode === 200) {
      getMatches();
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const openMatch = (seed: any) => {
    let arr = [...matches];
    let selectedRoundMatch = arr.filter((x: any) => x.match.id == seed.id);
    console.log({ selectedRoundMatch });
    setSelectedRoundMatch(selectedRoundMatch);
    console.log({ selectedRoundMatch });
    console.log({ seed, arr });
    if (selectedRoundMatch.length) {
      onOpen1();
    }
  };

  const updateMatchSet = async (e: any) => {
    setBestof(e.target.value);
    setShuffleLoading(true);
    let data = {
      challongetournamentId: state.challonge_Tournament_Id,
      bestOf: e.target.value,
      round: selectedRound,
      isGroup: isGroup,
    };

    let payload = {
      url: `${eventRootUrl}api/v1/challonge/bestof-round`,
      data: data,
    };
    let response = await post(payload);
    if (response.data.statusCode === 200) {
      setShuffleLoading(false);
      getMatches();
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const resetMatch = async () => {
    setShuffleLoading(true);
    const { id, winner_id, tournament_id, round, player1_id, isMatchReset } =
      selectedRoundMatch[0].match;
    if (!isMatchReset) return;
    let payload = {
      url: `${eventRootUrl}api/v1/challonge/match-reset?tournamentId=${state.challonge_Tournament_Id}&WinnerPlayerId=${player1_id}&MatchId=${id}&Round=${round}`,
      data: {},
    };
    let response = await post(payload);
    if (response.data.statusCode === 200) {
      getMatches();
      setShuffleLoading(false);
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const endGroupBracket = async () => {
    // startTournament()
    // return
    setisLoadingEndBracket(true);
    let payload = {
      url: `${eventRootUrl}api/v1/challonge/finalize-group-stage`,
      data: {
        tournamentId: challonge_Tournament_Id,
        tournamentUrl: state.urlKey,
      },
    };
    let response = await put(payload);
    setisLoadingEndBracket(false);
    if (response.data.statusCode === 200) {
      startTournament();
      getEventDetail();
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const endBracket = async () => {
    setisLoadingEndBracket(true);
    let payload = {
      url: `${eventRootUrl}api/v1/challonge/end-bracket`,
      data: {
        challongetournamentId: challonge_Tournament_Id,
      },
    };
    let response = await post(payload);
    setisLoadingEndBracket(false);
    if (response.data.statusCode === 200) {
      getEventDetail();
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const startTournament = async () => {
    let url = `${eventRootUrl}api/v1/challonge/start-tournament`;

    let payload = {
      data: {
        tournament: {
          tournament_Id: challonge_Tournament_Id,
          number_of_participants: 0,
        },
      },
      url: url,
    };

    try {
      const response: any = await post(payload);
      if (response.data.status === true) {
        getMatches();
        toast.closeAll();
        toast({
          title: "Success",
          description: "Final Stage Started Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast.closeAll();
        toast({
          title: "Error",
          description: "Server Error",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (err) {
      toast({
        title: "Error",
        description: "Server Error",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
 
  return (
    <Box justifyContent={"space-between"}>
      
      <Flex
        justifyContent={"space-between"}
        flexWrap="wrap"
        alignItems="center"
      >
        <Box w="70%">
          <EventConfiguration
            state={props.state}
            activeId={activeId}
            onChangeNavigation={onChangeNavigation}
          />
        </Box>
        <Flex justifyContent={"flex-end"} w="30%">
          {roundCompleted && (
            <Button
              disabled={isEventClosed}
              isLoading={isLoadingEndBracket}
              onClick={isGroup === 1 ? endGroupBracket : endBracket}
              w="200px"
              // mt="20px"
              fontSize="xs"
              variant="brand"
              fontWeight="normal"
              px="20px"
              // bg="red"
              color="#fff"
              // _hover={{ bg: mode("#31D0AA", "#31D0AA"), color: "#fff" }}
              borderRadius="15px"
            >
              End {isGroup === 1 ? "Group" : ""} Bracket
            </Button>
          )}
        </Flex>
      </Flex>
      <Box
        mt="50px"
        w="full"
        overflowX={"auto"}
        minH="1200px"
        css={{
          "&::-webkit-scrollbar": {
            width: "2px",
          },
          "&::-webkit-scrollbar-track": {
            width: "3px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#D2D2D2",
            borderRadius: "24px",
          },
        }}
      >
        {state.tournament_Type === "single stage tournament" && (
          <Tabs>
            <TabList>
              <Tab>Winning Rounds</Tab>
              <Tab>Standing Section</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                {loadingBrackets ? (
                  <LoadingBracketsComponent />
                ) : (
                  <>
                      {(state?.bracket_Type === "single elimination" ||   state?.bracket_Type === "swiss" )&& (
                      <>
                        {!loadingMatches && (
                          <Text
                            color={"black"}
                            mb="10px"
                            fontSize="24px"
                            fontWeight={"bold"}
                          >
                            Winners Rounds
                          </Text>
                        )}

                        <Bracket
                          renderSeedComponent={(props) =>
                            CustomSeed(props, openMatch, isEventClosed)
                          }
                          rounds={brackets}
                          roundTitleComponent={(
                            title: any,
                            roundIndex: number
                          ) => {
                            return (
                              <Flex
                                px="10px"
                                ml="10px"
                                style={{
                                  borderRadius: "8px",
                                  textAlign: "center",
                                  color: "black",
                                  background: "#ECF1F4",
                                  borderRightWidth: 3,
                                  borderColor: "white",
                                  height: "40px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // width:"300px"
                                }}
                              >
                                <Text>{"Round " + title.round}</Text>

                                {isEventClosed != true && (
                                  <Icon
                                    cursor={"pointer"}
                                    onClick={() => editRound(title)}
                                    ml="16px"
                                    as={FiEdit}
                                    color={"black"}
                                    boxSize={"15px"}
                                  />
                                )}
                              </Flex>
                            );
                          }}
                        />
                        {!loadingMatches && losersBracket.length > 0 && (
                          <Text
                            color={"black"}
                            mb="10px"
                            fontSize="24px"
                            fontWeight={"bold"}
                          >
                            Third Place Match
                          </Text>
                        )}
                        <Bracket
                          renderSeedComponent={(props) =>
                            CustomSeed(props, openMatch, isEventClosed)
                          }
                          rounds={losersBracket}
                          roundTitleComponent={(
                            title: any,
                            roundIndex: number
                          ) => {
                            return (
                              <Flex
                                px="10px"
                                style={{
                                  borderRadius: "8px",
                                  textAlign: "center",
                                  color: "black",
                                  background: "#ECF1F4",
                                  borderRightWidth: 3,
                                  borderColor: "white",
                                  height: "40px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // width:"300px"
                                }}
                              >
                                <Text>{"Round " + title.round}</Text>
                                {isEventClosed != true && (
                                  <Icon
                                    cursor={"pointer"}
                                    onClick={() => editRound(title)}
                                    ml="16px"
                                    as={FiEdit}
                                    color={"black"}
                                    boxSize={"15px"}
                                  />
                                )}
                              </Flex>
                            );
                          }}
                        />
                      </>
                    )}
                    {state?.bracket_Type === "double elimination" && (
                      <>
                        {!loadingMatches && (
                          <Text
                            color={"black"}
                            mb="10px"
                            fontSize="24px"
                            fontWeight={"bold"}
                          >
                            Winners Rounds
                          </Text>
                        )}
                        <Bracket
                          renderSeedComponent={(props) =>
                            CustomSeed(props, openMatch, isEventClosed)
                          }
                          rounds={brackets}
                          roundTitleComponent={(
                            title: any,
                            roundIndex: number
                          ) => {
                            return (
                              <Flex
                                px="10px"
                                ml="10px"
                                style={{
                                  borderRadius: "8px",
                                  textAlign: "center",
                                  color: "black",
                                  background: "#ECF1F4",
                                  borderRightWidth: 3,
                                  borderColor: "white",
                                  height: "40px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // width:"300px"
                                }}
                              >
                                <Text>{"Round " + title.round}</Text>
                                {isEventClosed != true && (
                                  <Icon
                                    cursor={"pointer"}
                                    onClick={() => editRound(title)}
                                    ml="16px"
                                    as={FiEdit}
                                    color={"black"}
                                    boxSize={"15px"}
                                  />
                                )}
                              </Flex>
                            );
                          }}
                        />
                        {!loadingMatches && (
                          <Text
                            color={"black"}
                            mb="10px"
                            fontSize="24px"
                            fontWeight={"bold"}
                          >
                            Losers Rounds
                          </Text>
                        )}
                        <Bracket
                          renderSeedComponent={(props) =>
                            CustomSeed(props, openMatch, isEventClosed)
                          }
                          rounds={losersBracket}
                          roundTitleComponent={(
                            title: any,
                            roundIndex: number
                          ) => {
                            return (
                              <Flex
                                px="10px"
                                ml="10px"
                                style={{
                                  borderRadius: "8px",
                                  textAlign: "center",
                                  color: "black",
                                  background: "#ECF1F4",
                                  borderRightWidth: 3,
                                  borderColor: "white",
                                  height: "40px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // width:"300px"
                                }}
                              >
                                {"Round " + title.round}
                              </Flex>
                            );
                          }}
                        />
                      </>
                    )}

                    {state?.bracket_Type === "round robin" &&
                      brackets.map((seed) => {
                        return (
                          <>
                            {/* <Text
                            color={"black"}
                            mb="10px"
                            fontSize="24px"
                            fontWeight={"bold"}
                          >
                            Round {seed.title}
                          </Text> */}
                            <Flex
                              px="10px"
                              my="20px"
                              style={{
                                borderRadius: "8px",
                                textAlign: "center",
                                color: "black",
                                background: "#ECF1F4",
                                borderRightWidth: 3,
                                borderColor: "white",
                                height: "40px",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "300px",
                              }}
                            >
                              <Text>{"Round " + seed.title.round}</Text>
                              {isEventClosed != true && (
                                <Icon
                                  cursor={"pointer"}
                                  onClick={() => editRound(seed.title)}
                                  ml="16px"
                                  as={FiEdit}
                                  color={"black"}
                                  boxSize={"15px"}
                                />
                              )}
                            </Flex>
                            <Flex
                              justifyContent={"flex-start"}
                              alignItems="center"
                              gap={"10px"}
                            >
                              {seed.seeds.map((innerSeed: any, i: number) => {
                                // console.log({ innerSeed });
                                return (
                                  <Box
                                    role={"group"}
                                    w="325px"
                                    position="relative"
                                  >
                                    <Box
                                      cursor={"pointer"}
                                      onClick={() => openMatch(innerSeed)}
                                      _groupHover={{
                                        display: "block",
                                        visibility: "visible",
                                      }}
                                      // _groupHover={{bg: 'red' }}
                                      display="none"
                                      visibility="hidden"
                                      position={"absolute"}
                                      top="0px"
                                      zIndex={100}
                                      right={"-10px"}
                                      w="20px"
                                      h="20px"
                                    >
                                      <Icon
                                        as={FiEdit}
                                        color={"#298BE2"}
                                        boxSize={"20px"}
                                      />
                                    </Box>

                                    <Flex
                                      justifyContent={"space-between"}
                                      alignItems="center"
                                      p="5px"
                                      marginLeft={i === 0 ? 0 : "10px"}
                                      bg="#ECF1F4"
                                      borderRadius={"4px"}
                                      w="300px"
                                    >
                                      <Box w="20%">
                                        <Avatar
                                          src={
                                            innerSeed.teams[0].id === null
                                              ? avatar
                                              : innerSeed.teams[0].imagePath ||
                                                avatar
                                          }
                                          w="20px"
                                          h="20px"
                                        />
                                      </Box>
                                      <Flex
                                        justifyContent={"center"}
                                        alignItems="center"
                                        w="60%"
                                        h="100%"
                                      >
                                        {innerSeed.teams[0].id === null
                                          ? "TBD"
                                          : innerSeed.teams[0]?.name || "TBD"}
                                      </Flex>

                                      <Flex
                                        justifyContent={"center"}
                                        alignItems="center"
                                        borderRightRadius={"4px"}
                                        w="40px"
                                        h="40px"
                                        bg={
                                          innerSeed.state === "complete"
                                            ? innerSeed.teams[0].score >
                                              innerSeed.teams[1].score
                                              ? "green"
                                              : "red"
                                            : "#93A8BA"
                                        }
                                      >
                                        <Text color={"white"}>
                                          {innerSeed.teams[0].score || 0}
                                        </Text>
                                      </Flex>
                                    </Flex>

                                    <Flex
                                      justifyContent={"space-between"}
                                      alignItems="center"
                                      p="5px"
                                      marginLeft={i === 0 ? 0 : "10px"}
                                      bg="#ECF1F4"
                                      borderRadius={"4px"}
                                      w="300px"
                                      mt={"2px"}
                                    >
                                      <Box w="20%">
                                        <Avatar
                                          src={
                                            innerSeed.teams[1].id === null
                                              ? avatar
                                              : innerSeed.teams[1].imagePath ||
                                                avatar
                                          }
                                          w="20px"
                                          h="20px"
                                        />
                                      </Box>
                                      <Flex
                                        justifyContent={"center"}
                                        alignItems="center"
                                        w="60%"
                                        h="100%"
                                      >
                                        {innerSeed.teams[1].id === null
                                          ? "TBD"
                                          : innerSeed.teams[1]?.name || "TBD"}
                                      </Flex>

                                      <Flex
                                        justifyContent={"center"}
                                        alignItems="center"
                                        borderRightRadius={"4px"}
                                        w="40px"
                                        h="40px"
                                        bg={
                                          innerSeed.state === "complete"
                                            ? innerSeed.teams[1].score >
                                              innerSeed.teams[0].score
                                              ? "green"
                                              : "red"
                                            : "#93A8BA"
                                        }
                                      >
                                        <Text color={"white"}>
                                          {innerSeed.teams[1].score || 0}
                                        </Text>
                                      </Flex>
                                    </Flex>
                                  </Box>
                                );
                              })}
                            </Flex>
                          </>
                        );
                      })}
                  </>
                )}
              </TabPanel>
              <TabPanel>
                <Box maxW="470.34px">
                  <Flex
                    bg="#F0F4F8"
                    p="16px"
                    borderTopRadius={"7.97px"}
                    justifyContent="space-between"
                    alignItems={"center"}

                    mb="35px"
                  >
                    <Box w="60px">
                      <Text color={"#555F84"} fontSize="16px">
                        Rank
                      </Text>
                    </Box>
                    <Box w="130px">
                      <Text color={"#555F84"} fontSize="16px">
                        Player
                      </Text>
                    </Box>
                    <Box ml="10px">
                      <Text color={"#555F84"} fontSize="16px">
                        Match History
                      </Text>
                    </Box>
                  </Flex>

                  {participantStandingsList.finalStandings.map((x: any, i: number) => {
                    return (
                      <Flex
                        key={Math.random().toString()}
                        mt="10px"
                        bg={i % 2 === 0 ? "#FAFAFA" : "white"}
                        p="16px"
                        py="10px"
                        borderTopRadius={"7.97px"}
                        // justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Box w="60px">
                          <Text color={"#555F84"} fontSize="16px">
                            {i + 1}
                          </Text>
                        </Box>
                        <HStack overflow={"hidden"} w="100%">
                          <Avatar h={"36px"} w={"36px"} src={x.playerImage} />
                          <Text noOfLines={2} color={"#555F84"} fontSize="16px">
                            {x.playerName}
                          </Text>
                        </HStack>
                        <HStack ml="10px" justifyContent={"space-between"}>
                          {x.matchesStatus.map((y: any, i: number) => {
                            return (
                              <Flex
                                key={i}
                                justifyContent={"center"}
                                alignItems="center"
                                bg={y ? "#1BAFED" : "#FF877F"}
                                // p="8px"
                                borderRadius={"8px"}
                                w="32px"
                                h="34px"
                              >
                                <Text fontSize={"19px"} color="#565656">
                                  {y ? "W" : "L"}
                                </Text>
                              </Flex>
                            );
                          })}
                        </HStack>
                      </Flex>
                    );
                  })}
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}

        {state.tournament_Type === "two stage tournament" && (
          <Tabs>
            <TabList>
              {groupsStages.map((x: any) => {
                return <Tab>Group {x.group}</Tab>;
              })}
              <Tab>Final Stage</Tab>
              <Tab>Group Standing Section</Tab>
              <Tab>Final Standing Section</Tab>
            </TabList>

            <TabPanels>
              {groupsStages.map((group: any, i: number) => {
                return (
                  <TabPanel key={i}>
                    {loadingBrackets ? (
                      <LoadingBracketsComponent />
                    ) : (
                      <>
                        {state?.group_stage_bracket_Type ===
                          "single elimination" && (
                          <>
                            {!loadingMatches && (
                              <Text
                                color={"black"}
                                mb="10px"
                                fontSize="24px"
                                fontWeight={"bold"}
                              >
                                Winners Rounds
                              </Text>
                            )}

                            <Bracket
                              renderSeedComponent={(props) =>
                                CustomSeed(props, openMatch, isEventClosed)
                              }
                              rounds={group.winnersBracket}
                              roundTitleComponent={(
                                title: any,
                                roundIndex: number
                              ) => {
                                return (
                                  <Flex
                                    px="10px"
                                    ml="10px"
                                    style={{
                                      borderRadius: "8px",
                                      textAlign: "center",
                                      color: "black",
                                      background: "#ECF1F4",
                                      borderRightWidth: 3,
                                      borderColor: "white",
                                      height: "40px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      // width:"300px"
                                    }}
                                  >
                                    <Text>{"Round " + title.round}</Text>

                                    {isEventClosed != true && (
                                      <Icon
                                        cursor={"pointer"}
                                        onClick={() => editRound(title)}
                                        ml="16px"
                                        as={FiEdit}
                                        color={"black"}
                                        boxSize={"15px"}
                                      />
                                    )}
                                  </Flex>
                                );
                              }}
                            />
                            {!loadingMatches && losersBracket.length > 0 && (
                              <Text
                                color={"black"}
                                mb="10px"
                                fontSize="24px"
                                fontWeight={"bold"}
                              >
                                Third Place Match
                              </Text>
                            )}
                            <Bracket
                              renderSeedComponent={(props) =>
                                CustomSeed(props, openMatch, isEventClosed)
                              }
                              rounds={losersBracket}
                              roundTitleComponent={(
                                title: any,
                                roundIndex: number
                              ) => {
                                return (
                                  <Flex
                                    px="10px"
                                    style={{
                                      borderRadius: "8px",
                                      textAlign: "center",
                                      color: "black",
                                      background: "#ECF1F4",
                                      borderRightWidth: 3,
                                      borderColor: "white",
                                      height: "40px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      // width:"300px"
                                    }}
                                  >
                                    <Text>{"Round " + title.round}</Text>
                                    {isEventClosed != true && (
                                      <Icon
                                        cursor={"pointer"}
                                        onClick={() => editRound(title)}
                                        ml="16px"
                                        as={FiEdit}
                                        color={"black"}
                                        boxSize={"15px"}
                                      />
                                    )}
                                  </Flex>
                                );
                              }}
                            />
                          </>
                        )}
                        {state?.group_stage_bracket_Type ===
                          "double elimination" && (
                          <>
                            {!loadingMatches && (
                              <Text
                                color={"black"}
                                mb="10px"
                                fontSize="24px"
                                fontWeight={"bold"}
                              >
                                Winners Rounds
                              </Text>
                            )}
                            <Bracket
                              renderSeedComponent={(props) =>
                                CustomSeed(props, openMatch, isEventClosed)
                              }
                              rounds={group.winnersBracket}
                              roundTitleComponent={(
                                title: any,
                                roundIndex: number
                              ) => {
                                return (
                                  <Flex
                                    px="10px"
                                    ml="10px"
                                    style={{
                                      borderRadius: "8px",
                                      textAlign: "center",
                                      color: "black",
                                      background: "#ECF1F4",
                                      borderRightWidth: 3,
                                      borderColor: "white",
                                      height: "40px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      // width:"300px"
                                    }}
                                  >
                                    <Text>{"Round " + title.round}</Text>
                                    {isEventClosed != true && (
                                      <Icon
                                        cursor={"pointer"}
                                        onClick={() => editRound(title)}
                                        ml="16px"
                                        as={FiEdit}
                                        color={"black"}
                                        boxSize={"15px"}
                                      />
                                    )}
                                  </Flex>
                                );
                              }}
                            />
                            {group.thirdPlaceMatch.length > 0 && (
                              <Text
                                color={"black"}
                                mb="10px"
                                fontSize="24px"
                                fontWeight={"bold"}
                              >
                                Losers Rounds
                              </Text>
                            )}
                            <Bracket
                              renderSeedComponent={(props) =>
                                CustomSeed(props, openMatch, isEventClosed)
                              }
                              rounds={group.thirdPlaceMatch}
                              roundTitleComponent={(
                                title: any,
                                roundIndex: number
                              ) => {
                                return (
                                  <Flex
                                    px="10px"
                                    ml="10px"
                                    style={{
                                      borderRadius: "8px",
                                      textAlign: "center",
                                      color: "black",
                                      background: "#ECF1F4",
                                      borderRightWidth: 3,
                                      borderColor: "white",
                                      height: "40px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      // width:"300px"
                                    }}
                                  >
                                    {"Round " + title.round}
                                  </Flex>
                                );
                              }}
                            />
                          </>
                        )}

                        {state?.group_stage_bracket_Type === "round robin" &&
                          group.winnersBracket.map((seed: any) => {
                            return (
                              <>
                                <Flex
                                  px="10px"
                                  my="20px"
                                  style={{
                                    borderRadius: "8px",
                                    textAlign: "center",
                                    color: "black",
                                    background: "#ECF1F4",
                                    borderRightWidth: 3,
                                    borderColor: "white",
                                    height: "40px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "300px",
                                  }}
                                >
                                  <Text>{"Round " + seed.title.round}</Text>
                                  {isEventClosed != true && (
                                    <Icon
                                      cursor={"pointer"}
                                      onClick={() => editRound(seed.title)}
                                      ml="16px"
                                      as={FiEdit}
                                      color={"black"}
                                      boxSize={"15px"}
                                    />
                                  )}
                                </Flex>
                                <Flex
                                  justifyContent={"flex-start"}
                                  alignItems="center"
                                  gap={"10px"}
                                >
                                  {seed.seeds.map(
                                    (innerSeed: any, i: number) => {
                                      // console.log({ innerSeed });
                                      return (
                                        <Box
                                          role={"group"}
                                          w="325px"
                                          position="relative"
                                        >
                                          <Box
                                            cursor={"pointer"}
                                            onClick={() => openMatch(innerSeed)}
                                            _groupHover={{
                                              display: "block",
                                              visibility: "visible",
                                            }}
                                            // _groupHover={{bg: 'red' }}
                                            display="none"
                                            visibility="hidden"
                                            position={"absolute"}
                                            top="0px"
                                            zIndex={100}
                                            right={"-10px"}
                                            w="20px"
                                            h="20px"
                                          >
                                            <Icon
                                              as={FiEdit}
                                              color={"#298BE2"}
                                              boxSize={"20px"}
                                            />
                                          </Box>

                                          <Flex
                                            justifyContent={"space-between"}
                                            alignItems="center"
                                            p="5px"
                                            marginLeft={i === 0 ? 0 : "10px"}
                                            bg="#ECF1F4"
                                            borderRadius={"4px"}
                                            w="300px"
                                          >
                                            <Box w="20%">
                                              <Avatar
                                                src={
                                                  innerSeed.teams[0].id === null
                                                    ? avatar
                                                    : innerSeed.teams[0]
                                                        .imagePath || avatar
                                                }
                                                w="20px"
                                                h="20px"
                                              />
                                            </Box>
                                            <Flex
                                              justifyContent={"center"}
                                              alignItems="center"
                                              w="60%"
                                              h="100%"
                                            >
                                              <Text noOfLines={1}>
                                                {innerSeed.teams[0].id === null
                                                  ? "TBD"
                                                  : innerSeed.teams[0]?.name ||
                                                    "TBD"}
                                              </Text>
                                            </Flex>

                                            <Flex
                                              justifyContent={"center"}
                                              alignItems="center"
                                              borderRightRadius={"4px"}
                                              w="40px"
                                              h="40px"
                                              bg={
                                                innerSeed.state === "complete"
                                                  ? innerSeed.teams[0].score >
                                                    innerSeed.teams[1].score
                                                    ? "green"
                                                    : "red"
                                                  : "#93A8BA"
                                              }
                                            >
                                              <Text color={"white"}>
                                                {innerSeed.teams[0].score || 0}
                                              </Text>
                                            </Flex>
                                          </Flex>

                                          <Flex
                                            justifyContent={"space-between"}
                                            alignItems="center"
                                            p="5px"
                                            marginLeft={i === 0 ? 0 : "10px"}
                                            bg="#ECF1F4"
                                            borderRadius={"4px"}
                                            w="300px"
                                            mt={"2px"}
                                          >
                                            <Box w="20%">
                                              <Avatar
                                                src={
                                                  innerSeed.teams[1].id === null
                                                    ? avatar
                                                    : innerSeed.teams[1]
                                                        .imagePath || avatar
                                                }
                                                w="20px"
                                                h="20px"
                                              />
                                            </Box>
                                            <Flex
                                              justifyContent={"center"}
                                              alignItems="center"
                                              w="60%"
                                              h="100%"
                                            >
                                              <Text noOfLines={1}>
                                                {innerSeed.teams[1].id === null
                                                  ? "TBD"
                                                  : innerSeed.teams[1]?.name ||
                                                    "TBD"}
                                              </Text>
                                            </Flex>

                                            <Flex
                                              justifyContent={"center"}
                                              alignItems="center"
                                              borderRightRadius={"4px"}
                                              w="40px"
                                              h="40px"
                                              bg={
                                                innerSeed.state === "complete"
                                                  ? innerSeed.teams[1].score >
                                                    innerSeed.teams[0].score
                                                    ? "green"
                                                    : "red"
                                                  : "#93A8BA"
                                              }
                                            >
                                              <Text color={"white"}>
                                                {innerSeed.teams[1].score || 0}
                                              </Text>
                                            </Flex>
                                          </Flex>
                                        </Box>
                                      );
                                    }
                                  )}
                                </Flex>
                              </>
                            );
                          })}
                      </>
                    )}
                  </TabPanel>
                );
              })}

              <TabPanel>
                {loadingBrackets ? (
                  <LoadingBracketsComponent />
                ) : (
                  <>
                    {state?.bracket_Type === "single elimination" && (
                      <>
                        {!loadingMatches && (
                          <Text
                            color={"black"}
                            mb="10px"
                            fontSize="24px"
                            fontWeight={"bold"}
                          >
                            Winners Rounds
                          </Text>
                        )}

                        <Bracket
                          renderSeedComponent={(props) =>
                            CustomSeed(props, openMatch, isEventClosed)
                          }
                          rounds={finalSatgeBracket}
                          roundTitleComponent={(
                            title: any,
                            roundIndex: number
                          ) => {
                            return (
                              <Flex
                                px="10px"
                                ml="10px"
                                style={{
                                  borderRadius: "8px",
                                  textAlign: "center",
                                  color: "black",
                                  background: "#ECF1F4",
                                  borderRightWidth: 3,
                                  borderColor: "white",
                                  height: "40px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // width:"300px"
                                }}
                              >
                                <Text>{"Round " + title.round}</Text>

                                {isEventClosed != true && (
                                  <Icon
                                    cursor={"pointer"}
                                    onClick={() => editRound(title)}
                                    ml="16px"
                                    as={FiEdit}
                                    color={"black"}
                                    boxSize={"15px"}
                                  />
                                )}
                              </Flex>
                            );
                          }}
                        />
                        {!loadingMatches &&
                          finalSatgelosersBracket.length > 0 && (
                            <Text
                              color={"black"}
                              mb="10px"
                              fontSize="24px"
                              fontWeight={"bold"}
                            >
                              Third Place Match
                            </Text>
                          )}
                        <Bracket
                          renderSeedComponent={(props) =>
                            CustomSeed(props, openMatch, isEventClosed)
                          }
                          rounds={finalSatgelosersBracket}
                          roundTitleComponent={(
                            title: any,
                            roundIndex: number
                          ) => {
                            return (
                              <Flex
                                px="10px"
                                style={{
                                  borderRadius: "8px",
                                  textAlign: "center",
                                  color: "black",
                                  background: "#ECF1F4",
                                  borderRightWidth: 3,
                                  borderColor: "white",
                                  height: "40px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // width:"300px"
                                }}
                              >
                                <Text>{"Round " + title.round}</Text>
                                {isEventClosed != true && (
                                  <Icon
                                    cursor={"pointer"}
                                    onClick={() => editRound(title)}
                                    ml="16px"
                                    as={FiEdit}
                                    color={"black"}
                                    boxSize={"15px"}
                                  />
                                )}
                              </Flex>
                            );
                          }}
                        />
                      </>
                    )}
                    {state?.bracket_Type === "double elimination" && (
                      <>
                        {!loadingMatches && (
                          <Text
                            color={"black"}
                            mb="10px"
                            fontSize="24px"
                            fontWeight={"bold"}
                          >
                            Winners Rounds
                          </Text>
                        )}
                        <Bracket
                          renderSeedComponent={(props) =>
                            CustomSeed(props, openMatch, isEventClosed)
                          }
                          rounds={finalSatgeBracket}
                          roundTitleComponent={(
                            title: any,
                            roundIndex: number
                          ) => {
                            return (
                              <Flex
                                px="10px"
                                ml="10px"
                                style={{
                                  borderRadius: "8px",
                                  textAlign: "center",
                                  color: "black",
                                  background: "#ECF1F4",
                                  borderRightWidth: 3,
                                  borderColor: "white",
                                  height: "40px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // width:"300px"
                                }}
                              >
                                <Text>{"Round " + title.round}</Text>
                                {isEventClosed != true && (
                                  <Icon
                                    cursor={"pointer"}
                                    onClick={() => editRound(title)}
                                    ml="16px"
                                    as={FiEdit}
                                    color={"black"}
                                    boxSize={"15px"}
                                  />
                                )}
                              </Flex>
                            );
                          }}
                        />
                        {!loadingMatches &&
                          finalSatgelosersBracket.length > 0 && (
                            <Text
                              color={"black"}
                              mb="10px"
                              fontSize="24px"
                              fontWeight={"bold"}
                            >
                              Losers Rounds
                            </Text>
                          )}
                        <Bracket
                          renderSeedComponent={(props) =>
                            CustomSeed(props, openMatch, isEventClosed)
                          }
                          rounds={finalSatgelosersBracket}
                          roundTitleComponent={(
                            title: any,
                            roundIndex: number
                          ) => {
                            return (
                              <Flex
                                px="10px"
                                ml="10px"
                                style={{
                                  borderRadius: "8px",
                                  textAlign: "center",
                                  color: "black",
                                  background: "#ECF1F4",
                                  borderRightWidth: 3,
                                  borderColor: "white",
                                  height: "40px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // width:"300px"
                                }}
                              >
                                {"Round " + title.round}
                              </Flex>
                            );
                          }}
                        />
                      </>
                    )}

                    {state?.bracket_Type === "round robin" &&
                      finalSatgeBracket.map((seed) => {
                        return (
                          <>
                            <Flex
                              px="10px"
                              my="20px"
                              style={{
                                borderRadius: "8px",
                                textAlign: "center",
                                color: "black",
                                background: "#ECF1F4",
                                borderRightWidth: 3,
                                borderColor: "white",
                                height: "40px",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "300px",
                              }}
                            >
                              <Text>{"Round " + seed.title.round}</Text>
                              {isEventClosed != true && (
                                <Icon
                                  cursor={"pointer"}
                                  onClick={() => editRound(seed.title)}
                                  ml="16px"
                                  as={FiEdit}
                                  color={"black"}
                                  boxSize={"15px"}
                                />
                              )}
                            </Flex>
                            <Flex
                              justifyContent={"flex-start"}
                              alignItems="center"
                              gap={"10px"}
                            >
                              {seed.seeds.map((innerSeed: any, i: number) => {
                                // console.log({ innerSeed });
                                return (
                                  <Box
                                    role={"group"}
                                    w="325px"
                                    position="relative"
                                  >
                                    <Box
                                      cursor={"pointer"}
                                      onClick={() => openMatch(innerSeed)}
                                      _groupHover={{
                                        display: "block",
                                        visibility: "visible",
                                      }}
                                      // _groupHover={{bg: 'red' }}
                                      display="none"
                                      visibility="hidden"
                                      position={"absolute"}
                                      top="0px"
                                      zIndex={100}
                                      right={"-10px"}
                                      w="20px"
                                      h="20px"
                                    >
                                      <Icon
                                        as={FiEdit}
                                        color={"#298BE2"}
                                        boxSize={"20px"}
                                      />
                                    </Box>

                                    <Flex
                                      justifyContent={"space-between"}
                                      alignItems="center"
                                      p="5px"
                                      marginLeft={i === 0 ? 0 : "10px"}
                                      bg="#ECF1F4"
                                      borderRadius={"4px"}
                                      w="300px"
                                    >
                                      <Box w="20%">
                                        <Avatar
                                          src={
                                            innerSeed.teams[0].id === null
                                              ? avatar
                                              : innerSeed.teams[0].imagePath ||
                                                avatar
                                          }
                                          w="20px"
                                          h="20px"
                                        />
                                      </Box>
                                      <Flex
                                        justifyContent={"center"}
                                        alignItems="center"
                                        w="60%"
                                        h="100%"
                                      >
                                        <Text noOfLines={1}>
                                          {innerSeed.teams[0].id === null
                                            ? "TBD"
                                            : innerSeed.teams[0]?.name || "TBD"}
                                        </Text>
                                      </Flex>

                                      <Flex
                                        justifyContent={"center"}
                                        alignItems="center"
                                        borderRightRadius={"4px"}
                                        w="40px"
                                        h="40px"
                                        bg={
                                          innerSeed.state === "complete"
                                            ? innerSeed.teams[0].score >
                                              innerSeed.teams[1].score
                                              ? "green"
                                              : "red"
                                            : "#93A8BA"
                                        }
                                      >
                                        <Text color={"white"}>
                                          {innerSeed.teams[0].score || 0}
                                        </Text>
                                      </Flex>
                                    </Flex>

                                    <Flex
                                      justifyContent={"space-between"}
                                      alignItems="center"
                                      p="5px"
                                      marginLeft={i === 0 ? 0 : "10px"}
                                      bg="#ECF1F4"
                                      borderRadius={"4px"}
                                      w="300px"
                                      mt={"2px"}
                                    >
                                      <Box w="20%">
                                        <Avatar
                                          src={
                                            innerSeed.teams[1].id === null
                                              ? avatar
                                              : innerSeed.teams[1].imagePath ||
                                                avatar
                                          }
                                          w="20px"
                                          h="20px"
                                        />
                                      </Box>
                                      <Flex
                                        justifyContent={"center"}
                                        alignItems="center"
                                        w="60%"
                                        h="100%"
                                      >
                                        <Text noOfLines={1}>
                                          {innerSeed.teams[1].id === null
                                            ? "TBD"
                                            : innerSeed.teams[1]?.name || "TBD"}
                                        </Text>
                                      </Flex>

                                      <Flex
                                        justifyContent={"center"}
                                        alignItems="center"
                                        borderRightRadius={"4px"}
                                        w="40px"
                                        h="40px"
                                        bg={
                                          innerSeed.state === "complete"
                                            ? innerSeed.teams[1].score >
                                              innerSeed.teams[0].score
                                              ? "green"
                                              : "red"
                                            : "#93A8BA"
                                        }
                                      >
                                        <Text color={"white"}>
                                          {innerSeed.teams[1].score || 0}
                                        </Text>
                                      </Flex>
                                    </Flex>
                                  </Box>
                                );
                              })}
                            </Flex>
                          </>
                        );
                      })}
                  </>
                )}
              </TabPanel>
              <TabPanel>
                <Box maxW="470.34px">
                  <Flex
                    bg="#F0F4F8"
                    p="16px"
                    borderTopRadius={"7.97px"}
                    // justifyContent="space-between"
                    alignItems={"center"}
                    mb="35px"
                  >
                    <Box w="60px">
                      <Text color={"#555F84"} fontSize="16px">
                        Rank
                      </Text>
                    </Box>
                    <Box w="130px">
                      <Text color={"#555F84"} fontSize="16px">
                        Player
                      </Text>
                    </Box>
                    <Box ml="10px">
                      <Text color={"#555F84"} fontSize="16px">
                        Match History
                      </Text>
                    </Box>
                  </Flex>

                  {participantStandingsList.groupStandings.map((x: any, i: number) => {
                    return (
                      <Flex
                        key={Math.random().toString()}
                        mt="10px"
                        bg={i % 2 === 0 ? "#FAFAFA" : "white"}
                        p="16px"
                        py="10px"
                        borderTopRadius={"7.97px"}
                        // justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Box w="60px">
                          <Text color={"#555F84"} fontSize="16px">
                            {i + 1}
                          </Text>
                        </Box>
                        <HStack overflow={"hidden"} w="130px">
                          <Avatar h={"36px"} w={"36px"} src={x.playerImage} />
                          <Text noOfLines={1} color={"#555F84"} fontSize="16px">
                            {x.playerName}
                          </Text>
                        </HStack>
                        <HStack ml="10px" justifyContent={"space-between"}>
                          {x.matchesStatus.map((y: any, i: number) => {
                            return (
                              <Flex
                                key={i}
                                justifyContent={"center"}
                                alignItems="center"
                                bg={y ? "#1BAFED" : "#FF877F"}
                                // p="8px"
                                borderRadius={"8px"}
                                w="32px"
                                h="34px"
                              >
                                <Text fontSize={"19px"} color="#565656">
                                  {y ? "W" : "L"}
                                </Text>
                              </Flex>
                            );
                          })}
                        </HStack>
                      </Flex>
                    );
                  })}
                </Box>
              </TabPanel>
              <TabPanel>
                <Box maxW="470.34px">
                  <Flex
                    bg="#F0F4F8"
                    p="16px"
                    borderTopRadius={"7.97px"}
                    // justifyContent="space-between"
                    alignItems={"center"}
                    mb="35px"
                  >
                    <Box w="60px">
                      <Text color={"#555F84"} fontSize="16px">
                        Rank
                      </Text>
                    </Box>
                    <Box w="130px">
                      <Text color={"#555F84"} fontSize="16px">
                        Player
                      </Text>
                    </Box>
                    <Box ml="10px">
                      <Text color={"#555F84"} fontSize="16px">
                        Match History
                      </Text>
                    </Box>
                  </Flex>

                  {participantStandingsList.finalStandings.map((x: any, i: number) => {
                    return (
                      <Flex
                        key={Math.random().toString()}
                        mt="10px"
                        bg={i % 2 === 0 ? "#FAFAFA" : "white"}
                        p="16px"
                        py="10px"
                        borderTopRadius={"7.97px"}
                        // justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Box w="60px">
                          <Text color={"#555F84"} fontSize="16px">
                            {i + 1}
                          </Text>
                        </Box>
                        <HStack overflow={"hidden"} w="130px">
                          <Avatar h={"36px"} w={"36px"} src={x.playerImage} />
                          <Text noOfLines={1} color={"#555F84"} fontSize="16px">
                            {x.playerName}
                          </Text>
                        </HStack>
                        <HStack ml="10px" justifyContent={"space-between"}>
                          {x.matchesStatus.map((y: any, i: number) => {
                            return (
                              <Flex
                                key={i}
                                justifyContent={"center"}
                                alignItems="center"
                                bg={y ? "#1BAFED" : "#FF877F"}
                                // p="8px"
                                borderRadius={"8px"}
                                w="32px"
                                h="34px"
                              >
                                <Text fontSize={"19px"} color="#565656">
                                  {y ? "W" : "L"}
                                </Text>
                              </Flex>
                            );
                          })}
                        </HStack>
                      </Flex>
                    );
                  })}
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </Box>
      <Modal
        isCentered
        size={"lg"}
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Set</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            minH="400px"
            overflowY="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                width: "8px",

                border: "1px solid",
                borderRadius: "24px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#1C2A5B",
                borderRadius: "24px",
              },
            }}
            ref={wrapper}
          >
            <Select
              disabled={shuffleLoading}
              onChange={(e) => updateMatchSet(e)}
              value={bestof}
              placeholder=""
            >
              {bestofOptions.map((x: any) => {
                return <option value={x.code}>{x.name}</option>;
              })}
            </Select>
            <Flex
              mt="20px"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Text color={"#1C2A5B"} fontSize="16px" fontWeight={"600"}>
                Player Swap
              </Text>
              <Text color={"#19BAEE"} fontSize="14px" fontWeight={"500"}>
                {/* Seed Players */}
              </Text>
            </Flex>
            {shuffleLoading ? (
              <Flex h="300px" justifyContent="center" alignItems={"center"}>
                <Spinner m="auto" />
              </Flex>
            ) : (
              <List
                container={container}
                values={shuffleParticipantsList}
                onChange={({ oldIndex, newIndex }) => {
                  swap(oldIndex, newIndex);
                  console.log({ oldIndex, newIndex });

                  // setItems(arrayMove(items, oldIndex, newIndex));
                }}
                renderList={({ children, props }) => (
                  <ul {...props}>{children}</ul>
                )}
                renderItem={({ value, props, index }) => {
                  if (value === undefined) {
                    return <Box {...props} />;
                  }
                  return (
                    <Box {...props}>
                      <Flex
                        bg="white"
                        zIndex={2}
                        cursor={"grab"}
                        border="1px solid #ECF1F4"
                        py="14px"
                        px="10px"
                        borderRadius={"8px"}
                        w="full"
                        justifyContent={"space-between"}
                        mt={index % 2 !== 0 ? "10px" : "12px"}
                      >
                        <HStack
                          w="50%"
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Icon
                            as={CgSwapVertical}
                            color={"black"}
                            boxSize={"25px"}
                          />
                          <Flex
                            justifyContent={"center"}
                            alignItems="center"
                            bg={"#9299B0"}
                            // p="8px"
                            borderRadius={"25px"}
                            w="25px"
                            h="25px"
                          >
                            <Text fontSize={"16px"} color="#fff">
                              {value.matchNo ?? ""}
                            </Text>
                          </Flex>
                          <HStack overflow={"hidden"} w="130px">
                            <Avatar
                              h={"36px"}
                              w={"36px"}
                              src={value.imagePath}
                            />
                            <Box>
                              <Text
                                noOfLines={1}
                                color={"#555F84"}
                                fontWeight="600"
                                fontSize="12px"
                              >
                                {value.name}
                              </Text>
                            </Box>
                          </HStack>
                        </HStack>
                        <HStack></HStack>
                      </Flex>
                      {index % 2 !== 0 && (
                        <Box w="full" mt="12px" h="2px" bg="#ECF1F4" />
                      )}
                    </Box>
                  );
                }}
              />
            )}
          </ModalBody>

          <ModalFooter>
            {/* <Button colorScheme="blue" w="full" onClick={onClose}>
              Close
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        // size={"2xl"}
        isCentered
        scrollBehavior="inside"
        isOpen={isOpen1}
        onClose={onClose1}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {" "}
            {(selectedRoundMatch[0]?.match?.isMatchReset === true && !isEventClosed) && (
              <Icon
                cursor={"pointer"}
                onClick={() => resetMatch()}
                ml="16px"
                as={FiRefreshCcw}
                color={"black"}
                boxSize={"15px"}
              />
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            // minH="400px"
            overflowY="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                width: "8px",

                border: "1px solid",
                borderRadius: "24px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#1C2A5B",
                borderRadius: "24px",
              },
            }}
          >
            <Flex justifyContent={"center"} alignItems="center">
              <MatchCard
              onClose={onClose1}
                loadingMatches={loadingMatches}
                getMatches={getMatches}
                selectedRoundMatch={selectedRoundMatch}
                match={""}
                challonge_Tournament_Id={state.challonge_Tournament_Id}
                isLoading={false}
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            {/* <Button colorScheme="blue" w="full" onClick={onClose}>
              Close
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default memo(EventBracketOrdering);
