import React, { useState } from "react";

import {
  FiMenu,
  FiHome,
  FiCalendar,
  FiUser,
  FiDollarSign,
  FiBriefcase,
  FiSettings,
} from "react-icons/fi";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdSettingsApplications,
} from "react-icons/md";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
  Flex,
  Box,
  Icon,
  Text,
} from "@chakra-ui/react";
import { FORMS_ID } from "utilities/constants";
import NavItem from "./NavItem";

import { motion, AnimatePresence } from "framer-motion";
import closeMnue from "assets/img/closeMnue.png";
import openMenu from "assets/img/openMenu.png";

export default function Sidebar(props) {
  const [isActive, setIsActive] = useState("Profile");

  const { navSize, routes, changeNavSize } = props;
  const updateNavigation = (nav) => {
    console.log({ nav });
    if (typeof nav === "string") {
      setIsActive(nav);
    }
  };
  return (
    <Flex
      // display={{ sm: "none", xl: "block" }}
      as={motion.div}
      animate={{
        width: navSize == "small" ? "84px" : "250px",
      }}
      transitionDuration=".1s, .1s, .2s"
      bg={"white"}
      // minHeight="100vh"
      height="100%"
      // overflow="hidden"
      // position="relative"

      maxH="100vh"
      minH="100vh"
      pt="70px"
      flexDir="column"
      borderRight="2px solid #ECF1F4"
      justifyContent="space-between"
      overflowY="scroll"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#1C2A5B",
          borderRadius: "24px",
        },
      }}
    >
      <Flex mt="19px" flexDir="column" w="100%" as="nav">
      {routes.includes(FORMS_ID.Event) && (
          <NavItem
            updateNavigation={() => updateNavigation("Event")}
            isActive={isActive === "Event"}
            key={"Event"}
            navSize={navSize}
            icon={""}
            title={"Dashboard"}
            isMenu={true}
            layout={"/admin"}
            path={"/Event"}
            index={0}
            //   active
          />
        )}
        {routes.includes(FORMS_ID.Profile) && (
          <NavItem
            updateNavigation={() => updateNavigation("Profile")}
            isActive={isActive === "Profile"}
            key={"Profile"}
            navSize={navSize}
            icon={""}
            title={"Profile"}
            isMenu={true}
            layout={"/admin"}
            path={"/Profile"}
            index={0}
            //   active
          />
        )}
        {routes.includes(FORMS_ID.Analytics) && (
          <NavItem
            updateNavigation={() => updateNavigation("Analytics")}
            isActive={isActive === "Analytics"}
            key={"Analytics"}
            navSize={navSize}
            icon={""}
            title={"Analytics"}
            isMenu={true}
            layout={"/admin"}
            path={"/Analytics"}
            index={0}
            //   active
          />
        )}

        {routes.includes(FORMS_ID.Settings) && (
          <Flex
            alignItems="center"
            // bg="red"
            justifyContent={navSize == "small" ? "center" : "flex-start"}
            onClick={updateNavigation}
            w="full"
            // alignItems={navSize == "small" ? "center" : "flex-start"}
            mt={navSize == "small" ? 3 : 0}
            p={navSize == "small" ? 0 : 3}
          >
            <Accordion
              m="auto"
              _hover={{ bg: "tranparent" }}
              allowToggle
              border={"0px"}
              p={0}
              //
              w="full"
            >
              <Flex
                w="full"
                justifyContent={navSize == "small" ? "center" : "flex-start"}
                alignItems={navSize == "small" ? "center" : "flex-start"}
              >
                <AccordionItem
                  w="full"
                  _hover={{ bg: "tranparent" }}
                  border={"0px"}
                  p={0}
                  m={0}
                  // bg="black"
                  // justifyContent={'center'}
                  // alignItems='center'
                  // display={'flex'}
                  // flexDir='column'
                >
                  <AccordionButton
                    _hover={{ bg: "tranparent" }}
                    _focus={{ border: 0 }}
                    p={0}
                    m={0}
                  >
                    <Flex
                      w="full"
                      // bg="black"
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Icon
                        p={0}
                        m={0}
                        as={MdSettingsApplications}
                        width="20px"
                        height="20px"
                        // color="inherit"
                        color={
                          isActive === "Business" ||
                          isActive === "Information" ||
                          isActive === "Locations" ||
                          isActive === "Documents"
                            ? "#298BE2"
                            : "#979C9E"
                        }
                      />
                      {navSize !== "small" && (
                        <Box p={0} as="span" flex="1" textAlign="left">
                          <Text
                            ml={5}
                            noOfLines={1}
                            display={navSize == "small" ? "none" : "flex"}
                          >
                            Settings
                          </Text>
                        </Box>
                      )}
                      {navSize !== "small" && <AccordionIcon />}
                    </Flex>
                  </AccordionButton>

                  <AccordionPanel m={0} p={navSize == "small" ? 0 : 3}>
                    <NavItem
                      updateNavigation={() => updateNavigation("Business")}
                      isActive={isActive === "Business"}
                      key={"Business"}
                      navSize={navSize}
                      icon={""}
                      title={"Business"}
                      isMenu={true}
                      layout={"/admin"}
                      path={"/Settings?route=Business"}
                      index={0}
                      //   active
                    />
                    <NavItem
                      updateNavigation={() => updateNavigation("Information")}
                      isActive={isActive === "Information"}
                      key={"Information"}
                      navSize={navSize}
                      icon={""}
                      title={"Information"}
                      isMenu={true}
                      layout={"/admin"}
                      path={"/Settings?route=Information"}
                      index={0}
                      //   active
                    />
                    <NavItem
                      updateNavigation={() => updateNavigation("Locations")}
                      isActive={isActive === "Locations"}
                      key={"Locations"}
                      navSize={navSize}
                      icon={""}
                      title={"Locations"}
                      isMenu={true}
                      layout={"/admin"}
                      path={"/Settings?route=Locations"}
                      index={0}
                      //   active
                    />
                    <NavItem
                      updateNavigation={() => updateNavigation("Documents")}
                      isActive={isActive === "Documents"}
                      key={"Documents"}
                      navSize={navSize}
                      icon={""}
                      title={"Documents"}
                      isMenu={true}
                      layout={"/admin"}
                      path={"/Settings?route=Documents"}
                      index={0}
                      //   active
                    />
                  </AccordionPanel>
                </AccordionItem>
              </Flex>
            </Accordion>
          </Flex>
        )}
        {routes.includes(FORMS_ID.Admins) && (
          <NavItem
            updateNavigation={() => updateNavigation("Admins")}
            isActive={isActive === "Admins"}
            key={"Admins"}
            navSize={navSize}
            icon={""}
            title={"Admin"}
            isMenu={true}
            layout={"/admin"}
            path={"/data-tables"}
            index={0}
            //   active
          />
        )}
       
        {/* {routes.includes(FORMS_ID.HubsList) && (
          <NavItem
            updateNavigation={() => updateNavigation("HubsList")}
            isActive={isActive === "HubsList"}
            key={"HubsList"}
            navSize={navSize}
            icon={""}
            title={"Hubs List"}
            isMenu={true}
            layout={"/admin"}
            path={"/HubsList"}
            index={0}
            //   active
          />
        )} */}
        {routes.includes(FORMS_ID.Users) && (
          <NavItem
            updateNavigation={() => updateNavigation("Users")}
            isActive={isActive === "Users"}
            key={"Users"}
            navSize={navSize}
            icon={""}
            title={"Users"}
            isMenu={true}
            layout={"/admin"}
            path={"/Users"}
            index={0}
            //   active
          />
        )}
        {routes.includes(FORMS_ID.UsersRoles) && (
          <NavItem
            updateNavigation={() => updateNavigation("UsersRoles")}
            isActive={isActive === "UsersRoles"}
            key={"Users Roles"}
            navSize={navSize}
            icon={""}
            title={"Users Roles"}
            isMenu={true}
            layout={"/admin"}
            path={"/UsersRoles"}
            index={0}
            //   active
          />
        )}
        {routes.includes(FORMS_ID.TicketingSystem) && (
          <NavItem
            updateNavigation={() => updateNavigation("TicketingSystem")}
            isActive={isActive === "TicketingSystem"}
            key={"Ticketing System"}
            navSize={navSize}
            icon={""}
            title={"Ticketing System"}
            isMenu={true}
            layout={"/admin"}
            path={"/TicketingSystem"}
            index={0}
            //   active
          />
        )}
        {routes.includes(FORMS_ID.Admins) && (
          <NavItem
            updateNavigation={() => updateNavigation("AddParticipants")}
            isActive={isActive === "AddParticipants"}
            key={"AddParticipants"}
            navSize={navSize}
            icon={""}
            title={"Add Participants"}
            isMenu={true}
            layout={"/admin"}
            path={"/AddParticipants"}
            index={0}
            //   active
          />
        )}
        {/* {routes.map((route, index) => {
          return (
            <NavItem
            updateNavigation={()=>updateNavigation("Profile")}
            isActive={isActive === "Profile"}
              key={index}
              navSize={navSize}
              icon={route.icon}
              title={route.name}
              isMenu={route.isMenu}
              layout={route.layout}
              path={route.path}
              index={index}
              //   active
            />
          );
        })} */}
      </Flex>

      <Flex justifyContent="flex-end" alignItems={"flex-end"}>
        <Image
          cursor={"pointer"}
          onClick={() => {
            if (navSize == "small") changeNavSize("large");
            else changeNavSize("small");
          }}
          mr="5px"
          src={navSize == "large" ? openMenu : closeMnue}
          alt="closeMnue"
          w={"36px"}
          h={"36px"}
        />
      </Flex>
    </Flex>
  );
}
