import React, { useState, memo } from "react";
import "../../../../../assets/css/App.css";

import {
  ModalBody,
  Heading,
  useColorModeValue,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Button,
  Box,
  Input,
  RadioGroup,
  Stack,
  Radio,
  HStack,
} from "@chakra-ui/react";

import Group_1 from "assets/img/global/Group_1.png";
interface Props {
  next: Function;
  handleBack: () => void;
  parantState: any;
}

function Step_4(props: Props) {
  const { next, handleBack, parantState } = props;
  const textColor = useColorModeValue("black.50", "white");
  const buttonColorBrand = "blue.200";

  const [state, setState] = useState({
    ...parantState,
    
    isPaid: false,
    isFree: true,
    entryFee: "",
    competitionFee: "",
    hostContribution: "",
  });
  const [invalidState, setInvalidState] = useState({
    entryFeeInvalid: false,
    competitionFeeInvalid: false,
    hostContributionInvalid: false,
  });
  const updateState = (val: string, prop: string) => {
    setState((prvState: any) => ({ ...prvState, [prop]: val }));
  };

  const checks = () => {
    if (state.tournament_Name === "") {
      setInvalidState((prvState) => ({
        ...prvState,
        tournament_NameInvalid: true,
      }));
      return false;
    } else if (state.tournament_Description.length < 10) {
      setInvalidState((prvState) => ({
        ...prvState,
        tournament_DescriptionInvalid: true,
      }));
      return false;
    } else if (state.number_of_participants < 1) {
      setInvalidState((prvState) => ({
        ...prvState,
        number_of_participantsInvalid: true,
      }));
      return false;
    }
    return true;
  };
  const handleNext = () => {
    if (checks()) {
      next(state);
    }
  };
 
  return (
    <ModalBody
      overflowY="auto"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
          
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#D2D2D2",
          borderRadius: "24px",
        },
      }}
      position={"relative"}
      px="50px"
    >
      <Heading color={textColor} fontSize="36px" w={400} mb="10px">
        Payment
      </Heading>
      <FormControl
        onChange={() =>
          setState((prvState: any) => ({
            ...prvState,
            isPaid: !prvState.isPaid,
            isFree: !prvState.isFree,
          }))
        }
        maxW={"60%"}
        mt={10}
      >
        <RadioGroup value={1} mb="8px">
          <FormLabel
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            display="flex"
          >
            Choose how attendees will pay for the event
          </FormLabel>
          <Stack direction="row">
            <Radio value={state.isPaid === true ? 1 : 0}>Paid</Radio>
            <Radio value={state.isFree === true ? 1 : 0}>Free</Radio>
          </Stack>
        </RadioGroup>
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Entry Fee
        </FormLabel>
        <Input
          onChange={(e) => updateState(e.target.value, "entryFee")}
          value={state.entryFee}
          isRequired={true}
          variant="auth"
          fontSize="sm"
          ms={{ base: "0px", md: "0px" }}
          type="email"
          borderRadius={8}
          placeholder="Optional"
          mb="24px"
          fontWeight="500"
          size="lg"
        />

        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Prize Pool Fee
        </FormLabel>
        <Input
          onChange={(e) => updateState(e.target.value, "competitionFee")}
          value={state.competitionFee}
          isRequired={true}
          variant="auth"
          fontSize="sm"
          ms={{ base: "0px", md: "0px" }}
          type="email"
          borderRadius={8}
          placeholder="Optional"
          mb="24px"
          fontWeight="500"
          size="lg"
        />
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Host Sponsorship
        </FormLabel>
        <Input
          onChange={(e) => updateState(e.target.value, "hostContribution")}
          value={state.hostContribution}
          isRequired={true}
          variant="auth"
          fontSize="sm"
          ms={{ base: "0px", md: "0px" }}
          type="email"
          borderRadius={8}
          placeholder="Optional"
          mb="24px"
          fontWeight="500"
          size="lg"
        />

        <Box
          bgImage={Group_1}
          bgSize="cover"
          position="relative"
          h={"122px"}
          w="94px"
          borderRadius="0px"
        >
          <Flex
            // background={"black"}
            backgroundColor="rgba(0, 0, 0, .5)"
            bgSize="cover"
            // opacity={0.5}
            position="relative"
            h={"122px"}
            w="94px"
            borderRadius="10px"
            direction={"column"}
            justifyContent="flex-end"
            alignItems={"center"}
          >
            <Text
              mb="20px"
              color={"white"}
              fontSize="19px"
              fontWeight={"semibold"}
            >
              $1,563
            </Text>
            <Flex
              justifyContent={"center"}
              alignItems="center"
              //   bg="#57575D"
              backgroundColor="rgba(100, 100, 100, .5)"
              w="94px"
              h={"28px"}
            >
              <Text color={"white"} fontSize="14px">
                ** GRID
              </Text>
            </Flex>
          </Flex>
        </Box>

        <HStack w="50%" py="20px" justifyContent={"space-between"}>
          <Button
            onClick={() => next()}
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="106px"
            h="50"
            borderRadius={8}
            bg={buttonColorBrand}
          >
            Next
          </Button>
          <Text>or</Text>
          <Button
            onClick={handleBack}
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="106px"
            h="50"
            bg="transparent"
            borderRadius={8}
            border={`1px solid `}
            color={buttonColorBrand}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
            borderColor={buttonColorBrand}
          >
            Back
          </Button>
        </HStack>
      </FormControl>
    </ModalBody>
  );
}
export default memo(Step_4);
