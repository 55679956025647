import React, { useMemo, useRef, useState } from "react";
import { Select, Spin } from "antd";
import type { SelectProps } from "antd";
import debounce from "lodash/debounce";
import { useAdmin } from "../hooks";
import { hubAdminUrl_table } from "../../../../utilities/constants";
import { post, put, get } from "../../../../redux/services/api";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      dropdownStyle={{ zIndex: 2000 }}
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      style={{ width: "100%",   }}
      {...props}
      options={options}
    />
  );
}

// Usage of DebounceSelect
interface UserValue {
  label: string;
  value: string;
}

async function fetchUserList(username: string): Promise<UserValue[]> {
  console.log("fetching user", username);

  let data = await get(
    `${hubAdminUrl_table}api/v1/user/get-hub-users-list?PageNumber=${1}&PageSize=${20}&Flag=${0}&SearchString=${username}&Flag=${1}`
  );

  console.log({ data });
  let res = data.data.response?.userList.map((x: any) => {
    return {
      label: x.name?.trim().length > 0 ? x.name : x.gamerTag,
      value: x.userId,
    };
  });
  console.log({ res });
  return res;
}

export const DebounceSelectComp  = ({value, setValue}:any) => {
  // const [value, setValue] = useState<UserValue[]>([]);
  const [search, setSearch] = useState({
    page: 1,
    per_page: 100,
    search: "",
  });
  const { data: searchData, isLoading: searching } = useAdmin({
    PageNumber: search.page,
    PageSize: search.per_page,
    search: search.search,
  });
  const onSearch_ = (value: string): any => {
    const name = value?.toString() || "";
    console.log({ value });
    setSearch({
      ...search,
      search: name,
      page: 1,
    });
  };
  return (
    <DebounceSelect
      mode="multiple"
      value={value}
      placeholder="Select users"
      fetchOptions={fetchUserList}
      onChange={(newValue) => {
        setValue(newValue as UserValue[]);
      }}
      // style={{ width: "100%", zIndex:100}}
    />
  );
};
