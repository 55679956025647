// import * as dotenv from "dotenv";
// dotenv.config();
export const FORMS_ID = {
  Analytics: "1",
  Profile: "2",
  Settings: "3",
  Admins: "6",
  Event: "7",
  HubsList: "8",
  Users: "9",
  UsersRoles: "12",
  TicketingSystem: "14",
  BracketPortal: "4",
  EditProfile: "5",
};
 
const env = process.env; 
console.log({env})
 export const environment = env.REACT_APP_environment==="prod"?"":env.REACT_APP_environment;
 

 export const rootUrl =  env.REACT_APP_rootUrl;
 export const gameRootUrl =  env.REACT_APP_gameRootUrl;
 export const eventRootUrl = env.REACT_APP_eventRootUrl;
 export const hubRootUrl = env.REACT_APP_hubRootUrl;
 export const hubAdminUrl =  env.REACT_APP_hubAdminUrl;
 export const hubAdminUrl_table =  env.REACT_APP_hubAdmin_Url;

 