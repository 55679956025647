import React, { useState, useCallback, useEffect } from "react";

// Chakra imports
import {
  Box,
  Text,
  Flex,
  Grid,
  Image,
  GridItem,
  useColorModeValue,
  SimpleGrid,
  chakra,
} from "@chakra-ui/react";
import Navigations from "views/admin/accountSettings/components/navigations";
import Business from "views/admin/accountSettings/components/Business";
import Information from "views/admin/accountSettings/components/Information";
import OtherLocations from "views/admin/accountSettings/components/OtherLocations";
import Card from "components/card/Card";
import Rectangle905 from "assets/img/global/Rectangle905.png";
import Documents from "views/admin/accountSettings/components/Documents";
import { get } from "redux/services/api";
import { hubRootUrl } from "utilities/constants";
import { useDispatch, useSelector } from "react-redux";

export default function Index(props:any) {
  const query = new URLSearchParams(props.location.search);
  const route = query.get("route");
  const cardBg = "#ECF1F4";
  const textColorSecondary_2 = "gray.300";
  const [activeId, setActiveId] = useState("");
  const [locationList, setlocationList] = useState([]);
  const [pendingLocationList, setPendingLocationList] = useState([]);

  const { userId, locations, isHubApproved } = useSelector(
    (state: any) => state.Auth
  );

 
  useEffect(() => {
    if (locations.length) {
      let arr = locations.filter((x:any)=>x.locationStatus==="Approved")
      let arr2 = locations.filter((x:any)=>x.locationStatus==="Pending")
      setPendingLocationList(arr2)
      setlocationList(arr);
    }
  }, [locations]);

  useEffect(() => {
    if (route) {
     console.log({route})
     setActiveId(route)
    }
  }, [route]);
  
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={9} borderRadius="10px">
          {activeId === "Business" && <Business />}
          {activeId === "Information" && <Information />}
          {activeId === "Locations" && <OtherLocations />}
          {activeId === "Documents" && <Documents />}
        </GridItem>
   
        {(activeId ==="Locations" && isHubApproved==="Approved") &&  (
          <GridItem colSpan={3} borderRadius="10px">
           {locationList.length>0 &&<Card
              shadow={"lg"}
              maxH="60vh"
              overflowY={"scroll"}
              css={{
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                  
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#1C2A5B",
                  borderRadius: "24px",
                },
              }}
              borderRadius={"8px"}
              p="40px"
            >
              <Text fontSize={"20px"} color="#1C2A5B">
                Active Locations{" "}
              </Text>
              {locationList.map((x, i) => {
                // console.log({ x });
                return (
                  <Card
                    key={i}
                    bg={cardBg}
                    p="10px"
                    mt="20px"
                    borderRadius={"8px"}
                  >
                    <Flex alignItems={"flex-start"}>
                      <Image
                        width={"100px"}
                        height="70px"
                        borderRadius={"8px"}
                        src={x.hubCoverPicPath}
                      />
                      <Text
                        ml="10px"
                        maxW={"170px"}
                        color={textColorSecondary_2}
                        fontSize={"14px"}
                      >
                        {x.hubAdress}
                      </Text>
                    </Flex>
                  </Card>
                );
              })}
            </Card>}

        {  pendingLocationList.length>0 &&  <Card
              mt={locationList.length>0?"20px":"0"}
              shadow={"lg"}
              borderRadius={"8px"}
              p="40px"
              maxH="40vh"
              overflowY={"scroll"}
              css={{
                "&::-webkit-scrollbar": {
                  width: "8px",
                  // border:"0px"
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                  // ,
                  // border:"0px"
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#1C2A5B",
                  borderRadius: "24px",
                  // border:"0px"
                },
              
              }}
            >
              <Text fontSize={"20px"} color="#1C2A5B">
                Pending
              </Text>
              {pendingLocationList.map((x, i) => {
                // console.log({ x });
                return (
                  <Card
                    key={i}
                    bg={cardBg}
                    p="10px"
                    mt="20px"
                    borderRadius={"8px"}
                  >
                    <Flex alignItems={"flex-start"}>
                      <Image
                        width={"100px"}
                        height="70px"
                        borderRadius={"8px"}
                        src={x.hubCoverPicPath}
                      />
                      <Text
                        ml="10px"
                        maxW={"170px"}
                        color={textColorSecondary_2}
                        fontSize={"14px"}
                      >
                        {x.hubAdress}
                      </Text>
                    </Flex>
                  </Card>
                );
              })}
            </Card>}
          </GridItem>
        )}
      </Grid>
    </Box>
  );
}
