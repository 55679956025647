import { memo, useState, useEffect } from "react";
import "../../../../../assets/css/App.css";
import {
  ModalBody,
  Heading,
  useColorModeValue,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Button,
  Input,
  Textarea,
  RadioGroup,
  Stack,
  ModalFooter,
  HStack,
  FormErrorMessage,
  Select,
  Checkbox,
  chakra,
} from "@chakra-ui/react";

interface Props {
  next: Function;
  parantState: any;
  handleBack: () => void;
}

function Step_3(props: Props) {
  const { next, handleBack, parantState } = props;
  const textColor = useColorModeValue("black.50", "white");
  const textColorSecondary = "gray.400";
  const buttonColorBrand = "blue.200";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [
    participants_play_each_other_error,
    setparticipants_play_each_other_error,
  ] = useState("");
  const [group_size_error, set_group_size_error] = useState("");

  const [state, setState] = useState<any>({
    bracket_Type: "single elimination",
    group_stage_bracket_Type: "single elimination",
    tournament_Type: "single stage tournament",
    require_team_registration: "false",
    group_size: 0,
    participant_count_to_advance_per_group: "",
    group_stage_split_participants: false,
    group_stage_participants_play_each_other: null,
    group_stage_ranked_by: "match wins",
    group_stage_rr_pts_for_match_win: 0,
    group_stage_rr_pts_for_match_tie: 0,
    group_stage_rr_pts_for_game_win: 0,
    group_stage_rr_pts_for_game_tie: 0,
    tournamentFormat: "1",
    holdThirdPlaceMatch: false,
    split_participants: false,
    rankBy: "match wins",
    grandFinal: null,
    participants_play_each_other: 1,
  });
  console.log("🚀 ~ Step_3 ~ parantState:    state:", state.holdThirdPlaceMatch)
  const [invalidState, setInvalidState] = useState({
    tournament_NameInvalid: false,
    tournament_DescriptionInvalid: false,
    number_of_participantsInvalid: false,
    participant_count_to_advance_per_group: false,
    group_size: false,
  });
  const updateState = (val: any, prop: string, invalidProp?: string) => {
    setState((prvState: any) => ({ ...prvState, [prop]: val }));
    if (invalidProp) {
      setInvalidState((prvState: any) => ({
        ...prvState,
        [invalidProp]: false,
      }));
    }
  };
  useEffect(() => {
    if (parantState.tournament_Name) {
      setState((prvState: any) => ({
        ...parantState,
        group_size: Number(parantState.group_size),
        participant_count_to_advance_per_group: Number(parantState.participant_count_to_advance_per_group),
      }));
    }
  }, [parantState]);
  const checks = () => {
    console.log({
      tournament_Type: state.tournament_Type,
      group_size: state.group_size,
      group_stage_bracket_Type: state.group_stage_bracket_Type,
      participant_count_to_advance_per_group:
        state.participant_count_to_advance_per_group,
    });
    if (state.tournament_Type === "single stage tournament") {
      return true;
    } else if (Number(state.group_size) === 0 || state.group_size === "") {
      set_group_size_error(
        "Please add the # of participants compete in each group"
      );
      setInvalidState((prvState: any) => ({
        ...prvState,
        group_size: true,
      }));
      return false;
    } else if (
      state.group_stage_bracket_Type == "round robin" &&
      Number(state.group_size) > 20
    ) {
      set_group_size_error("Players can be 20 max to compete in group");
      setInvalidState((prvState: any) => ({
        ...prvState,
        group_size: true,
      }));
      return false;
    } else if (
      state.participant_count_to_advance_per_group === "" ||
      Number(state.participant_count_to_advance_per_group) === 0
    ) {
      setparticipants_play_each_other_error(
        "Group stages participant count to advance per group must be greater than 0"
      );
      setInvalidState((prvState: any) => ({
        ...prvState,
        participant_count_to_advance_per_group: true,
      }));
      return false;
    }
     else if (
     
      Number(state.participant_count_to_advance_per_group)>=
      Number(state.group_size) 
    ) {
      set_group_size_error(
        "Participants compete each other count cannot be greater or equal than participants advance from each group"
      );
      setparticipants_play_each_other_error(
        "Participants compete each other count cannot be greater or equal than participants advance from each group"
      );

      setInvalidState((prvState: any) => ({
        ...prvState,
        participant_count_to_advance_per_group: true,
        group_size: true,
      }));
      return false;
    }
    return true;
  };
  const handleNext = () => {
    if (checks()) {
      next(state);
    }
  };
  return (
    <>
      <ModalBody
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#1C2A5B",
            borderRadius: "24px",
          },
        }}
        position={"relative"}
        px="50px"
      >
        <Heading color={textColor} fontSize="36px" w={400} mb="10px">
          Set Tournament
        </Heading>
        <HStack>
          <FormControl>
            <FormLabel
              display="flex"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              Tournament Type<Text color={brandStars}>*</Text>
            </FormLabel>
            <Select
              minH="48px"
              value={state.tournament_Type}
              onChange={(e) => {
                updateState(e.target.value, "tournament_Type");
              }}
              id="user_type"
              w="unset"
              display="flex"
              alignItems="center"
              defaultValue="Weekly"
            >
              <option value="single stage tournament">
                Single Stage Tournament
              </option>
              <option value="two stage tournament">Two Stage Tournament</option>
            </Select>
          </FormControl>
        </HStack>
        {state.tournament_Type === "single stage tournament" ? (
          <HStack mt="20px">
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
              >
                Bracket Type<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select
                minH="48px"
                value={state.bracket_Type}
                onChange={(e) => {
                  updateState(e.target.value, "bracket_Type");
                }}
                id="user_type"
                w="unset"
                display="flex"
                alignItems="center"
                defaultValue="Weekly"
              >
                <option value="single elimination">Single Elimination</option>
                <option value="double elimination">Double Elimination</option>
                <option value="round robin">Round Robin</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
              >
                Format<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select
                minH="48px"
                value={state.tournamentFormat}
                onChange={(e) => {
                  updateState(e.target.value, "tournamentFormat");
                }}
                id="user_type"
                w="unset"
                display="flex"
                alignItems="center"
                defaultValue="Weekly"
              >
                <option value={"1"}>1v1</option>
                <option value={"2"}>2v2</option>
                <option value={"3"}>3v3</option>
                <option value={"4"}>4v4</option>
                <option value={"5"}>5v5</option>
              </Select>
            </FormControl>
          </HStack>
        ) : (
          <>
            <HStack mt="20px">
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                >
                  Group Stage <Text color={brandStars}>*</Text>
                </FormLabel>
                <Select
                  minH="48px"
                  value={state.group_stage_bracket_Type}
                  onChange={(e) => {
                    updateState(e.target.value, "group_stage_bracket_Type");
                  }}
                  id="user_type"
                  w="unset"
                  display="flex"
                  alignItems="center"
                  defaultValue="Weekly"
                >
                  <option value="single elimination">Single Elimination</option>
                  <option value="double elimination">Double Elimination</option>
                  <option value="round robin">Round Robin</option>
                </Select>
              </FormControl>
            </HStack>
            {state.group_stage_bracket_Type === "double elimination" && (
              <FormControl mt="20px">
                <Checkbox
                  isChecked={state.group_stage_split_participants}
                  onChange={(e) =>
                    // setState([e.target.checked, e.target.checked])
                    updateState(
                      e.target.checked,
                      "group_stage_split_participants"
                    )
                  }
                  size="md"
                >
                  Split participants - start with half in loser bracket
                </Checkbox>
              </FormControl>
            )}
            {state.group_stage_bracket_Type === "round robin" && (
              <HStack mt="20px">
                <FormControl>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                  >
                    Participants play each other{" "}
                    <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Select
                    minH="48px"
                    value={state.group_stage_participants_play_each_other}
                    onChange={(e) => {
                      updateState(
                        e.target.value,
                        "group_stage_participants_play_each_other"
                      );
                    }}
                    id="user_type"
                    w="unset"
                    display="flex"
                    alignItems="center"
                    defaultValue="Weekly"
                  >
                    <option value={"1"}>Once</option>
                    <option value={"2"}>Twice</option>
                    <option value={"3"}>3 Times</option>
                  </Select>
                </FormControl>
              </HStack>
            )}
            <HStack mt="20px">
              <FormControl isInvalid={invalidState.group_size}>
                <FormLabel
                  display="flex"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                >
                  No. of Players
                </FormLabel>
                <Input
                  minH="48px"
                  onChange={(e) =>
                    updateState(e.target.value, "group_size", "group_size")
                  }
                  borderColor={invalidState.group_size ? "red" : null}
                  value={state.group_size===0?"":state.group_size}
                  isRequired={true}
                  variant="auth"
                  placeholder="##"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="number"
                  borderRadius={8}
                  // mb="10px"
                  fontWeight="500"
                  size="lg"
                />

                <Text color={"#9299B0"} fontSize={"12px"}>
                  Participants compete in each group
                  <br />
                  <chakra.span fontSize={"10px"}>.</chakra.span>
                </Text>
                <FormErrorMessage> {group_size_error}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={invalidState.participant_count_to_advance_per_group}
              >
                <FormLabel
                  display="flex"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                >
                  No. of Players
                </FormLabel>
                <Input
                  minH="48px"
                  onChange={(e) =>
                    updateState(
                      e.target.value,
                      "participant_count_to_advance_per_group",
                      "participant_count_to_advance_per_group"
                    )
                  }
                  borderColor={
                    invalidState.participant_count_to_advance_per_group
                      ? "red"
                      : null
                  }
                  value={state.participant_count_to_advance_per_groupe===0?"":state.participant_count_to_advance_per_groupe}
                  
                  isRequired={true}
                  variant="auth"
                  placeholder="##"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="number"
                  borderRadius={8}
                  // mb="10px"
                  fontWeight="500"
                  size="lg"
                />
                <Text color={"#9299B0"} fontSize={"12px"}>
                  Participants advance from each group
                  <br />
                  <chakra.span fontSize={"10px"}>
                    (must be a power of 2 for single & double)
                  </chakra.span>
                </Text>
                <FormErrorMessage>
                  {" "}
                  {participants_play_each_other_error}
                </FormErrorMessage>
              </FormControl>
            </HStack>
            {/* {(state.bracket_Type === "round robin" ||
              state.group_stage_bracket_Type === "round robin") && (
              <HStack mt="20px">
                <FormControl>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                  >
                    Rank By <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Select
                    minH="48px"
                    value={state.rankBy}
                    onChange={(e) => {
                      updateState(e.target.value, "rankBy");
                      updateState(e.target.value, "group_stage_ranked_by");
                    }}
                    id="user_type"
                    w="unset"
                    display="flex"
                    alignItems="center"
                    defaultValue="Weekly"
                  >
                   
                    <option value="match wins">Match Wins</option>
                    <option value="game wins">Game Wins</option>
                    <option value="point scored">Point Scored</option>
                    <option value="point difference">Point Difference</option>
                    
                  </Select>
                </FormControl>
              </HStack>
            )} */}
            <HStack mt="20px">
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                >
                  Final Stage <Text color={brandStars}>*</Text>
                </FormLabel>
                <Select
                  minH="48px"
                  value={state.bracket_Type}
                  onChange={(e) => {
                    updateState(e.target.value, "bracket_Type");
                  }}
                  id="user_type"
                  w="unset"
                  display="flex"
                  alignItems="center"
                  defaultValue="Weekly"
                >
                  <option value="single elimination">Single Elimination</option>
                  <option value="double elimination">Double Elimination</option>
                  <option value="round robin">Round Robin</option>
                </Select>
              </FormControl>
            </HStack>
            {state.bracket_Type === "single elimination" && (
              <FormControl mt="20px">
                <Checkbox
                  isChecked={state.holdThirdPlaceMatch}
                  onChange={(e) =>
                    // setState([e.target.checked, e.target.checked])
                    updateState(e.target.checked, "holdThirdPlaceMatch")
                  }
                  py={"10px"}
                  size="md"
                >
                  Include a match for third place
                </Checkbox>
              </FormControl>
            )}{" "}
            {state.bracket_Type === "double elimination" && (
              <>
                <FormControl mt="20px">
                  <Checkbox
                    isChecked={state.split_participants}
                    onChange={(e) =>
                      // setState([e.target.checked, e.target.checked])
                      updateState(e.target.checked, "split_participants")
                    }
                    size="md"
                  >
                    Split participants - start with half in loser bracket
                  </Checkbox>
                </FormControl>
                <HStack mt="20px">
                  <FormControl>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                    >
                      Grand Finals <Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Select
                      minH="48px"
                      value={state.grandFinal}
                      onChange={(e) => {
                        updateState(e.target.value, "grandFinal");
                      }}
                      id="user_type"
                      w="unset"
                      display="flex"
                      alignItems="center"
                      defaultValue="Weekly"
                    >
                      <option value="single match">1-2 Match</option>
                      <option value="">1 Match</option>
                      <option value="skip">None</option>
                    </Select>
                  </FormControl>
                </HStack>
              </>
            )}
            {state.bracket_Type === "round robin" && (
              <HStack mt="20px">
                <FormControl>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                  >
                    Participants play each other{" "}
                    <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Select
                    minH="48px"
                    value={state.participants_play_each_other}
                    onChange={(e) => {
                      updateState(
                        e.target.value,
                        "participants_play_each_other"
                      );
                    }}
                    id="user_type"
                    w="unset"
                    display="flex"
                    alignItems="center"
                    defaultValue="Weekly"
                  >
                    <option value={1}>Once</option>
                    <option value={2}>Twice</option>
                    <option value={3}>3 Times</option>
                  </Select>
                </FormControl>
              </HStack>
            )}
          </>
        )}
      </ModalBody>

      <ModalFooter px="50px">
        <Flex w="full" justifyContent={"space-between"}>
          <Button
            onClick={handleBack}
            fontSize="sm"
            variant="brand"
            fontWeight="700"
            w="276px"
            h="50"
            bg="transparent"
            borderRadius={8}
            border={`1px solid `}
            color={"black"}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
            borderColor={"black"}
          >
            Back
          </Button>

          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="276px"
            h="50"
            onClick={() => handleNext()}
            borderRadius={8}
            bg={buttonColorBrand}
          >
            Continue
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
}
export default memo(Step_3);
