import React, { useState, memo } from "react";
// Chakra imports
import {
  Button,
  Grid,
  Flex,
  Avatar,
  Text,
  Tabs,
  TabList,
  Box,
  Tab,
  HStack,
  Image,
  
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { getProfile } from "redux/slices/Auth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
// Assets
import banner from "assets/img/global/banner.png";
import avatar from "assets/img/global/avatar.png";
interface Props {
  tabIndex: number;
  handleTabsChange: any;
  onOpenDrawer: any;
}
function Banner(props: any) {
  const {
    userId,
    userName,
    location,
    description,
    website,
    games,
    logo,
    coverImage,
    title,
  } = useSelector((state: any) => state.Auth);

  let { tabIndex, handleTabsChange, state, onOpenDrawer, selectedLocation } =
    props;

  // Chakra Color Mode
  console.log({ coverImage, logo });
  //
  const textColorSecondary = "gray.200";
  const textColorSecondary_2 = "gray.300";

  return (
    <Grid bg={"white"} borderRadius="10px" pb="10px" border="2px solid #E2E2EA">
      <Grid
        bgSize="cover"
        // bgImage={coverImage?.replace("\\", "/") ?? banner}
        bgImage={state?.hubCoverPicPath?.replace("\\", "/") ?? banner}
        // position="relative"
        h={"260px"}
        w="full"
        borderTopRadius="10px"
        templateColumns="repeat(2, 1fr)"
      >
        <Flex alignItems={"flex-end"}>
          <Image
            height="160px"
            w="160px"
            borderRadius="160px"
            mb={"-50px"}
            ml={"20px"}
            border="5px solid white"
            src={state?.hubProfilePicPath ?? avatar}
          />
          <Grid ml={"20px"} mb={"20px"}>
            <Text color={"white"} fontSize="x-large">
              {state?.companyName ?? ""}
            </Text>
            {/* <Text color={textColorSecondary} fontSize="sm">
              {location ?? "1076 McCullen Ave. Dallas, TX"}
            </Text> */}
            {/* <Box mb="15px">
              <Select
                borderRadius={"8px"}
                iconColor="white"
                color={"white"}
                w="250px"
                value={selectedLocation?.locationId}
                onChange={onSelectLocation}
                placeholder="Select Location"
              >
                {locations.map((loc: any) => {
                  return (
                    <option value={loc.locationId}>{loc.hubAdress}</option>
                  );
                })}
              </Select>
            </Box> */}
          </Grid>
        </Flex>
        {/* <Flex alignItems={"flex-start"} justifyContent="flex-end">
          {selectedLocation && (
            <Button
              onClick={onOpenDrawer}
              fontSize="sm"
              variant="outline"
              borderColor="white"
              color="white"
              fontWeight="500"
              w="20%"
              h="40px"
              borderRadius={8}
              _hover={{ bg: mode("blue.200", "blue.200") }}
              m={"20px"}
            >
              Edit Profile
            </Button>
          )}
        </Flex> */}
      </Grid>
      <Grid position="relative" templateColumns="repeat(3, 1fr)">
        <Flex></Flex>
        <Flex></Flex>
        <Flex mt="10px" w={"full"} px="20px" justifyContent={"flex-end"}>
          <HStack w="250px" spacing="24px">
            <Box w="33%">
              <Text
                color={textColorSecondary_2}
                textAlign={"center"}
                fontSize="14px"
              >
                Followers
              </Text>
              <Text
                textAlign={"center"}
                fontSize="16px"
                fontWeight={"semibold"}
              >
                {state?.followersCount??0}
              </Text>
            </Box>
            <Box w="33%">
              <Text
                color={textColorSecondary_2}
                fontSize="14px"
                textAlign={"center"}
              >
                Friends
              </Text>
              <Text
                textAlign={"center"}
                fontSize="16px"
                fontWeight={"semibold"}
              >
                {state?.friendsCount??0}
              </Text>
            </Box>
            <Box w="33%">
              <Text
                color={textColorSecondary_2}
                fontSize="14px"
                textAlign={"center"}
              >
                Hosted
              </Text>
              <Text
                textAlign={"center"}
                fontSize="16px"
                fontWeight={"semibold"}
              >
                {state?.hostedCount??0}
              </Text>
            </Box>
          </HStack>
        </Flex>
      </Grid>
    </Grid>
  );
}

export default memo(Banner);
