// // import react, { useEffect, useState } from "react";
// // import { Box, SimpleGrid } from "@chakra-ui/react";
// // import ComplexTable from "./components/ComplexTable";
// // import tableDataComplex from "./variables/tableDataComplex";
// // import { useDispatch, useSelector } from "react-redux";
// // import { getHubList } from "redux/slices/AdminsTableLists";
// // export default function Settings() {
// //   const dispatch: any = useDispatch();
// //   const { hubListloading, hubList } = useSelector(
// //     (state: any) => state.AdminsTableLists
// //   );

// //   const [tableData, setTableData] = useState([]);
// //   useEffect(() => {
// //     let payload = { PageNumber: 1, PageSize: 100, Flag: 0 };
// //     dispatch(getHubList(payload));
// //   }, []);
// //   useEffect(() => {
// //     if (hubList) {
// //       setTableData(hubList);
// //     }
// //     console.log({ hubList });
// //   }, [hubList]);
// //   return (
// //     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
// //       <SimpleGrid
// //         mb="20px"
// //         columns={{ sm: 1, md: 1 }}
// //         spacing={{ base: "20px", xl: "20px" }}
// //       >
// //         <ComplexTable tableData={tableData} />
// //       </SimpleGrid>
// //     </Box>
// //   );
// // }
// // import react, { useEffect, useState } from "react";
// // import { Box, SimpleGrid } from "@chakra-ui/react";
// // import ComplexTable from "./components/ComplexTable";
// // import tableDataComplex from "./variables/tableDataComplex";
// // import { useDispatch, useSelector } from "react-redux";
// // import { getAdminsList } from "redux/slices/AdminsTableLists";
// // export default function Settings() {

// //   const dispatch: any = useDispatch();
// //   const { adminsListloading, adminsList } = useSelector(
// //     (state: any) => state.AdminsTableLists
// //   );
// //   const [tableData, setTableData] = useState([]);
// //   const [pagination, setPagination] = useState({
// //     pageIndex: 0,
// //     pageSize: 10,
// //     pageCount: -1, // -1 allows the table to calculate the page count for us via instance.getPageCount()
// //   });
// //   useEffect(() => {
// //     let payload = { PageNumber: 1, PageSize: 100, Flag: 0 };
// //     dispatch(getAdminsList(payload));
// //   }, []);
// //   useEffect(() => {
// //     if (adminsList) {
// //       setTableData(adminsList);
// //     }
// //     console.log({ adminsList });
// //   }, [adminsList]);
// //   return (
// //     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
// //       <SimpleGrid
// //         mb="20px"
// //         columns={{ sm: 1, md: 1 }}
// //         spacing={{ base: "20px", xl: "20px" }}
// //       >
// //         <ComplexTable
// //           tableData={tableData}
// //           usersListloading={adminsListloading}
// //           pagination={pagination}
// //           setPagination={setPagination}
// //         />
// //       </SimpleGrid>
// //     </Box>
// //   );
// // }
// import { Box, SimpleGrid, Text, Flex } from "@chakra-ui/react";
// import react, { useEffect, useState } from "react";
// import ComplexTable from "./components/ComplexTable";
// import tableDataComplex from "./variables/tableDataComplex";
// import { useDispatch, useSelector } from "react-redux";
// import { getEventList } from "redux/slices/AdminsTableLists";
// import { useHistory, useLocation } from "react-router-dom";

// import { useAdmin } from "./hooks";
// import { Table, Pagination, Input } from "antd";

// const { Search } = Input;
// const DEFAULT_PAGE_SIZE = 10;
// export default function EventsList() {
//   const history = useHistory();

//   const [filters, setFilters] = useState({
//     page: 1,
//     per_page: DEFAULT_PAGE_SIZE,
//     search: "",
//   });

//   const { data, isLoading, isSuccess, isError, error, isFetching } = useAdmin({
//     PageNumber: filters.page,
//     PageSize: filters.per_page,
//     search: filters.search,
//   });

//   const [tableData, setTableData] = useState([]);
//   const userTransactions = data?.response?.userList || [];
//   const totalTransactions = data?.response?.totalCount || 0;
//   // const currentPage = data?.data?.current_page || 0;
//   const handlePageChange = (page: number, size: number) => {
//     console.log({ page, size });
//     setFilters({ ...filters, page, per_page: size });
//   };

//   const handleTableChange = (
//     pagination: any,
//     tableFilters: any,
//     sorter: any
//   ) => {
//     setFilters({
//       search: "",
//       page: 1,
//       per_page: DEFAULT_PAGE_SIZE,
//     });
//   };
//   const navigate = (hubId: any) => {
//     console.log({ hubId });
//     history.push({
//       pathname: "/admin/ViewHubDetails",
//       search: `?hubId=${hubId.userId}`,
//     });
//   };
//   const columns = [
//     {
//       title: "Name",
//       dataIndex: "name",
//       key: "name",
//       render: (text: any, record: any) => (
//         <Text
//           cursor={"pointer"}
//           onClick={() => navigate(record)}
//           textDecorationLine={"underline"}
//         >
//           {record.name}
//         </Text>
//       ),
//     },
//     {
//       title: "Company Name",
//       dataIndex: "companyName",
//       key: "companyName",
//     },
//     {
//       title: "Email",
//       dataIndex: "email",
//       key: "email",
//     },
//     {
//       title: "Phone",
//       dataIndex: "phone",
//       key: "phone",
//     },
//     {
//       title: "Status",
//       dataIndex: "status",
//       key: "status",
//     },
//   ];
//   const onSearch = (value: string) => {
//     const name = value?.toString() || "";

//     setFilters({
//       ...filters,
//       search: name,
//       page: 1,
//     });
//   };
//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       <SimpleGrid
//         mb="20px"
//         columns={{ sm: 1, md: 1 }}
//         spacing={{ base: "20px", xl: "20px" }}
//       >
//         <Flex justifyContent={"flex-end"} mt="10px">
//           <Search
//             placeholder="Search entities"
//             onSearch={onSearch}
//             allowClear
//             style={{ width: 300 }}
//           />
//         </Flex>
//         <div>
//           <Table
//             rowKey={(record) => record.id}
//             columns={columns}
//             dataSource={userTransactions}
//             onChange={handleTableChange}
//             pagination={false}
//             loading={isLoading}
//           />
//           {totalTransactions > 0 ? (
//             <Flex justifyContent={"flex-end"} mt="10px">
//               <Pagination
//                 // showLessItems
//                 defaultPageSize={DEFAULT_PAGE_SIZE}
//                 total={totalTransactions}
//                 showSizeChanger={true}
//                 current={filters.page}
//                 onChange={handlePageChange}
//                 pageSizeOptions={[10, 20, 30, 50]}
//                 pageSize={filters.per_page}
//               />
//             </Flex>
//           ) : null}
//         </div>
//       </SimpleGrid>
//     </Box>
//   );
// }

import react, { useEffect, useState, useRef, useMemo } from "react";
import ComplexTable from "./components/ComplexTable";
import tableDataComplex from "./variables/tableDataComplex";
import { useDispatch, useSelector } from "react-redux";
import { getUserRolesList } from "redux/slices/AdminsTableLists";
import { useAdmin } from "./hooks";
import { useHistory, useLocation } from "react-router-dom";
import { Select as AntdSelect, Spin } from "antd";
import debounce from "lodash/debounce";
import {
  Box,
  SimpleGrid,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Button,
  Icon,
  Input,
  useColorModeValue,
  Text,
  FormErrorMessage,
  useToast,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { Table, Pagination, Input as antdInputs } from "antd";
import { DebounceSelectComp } from "./components/DebounceSelect";
import { hubAdminUrl } from "utilities/constants";
import { post } from "redux/services/api";
import {
  AsyncCreatableSelect,
  AsyncSelect,
  CreatableSelect,
  Select,
} from "chakra-react-select";
const { Search } = antdInputs;
const DEFAULT_PAGE_SIZE = 10;

export default function Settings() {
  const history = useHistory();
  const dispatch: any = useDispatch();
  const [filters, setFilters] = useState({
    page: 1,
    per_page: DEFAULT_PAGE_SIZE,
    search: "",
  });
  const [search, setSearch] = useState({
    page: 1,
    per_page: 100,
    search: "",
  });
  const { data, isLoading, isSuccess, isError, error, isFetching } = useAdmin({
    PageNumber: filters.page,
    PageSize: filters.per_page,
    search: filters.search,
  });
  const { data: searchData, isLoading: searching } = useAdmin({
    PageNumber: search.page,
    PageSize: search.per_page,
    search: search.search,
  });

  const { usersListloading, usersList, userRolesList } = useSelector(
    (state: any) => state.AdminsTableLists
  );
  const textColor = useColorModeValue("black.50", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonColorBrand = useColorModeValue("blue.200", "white");
  const toast = useToast();

  const [isLoadingOnSave, setisLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tableData, setTableData] = useState([]);
  const [userDropDown, setUserDropDown] = useState([]);
  const [roleDropDown, setRoleDropDown] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [selectedRole, setSelectedRole] = useState<any>([]);

  const [invalidState, setInvalidState] = useState({
    userInvalid: false,
    roleInvalid: false,
  });
  const userTransactions = data?.response?.userList || [];
  const searchDataTransactions = searchData?.response?.userList || [];

  const totalTransactions = data?.response?.totalCount || 0;
  useEffect(() => {
    let payload = { PageNumber: 1, PageSize: 100, Flag: 1 };
    dispatch(getUserRolesList(payload));
  }, []);
  // useEffect(() => {
  //   if (searchDataTransactions) {

  //     setUserDropDown(arr);
  //   }
  // }, [searchDataTransactions]);
  useEffect(() => {
    if (userRolesList) {
      let arr = userRolesList.map((x: any) => {
        return {
          label: x.roleName,
          value: x.roleId,
        };
      });
      setRoleDropDown(arr);
    }
  }, [userRolesList]);
  const checks = () => {
    if (value.length === 0) {
      setInvalidState((prvState) => ({
        ...prvState,
        userInvalid: true,
      }));
      return false;
    } else if (selectedRole.length === 0) {
      setInvalidState((prvState) => ({
        ...prvState,
        roleInvalid: true,
      }));
      return false;
    }
    return true;
  };
  const saveRole = () => {
    if (checks()) {
      save();
    }
  };
  const save = async () => {
    setisLoading(true);
    let roleId = selectedRole.value;
    let userIds = value.map((x: any) => x.value);

    let data = { roleId, userIds };
    let payload = {
      url: `${hubAdminUrl}/api/v1/user/save-user-role-assign`,
      data,
    };
    let response = await post(payload);
    console.log({ response });

    if (response.data.statusCode === 200) {
      onClose();
      setisLoading(false);
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const onAdd = () => {
    setSelectedRole([]);
    setValue([]);
    onOpen();
  };

  // const currentPage = data?.data?.current_page || 0;
  const handlePageChange = (page: number, size: number) => {
    console.log({ page, size });
    setFilters({ ...filters, page, per_page: size });
  };

  const handleTableChange = (
    pagination: any,
    tableFilters: any,
    sorter: any
  ) => {
    setFilters({
      ...filters,
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
    });
  };
  const navigate = (hubId: any) => {
    console.log({ hubId });
    history.push({
      pathname: "/admin/ViewHubDetails",
      search: `?hubId=${hubId.userId}`,
    });
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
        <Text
          cursor={"pointer"}
          onClick={() => navigate(record)}
          textDecorationLine={"underline"}
        >
          {record.name}
        </Text>
      ),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];
  const onSearch = (value: string) => {
    const name = value?.toString() || "";

    setFilters({
      ...filters,
      search: name,
      page: 1,
    });
  };
  const [value, setValue] = useState<any[]>([]);

  const onSearch_ = (value: string) => {
    const name = value?.toString() || "";
    console.log({ value });
    setSearch({
      ...search,
      search: name,
      page: 1,
    });
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          ></Text>
          <IconButton
            bg="#ccc"
            _hover={{ background: "#c2c2c2" }}
            onClick={onAdd}
            aria-label=""
            p="10px"
          >
            <Text color={"white"}>Assign Roles</Text>
          </IconButton>
        </Flex>

        <Flex justifyContent={"flex-end"} mt="10px">
          <Search
            placeholder="Search entities"
            onSearch={onSearch}
            allowClear
            style={{ width: 300 }}
          />
        </Flex>
        <div>
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={userTransactions}
            onChange={handleTableChange}
            pagination={false}
            loading={isLoading}
          />
          {totalTransactions > 0 ? (
            <Flex justifyContent={"flex-end"} mt="10px">
              <Pagination
                // showLessItems
                defaultPageSize={DEFAULT_PAGE_SIZE}
                total={totalTransactions}
                showSizeChanger={true}
                current={filters.page}
                onChange={handlePageChange}
                pageSizeOptions={[10, 20, 30, 50]}
                pageSize={filters.per_page}
              />
            </Flex>
          ) : null}
        </div>
        <Drawer size={"xl"} isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay zIndex={10} />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Assign Role</DrawerHeader>

            <DrawerBody zIndex={10}>
              <Flex mt="10px">
                {/* <Search
                  placeholder="Search Users"
                  onSearch={onSearch_}
                  allowClear
                  style={{ width: 300 }}
                  loading={searching}
                /> */}
              </Flex>
              <FormControl zIndex={100} isInvalid={invalidState.userInvalid}>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Users<Text color={brandStars}>*</Text>
                </FormLabel>
                <Flex w="full" position={"relative"}>
                  <DebounceSelectComp value={value} setValue={setValue} />
                </Flex>

                {/* <Select
                  isMulti
                  colorScheme="purple"
                  options={searchDataTransactions.map((x: any) => {
                    return {
                      label: x.name?.trim().length>0?x.name:x.gamerTag,
                      value: x.userId,
                    };
                  })}
                  onChange={setSelectedUsers}
                  value={selectedUsers}
                />
                {invalidState.userInvalid && (
                  <FormErrorMessage>Please select user(s).</FormErrorMessage>
                )} */}
              </FormControl>
              <FormControl isInvalid={invalidState.roleInvalid}>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Role<Text color={brandStars}>*</Text>
                </FormLabel>
                <Select
                  onChange={setSelectedRole}
                  value={selectedRole}
                  colorScheme="purple"
                  options={roleDropDown}
                />
                {invalidState.roleInvalid && (
                  <FormErrorMessage>Please select role</FormErrorMessage>
                )}
              </FormControl>
              <Button
                isLoading={isLoadingOnSave}
                loadingText="Saving..."
                onClick={() => saveRole()}
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                mt="20px"
                w="100%"
                h="50"
                mb="24px"
                borderRadius={8}
                bg={buttonColorBrand}
              >
                Save
              </Button>
            </DrawerBody>

            <DrawerFooter></DrawerFooter>
          </DrawerContent>
        </Drawer>
      </SimpleGrid>
    </Box>
  );
}
