import { useState } from "react";
// Chakra imports
import {
  Flex,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DatePicker from "react-datepicker";
import moment from "moment";
export default function Default(props: {
  innerTextColor: string;
  label: string;
  width?: string;
  bg: string;
  px?: string;
  m?: string;
  isReadOnly?:boolean;
  [x: string]: any;
}) {
  const {
    width,
    innerTextColor,
    label,
    bg,
    px,
    handleStartDate,
    handleEndDate,
    HubStartTime,
    HubEndTime,
    m,
    index,
    IsClose,
    isReadOnly,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [isOpenStartTime, setIsOpenStartTime] = useState(false);
  const [isOpenEndTime, setIsOpenEndTime] = useState(false);

  const handleChangeStartTime = (e: any) => {
    setIsOpenStartTime(!isOpenStartTime);
    setStartTime(e);
    let time = moment(e).format("h:mm a");
    handleStartDate(time, index);
  };
  const handleChangeEndTime = (e: any) => {
    setIsOpenEndTime(!isOpenEndTime);
    setEndTime(e);
    let time = moment(e).format("h:mm a");
    handleEndDate(time, index);
  };
  const handleEndTime = (e: any) => {
    if (IsClose||isReadOnly) return;
    e.preventDefault();
    setIsOpenEndTime(!isOpenEndTime);
  };
  const handleSatrtTime = (e: any) => {
    if (IsClose ||isReadOnly) return;
    e.preventDefault();
    setIsOpenStartTime(!isOpenEndTime);
  };
  return (
    <Flex
      {...rest}
      // width={width}
      // px='10px'
      m={m}
      borderRadius={"50px"}
      padding={"5px"}
      bg={IsClose ?"#E3E4E5"   :bg }
      justifyContent={"center"}
      alignItems="center"
      position={"relative"}
    >
      {IsClose === false ? (
        <>
          <Text
            onClick={handleSatrtTime}
            px={px}
            color={innerTextColor}
            fontSize={"14px"}
          >
            {HubStartTime}
          </Text>
          <Text px={px} color={innerTextColor} fontSize={"14px"}>
            - -{" "}
          </Text>
          <Text
            onClick={handleEndTime}
            px={px}
            color={innerTextColor}
            fontSize={"14px"}
          >
            {HubEndTime}
          </Text>
        </>
      ) : (
        <Text onClick={handleEndTime} px={px} color={"black"} fontSize={"14px"}>
          Closed
        </Text>
      )}

      {isOpenStartTime && (
        <div style={{ position: "absolute", top: 20, zIndex: 1, left: -50 }}>
          <DatePicker
            selected={startTime}
            onChange={handleChangeStartTime}
            inline
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </div>
      )}
      {isOpenEndTime && (
        <div style={{ position: "absolute", top: 20, zIndex: 1, right: -50 }}>
          <DatePicker
            selected={endTime}
            onChange={handleChangeEndTime}
            inline
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </div>
      )}
    </Flex>
  );
}
