import React, { useState, memo, useEffect } from "react";
// Chakra imports
import {
  Input,
  Grid,
  Flex,
  Image,
  Text,
  GridItem,
  InputGroup,
  Box,
  Icon,
  HStack,
  InputRightElement,
  Avatar,
} from "@chakra-ui/react";
import EventConfiguration from "views/admin/bracketPortal/components/EventConfiguration";

import { RxFile } from "react-icons/rx";

import Card from "components/card/Card";
import Chip from "components/chip/Chip";
import { useDispatch, useSelector } from "react-redux";

import pubg_rec from "assets/img/global/pubg_rec.png";

import EventDetailsBanner from "./EventDetailsBanner";
import Chat from "./Chat";

import Leaderboard from "views/admin/bracketPortal/components/Leaderboard";

interface Props {
  state: any;
}
function EventDetails(props: any) {
  const {
    tournament_Description,
    registerPlayers,
    eventGames,
    leaderBoard,
    rules,
    attachmentsPath,
    groupLeaderBoard,
    amenity,
    eventType
  } = props.state;
    console.log("🚀 ~ EventDetails ~ eventType:", eventType)
  const { aminitiesList } = useSelector((state: any) => state.Auth);
  const { activeId, onChangeNavigation } = props;
  const textColorSecondary_2 = "#707997";
  const brandBlue = "brand.500";
  const textColorSecondary = "gray.400";
  const downloadAttachment = () => {};
  const [Amenities, setAmenities] = useState([]);
  useEffect(() => {
    if (aminitiesList.length) {
      let arr = aminitiesList.map((x: any) => {
        return {
          ...x,
          AminityId: x.amenityId,
          IsSelected: false,
        };
      });
      setAmenities(arr);
    }
  }, [aminitiesList]);
  return (
    <Box>
      <EventDetailsBanner state={props.state} />
      <Box mt="20px" />
      <EventConfiguration
        state={props.state}
        activeId={activeId}
        onChangeNavigation={onChangeNavigation}
      />
      {leaderBoard?.length > 0 && (
        <Leaderboard
          leaderBoard={leaderBoard ?? []}
          isgroupLeaderBoard={false}
        />
      )}
      {groupLeaderBoard?.length > 0 && (
        <Leaderboard
          leaderBoard={groupLeaderBoard ?? []}
          isgroupLeaderBoard={true}
        />
      )}

      <Card color={"black"} p="16px" mt="16px"  border="1px solid #ECF1F4">
        <Text color={"black"} fontWeight="600" fontSize={"20px"}>
          Description
        </Text>
        <Text mt="10px" color={textColorSecondary_2} fontSize={"16px"}>
          {/* {tournament_Description} */}
          <Box dangerouslySetInnerHTML={{ __html: tournament_Description }} />
        </Text>
      </Card>
      <Card p="16px" mt="16px" border="1px solid #ECF1F4">
        <Text color={"black"} fontWeight="600" fontSize={"20px"}>
          Rules
        </Text>
        <Text mt="10px" color={textColorSecondary_2} fontSize={"16px"}>
          {/* {rules} */}
          <Box dangerouslySetInnerHTML={{ __html: rules }} />
        </Text>
      </Card>
      {eventType != "Online Event" ?
      <>
      <Card mt="16px" p="16px" border="1px solid #ECF1F4">
        <Text color={"black"} fontWeight="600" fontSize={"20px"}>
          Amenities
        </Text>
        <Flex
          flexWrap={"wrap"}
          justifyContent={"flex-start"}
          alignItems="center"
          py="7px"
          // px="20px"
        >
          {Amenities.map((item: any, index: number) => {
            if (amenity?.includes(item.AminityId)) {
              return (
                <Chip
                  onClick={() => {}}
                  key={index}
                  // cursor="pointer"
                  innerTextColor={"white"}
                  label={item.amenitiesChoice}
                  px="10px"
                  m="2px"
                  bg={brandBlue}
                  // _hover={{ bg: textColorSecondary }}
                />
              );
            }
            return null;
          })}
        </Flex>
      </Card>
      </>
      : null  }
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={12}>
          <Flex justifyContent={"space-between"}>
            <Card
              p="16px"
              mt="16px"
              w="33%"
              px="20px"
              border="1px solid #ECF1F4"
            >
              <Text color={"black"} fontWeight="600" fontSize={"16px"}>
                Attachments
              </Text>
              <HStack
                w="50%"
                py="10px"
                overflow={"hidden"}
                justifyContent={"space-between"}
              >
                {attachmentsPath?.map((x: any, i: any) => {
                  return (
                    <Icon
                      k={i}
                      onClick={() => window.open(x, "_blank")}
                      as={RxFile}
                      color={"#FF7763"}
                      cursor="pointer"
                      w="68px"
                      h="68px"
                    />
                  );
                })}
              </HStack>
            </Card>
            <Card
              p="16px"
              mt="16px"
              w="33%"
              px="20px"
              border="1px solid #ECF1F4"
            >
              <Text color={"black"} fontWeight="600" fontSize={"16px"}>
                Game(s)
              </Text>
              {eventGames.map((x: any, i: number) => {
                return (
                  <Box key={x.gameId} maxW={"100px"}>
                    <Image
                      h="110"
                      w="83px"
                      objectFit={"cover"}
                      borderRadius={8}
                      src={x.gamePicPath}
                      mt="10px"
                    />
                    <Text
                      // ml="10px"
                      // mt="-20px"
                      color={"black"}
                      fontSize={"12px"}
                      noOfLines={2}
                    >
                      {x.name}
                    </Text>
                  </Box>
                );
              })}
            </Card>
            <Card
              p="16px"
              mt="16px"
              w="33%"
              px="20px"
              border="1px solid #ECF1F4"
            >
              <Text color={"black"} fontWeight="600" fontSize={"16px"}>
                Participants
              </Text>
              <Flex
                overflowY="auto"
                maxH="400px"
                flexWrap={"wrap"}
                css={{
                  "&::-webkit-scrollbar": {
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#D2D2D2",
                    borderRadius: "24px",
                  },
                }}
              >
                {registerPlayers?.map((data: any) => {
                  return (
                    <Flex
                      w="50%"
                      key={data.playerId}
                      alignItems={"center"}
                      mt="20px"
                      justifyContent={"flex-start"}
                    >
                      <Box>
                        <Avatar src={data.playerProfilePicPath} size="md" />
                      </Box>
                      <Box ml="10px">
                        <Text fontWeight={"500"} fontSize={"12px"}>
                          {data.playerName}
                        </Text>
                        <Text fontSize={"12px"}> {data.title}</Text>
                      </Box>
                    </Flex>
                  );
                })}
              </Flex>
            </Card>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default memo(EventDetails);
