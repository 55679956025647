import React, { useState, memo, useEffect, useCallback } from "react";
// Chakra imports
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import {
  Flex,
  Text,
  HStack,
  Box,
  Button,
  Avatar,
  Input,
  Icon,
  useToast,
  Select,
  Spinner,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { eventRootUrl } from "utilities/constants";
import Card from "components/card/Card";
import avatar_New from "assets/img/global/avatar_New.png";
import { get, post, put } from "redux/services/api";
import { Skeleton } from "@chakra-ui/react";
import moment from "moment";
import { BsFileEarmarkTextFill } from "react-icons/bs";
import { GoTriangleUp } from "react-icons/go";
import { GoTriangleDown } from "react-icons/go";
import { Spin } from "antd";

const MatchCard = (props: any) => {
  const {
    challonge_Tournament_Id,
    isLoading,
    onClose,
    selectedRoundMatch,
    getMatches,
    loadingMatches,
  } = props;
  const {
    id,
    matchSets,
    state,
    player1ImagePath,
    player1Wins,
    player1_Userid,
    player1_id,
    player1_name,
    player1_fullname,
    player2ImagePath,
    player2Wins,
    player2_Userid,
    player2_id,
    player2_name,
    player2_fullname,
    player1_Score,
    player2_Score,
    matchStatus,
    matchLocation,
    bestOfMatch,
    roundName,
    isCompleted,
    isLosersRound,
    isMatchReset,
    round,
    winner_id,
    loser_id,
    Image,
    scores_csv,
    MatchStatus,
    Player1ImagePath,
    Player2ImagePath,
    underway_at,
    updated_at,
    suggested_play_order,
    matchAttachmentPath,
  } = selectedRoundMatch[0].match;
  const toast = useToast();
  const [loading, setisLoading] = useState(false);
  const [bestof, setBestof] = useState<any>(0);
  const [showEndMatchBtn, setShowEndMatchBtn] = useState(false);
  const [selectedSet, setSelectedSet] = useState<any>(null);
  const [matchSetsArr, setMatchSetsArr] = useState<any>([
    { player1_Score: 0, player2_Score: 1 },
  ]);
  useEffect(() => {
    console.log({ selectedRoundMatch, matchSets, matchAttachmentPath });
    if (matchSets) {
      let arr = [...matchSets];
    
      arr.every((x:any,i:number)=>{
        console.log({i, status:x.status})
        if(x.status==="open"|| x.status==="In Progress" ||  x.status==="Open"){
          updateMatchSet(i,arr)
          return false
        }
        return true
      })
     
      setMatchSetsArr(arr);
      // console.log({ matchSets });
      if (arr.length === 1) {
        setShowEndMatchBtn(true);
      }
      // setSelectedSet(arr[bestof]);
      let checkArr = arr.filter((x: any) => x.status === "Complete");

      if (checkArr.length === arr.length) {
        console.log("1");
        setShowEndMatchBtn(true);
      }
    }
  }, [matchSets]);
  useEffect(() => {
    console.log({ selectedRoundMatch });
  }, [selectedRoundMatch]);
  useEffect(() => {
    console.log({ loadingMatches });
  }, [loadingMatches]);
  const updateScore = (i?: number, opr?: string, property?: string) => {
    let arr = [...matchSetsArr];
    let obj = arr[bestof];
    console.log({ obj, bestof });
    if (opr === "increment") {
      obj[property] = obj[property] + 1;
    } else {
      if (obj[property] < 1) return;
      obj[property] = obj[property] - 1;
    }
    setMatchSetsArr(arr);
  };

  // const updateMatchSet = (e: any) => {
  //   setBestof(e);
  //   setSelectedSet(matchSetsArr[e]);
  // };

  const updateMatchSet =useCallback((e: any, arr?:any)=>{
    console.log({e})
    setBestof(e);
    if(arr){
      setSelectedSet(arr[e]);
    }else{
      setSelectedSet(matchSetsArr[e]);
    }
    
  },[matchSetsArr])
  const StartSet = async () => {
    setisLoading(true);
    // let arr = [...matchSetsArr];
    // let obj = arr[bestof];
    // obj['status']="In Progress"
    let data = {
      challonge_match_id: id,
      player1_id: player1_id,
      player2_id: player2_id,
      set: Number(bestof) + 1,
    };
    console.log({ data });
    let payload = {
      url: `${eventRootUrl}api/v1/challonge/start-set`,
      data: data,
    };
    let response = await post(payload);
    if (response.data.statusCode === 200) {
      setisLoading(false);
      getMatches();
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const endSet = async () => {
    let arr = [...matchSetsArr];
    let obj = arr[bestof];
    if (obj.player1_Score == obj.player2_Score) {
      toast.closeAll();
      toast({
        title: "Set Tied",
        description: "Set can not be tied!!",
        status: "info",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    setisLoading(true);
    let formdata = new FormData();
    formdata.append("match.id", id);
    formdata.append("match.tournament_id", challonge_Tournament_Id);
    formdata.append("match.winner_id", winner_id);
    formdata.append("match.loser_id", loser_id);
    formdata.append("match.player1_id", player1_id);
    formdata.append("match.player1_name", player1_name);
    formdata.append("match.player2_name", player2_name);
    formdata.append("match.player2_id", player2_id);
    formdata.append("match.state", state);
    formdata.append("match.scores_csv", scores_csv);
    formdata.append("match.Image", Image);
    formdata.append("match.MatchStatus", matchStatus);
    formdata.append("match.Player1ImagePath", player1ImagePath);
    formdata.append("match.Player2ImagePath", player2ImagePath);
    formdata.append("match.BestOfMatch", bestOfMatch);
    formdata.append("match.IsCompleted", "false");
    // formdata.append("match.matchSets", JSON.stringify(arr))
    for (let i = 0; i <= bestof; i++) {
      formdata.append("match.matchSets" + `[${i}]` + [`[status]`], "Complete");

      Object.keys(matchSetsArr[i]).map((key) => {
        if (key != "status") {
          formdata.append(
            "match.matchSets" + `[${i}]` + `[${key}]`,
            matchSetsArr[i][key]
          );
        }
      });
    }

    let payload = {
      url: `${eventRootUrl}api/v1/challonge/update-match`,
      data: formdata,
    };
    let response = await put(payload);
    console.log({ response });
    setisLoading(false);

    if (response.data.statusCode === 200) {
      if (matchSetsArr.length != bestof + 1) {
        updateMatchSet(bestof + 1);
      }

      getMatches();
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const startMatch = async (id: string) => {
    setisLoading(true);
    let payload = {
      url: `${eventRootUrl}api/v1/challonge/start-match`,
      data: {
        challongetournamentId: challonge_Tournament_Id,
        matchId: id.toString(),
      },
    };
    let response = await post(payload);
    setisLoading(false);
    if (response.data.statusCode === 200) {
      StartSet();
      // getMatches();
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const endMatch = async (
    id: string,
    player1_id: string,
    player2_id: string,
    tournament_id: string,
    matchSets: any
  ) => {
    let arr = [...matchSetsArr];
    let obj = arr[bestof];
    if (obj.player1_Score == obj.player2_Score) {
      toast.closeAll();
      toast({
        title: "Set Tied",
        description: "Set can not be tied!!",
        status: "info",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    setisLoading(true);
    let formdata = new FormData();
    formdata.append("match.id", id);
    formdata.append("match.tournament_id", challonge_Tournament_Id);
    formdata.append("match.winner_id", winner_id);
    formdata.append("match.loser_id", loser_id);
    formdata.append("match.player1_id", player1_id);
    formdata.append("match.player1_name", player1_name);
    formdata.append("match.player2_name", player2_name);
    formdata.append("match.player2_id", player2_id);
    formdata.append("match.state", state);
    formdata.append("match.scores_csv", scores_csv);
    formdata.append("match.Image", Image);
    formdata.append("match.MatchStatus", matchStatus);
    formdata.append("match.Player1ImagePath", player1ImagePath);
    formdata.append("match.Player2ImagePath", player2ImagePath);
    formdata.append("match.BestOfMatch", bestOfMatch);

    formdata.append("match.IsCompleted", "true");

    matchSets.forEach((x: any, i: number) => {
      formdata.append("match.matchSets" + `[${i}]` + [`status`], "Complete");
      Object.keys(x).map((key) => {
        if (key != "status")
          formdata.append("match.matchSets" + `[${i}]` + `[${key}]`, x[key]);
      });
    });

    console.log({ formdata });
    let payload = {
      url: `${eventRootUrl}api/v1/challonge/update-match`,
      data: formdata,
    };
    let response = await put(payload);
    console.log({ response });

    if (response.data.statusCode === 200) {
      onClose();
      setisLoading(false);
      getMatches();
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast.closeAll();
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
   
    <Box w="full">
    <Spin spinning={loadingMatches} tip="Loading...">
     
        <>
          <Flex
            mt={"10px"}
            direction={"column"}
            p="10px"
            alignItems={"center"}
            // bg="#ECF1F4"
            border="1px solid #ECF1F4"
            borderRadius={"8px"}
          >
            {/* <Text>{roundName}, Match 1 {| 07:00pm - 07:15pm} </Text> */}

            <Text>
              {roundName}, Match {suggested_play_order + " "}
              {underway_at ? moment(underway_at).format("hh:mm a") : ""}
              {underway_at ? "-" : ""}
              {underway_at ? moment(updated_at).format("hh:mm a") : ""}
            </Text>

            <Flex w="full" justifyContent={"center"} mt="10px">
              <Text color={"#9299B0"}>Best of {bestOfMatch} </Text>
              <Select
                ml="10px"
                w="100px"
                h="30px"
                onChange={(e) => updateMatchSet(e.target.value)}
                value={bestof}
                // placeholder="Select option"
              >
                {matchSetsArr.map((x: any, i: number) => {
                  return (
                    <option key={i} value={i}>
                      Set {i + 1}
                    </option>
                  );
                })}
              </Select>
            </Flex>

            <HStack w="90%" justifyContent={"space-between"}>
              <Box w={"100px"} textAlign={"center"}>
                <Avatar src={player1ImagePath} w="50px" h="50px" />
                <Text noOfLines={1}>{player1_name} </Text>
                <Text fontSize={"small"}> {player1_fullname}</Text>
                <Text color={"#999DA0"}> {player1Wins}W:0L</Text>
                {/* <Text color={"#999DA0"}> {player1Wins}W:0L</Text> */}
              </Box>
              <Box w={"80px"}>
                <Flex
                  mt="15px"
                  w="100px"
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Box>
                    {selectedSet?.status === "In Progress" && (
                      <Icon
                        cursor={"pointer"}
                        onClick={() =>
                          updateScore(1, "increment", "player1_Score")
                        }
                        as={GoTriangleUp}
                        height="25px"
                        color="#19BAEE"
                      />
                    )}
                    <Flex w="20px">
                      <Text fontSize={"28px"}>
                        {matchSetsArr[bestof]?.player1_Score}
                      </Text>{" "}
                    </Flex>
                    {selectedSet?.status === "In Progress" && (
                      <Icon
                        cursor={"pointer"}
                        onClick={() =>
                          updateScore(1, "decrement", "player1_Score")
                        }
                        as={GoTriangleDown}
                        height="25px"
                        color={
                          matchSetsArr[bestof]?.player1_Score === 0
                            ? "#7A7B85"
                            : "#FF5247"
                        }
                      />
                    )}
                  </Box>
                  <Text fontSize={"28px"}>:</Text>
                  <Box>
                    {selectedSet?.status === "In Progress" && (
                      <Icon
                        cursor={"pointer"}
                        onClick={() =>
                          updateScore(1, "increment", "player2_Score")
                        }
                        as={GoTriangleUp}
                        height="25px"
                        color="#19BAEE"
                      />
                    )}
                    <Flex w="20px">
                      <Text fontSize={"28px"}>
                        {matchSetsArr[bestof]?.player2_Score}
                      </Text>{" "}
                    </Flex>
                    {selectedSet?.status === "In Progress" && (
                      <Icon
                        cursor={"pointer"}
                        onClick={() =>
                          updateScore(1, "decrement", "player2_Score")
                        }
                        as={GoTriangleDown}
                        height="25px"
                        color={
                          matchSetsArr[bestof]?.player2_Score === 0
                            ? "#7A7B85"
                            : "#FF5247"
                        }
                      />
                    )}
                  </Box>
                </Flex>

                <Text textAlign={"center"} color={"#999DA0"}>
                  {matchLocation ?? ""}
                </Text>
              </Box>

              <Box  w={"100px"} textAlign={"center"}>
                <Avatar src={player2ImagePath} w="50px" h="50px" />
                <Text noOfLines={1}>{player2_name} </Text>
                <Text fontSize={"small"}> {player2_fullname}</Text>
                <Text color={"#999DA0"}> {player2Wins}W:0L</Text>
                {/* <Text color={"#999DA0"}> {0}W:1L</Text> */}
              </Box>
            </HStack>
            <Text
              mt="15px"
              fontSize={"15px"}
              textAlign="center"
              fontWeight="semibold"
              color="#FF5247"
            >
              {matchStatus}
            </Text>
            {showEndMatchBtn === false && selectedSet?.status === "In Progress" &&
              matchSetsArr.length > 1 && (
                <Button
                  isLoading={loading}
                  onClick={endSet}
                  w="full"
                  mt="20px"
                  fontSize="xs"
                  variant="brand"
                  fontWeight="normal"
                  px="20px"
                  bg="white"
                  color="#298BE2"
                  _hover={{ bg: "white" }}
                  borderRadius="16px"
                  border={"1px solid #298BE2"}
                >
                  End Set
                </Button>
              )}

            {selectedSet?.status === "Open" &&
              matchStatus != "Waiting on Host" && (
                <Button
                  isLoading={loading}
                  onClick={StartSet}
                  w="full"
                  mt="20px"
                  fontSize="xs"
                  variant="brand"
                  fontWeight="normal"
                  px="20px"
                  bg="#298BE2"
                  color="#fff"
                  _hover={{ bg: "#298BE2", color: "#fff" }}
                  borderRadius="16px"
                >
                  Start Set
                </Button>
              )}
          </Flex>

          {/* {matchStatus != "Waiting on Host" && matchStatus != "Completed" && ( */}
          {matchStatus === "Waiting on Host" && (
            <Button
              isLoading={loading}
              onClick={() => startMatch(id)}
              w="full"
              mt="20px"
              fontSize="xs"
              variant="brand"
              fontWeight="normal"
              px="20px"
              bg="#298BE2"
              color="#fff"
              _hover={{ bg: "#298BE2", color: "#fff" }}
              borderRadius="16px"
            >
              Start Match
            </Button>
          )}
          {showEndMatchBtn === true && matchStatus === "In Progress" && (
            <Button
              isLoading={loading}
              onClick={() =>
                endMatch(
                  id,
                  player1_id,
                  player2_id,
                  challonge_Tournament_Id,
                  matchSetsArr
                )
              }
              w="full"
              mt="20px"
              fontSize="xs"
              variant="brand"
              fontWeight="normal"
              px="20px"
              bg="#FF5247"
              color="#fff"
              _hover={{ bg: mode("#FF5247", "#FF5247"), color: "#fff" }}
              borderRadius="16px"
            >
              End Match
            </Button>
          )}
          {matchAttachmentPath && (
            <>
              <Text
                mt="15px"
                fontSize={"15px"}
                textAlign="start"
                fontWeight="semibold"
                color="#1C2A5B"
              >
                Verify Match
              </Text>
              <Icon
                mt="15px"
                onClick={() => window.open(matchAttachmentPath, "_blank")}
                color={"#1C2A5B"}
                _hover={{ cursor: "pointer" }}
                boxSize={"50px"}
                as={BsFileEarmarkTextFill}
              />

              <Text
                mt="15px"
                fontSize={"15px"}
                textAlign="start"
                // fontWeight="semibold"
                // color="blue.100"
              >
                Match Results
              </Text>
            </>
          )}
        </>
        </Spin>
    </Box>

     
  );
};
export default memo(MatchCard);
