import react, { useEffect, useState } from "react";
import CheckTable from "./components/CheckTable";
import ComplexTable from "./components/ComplexTable";
import {
  Box,
  SimpleGrid,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Button,
  Icon,
  Input,
  useColorModeValue,
  Text,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserRolesList,
  getRolePermssionList,
} from "redux/slices/AdminsTableLists";
import { post, get } from "redux/services/api";
import { hubAdminUrl } from "utilities/constants";
import async from "react-select/dist/declarations/src/async/index";

export default function Settings() {
  const textColor = useColorModeValue("black.50", "white");

  const buttonColorBrand = useColorModeValue("blue.200", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch: any = useDispatch();
  const toast = useToast();
  const { usersListloading, userRolesList } = useSelector(
    (state: any) => state.AdminsTableLists
  );
  const [isLoading, setisLoading] = useState(false);
  const [isLoadingRole, setIsLoadingRole] = useState(false);

  const [isAssignPermssion, setIsAssignPermssion] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [tableDataRoles, setTableDataRoles] = useState([]);

  useEffect(() => {
    let payload = { PageNumber: 1, PageSize: 100, Flag: 1 };
    dispatch(getUserRolesList(payload));
    dispatch(getRolePermssionList(payload));
  }, []);
  useEffect(() => {
    if (userRolesList) {
      setTableData(userRolesList);
    }
    console.log({ userRolesList });
  }, [userRolesList]);
  const [state, setState] = useState({
    RoleName: "",
    roleId: "",
  });

  const [invalidState, setInvalidState] = useState({
    RoleNameInvalid: false,

    PasswordInvalid: false,
  });
  const updateState = (val: string, prop: string) => {
    setState((prvState) => ({ ...prvState, [prop]: val }));
  };
  const checks = () => {
    if (state.RoleName.length < 3 || state.RoleName === "") {
      setInvalidState((prvState) => ({
        ...prvState,
        RoleNameInvalid: true,
      }));
    }
    return true;
  };
  const saveRole = () => {
    if (checks()) {
      save();
    }
  };
  const save = async () => {
    let data = { roleName: state.RoleName, active: true };
    let payload = {
      url: `${hubAdminUrl}/api/v1/user/save-user-role`,
      data,
    };
    let response = await post(payload);
    console.log({ response });
    setisLoading(false);

    if (response.data.statusCode === 200) {
      onClose();
      toast.closeAll()
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      let payload = { PageNumber: 1, PageSize: 100, Flag: 1 };
      dispatch(getUserRolesList(payload));
    } else {
      toast.closeAll()
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const AssignPermission = async (info: any) => {
    let roleId = info.row.original.roleId;
    setTableDataRoles([]);
    setState((prvState) => ({ ...prvState, roleId: roleId }));
    setIsLoadingRole(true);

    // console.log({ info: info.row.original.roleId });
    setIsAssignPermssion(true);
    onOpen();
    try {
      let url = `${hubAdminUrl}/api/v1/user/get-role-permssion-list?PageNumber=1&PageSize=100&RoleId=${roleId}`;
      const response: any = await get(url);
      setIsLoadingRole(false);
      setTableDataRoles(response.data.response.rolePermissions);
      console.log({ rolePermissions: response.data.response.rolePermissions });
    } catch (err: any) {
      console.log({ errorz: err });
      if (err) {
        throw err;
      } else {
        throw err;
      }
    }
  };
  const onAdd = () => {
    setIsAssignPermssion(false);
    onOpen();
  };
  const updateChecked = (e: any, info: any) => {
    let arr = [...tableDataRoles];
    let index = info.row.index;
    let column = info.column.id;
    arr[index][column] = !arr[index][column];

    setTableDataRoles(arr);
  };
  const saveRolePermission = async () => {
    setisLoading(true);
    let rolePermissions = tableDataRoles.map((x: any) => {
      return {
        formId: x.form_Id,
        canView: x.canView,
        canInsert: x.canInsert,
        canDelete: x.canDelete,
        canEdit: x.canEdit,
        canTicketAssign:x.canTicketAssign,
      };
    });
    rolePermissions = rolePermissions.filter((x: any) => {
      return (
        x.canView === true ||
        x.canInsert === true ||
        x.canDelete === true ||
        x.canEdit === true||
        x.canTicketAssign === true
      );
    });
    let data = { roleId: state.roleId, rolePermissions: rolePermissions };
    let payload = {
      url: `${hubAdminUrl}/api/v1/user/save-role-permission`,
      data,
    };
    let response = await post(payload);
    console.log({ response });
    setisLoading(false);

    if (response.data.statusCode === 200) {
      onClose();
      toast.closeAll()
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      let payload = { PageNumber: 1, PageSize: 100, Flag: 1 };
      dispatch(getUserRolesList(payload));
    } else {
      toast.closeAll()
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <ComplexTable
          tableData={tableData}
          onAdd={onAdd}
          AssignPermission={AssignPermission}
        />
        <Drawer size={"xl"} isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Add Role</DrawerHeader>

            <DrawerBody>
              {isAssignPermssion === false && (
                <FormControl isInvalid={invalidState.RoleNameInvalid}>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Role Title<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    borderColor={invalidState.RoleNameInvalid ? "red" : null}
                    onChange={(e) => updateState(e.target.value, "RoleName")}
                    value={state.RoleName}
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    borderRadius={8}
                    mb="10px"
                    fontWeight="500"
                    size="lg"
                  />
                  {invalidState.RoleNameInvalid && (
                    <FormErrorMessage>Role Name is required.</FormErrorMessage>
                  )}
                  <Button
                    isLoading={isLoading}
                    loadingText="Saving..."
                    onClick={() => saveRole()}
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    mb="24px"
                    borderRadius={8}
                    bg={buttonColorBrand}
                  >
                    Save
                  </Button>
                </FormControl>
              )}
              {isAssignPermssion === true && (
                <>
                  <CheckTable
                    updateChecked={updateChecked}
                    tableData={tableDataRoles}
                    isLoadingRole={isLoadingRole}
                  />
                  <Button
                    isLoading={isLoading}
                    loadingText="Saving..."
                    onClick={() => saveRolePermission()}
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    mb="24px"
                    borderRadius={8}
                    bg={buttonColorBrand}
                  >
                    Save
                  </Button>
                </>
              )}
            </DrawerBody>

            <DrawerFooter></DrawerFooter>
          </DrawerContent>
        </Drawer>
      </SimpleGrid>
    </Box>
  );
}
