import React, { memo, useState, useEffect } from "react";
import { RxFile } from "react-icons/rx";
import "../../../../../assets/css/App.css";

import {
  ModalBody,
  Heading,
  useColorModeValue,
  Flex,
  Text,
  Icon,
  FormLabel,
  Button,
  Box,
  Image,
  HStack,
  Spinner,
  ModalFooter,
  Switch
} from "@chakra-ui/react";
import { eventRootUrl } from "utilities/constants";
import { post } from "redux/services/api";
import AddImage from "assets/img/global/AddImage.png";
import AddCover from "assets/img/global/AddCover.png";
import { RxCross1 } from "react-icons/rx";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { TbPhotoPlus } from "react-icons/tb";
import { FiFilePlus } from "react-icons/fi";
import { BiTrashAlt } from "react-icons/bi";

interface Props {
  next: Function;
  handleBack: () => void;
  parantState: any;
}

function Step_8(props: Props) {
  const { next, handleBack, parantState } = props;
  const textColor = useColorModeValue("black.50", "white");
  const textColorSecondary = "gray.400";
  const buttonColorBrand = "blue.200";
  const hiddenFileInputBanner = React.useRef(null);
  const hiddenFileInput = React.useRef(null);
  const hiddenUploadInputBanner = React.useRef(null);
  const [AddMedia, setAddMedia] = useState(true);
  const [coverLoading, setCoverLoading] = useState(false);
  const [loadingAdditionalImages, setLoadingAdditionalImages] = useState(false);
  const [localPreview, setLocalPreview] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [localattachmentsPath] = useState([false, false, false]);
  const [gameImg, setGameImg] = useState()
  const [isChecked, setIsChecked] = useState(false);
  const [state, setState] = useState({
    additionalImagePath: [],
    coverPath: null,
    preview: [],
    previewCover: null,
    attachmentsPath: [],
  });

    const fetchImage = async () => {
      try {
        const imageUrlPath = parantState?.selectedGame?.background_image;
        const response = await fetch(imageUrlPath);
        const blob : any = await response.blob();

        const reader = new FileReader();
        reader.onloadend = () => {
          const prevCover: any = reader.result;
          // setBase64Image({size, type, lastModifiedDate,  name});
          setState((prvState)=>({
            ...prvState,
            previewCover: [prevCover],
          }))
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };

  const handleChangeToggle = (data: any) => {
    setTimeout(()=>{
      setIsChecked(!isChecked)
    if(data.target.checked === true){
      setGameImg(parantState?.selectedGame?.background_image)
      fetchImage();
      uploadHandlerApi("", "cover",parantState?.selectedGame?.background_image)
      setState((prevState)=> ({
        ...prevState,
        isCheckedToggle: true,
      }))
    }
    else if(data.target.checked === false){
      setGameImg(null)
      setState((prevState)=>({
        ...prevState, 
        previewCover: null,
        coverPath: "",
        isCheckedToggle: false,
    }))
    }
  },1000)
  }

  useEffect(() => {
    // console.log({ parantState });
    if (
      parantState.previewCover ||
      parantState.coverPath ||
      parantState.additionalImagePath ||
      parantState.attachmentsPath
    ) {
      setState(() => ({ ...parantState }));
    }
  }, [parantState]);

  useEffect(()=>{
    if(parantState.isCheckedToggle){
      setIsChecked(!isChecked)
    }
    // else if(parantState.isCheckedToggle === false){
    //   setIsChecked(isChecked)
    // }
  },[])

  const handleClickAttach = () => {
    hiddenUploadInputBanner.current.click();
  };
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event: any) => {
    setLoadingAdditionalImages(true);
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(fileUploaded);
    reader.onloadend = function (e) {
      let arr: any = [...state.preview];
      arr.push(reader.result);
      // setPreview(arr);
      setState((prvState) => ({ ...prvState, preview: arr }));
      uploadHandlerApi(fileUploaded, "additionalImage","");
    };
  };
  const handleChangeAttach = (event: any) => {
    setLoadingAdditionalImages(true);
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();

    uploadHandlerApi(fileUploaded, "attachment","");
  };
  const handleClickBanner = () => {
    hiddenFileInputBanner.current.click();
  };
  const handleChangeBanner = (event: any) => {
    setCoverLoading(true);
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(fileUploaded);
    reader.onloadend = function (e) {
      setState((prvState) => ({ ...prvState, previewCover: [reader.result] }));
      // setPreviewCover([reader.result]);
    };
    uploadHandlerApi(fileUploaded, "cover","");
  };

  const uploadHandlerApi = async (Image: any, imagetype: string, onlineImgPath: any) => {
    var myHeaders = new Headers();
    var formdata = new FormData();

    myHeaders.append("accept", "*/*");
    formdata.append("Image", Image);
    formdata.append("imagetype", imagetype);
    formdata.append("relatedTo", "tournament");
    formdata.append("ExternalImagePath", onlineImgPath)

    var requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    let payload = {
      url: `${eventRootUrl}api/v1/challonge/upload-attachment`,
      data: formdata,
    };
    let result: any = await post(payload);
    // console.log({ result: result.data.response.imagepath });
    if (
      result.data.status === true &&
      result.data.response.imagetype === "cover"
    ) {
      setCoverLoading(false);
      setState((prvState: any) => ({
        ...prvState,
        coverPath: result.data.response.imagepath,
      }));
    } else if (
      result.data.status === true &&
      result.data.response.imagetype === "additionalImage"
    ) {
      setLoadingAdditionalImages(false);
      let arr = [...state.additionalImagePath];
      arr.push(result.data.response.imagepath);
      setState((prvState: any) => ({
        ...prvState,
        additionalImagePath: arr,
      }));
    } else if (
      result.data.status === true &&
      result.data.response.imagetype === "attachment"
    ) {
      setLoadingAdditionalImages(false);
      let arr = [...state.attachmentsPath];
      arr.push(result.data.response.imagepath);
      setState((prvState: any) => ({
        ...prvState,
        attachmentsPath: arr,
      }));
    }
  };
  const handleNext = () => {
    next(state);
  };
  const handleRemoveUpload = (i: number) => {
    const fileUploaded = [...state.attachmentsPath];

    fileUploaded.splice(i, 1);
    setState((prvState: any) => ({
      ...prvState,
      attachmentsPath: fileUploaded,
    }));
  };
  const handleBackFunction = () => {
    if (AddMedia === true) {
      handleBack();
    } else {
      setAddMedia(true);
    }
  };
  const handleNextFunction = () => {
    if (AddMedia === true) {
      setAddMedia(false);
    } else {
      handleNext();
    }
  };

  const checkBannerImg = () => {
    if(state.previewCover){
      return state.previewCover
    }else if(gameImg){
      return gameImg
    }
    return AddCover
  }
  return (
    <>
      <ModalBody
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
            
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#D2D2D2",
            borderRadius: "24px",
          },
        }}
        position={"relative"}
        py="20px"
        px="50px"
        // minH={"500px"}
      >
        <Box minH={"300px"}>
          {AddMedia && (
            <>
              <Heading color={textColor} fontSize="36px" w={400} mb="10px">
                Media
              </Heading>
              <Flex>
                <Box w="full" position={"relative"}>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Set Cover Image
                  </FormLabel>

                  <Flex
                    border={
                      state.previewCover ? "0px solid" : "1px solid #707997"
                    }
                    borderStyle={"dashed"}
                    justifyContent={"center"}
                    alignItems="center"
                    bgColor="#F9F9F9"
                    minH="172px"
                    position={"relative"}
                  >
                    {(state.previewCover !== null || gameImg) && (
                      <Icon
                        cursor={"pointer"}
                        position={"absolute"}
                        top="5px"
                        right={"5px"}
                        color={"gray.700"}
                        w="25px"
                        h="25px"
                        bg='white'
                        borderRadius={"25px"}
                        as={BiTrashAlt}
                        onClick={() => {
                          setState((prvState) => ({
                            ...prvState,
                            previewCover: null,
                            coverPath: "",
                            isCheckedToggle: false,
                          }));
                          if(isChecked == true){
                          setIsChecked(!isChecked);
                          setGameImg(null)
                          }
                        }}
                      />
                    )}
                    {(state.previewCover !== null || gameImg) && !coverLoading && (
                      <Image
                        borderRadius={"8px"}
                        w="100%"
                        height="170px"
                        cursor={"pointer"}
                        objectFit="cover"
                        src={checkBannerImg()}
                      />
                    )}
                    {state.previewCover === null && !gameImg && (
                      <>
                        {coverLoading === true ? (
                          <Spinner color="black" />
                        ) : (
                          <Flex
                            flexDir={"column"}
                            justifyContent={"center"}
                            alignItems="center"
                            borderRadius={"8px"}
                            p="50px"
                            onClick={handleClickBanner}
                            cursor={"pointer"}
                          >
                            <Icon
                              color={"gray.700"}
                              w="25px"
                              h="25px"
                              as={TbPhotoPlus}
                              // onClick={() => handleRemoveUpload(i)}
                            />
                            <Text mt="10px" fontSize={"14px"}>
                              Add Cover Photo
                            </Text>
                          </Flex>
                        )}
                      </>
                    )}
                  </Flex>
                  <input
                    accept="image/*"
                    type="file"
                    ref={hiddenFileInputBanner}
                    onChange={handleChangeBanner}
                    value={""}
                    style={{ display: "none" }}
                  />
                </Box>
              </Flex>
              <Flex>
                <Box mt="20px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                   Add Additional Images
                  </FormLabel>

                  <HStack justifyContent={"space-between"} flexWrap="wrap">
                    <input
                      accept="image/*"
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      value={""}
                      style={{ display: "none" }}
                    />

                    {localPreview.map((img: any, i: number) => {
                      // state.preview?
                      return (
                        <Box position={"relative"}>
                          <Icon
                            cursor={"pointer"}
                            position={"absolute"}
                            top="5px"
                            right={"5px"}
                            color={"gray.700"}
                            w="25px"
                            bg='white'
                            borderRadius={"25px"}
                            h="25px"
                            as={BiTrashAlt}
                            onClick={() => {
                              let arr = [...state.preview];
                              arr.splice(i, 1);
                              setState((prvState) => ({
                                ...prvState,
                                preview: arr,
                              }));
                            }}
                          />
                          {!state.preview[i] ? (
                            <Flex
                              border={"1px solid #707997"}
                              borderStyle={"dashed"}
                              justifyContent={"center"}
                              alignItems="center"
                              bgColor="#F9F9F9"
                              w={"107px"}
                              h="107px"
                              borderRadius={"8px"}
                              position={"relative"}
                            >
                              {loadingAdditionalImages ? (
                                <Spinner color="black" />
                              ) : (
                                <Flex
                                  flexDir={"column"}
                                  justifyContent={"center"}
                                  alignItems="center"
                                  borderRadius={"8px"}
                                  onClick={handleClick}
                                  cursor={"pointer"}
                                >
                                  <Icon
                                    color={"gray.700"}
                                    w="25px"
                                    h="25px"
                                    as={TbPhotoPlus}
                                    // onClick={() => handleRemoveUpload(i)}
                                  />
                                  <Text mt="10px" fontSize={"14px"}>
                                    Add Image
                                  </Text>
                                </Flex>
                              )}
                            </Flex>
                          ) : (
                            <Image
                            borderRadius={"8px"}
                              key={i}
                              m="5px"
                              h="110px"
                              w="110px"
                              src={state.preview[i]}
                              objectFit='cover'
                            />
                          )}
                        </Box>
                      );
                    })}
                  </HStack>
                </Box>
              </Flex>
              <Flex justifyContent={"space-between"} alignItems={"center"}  mt="50px">
                  <Text fontSize={"16px"} fontWeight="500">
                    Use Game Image
                  </Text>
                  <Switch
                    isChecked={isChecked}
                    // id={id}
                    variant='main'
                    colorScheme='brandScheme'
                    size='md'
                    onChange={handleChangeToggle}
                  />
              </Flex>
            </>
          )}
          {!AddMedia && (
            <>
              <Heading
                color={textColor}
                fontSize="24px"
                fontWeight={400}
                mb="10px"
              >
                Attachments
              </Heading>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="light"
                color={textColorSecondary}
                mb="8px"
              >
                Maximum of 3 documents can be added for your event. Try adding
                maps to your facility or menus for food!
              </FormLabel>

              <input
                accept="/*"
                type="file"
                value={""}
                ref={hiddenUploadInputBanner}
                onChange={handleChangeAttach}
                style={{ display: "none" }}
              />
              <HStack w={"full"} py="20px" justifyContent={"flex-start"}>
                {localattachmentsPath.map((x, i) => {
                  return (
                    <Box key={i} mr="10px" position={"relative"}>
                      {!state.attachmentsPath[i] ? (
                        <Flex
                          border={"1px solid #707997"}
                          borderStyle={"dashed"}
                          justifyContent={"center"}
                          alignItems="center"
                          bgColor="#F9F9F9"
                          w={"107px"}
                          h="107px"
                          borderRadius={"8px"}
                          position={"relative"}
                        >
                          {loadingAdditionalImages ? (
                            <Spinner color="black" />
                          ) : (
                            <Flex
                              flexDir={"column"}
                              justifyContent={"center"}
                              alignItems="center"
                              borderRadius={"8px"}
                              onClick={
                                state.attachmentsPath.length > 2
                                  ? null
                                  : handleClickAttach
                              }
                              cursor={"pointer"}
                            >
                              <Icon
                                color={"gray.700"}
                                w="25px"
                                h="25px"
                                as={FiFilePlus}
                                // onClick={() => handleRemoveUpload(i)}
                              />
                            </Flex>
                          )}
                        </Flex>
                      ) : (
                        <Flex
                          justifyContent={"center"}
                          alignItems="center"
                          w={"107px"}
                          h="107px"
                        >
                          <Icon
                            position={"absolute"}
                            top={"-5px"}
                            right={"-2px"}
                            fontWeight="bold"
                            color={"red"}
                            _hover={{ cursor: "pointer" }}
                            as={RxCross1}
                            onClick={() => handleRemoveUpload(i)}
                          />
                          <Icon
                            as={RxFile}
                            color={"#022E57"}
                            cursor="pointer"
                            w="68px"
                            h="68px"
                          />
                        </Flex>
                      )}
                    </Box>
                  );
                })}
              </HStack>
            </>
          )}
        </Box>
      </ModalBody>
      <ModalFooter px="50px">
        <Flex w="full" justifyContent={"space-between"}>
          <Button
            onClick={handleBackFunction}
            fontSize="sm"
            variant="brand"
            fontWeight="700"
            w="276px"
            h="50"
            bg="transparent"
            borderRadius={8}
            border={`1px solid `}
            color={"black"}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
            borderColor={"black"}
          >
            Back
          </Button>

          {(state.coverPath) || isChecked ? (
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="276px"
              h="50"
              onClick={() => handleNextFunction()}
              borderRadius={8}
              bg={buttonColorBrand}
            >
              Continue
            </Button>
          ): null}
        </Flex>
      </ModalFooter>
    </>
  );
}
export default memo(Step_8);
