// import React, { useState, useCallback, useEffect } from "react";
// // Chakra imports
// import { Box, Grid, GridItem, Stack } from "@chakra-ui/react";
// import EventConfiguration from "views/admin/EventDetails/components/EventConfiguration";
// import EventDetails from "views/admin/EventDetails/components/EventDetails";
// import EventSettings from "views/admin/EventDetails/components/EventSettings";
// import EventCheckIn from "views/admin/EventDetails/components/EventCheckIn";
// import EventBracketOrdering from "views/admin/EventDetails/components/EventBracketOrdering";
// import EventBracketConfirmation from "views/admin/EventDetails/components/EventBracketConfirmation";
// import Chat from "views/admin/EventDetails/components/Chat";
// import MatchDetails from "views/admin/EventDetails/components/MatchDetails";
// import { eventRootUrl } from "utilities/constants";
// import { get } from "redux/services/api";
// import { getAllUsers, getAllLocations } from "redux/slices/Auth";
// import { useDispatch, useSelector } from "react-redux";
// import { AppDispatch, RootState } from "redux/store";
// // import { Launcher } from "react-chat-window";
// import _, { map, object } from "underscore";
// // import Loading from "react-fullscreen-loading";
// import { Skeleton } from "@chakra-ui/react";
// // getAllUsers()
// export default function Index(props: any) {
//   const { eventDetail } = (props.location && props.location.state) || {};
//   const [isLoading, setIsloading] = useState(false);
//   const dispatch: AppDispatch = useDispatch();
//   const [activeId, setActiveId] = useState(0);
//   const [brackets, setBrackets] = useState([]);
//   const [state, setState] = useState({
//     id: 0,
//     challonge_Tournament_Id: "",
//     full_challonge_url: "",
//     live_image_url: "",
//     moderatorIds: "",
//     moderatorName: "",
//     otherPath: null,
//     leaderBoard: [],
//     isEventClosed: false,
//     totalComments: "",
//     totalLikes: "",
//     registerPlayers: [],
//     eventGames: [],
//     bookMarkUsers: "",
//     hostProfilePicPath: null,
//     hostProfilePic: null,
//     hostFollowers: "",
//     hostRating: "",
//     hostReviews: "",
//     hasLoggedInUserLiked: false,
//     tournament_Name: "",
//     tournament_Description: "",
//     tournament_Type: "",
//     bracket_Type: null,
//     hostId: "",
//     hostName: "",
//     tournament_Started: null,
//     number_of_participants: null,
//     eventLocation: null,
//     require_team_registration: "",
//     rules: null,
//     link: "",
//     datetime: null,
//     startDate: "",
//     endDate: "",
//     games: [],
//     isHub: true,
//     isMyPlace: true,
//     amenities: "",
//     start_Time: "",
//     end_Time: "",
//     checkInAccessValue: "",
//     checkInAccessWeightage: null,
//     coverPath: null,
//     rulePath: null,
//     registerParticipants: "",
//     additionalImagePath: null,
//     isPaid: false,
//     isFree: false,
//     entryFee: null,
//     competitionFee: null,
//     hostContribution: null,
//     longitude: null,
//     latitude: null,
//     attachmentsPath: [],
//   });

//   useEffect(() => {
//     dispatch(getAllUsers());
//   }, []);

//   useEffect(() => {
//     const query = new URLSearchParams(props.location.search);
//     const eventId = query.get("eventId");
//     if (eventId) {
//       getEventDetail();
//       // generateBracket();
//     }
//   }, [eventDetail]);

//   const onChangeNavigation = useCallback((id: number) => {
//     setActiveId(id);
//   }, []);

//   const getEventDetail = async () => {
//     const query = new URLSearchParams(props.location.search);
//     const eventId = query.get("eventId");
//     setIsloading(true);
//     let url = `${eventRootUrl}api/v1/challonge/tournament-details-list?TournamentId=${eventId}&GameId=-1`;
//     try {
//       const response: any = await get(url);
//       setIsloading(false);
//       if (response.data.status === true) {
//         console.log({ response: response.data });
//         setState(response.data.response[0]);
//       }
//     } catch (err) {
//       setIsloading(false);
//     }
//   };
//   const getEventDetailInside = useCallback(async () => {
//     const query = new URLSearchParams(props.location.search);
//     const eventId = query.get("eventId");

//     let url = `${eventRootUrl}api/v1/challonge/tournament-details-list?TournamentId=${eventId}&GameId=-1`;
//     const response: any = await get(url);
//     if (response.data.status === true) {
//       console.log({ response: response.data });
//       setState(response.data.response[0]);
//     }
//   }, []);

//   const next = (number_of_participants: any) => {
//     console.log({ number_of_participants });
//     setState((prvState: any) => ({
//       ...prvState,
//       number_of_participants: number_of_participants,
//     }));
//     setActiveId(5);
//   };

//   return (
//     <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
//       {isLoading && (
//         <Grid templateColumns="repeat(12, 1fr)" gap={4}>
//           <GridItem height="1200px" bg="white" colSpan={2}>
//             <Skeleton height="full" />
//           </GridItem>
//           <GridItem colSpan={10}>
//             <Skeleton borderTopRadius="10px" h={"260px"} />
//             <Skeleton borderRadius="10px" h={"160px"} mt="20px" />
//             <Grid templateColumns="repeat(12, 1fr)" gap={4}>
//               <GridItem py="20px" colSpan={8}>
//                 <Skeleton borderRadius="10px" h={"100px"} mt="20px" />
//                 <Skeleton borderRadius="10px" h={"100px"} mt="20px" />
//                 <Skeleton borderRadius="10px" h={"100px"} mt="20px" />
//               </GridItem>
//               <GridItem py="20px" colSpan={4}>
//                 <Skeleton borderRadius="10px" h={"660px"} mt="20px" />
//                 <Skeleton borderRadius="10px" h={"660px"} mt="20px" />
//               </GridItem>
//             </Grid>
//           </GridItem>
//         </Grid>
//       )}
//       {!isLoading && (
//         <Grid
//           bg="white"
//           borderRadius={"24px"}
//           p="24px"
//           boxShadow={"sm"}
//           templateColumns="repeat(12, 1fr)"
//           gap={4}
//         >
//           <GridItem colSpan={12}>
//             {activeId === 0 && (
//               <EventDetails
//                 state={state}
//                 activeId={activeId}
//                 onChangeNavigation={onChangeNavigation}
//               />
//             )}
//             {activeId === 1 && (
//               <EventSettings
//                 state={state}
//                 activeId={activeId}
//                 onChangeNavigation={onChangeNavigation}
//               />
//             )}
//             {activeId === 2 && (
//               <EventCheckIn
//                 activeId={activeId}
//                 onChangeNavigation={onChangeNavigation}
//                 state={state}
//                 next={next}
//                 getEventDetail={getEventDetailInside}
//               />
//             )}
//             {activeId === 3 && (
//               <EventBracketOrdering
//                 activeId={activeId}
//                 onChangeNavigation={onChangeNavigation}
//                 state={state}
//                 brackets={brackets}
//                 getEventDetail={getEventDetailInside}
//               />
//             )}
//             <Chat state={state} />
//           </GridItem>
//         </Grid>
//       )}
//     </Box>
//   );
// }
import React, { useState, useCallback, useEffect } from "react";

// Chakra imports
import { Box, Grid, GridItem, Stack } from "@chakra-ui/react";
import EventConfiguration from "views/admin/bracketPortal/components/EventConfiguration";
import EventDetails from "views/admin/bracketPortal/components/EventDetails";
import EventSettings from "views/admin/bracketPortal/components/EventSettings";
import EventCheckIn from "views/admin/bracketPortal/components/EventCheckIn";
import EventBracketOrdering from "views/admin/bracketPortal/components/EventBracketOrdering";
import EventBracketConfirmation from "views/admin/bracketPortal/components/EventBracketConfirmation";
import Chat from "views/admin/bracketPortal/components/Chat";
import MatchDetails from "views/admin/bracketPortal/components/MatchDetails";
import { eventRootUrl } from "utilities/constants";
import { get } from "redux/services/api";
import { getAllUsers, getAllLocations } from "redux/slices/Auth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
// import { Launcher } from "react-chat-window";
import _, { map, object } from "underscore";
// import Loading from "react-fullscreen-loading";
import { Skeleton } from "@chakra-ui/react";
// getAllUsers()
export default function Index(props: any) {
  const { eventDetail } = (props.location && props.location.state) || {};
  const [isLoading, setIsloading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const [activeId, setActiveId] = useState(0);
  const [brackets, setBrackets] = useState([]);
  const [state, setState] = useState({
    id: 0,
    challonge_Tournament_Id: "",
    full_challonge_url: "",
    live_image_url: "",
    moderatorIds: "",
    moderatorName: "",
    otherPath: null,
    leaderBoard: [],
    isEventClosed: false,
    totalComments: "",
    totalLikes: "",
    registerPlayers: [],
    eventGames: [],
    bookMarkUsers: "",
    hostProfilePicPath: null,
    hostProfilePic: null,
    hostFollowers: "",
    hostRating: "",
    hostReviews: "",
    hasLoggedInUserLiked: false,
    tournament_Name: "",
    tournament_Description: "",
    tournament_Type: "",
    bracket_Type: null,
    hostId: "",
    hostName: "",
    tournament_Started: null,
    number_of_participants: null,
    eventLocation: null,
    require_team_registration: "false",
    rules: null,
    link: "",
    datetime: null,
    startDate: "",
    endDate: "",
    games: [],
    isHub: true,
    isMyPlace: true,
    amenities: "",
    start_Time: "",
    end_Time: "",
    checkInAccessValue: "",
    checkInAccessWeightage: null,
    coverPath: null,
    rulePath: null,
    registerParticipants: "",
    additionalImagePath: null,
    isPaid: false,
    isFree: false,
    entryFee: null,
    competitionFee: null,
    hostContribution: null,
    longitude: null,
    latitude: null,
    attachmentsPath: [],
  });

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const eventId = query.get("eventId");
    if (eventId) {
      getEventDetail();
      // generateBracket();
    }
  }, [eventDetail]);

  const onChangeNavigation = useCallback((id: number) => {
    setActiveId(id);
  }, []);

  const getEventDetail = async () => {
    const query = new URLSearchParams(props.location.search);
    const eventId = query.get("eventId");
    setIsloading(true);
    let url = `${eventRootUrl}api/v1/challonge/tournament-details-list?TournamentId=${eventId}&GameId=-1`;
    try {
      const response: any = await get(url);
      setIsloading(false);
      if (response.data.status === true) {
        console.log({ response: response.data });
        setState(response.data.response[0]);
      }
    } catch (err) {
      setIsloading(false);
    }
  };
  const getEventDetailInside = useCallback(async () => {
    const query = new URLSearchParams(props.location.search);
    const eventId = query.get("eventId");

    let url = `${eventRootUrl}api/v1/challonge/tournament-details-list?TournamentId=${eventId}&GameId=-1`;
    const response: any = await get(url);
    if (response.data.status === true) {
      console.log({ response: response.data });
      setState(response.data.response[0]);
    }
  }, []);

  const next = (number_of_participants: any) => {
    console.log({ number_of_participants });
    setState((prvState: any) => ({
      ...prvState,
      number_of_participants: number_of_participants,
    }));
    setActiveId(5);
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {isLoading && (
        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem height="1200px" bg="white" colSpan={2}>
            <Skeleton height="full" />
          </GridItem>
          <GridItem colSpan={10}>
            <Skeleton borderTopRadius="10px" h={"260px"} />
            <Skeleton borderRadius="10px" h={"160px"} mt="20px" />
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem py="20px" colSpan={8}>
                <Skeleton borderRadius="10px" h={"100px"} mt="20px" />
                <Skeleton borderRadius="10px" h={"100px"} mt="20px" />
                <Skeleton borderRadius="10px" h={"100px"} mt="20px" />
              </GridItem>
              <GridItem py="20px" colSpan={4}>
                <Skeleton borderRadius="10px" h={"660px"} mt="20px" />
                <Skeleton borderRadius="10px" h={"660px"} mt="20px" />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      )}
      {!isLoading && (
        <Grid
          bg="white"
          borderRadius={"24px"}
          p="24px"
          boxShadow={"sm"}
          templateColumns="repeat(12, 1fr)"
          gap={4}
        >
          <GridItem colSpan={12}>
            {activeId === 0 && (
              <EventDetails
                state={state}
                activeId={activeId}
                onChangeNavigation={onChangeNavigation}
              />
            )}
            {activeId === 1 && (
              <EventSettings
                state={state}
                activeId={activeId}
                onChangeNavigation={onChangeNavigation}
              />
            )}
            {activeId === 2 && (
              <EventCheckIn
                activeId={activeId}
                onChangeNavigation={onChangeNavigation}
                state={state}
                next={next}
                getEventDetail={getEventDetailInside}
              />
            )}
            {activeId === 3 && (
              <EventBracketOrdering
                activeId={activeId}
                onChangeNavigation={onChangeNavigation}
                state={state}
                brackets={brackets}
                getEventDetail={getEventDetailInside}
              />
            )}
            <Chat state={state} />
          </GridItem>
        </Grid>
      )}
    </Box>
  );
}
