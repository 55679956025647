/* eslint-disable */
// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  Text,
  useColorModeValue,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import AdminNavbarLinks from "components/navbar/NavbarLinksAdmin";
import { useDispatch, useSelector } from "react-redux";
import logo from "assets/img/global/logo.png";
import newLogo from "assets/img/global/16_PNG-logo.png";
export default function AdminNavbar(props: {
  secondary: boolean;
  message: string | boolean;
  brandText: string;
  logoText: string;
  fixed: boolean;
  navSize: any;
  changeNavSize: any;
  onOpen: (...args: any[]) => any;
}) {
  const [scrolled, setScrolled] = useState(false);
  const { menuList } = useSelector((state: any) => state.AdminsTableLists);
  const { navSize, changeNavSize } = props;
  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const { secondary, brandText } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  let navbarPosition = "fixed" as const;
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue(
    "rgba(244, 247, 254, 0.2)",
    "rgba(11,20,55,0.5)"
  );
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  let gap = "0px";
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <Box
      //   onClick={() => changeNavSize("small")}
      position={navbarPosition}
      zIndex={2}
      bg={"white"}
      w="full"
      borderBottom="2px solid #ECF1F4"
    >
      <Flex justifyContent={"space-between"} alignItems="center">
        {/* <Box ml={"1%"}>
          <IconButton
            aria-label=""
            bg="none"
            _hover={{ background: "none" }}
            icon={<FiMenu />}
            onClick={() => {
              if (navSize == "small") changeNavSize("large");
              else changeNavSize("small");
            }}
          />
        </Box> */}
        <Box
          boxShadow={navbarShadow}
          borderColor={navbarBorder}
          filter={navbarFilter}
          backdropFilter={navbarBackdrop}
          backgroundPosition="center"
          backgroundSize="cover"
          // borderRadius='16px'
          borderWidth="1.5px"
          borderStyle="solid"
          transitionDelay="0s, 0s, 0s, 0s"
          transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
          transition-property="box-shadow, background-color, filter, border"
          transitionTimingFunction="linear, linear, linear, linear"
          alignItems={{ xl: "center" }}
          display={secondary ? "block" : "flex"}
          minH="75px"
          justifyContent={{ xl: "center" }}
          lineHeight="25.6px"
          mx="auto"
          mt={secondaryMargin}
          pb="8px"
          right={{ base: "12px", md: "30px", lg: "30px", xl: "30px" }}
          // px={{
          //   sm: paddingX,
          //   md: "10px",
          // }}
          // px={{ "2xl": "120px" }}

          m="auto"
          ps={{
            xl: "12px",
          }}
          pt="8px"
          top={{ base: "12px", md: "16px", xl: "18px" }}
          // w={{
          // base: "calc(100vw - 6%)",
          // md: "calc(100vw - 8%)",
          // lg: "calc(100vw - 6%)",
          // xl: "calc(100vw - 350px)",
          // "2xl": "calc(100vw - 365px)",
          // }}
          w="full"
        >
          <Flex
            w="100%"
            flexDirection={{
              sm: "column",
              md: "row",
            }}
            alignItems={{ xl: "center" }}
            mb={gap}
          >
            <Box mb={{ sm: "8px", md: "0px" }} ml="20px">
              <Image src={newLogo} alt="logo" w={"107px"} h={"50px"} />
            </Box>
            <Box ms="auto" mr="20px" w={{ sm: "100%", md: "unset" }}>
              <AdminNavbarLinks
                onOpen={props.onOpen}
                secondary={props.secondary}
                fixed={props.fixed}
              />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
