import React from "react";
import {
  Bracket,
  IRenderSeedProps,
  Seed,
  SeedItem,
  SeedTeam,
  SeedTime,
} from "react-brackets";
import "./loading.css";
const loadingData = [
  {
    title: "Loading....",
    seeds: [
      {},
      {
        id: 1,
      },
      {},
      {
        id: 2,
      },
    ],
  },
  {
    title: "Loading..",
    seeds: [...new Array(2)].fill({
      
    }),
  },
  {
    title: "Loading...",
    seeds: [...new Array(1)].fill({
       
    }),
  },
];

const RenderLoadingSeed = ({ seed, breakpoint }: IRenderSeedProps) => {
  return (
    <Seed
      style={{ fontSize: 12, borderRadius: "4px" }}
      mobileBreakpoint={breakpoint}
      className="test"
    >
      <SeedItem
        style={{
          fontSize: 12,
          borderRadius: "4px",
          background: "#ECF1F4",
          boxShadow: "none",
        }}
        className="skeleton-item"
      >
        <div>
          {/* you can use height instead of adding a dot also :O */}
          <SeedTeam
            style={{
              borderRadius: "4px",
              color: "black",
              background: "#ECF1F4",
              padding: 0,
              height: "40px",
              width: "250px",
              borderTopWidth: 1,
            }}
          >
            .
          </SeedTeam>
          <SeedTeam
            style={{
              borderRadius: "4px",
              color: "black",
              background: "#ECF1F4",
              padding: 0,
              height: "40px",
              width: "250px",
              borderTopWidth: 1,
            }}
          >
            .
          </SeedTeam>
        </div>
      </SeedItem>
      <SeedTime mobileBreakpoint={breakpoint} style={{ fontSize: 9 }}>
        {seed.date}
      </SeedTime>
    </Seed>
  );
};

const LoadingBracket = () => {
  return (
    <Bracket rounds={loadingData} renderSeedComponent={RenderLoadingSeed} />
  );
};

export default LoadingBracket;
