import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { AppProviders } from "./providers/AppProviders";
import App from "App";

// 
// serviceWorkerRegistration.register();

ReactDOM.render(
  <AppProviders>
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ChakraProvider>
  </AppProviders>,
  document.getElementById("root")
);
 