import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  Image,
  useToast,
  Text,
  chakra,
  Box,
  ModalFooter,
  ModalCloseButton,
} from "@chakra-ui/react";
import { post } from "redux/services/api";
import { eventRootUrl } from "utilities/constants";
import { useDispatch, useSelector } from "react-redux";

import logoWite from "assets/img/global/logoWite.png";
import newlogoWite from "assets/img/global/12_PNG.png";
import EventStepper_1 from "assets/img/global/EventStepper_1.png";
// import EventStepper_1 from "assets/img/global/EventStepper_1.png";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { AppDispatch } from "redux/store";
import { getEventList } from "redux/slices/Events";
import Step_1 from "./Step_1";
import Step_2 from "./Step_2";
import Step_3 from "./Step_3";
import Step_4 from "./Step_4";
import Step_5 from "./Step_5";
import Step_6 from "./Step_6";
import Step_7 from "./Step_7";
import Step_8 from "./Step_8";
import Step_9 from "./Step_9";
import Step_10 from "./Step_10";

interface Props {
  onOpen: Function;
  isOpen: boolean;
  onClose: Function;
  [x: string]: any;
}

export default function EventSetup(props: Props) {
  const toast = useToast();
  const dispatch: AppDispatch = useDispatch();
  const queryClient = new QueryClient()
 
  const [isReview, setIsReview] = React.useState(false);
  const [loaing, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose, editState,refetch, ...rest } = props;
  const {
    userId,
    userName,
    location,
    description,
    website,
    games,
    logo,
    coverImage,
    title,
    selectedLocation,
    locations,
  } = useSelector((state: any) => state.Auth);
  const [steps, setStep] = React.useState(1);

  let initialState: any = {
    tournament_Name: "",
    tournament_Description: "",
    number_of_participants: null,
    bracket_Type: "single elimination",
    group_stage_bracket_Type: "single elimination",
    tournament_Type: "single stage tournament",
    
    group_size: 0,
    participant_count_to_advance_per_group: 0,
    group_stage_split_participants: false,
    group_stage_participants_play_each_other: null,
    group_stage_ranked_by: "match wins",
    group_stage_rr_pts_for_match_win: 0,
    group_stage_rr_pts_for_match_tie: 0,
    group_stage_rr_pts_for_game_win: 0,
    group_stage_rr_pts_for_game_tie: 0,
    tournamentFormat: "1",
    holdThirdPlaceMatch: false,
    split_participants: false,
    rankBy: "match wins",
    grandFinal: "",
    participants_play_each_other: 1,
    hostId: userId?.toString(),
    hostName: "string",
    tournament_Started: "",
    eventLocation: "",
    require_team_registration: "false",
    rules: "",
    link: "",
    datetime: "",
    startDate: "",
    endDate: "",
    games: [],
    isHub: true,
    isMyPlace: false,
    amenities: "",
    start_Time: "",
    end_Time: "",
    checkInAccessValue: "",
    checkInAccessWeightage: "",
    coverPath: "",
    rulePath: "",
    registerParticipants: "",
    additionalImagePath: [],
    selectedGame: null,
    preview: [],
    previewCover: null,
    amenity: [],
    attachmentsPath: [],
    isPaid: false,
    isFree: true,
    entryFee: "",
    competitionFee: "",
    hostContribution: "",
    longitude: "",
    latitude: "",
    eventType: "",
    hubLocationId:selectedLocation?.locationId,
    isCheckedToggle: false,
  };
  const [state, setState] = useState<any>(initialState);

  useEffect(() => {
    if (editState) {
      setState(editState);
      console.log({ editState });
      onOpen();
    }
  }, [editState]);

  const handleNext = useCallback(
    (data: any) => {
      setIsReview(false);
      console.log({ data });
      if (steps === 1) {
        setState((prvState: any) => ({ ...prvState, ...data }));
        setStep(steps + 2);
      } else if (steps === 3) {
        setState((prvState: any) => ({ ...prvState, ...data }));
        setStep(steps + 2);
      } else if (steps === 4) {
        setStep(steps + 1);
      } else if (steps === 5) {
        setState((prvState: any) => ({ ...prvState, ...data }));
        setStep(steps + 1);
      } else if (steps === 6) {
        setState((prvState: any) => ({ ...prvState, ...data }));
        setStep(steps + 1);
      } else if (steps === 7) {
        setState((prvState: any) => ({
          ...prvState,
          games: [data.apiGameId],
          selectedGame: data,
        }));
        setStep(steps + 1);
      } else if (steps === 8) {
        console.log({ data });
        setState((prvState: any) => ({ ...prvState, ...data }));
        setStep(steps + 1);
      } else if (steps === 9) {
        console.log({ data });
        setState((prvState: any) => ({ ...prvState, ...data }));
        setStep(steps + 1);
      } else if (steps === 10) {
        createEvent(data);
      } else {
        setStep(steps + 1);
      }
    },
    [steps]
  );
  const handleBack = useCallback(() => {
    if (isReview) {
      setStep(10);
    } else if (steps === 3) {
      setStep(steps - 2);
    } else if (steps === 5) {
      setStep(steps - 2);
    } else {
      setStep(steps - 1);
    }
  }, [steps]);

  const handleReset = useCallback(() => {
    setStep(1);
    onClose();
  }, []);

  const createEvent = async (data: string) => {
    if (state.tournament_Type === "two stage tournament") {
      if (
        state.number_of_participants != null &&
        state.number_of_participants != "" &&
        state.number_of_participants < 4
      ) {
        toast.closeAll()
        toast({
          title: "Invalid Entry",
          description:
            "Max # of Players can not be less than 4 in two stage tournament",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
    }
    let url = "";
    if (data === "create") {
      url = `${eventRootUrl}api/v1/challonge/create-tournament`;
    } else {
      url = `${eventRootUrl}api/v1/challonge/create-tournament-as-draft`;
    }

    let obj = {
      ...state,
      // number_of_participants: state.number_of_participants
      //  === ""
      // ? null
      // : state.number_of_participants,
      hubLocationId:selectedLocation.locationId,
      participant_count_to_advance_per_group: Number(
        state.participant_count_to_advance_per_group
      ),
      group_size: Number(state.group_size),
    };
    let arr = [...obj.amenitiesList];
    arr = arr.filter((x: any) => x.IsSelected === true);
    arr = arr.map((x: any) => x.amenityId);
    obj.amenity = arr;
    delete obj.preview;
    delete obj.previewCover;
    delete obj.amenitiesList;
    delete obj.isCheckedToggle;
    setLoading(true);
    let payload = {
      url,
      data: obj,
    };
    const response: any = await post(payload);

    if (response.data.statusCode === 200) {
      toast.closeAll()
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      handleReset();
      setState(initialState);
      // dispatch(getEventList(userId));
      // queryClient.invalidateQueries({queryKey: ['fetchEventList']});
      refetch()
    } else {
      toast.closeAll()
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };
  const handleSteps = (step: number) => {
    setIsReview(true);
    setStep(step);
  };
  return (
    <Modal
    isCentered
      {...rest}
      closeOnOverlayClick={false}
      scrollBehavior="inside"
      size={"4xl"}
      isOpen={isOpen}
      onClose={() => {
        setState(initialState);
        onClose();
        setStep(1);
      }}
    >
      <ModalOverlay />
      <ModalContent borderRadius="14px" position={"relative"} p={0}>
        <ModalHeader h={"145px"} p="0px" borderRadius="14px">
          <Box
            borderTopRadius="14px"
            // borderBottomRadius="16px"
            justifyContent={"space-between"}
            bg="#1C2A5B"
            alignItems="center"
            py="20px"
            px="50px"
          >
            <Image src={newlogoWite} w="90px" h="40px" />
            <Text color={"white"} fontSize="18px" w={"700"} mt="10px">
              Lets get another
              <chakra.span color={"#19BAEE"}> Event</chakra.span> setup for you!
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton color={"white"} />
        {steps === 1 && <Step_1 state={state} next={handleNext} />}
        {steps === 2 && (
          <Step_2 state={state} handleBack={handleBack} next={handleNext} />
        )}
        {steps === 3 && (
          <Step_3
            parantState={state}
            handleBack={handleBack}
            next={handleNext}
          />
        )}
        {steps === 4 && (
          <Step_4
            parantState={state}
            handleBack={handleBack}
            next={handleNext}
          />
        )}
        {steps === 5 && (
          <Step_5
            parantState={state}
            handleBack={handleBack}
            next={handleNext}
          />
        )}
        {steps === 6 && (
          <Step_6
            isReview={isReview}
            parantState={state}
            handleBack={handleBack}
            next={handleNext}
          />
        )}
        {steps === 7 && (
          <Step_7
            isReview={isReview}
            parantState={state}
            handleBack={handleBack}
            next={handleNext}
          />
        )}
        {steps === 8 && (
          <Step_8
            parantState={state}
            handleBack={handleBack}
            next={handleNext}
          />
        )}
        {steps === 9 && (
          <Step_9
            parantState={state}
            handleBack={handleBack}
            next={handleNext}
          />
        )}
        {steps === 10 && (
          <Step_10
            parantState={state}
            loaing={loaing}
            handleSteps={handleSteps}
            onClose={handleReset}
            handleBack={handleBack}
            next={handleNext}
          />
        )}
      </ModalContent>
    </Modal>
  );
}
