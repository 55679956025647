import React from "react";
import {
  Flex,
  Text,
  Box,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Icon,
} from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdSettingsApplications,
  MdOutlineBusinessCenter,
  MdLocationOn,
} from "react-icons/md";
import { AiFillDashboard } from "react-icons/ai";

import { TbCircleDotFilled } from "react-icons/tb";
import { BsInfoCircleFill } from "react-icons/bs";
import { HiDocumentText } from "react-icons/hi";

import NavHoverBox from "./NavHoverBox";
import { NavLink, useLocation } from "react-router-dom";

export default function NavItem({
  icon,
  title,
  description,
  active,
  navSize,
  isMenu,
  layout,
  path,
  index,
  isActive,
  updateNavigation,
}) {
  if (!isMenu) {
    return null;
  }

  const getIcon = (title,isActive) => {
    if (title === "Analytics") {
      return (
        <Icon as={MdBarChart} width="20px" height="20px" color={isActive?"#298BE2":"#979C9E"} />
      );
    } else if (title === "Profile") {
      return <Icon as={MdPerson} width="20px" height="20px" color={isActive?"#298BE2":"#979C9E"} />;
    } else if (title === "Business") {
      return (
        <Icon
          as={MdOutlineBusinessCenter}
          width="20px"
          height="20px"
          color={isActive?"#298BE2":"#979C9E"}
        />
      );
    } else if (title === "Information") {
      return (
        <Icon
          as={BsInfoCircleFill}
          width="20px"
          height="20px"
          color={isActive?"#298BE2":"#979C9E"}
        />
      );
    } else if (title === "Locations") {
      return (
        <Icon as={MdLocationOn} width="20px" height="20px" color={isActive?"#298BE2":"#979C9E"} />
      );
    } else if (title === "Settings") {
      return (
        <Icon
          as={MdSettingsApplications}
          width="20px"
          height="20px"
          color={isActive?"#298BE2":"#979C9E"}
        />
      );
    } else if (title === "Documents") {
      return (
        <Icon as={HiDocumentText} width="20px" height="20px" color={isActive?"#298BE2":"#979C9E"} />
      );
    } else if (title === "Admins") {
      return (
        <Icon
          as={TbCircleDotFilled}
          width="20px"
          height="20px"
          color={isActive?"#298BE2":"#979C9E"}
        />
      );
    } else if (title === "Dashboard") {
      return (
        <Icon
          as={AiFillDashboard}
          width="20px"
          height="20px"
          color={isActive?"#298BE2":"#979C9E"}
        />
      );
    } else if (title === "HubsList") {
      return (
        <Icon
          as={TbCircleDotFilled}
          width="20px"
          height="20px"
          color={isActive?"#298BE2":"#979C9E"}
        />
      );
    } else if (title === "Users") {
      return (
        <Icon
          as={TbCircleDotFilled}
          width="20px"
          height="20px"
          color={isActive?"#298BE2":"#979C9E"}
        />
      );
    } else {
      return (
        <Icon
          as={TbCircleDotFilled}
          width="20px"
          height="20px"
          color={isActive?"#298BE2":"#979C9E"}
        />
      );
    }
  };
  // console.log({isActive})
  return (
    <NavLink  key={index} to={layout + path}>
    <Flex
      mt={"5px"}
      key={index}
      flexDir="column"
      backgroundColor={isActive && "#ECF1F4"}
      _hover={{ textDecor: "none", backgroundColor: "#ECF1F4" }}
      w="100%"
       
      alignItems={navSize == "small" ? "center" :"flex-start"}
      onClick={updateNavigation}
      //   alignItems={navSize == "small" ? "center" : "flex-start"}
    >
     
        <Menu placement="right">
          <Flex
            justifyContent={"center"}
            alignItems="center"
           
            p={3}
            borderRadius={8}
          
            w={navSize == "large" && "100%"}
          >
            <MenuButton w="100%">
              <Flex>
                {getIcon(title,isActive)}

                {navSize !== "small" && (
                  <Text
                  color={isActive?"#298BE2":"black"}
                    ml={5}
                    noOfLines={1}
                    display={navSize == "small" ? "none" : "flex"}
                  >
                    {title}
                  </Text>
                )}
              </Flex>
            </MenuButton>
          </Flex>
        </Menu>
       
    </Flex>
    </NavLink>
  );
}
