import React from "react";

import {
  Flex,
  Text,
  Input,
  FormLabel,
  useColorModeValue,
  Box,
  HStack,
  Switch,
  Button,
  FormControl,
  FormErrorMessage,
  useToast,
  Icon,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import Chip from "components/chip/Chip";
import TimeChip from "components/TimeChip";

import { useState, useRef, useEffect } from "react";
import { post } from "redux/services/api";
import { hubRootUrl } from "utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import FileUpload from "components/fileUpload";
import { useForm } from "react-hook-form";
import { RxCross1 } from "react-icons/rx";
import { FaFile } from "react-icons/fa";
import { getAllLocations } from "redux/slices/Auth";
import { AppDispatch } from "redux/store";
import moment from "moment";
import { onChangeSelectedLocation } from "redux/slices/Auth";

import "react-datepicker/dist/react-datepicker.css";

function EditLocation(props: any) {
  const { isOpen, onOpen, onClose } = props;

  const dispatch: AppDispatch = useDispatch();

  const { userId, locations, aminitiesList, selectedLocation } = useSelector(
    (state: any) => state.Auth
  );
  const hiddenFileInput = useRef(null);
  const toast = useToast();

  const textColor = useColorModeValue("black.50", "white");
  const buttonColorBrand = useColorModeValue("#19BAEE", "white");
  const textColorSecondary_2 = "gray.300";
  const brandBlue = "brand.500";
  const textColorSecondary = "gray.400";
  const [aminities, setAminities] = useState([]);

  const [invalidState, setInvalidState] = useState({
    AddressInvalid: false,
  });
  const [isLoading, setisLoading] = useState(false);
  const initialState: any = {
    HubId: userId,
    LocationId: 0,
    HubCoverPhotoPath: "",
    Bio: "",
    Photos: {},
    Website: "www.test.com",
    Timings: [
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: false,
        HubDay: "Monday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: false,
        HubDay: "Tuesday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: false,
        HubDay: "Wednesday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: false,
        HubDay: "Thursday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: false,
        HubDay: "Frida",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: false,
        HubDay: "Saturday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: false,
        HubDay: "Sunday",
      },
    ],
    Amenities: [],
    CovidRules: "",
    Occupancy: 0,
    CleaningProtocol: "",
    Parking: "",
    AnyAdditionalRules: "",
    AttachmentIds: "",
    Latitude: "",
    Longitude: "",
    Address: "",
    Street: "",
  };
  const [state, setState] = useState<any>(initialState);
  useEffect(() => {
    if (locations) {
      let index = selectedLocation
        ? locations.findIndex(
            (x: any) => x.locationId == selectedLocation?.locationId
          )
        : 0;
      let location = locations[index];
      console.log({ index });
      dispatch(onChangeSelectedLocation(location));
    }
  }, [locations]);

  useEffect(() => {
    console.log({ selectedLocation });
    if (selectedLocation) {
      let hubTimings = selectedLocation.hubTimings.map((x: any) => {
        return {
          HubStartTime: x.hubStartTime,
          HubEndTime: x.hubEndTime,
          IsClose: x.isClosed,
          HubDay: x.hubDay,
        };
      });
      let hubAmenities = selectedLocation.hubAmenities.map((x: any) => {
        return {
          amenitiesChoice: x.amenitiesChoice,
          AminityId: x.amenityId,
          IsSelected: x.isSelected,
        };
      });
      console.log({ selectedLocation });
      setState((prvState: any) => ({
        // ...prvState,
        // ...selectedLocation,
        HubId: userId,
        LocationId: selectedLocation.locationId,
        locationId: selectedLocation.locationId,

        Timings: hubTimings,
        Amenities: hubAmenities,
        CovidRules: selectedLocation.covidRules,
        Occupancy: selectedLocation.occupancy,
        CleaningProtocol: selectedLocation.cleaningProtocol,
        Parking: selectedLocation.parking,
        AnyAdditionalRules: selectedLocation.otherRules,
        Address: selectedLocation.hubAdress,
      }));
    }
  }, [selectedLocation]);
  const updateState = (val: string, prop: string) => {
    setState((prvState: any) => ({ ...prvState, [prop]: val }));
  };
  const updateSwitch = (IsClose: boolean, index: number) => {
    let arr = [...state.Timings];
    arr[index].IsClose = !arr[index].IsClose;
    setState((prvState: any) => ({ ...prvState, Timings: arr }));
  };

  const check = () => {
    if (state.Address === "" || state.Address.length < 10) {
      setInvalidState((prv) => ({ ...prv, AddressInvalid: true }));
      return false;
    }
    return true;
  };
  const saveLocation = async () => {
    if (check()) {
      setisLoading(true);
      let files: any = { ...state.Photos };
      let data: any = { ...state };
      let Timings = data.Timings;
      // Timings = JSON.stringify(data.Timings);
      let Amenities = data.Amenities;
      Amenities = Amenities.map((x: any) => {
        return {
          AminityId: x.AminityId,
          IsSelected: x.IsSelected,
        };
      });
      // Amenities = JSON.stringify(Amenities);
      let formdata = new FormData();
      for (var key in data) {
        if (
          key != "Photos" &&
          key != "Amenities" &&
          key != "Timings" &&
          key != "attachments" &&
          key != "hubTimings"
        ) {
          formdata.append(key, data[key]);
        }
      }
      Timings.forEach((x: any, i: number) => {
        Object.keys(x).map((key) => {
          formdata.append("Timings" + `[${i}]` + `[${key}]`, x[key]);
        });
      });
      console.log({ Amenities, Timings });
      Amenities.forEach((x: any, i: number) => {
        // formdata.append("Amenities", JSON.stringify(x));
        Object.keys(x).map((key) => {
          formdata.append("Amenities" + `[${i}]` + `[${key}]`, x[key]);
        });
      });

      if (Object.keys(files).length) {
        for (const file in files) {
          console.log({ x: files[file] });
          formdata.append("Photos", files[file]);
        }
      }

      let payload = {
        url: `${hubRootUrl}api/v1/profile/profile-detail`,
        data: formdata,
      };
      let response = await post(payload);
      console.log({ response });
      setisLoading(false);
      if (response.data.statusCode === 200) {
        toast.closeAll()
        toast({
          title: response.data.message,
          description: response.data.errorMessage,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setState(initialState);
        dispatch(getAllLocations());
      } else {
        toast.closeAll()
        toast({
          title: response.data.message,
          description: response.data.errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  const handleClickDocuments = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event: any) => {
    let files = event.target.files;
    let updatedFile: any = {};
    Object.keys(files).map((key) => {
      updatedFile[files[key]["name"]] = files[key];
    });
    updatedFile = { ...updatedFile, ...state.Photos };

    let size = Object.keys(updatedFile).length;
    if (size > 7) {
      toast({
        title: "Warning",
        description: "Only maximum 7 files accepted.",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      event.preventDefault();
      return;
    }

    setState((prvState: any) => ({
      ...prvState,
      Photos: updatedFile,
    }));
  };
  const handleRemoveUpload = (key: string) => {
    const fileUploaded = { ...state.Photos };

    delete fileUploaded[key];
    setState((prvState: any) => ({
      ...prvState,
      Photos: fileUploaded,
    }));
  };

  const addAminities = (index: number) => {
    let arr = [...state.Amenities];
    arr = arr.map((x, i) => {
      return {
        ...x,
        IsSelected: i === index ? !x.IsSelected : x.IsSelected,
      };
    });

    setState((prvState: any) => ({
      ...prvState,
      Amenities: arr,
    }));
  };

  const handleStartDate = (time: any, index: number) => {
    let arr = [...state.Timings];
    arr[index].HubStartTime = time;
    setState((prvState: any) => ({
      ...prvState,
      Timings: arr,
    }));
  };
  const handleEndDate = (time: any, index: number) => {
    console.log({ time, index });
    let arr = [...state.Timings];
    arr[index].HubEndTime = time;
    setState((prvState: any) => ({
      ...prvState,
      Timings: arr,
    }));
  };
  return (
    <Drawer
     
      size={"sm"}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit Profile</DrawerHeader>

        <DrawerBody  css={{
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
          
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#1C2A5B",
          borderRadius: "24px",
        },
      }}>
          <FormControl isInvalid={invalidState.AddressInvalid}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Physical Address
            </FormLabel>
            <Input
              borderColor={invalidState.AddressInvalid ? "red" : null}
              onChange={(e) => updateState(e.target.value, "Address")}
              value={state.Address}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              borderRadius={8}
              mb="10px"
              fontWeight="500"
              size="lg"
            />
            {invalidState.AddressInvalid && (
              <FormErrorMessage>Physical address is required.</FormErrorMessage>
            )}
          </FormControl>
          <Box justifyContent={"space-between"}>
            <Box w={"100%"}>
              <Box>
                <HStack justifyContent={"space-between"} py="7px">
                  <Text color={textColorSecondary_2} fontSize={"14px"}>
                    TIMINGS
                  </Text>
                </HStack>
                {state.Timings.map((x: any, i: number) => {
                  return (
                    <HStack key={i} justifyContent={"space-between"} py="7px">
                      <Switch
                        onChange={() => updateSwitch(x.IsClose, i)}
                        isChecked={!x.IsClose}
                        colorScheme={"brand"}
                        w="50px"
                        id="email-alerts"
                      />
                      <Text
                        w="100px"
                        color={textColorSecondary_2}
                        fontSize={"14px"}
                      >
                        {x.HubDay}
                      </Text>
                      <TimeChip
                        index={i}
                        handleStartDate={handleStartDate}
                        handleEndDate={handleEndDate}
                        HubStartTime={x.HubStartTime}
                        HubEndTime={x.HubEndTime}
                        innerTextColor={brandBlue}
                        IsClose={x.IsClose}
                        label={`${x.HubStartTime} - ${x.HubEndTime}`}
                        minW="150px"
                        bg="#F4FAFF"
                      />
                    </HStack>
                  );
                })}
              </Box>
         
            </Box>
            <Box w={"100%"}>
              <Box>
                <HStack py="7px">
                  <Text color={textColorSecondary_2} fontSize={"14px"}>
                    Host Guidelines (optional)
                  </Text>
                </HStack>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Occupancy
                </FormLabel>
                <Input
                  onChange={(e) => updateState(e.target.value, "Occupancy")}
                  value={state.Occupancy}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="number"
                  borderRadius={8}
                  mb="24px"
                  fontWeight="500"
                  placeholder="Total Occupancy"
                  size="lg"
                />

                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Health Protocols
                </FormLabel>
                <Input
                  onChange={(e) => updateState(e.target.value, "CovidRules")}
                  value={state.CovidRules}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  borderRadius={8}
                  mb="24px"
                  fontWeight="500"
                  placeholder="Mask up!"
                  size="lg"
                />
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Cleaning Protocols
                </FormLabel>
                <Input
                  onChange={(e) =>
                    updateState(e.target.value, "CleaningProtocol")
                  }
                  value={state.CleaningProtocol}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  borderRadius={8}
                  mb="24px"
                  fontWeight="500"
                  placeholder="Any cleaning rules?"
                  size="lg"
                />
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Parking
                </FormLabel>
                <Input
                  onChange={(e) => updateState(e.target.value, "Parking")}
                  value={state.Parking}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  borderRadius={8}
                  mb="24px"
                  fontWeight="500"
                  placeholder="Park properly..."
                  size="lg"
                />
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Any Additional Rules
                </FormLabel>
                <Input
                  onChange={(e) =>
                    updateState(e.target.value, "AnyAdditionalRules")
                  }
                  value={state.AnyAdditionalRules}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  borderRadius={8}
                  mb="24px"
                  fontWeight="500"
                  placeholder="Get specific!"
                  size="lg"
                />
              </Box>
              <Box>
                <HStack justifyContent={"space-between"} py="7px">
                  <Text
                    textTransform={"uppercase"}
                    color={textColorSecondary_2}
                    fontSize={"14px"}
                    fontWeight="semibold"
                  >
                    Amenities
                  </Text>
                </HStack>
                <Flex
                  flexWrap={"wrap"}
                  justifyContent={"flex-start"}
                  alignItems="center"
                  py="7px"
                  // px="20px"
                >
                  {state.Amenities.map((item: any, index: number) => {
                    return (
                      <Chip
                        onClick={() => addAminities(index)}
                        key={index}
                        cursor="pointer"
                        innerTextColor={"white"}
                        label={item.amenitiesChoice}
                        px="10px"
                        m="2px"
                        bg={item.IsSelected ? brandBlue : textColorSecondary}
                        _hover={{ bg: brandBlue }}
                      />
                    );
                  })}
                </Flex>
              </Box>
            </Box>
          </Box>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            isLoading={isLoading}
            loadingText="Saving..."
            onClick={saveLocation}
          >
            Update
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
export default React.memo(EditLocation);
