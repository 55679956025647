import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  Image,
  useToast,
  Text,
  chakra,
  Box,
  ModalFooter,
  ModalBody,
  Heading,
  useColorModeValue,
  HStack,
  Icon,
  Button,
  Avatar,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Textarea,
} from "@chakra-ui/react";
import { HiOutlineCamera } from "react-icons/hi";
import { rootUrl } from "utilities/constants";
import { BsFileEarmarkMinusFill } from "react-icons/bs";

import { post } from "redux/services/api";
import { eventRootUrl } from "utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import pubg_banner2 from "assets/img/global/pubg_banner2.png";

import logoWite from "assets/img/global/logoWite.png";
import EventStepper_1 from "assets/img/global/EventStepper_1.png";
// import EventStepper_1 from "assets/img/global/EventStepper_1.png";
import { getProfile } from "redux/slices/Auth";

import { AppDispatch } from "redux/store";
import { getEventList } from "redux/slices/Events";

interface Props {
  onOpen: Function;
  isOpen: boolean;
  onClose: Function;
  [x: string]: any;
}

export default function ViewLocationRejection(props: Props) {
  const {
    userId,
    userName,
    location,
    description,
    website,
    games,
    logo,
    coverImage,
    title,
    selectedLocation,
  } = useSelector((state: any) => state.Auth);
  const [isLoading, setIsLoading] = useState(false);

  const { next, handleSteps, parantState } = props;
  const textColor = useColorModeValue("black.50", "white");
  const textColorBrand = useColorModeValue("blue.100", "white");
  const buttonColorBrand = "blue.200";
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const toast = useToast();
  const dispatch: AppDispatch = useDispatch();
  const [preview, setPreview] = useState<any>(null);
  const [isReview, setIsReview] = React.useState(false);
  const [loaing, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose, editState, ...rest } = props;
  const [steps, setStep] = React.useState(5);
  const hiddenFileInputBanner = React.useRef(null);
  const [previewCover, setPreviewCover] = useState<any>(null);

  let initialState: any = {};
  const [state, setState] = useState({
    HubProfilePicName: "",
    HubProfileCover: "",
    HubWebsite: website,
    UserId: userId,
    HubBio: description,
    HubProfilePic: "",
  });
  const handleChangeBanner = (event: any) => {
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(fileUploaded);
    reader.onloadend = function (e) {
      setPreviewCover([reader.result]);
    };
    setState((prvState) => ({
      ...prvState,
      HubProfileCover: fileUploaded,
    }));
  };
  const handleClickBanner = () => {
    hiddenFileInputBanner.current.click();
  };
  const hiddenFileInput = React.useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(fileUploaded);
    reader.onloadend = function (e) {
      setPreview([reader.result]);
    };

    setState((prvState: any) => ({
      ...prvState,
      HubProfilePicName: fileUploaded.name,
      HubProfilePic: fileUploaded,
    }));
  };
  useEffect(() => {
    console.log({ coverImage });
    setPreviewCover(coverImage?.replace("\\", "/") ?? null);
    setPreview(logo);
    setState((prvState: any) => ({
      ...prvState,
      HubWebsite: website,
      HubBio: description,
    }));
  }, [logo, coverImage, website, description]);

  const updateState = (val: string, prop: string) => {
    setState((prvState) => ({ ...prvState, [prop]: val }));
  };
  const checks = (): boolean => {
    if (state.HubProfilePic === "" && preview === null) {
      toast.closeAll()
      toast({
        title: "Profile picture is required",
        description: "Please upload profile picture",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  return (
    <Modal
    
    isCentered
      {...rest}
      // closeOnOverlayClick={false}
      scrollBehavior="inside"
      size={"4xl"}
      isOpen={isOpen}
      onClose={() => {
        setState(initialState);
        onClose();
        setStep(1);
      }}
    >
      <ModalOverlay />
      <ModalContent borderRadius="14px" position={"relative"} p={0}>
        <ModalHeader h={"80px"} p="0px" borderRadius="14px">
          <Box
            borderTopRadius="14px"
            // borderBottomRadius="16px"
            justifyContent={"space-between"}
            bg="#1C2A5B"
            alignItems="center"
            py="20px"
            px="50px"
          >
            <Text color={"white"} fontSize="24px" fontWeight={"600"} mt="10px">
              Reason for Rejection
            </Text>
          </Box>
        </ModalHeader>
        <ModalBody
          overflowY="auto"
          css={{
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#1C2A5B",
              borderRadius: "24px",
            },
          }}
          position={"relative"}
          p="35px"
        >
          <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={"black"}
                 
              >
                Reason of Rejection
              </FormLabel>
          <Flex border="0px solid #CECECE" borderRadius="5px" p="10px">
            <Text color={"#090909"} fontSize="16px">
              {selectedLocation?.locationRejectReason}
            </Text>
          </Flex>
          <Text fontWeight={"600"} mt="40px" color={"#090909"} fontSize="14px">
            Document
          </Text>

          <Flex
            mt="18px"
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
          >
            {selectedLocation?.locationRejectDocument && (
              <Box
                cursor={"pointer"}
                onClick={() =>
                  window.open(
                    selectedLocation.locationRejectDocument,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                bg="#ECF1F4"
                p="8px"
                borderRadius="4px"
              >
                <BsFileEarmarkMinusFill size={"40px"} color="#1C2A5B" />
              </Box>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter px="50px">
          <Flex w="full" justifyContent={"space-between"} alignItems="center">
            <Button
              isLoading={loaing}
              loadingText="Loading..."
              onClick={() => onClose()}
              fontSize="16px"
              variant="brand"
              fontWeight="700"
              w="276px"
              h="50"
              color={"black"}
              bg="transparent"
              border={`1px solid  black`}
              borderRadius={8}
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
            >
              Close
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
