// Chakra imports
import {
  Flex,
  Text,
  Input,
  FormLabel,
  useColorModeValue,
  Box,
  HStack,
  Switch,
  Button,
  FormControl,
  FormErrorMessage,
  useToast,
  Icon,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import Chip from "components/chip/Chip";
import TimeChip from "components/TimeChip";

import { useState, useRef, useEffect } from "react";
import { post } from "redux/services/api";
import { hubRootUrl } from "utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import FileUpload from "components/fileUpload";
import { useForm } from "react-hook-form";
import { RxCross1 } from "react-icons/rx";
import { FaFile } from "react-icons/fa";
import { getAllLocations } from "redux/slices/Auth";
import { AppDispatch } from "redux/store";
import moment from "moment";
import { BsFileEarmarkTextFill } from "react-icons/bs";

import "react-datepicker/dist/react-datepicker.css";
export default function OtherLocations() {
  const dispatch: AppDispatch = useDispatch();

  const { userId, locations, aminitiesList,isHubApproved } = useSelector(
    (state: any) => state.Auth
  );
  const hiddenFileInput = useRef(null);
  const toast = useToast();

  const textColor = useColorModeValue("black.50", "white");
  const buttonColorBrand = useColorModeValue("#19BAEE", "white");
  const textColorSecondary_2 = "#92929D";
  const brandBlue = "brand.500";
  const textColorSecondary = "gray.400";
  const [aminities, setAminities] = useState([]);

  const [invalidState, setInvalidState] = useState({
    AddressInvalid: false,
  });
  const [isLoading, setisLoading] = useState(false);
  const initialState: any = {
    HubId: userId,
    LocationId: 0,
    HubCoverPhotoPath: "",
    Bio: "",
    Photos: {},
    Website: "www.test.com",
    Timings: [
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: true,
        HubDay: "Monday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: true,
        HubDay: "Tuesday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: true,
        HubDay: "Wednesday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: true,
        HubDay: "Thursday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: true,
        HubDay: "Friday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: true,
        HubDay: "Saturday",
      },
      {
        HubStartTime: "08:00 am",
        HubEndTime: "05:00 pm",
        IsClose: true,
        HubDay: "Sunday",
      },
    ],
    Amenities: [],
    CovidRules: "",
    Occupancy: 0,
    CleaningProtocol: "",
    Parking: "",
    AnyAdditionalRules: "",
    AttachmentIds: "",
    Latitude: "",
    Longitude: "",
    Address: "",
    Street: "",
  };
  const [state, setState] = useState<any>(initialState);
  useEffect(() => {
    if (aminitiesList.length) {
      let arr = aminitiesList.map((x: any) => {
        return {
          ...x,
          AminityId: x.amenityId,
          IsSelected: false,
        };
      });
      setState((prvState: any) => ({ ...prvState, Amenities: arr }));

      // setAminities(aminitiesList);
    }
  }, [aminitiesList]);
  const updateState = (val: string, prop: string) => {
    setState((prvState: any) => ({ ...prvState, [prop]: val }));
  };
  const updateSwitch = (IsClose: boolean, index: number) => {
    let arr = [...state.Timings];
    arr[index].IsClose = !arr[index].IsClose;
    // if(IsClose){
    //   arr[index].HubStartTime = "08:00 am";
    //   arr[index].HubEndTime = "05:00 pm";
    // }else{
    //   arr[index].HubStartTime =null;
    //   arr[index].HubEndTime = null;
    // }
  

    setState((prvState: any) => ({ ...prvState, Timings: arr }));
  };

  const check = () => {
    if (state.Address === "") {
      setInvalidState((prv) => ({ ...prv, AddressInvalid: true }));
      return false;
    }
    return true;
  };
  const saveLocation = async () => {
    if (check()) {
      setisLoading(true);
      let files: any = { ...state.Photos };
      let data: any = { ...state };
      let Timings = data.Timings;
      // Timings = JSON.stringify(data.Timings);
      let Amenities = data.Amenities;
      Amenities = Amenities.map((x: any) => {
        return {
          AminityId: x.AminityId,
          IsSelected: x.IsSelected,
        };
      });
      // Amenities = JSON.stringify(Amenities);
      let formdata = new FormData();
      for (var key in data) {
        if (key != "Photos" && key != "Amenities" && key != "Timings") {
          formdata.append(key, data[key]);
        }
      }
      Timings.forEach((x: any, i: number) => {
        Object.keys(x).map((key) => {
          formdata.append("Timings" + `[${i}]` + `[${key}]`, x[key]);
        });
      });
      console.log({ Amenities });
      Amenities.forEach((x: any, i: number) => {
        // formdata.append("Amenities", JSON.stringify(x));
        Object.keys(x).map((key) => {
          formdata.append("Amenities" + `[${i}]` + `[${key}]`, x[key]);
        });
      });

      if (Object.keys(files).length) {
        for (const file in files) {
          console.log({ x: files[file] });
          formdata.append("Photos", files[file]);
        }
      }

      let payload = {
        url: `${hubRootUrl}api/v1/profile/profile-detail`,
        data: formdata,
      };
      let response = await post(payload);
      console.log({ response });
      setisLoading(false);
      if (response.data.statusCode === 200) {
        toast.closeAll()
        toast({
          title: "Success",
          description: "Hub location saved",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setState(initialState);
        dispatch(getAllLocations());
      } else {
        toast.closeAll()
        toast({
          title: response.data.message,
          description: response.data.errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  const handleClickDocuments = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event: any) => {
    let files = event.target.files;
    let updatedFile: any = {};
    Object.keys(files).map((key) => {
      updatedFile[files[key]["name"]] = files[key];
    });
    updatedFile = { ...updatedFile, ...state.Photos };

    let size = Object.keys(updatedFile).length;
    if (size > 7) {
      toast.closeAll()
      toast({
        title: "Warning",
        description: "Only maximum 7 files accepted.",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      event.preventDefault();
      return;
    }

    setState((prvState: any) => ({
      ...prvState,
      Photos: updatedFile,
    }));
  };
  const handleRemoveUpload = (key: string) => {
    const fileUploaded = { ...state.Photos };

    delete fileUploaded[key];
    setState((prvState: any) => ({
      ...prvState,
      Photos: fileUploaded,
    }));
  };

  const addAminities = (index: number) => {
    let arr = [...state.Amenities];
    arr = arr.map((x, i) => {
      return {
        ...x,
        IsSelected: i === index ? !x.IsSelected : x.IsSelected,
      };
    });

    setState((prvState: any) => ({
      ...prvState,
      Amenities: arr,
    }));
  };

  const handleStartDate = (time: any, index: number) => {
    let arr = [...state.Timings];
    arr[index].HubStartTime = time;
    setState((prvState: any) => ({
      ...prvState,
      Timings: arr,
    }));
  };
  const handleEndDate = (time: any, index: number) => {
    console.log({ time, index });
    let arr = [...state.Timings];
    arr[index].HubEndTime = time;
    setState((prvState: any) => ({
      ...prvState,
      Timings: arr,
    }));
  };
  if(isHubApproved!="Approved"){
    return(
      <Card shadow={"lg"} borderRadius={"8px"} p="40px">
       <Text fontSize={"36px"} fontWeight="bold" color="#1C2A5B">
         Account Status :{isHubApproved}
        </Text>
      </Card>
    )
  }
  return (
    <Card shadow={"lg"} borderRadius={"8px"} p="40px">
      <Flex justifyContent={"space-between"}>
        <Text fontSize={"36px"} fontWeight="bold" color="#1C2A5B">
          Other Locations
        </Text>
        <Text
          onClick={() => setState(initialState)}
          cursor={"pointer"}
          fontSize={"14px"}
          color="blue.100"
        >
          Reset
        </Text>
      </Flex>
      <Text py="10px" fontSize={"14px"} color="#1C2A5B">
        Add an additional location
      </Text>

      <FormControl isInvalid={invalidState.AddressInvalid}>
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="14px"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Physical Address
        </FormLabel>
        <Input
          borderColor={invalidState.AddressInvalid ? "red" : null}
          onChange={(e) => {updateState(e.target.value, "Address"); setInvalidState({AddressInvalid:false})}}
          value={state.Address}
          isRequired={true}
          variant="auth"
          fontSize="14px"
          ms={{ base: "0px", md: "0px" }}
          type="text"
          placeholder="Location"
          borderRadius={8}
          mb="10px"
          fontWeight="500"
          size="lg"
        />
        {invalidState.AddressInvalid && (
          <FormErrorMessage>Physical address is required.</FormErrorMessage>
        )}
      </FormControl>
      <Flex justifyContent={"space-between"}>
        <Box w={"35%"}>
          <Box>
            <HStack justifyContent={"space-between"} py="7px">
              <Text
                color={textColorSecondary_2}
                fontWeight="600"
                fontSize={"16px"}
              >
                TIMINGS
              </Text>
            </HStack>
            {state.Timings.map((x: any, i: number) => {
              return (
                <HStack key={i} justifyContent={"space-between"} py="7px">
                  <Switch
                    onChange={() => updateSwitch(x.IsClose, i)}
                    isChecked={!x.IsClose}
                    colorScheme={"brand"}
                    w="50px"
                    id="email-alerts"
                  />
                  <Text
                    w="100px"
                    color={textColorSecondary_2}
                    fontSize={"14px"}
                  >
                    {x.HubDay}
                  </Text>
                  <TimeChip
                    index={i}
                    handleStartDate={handleStartDate}
                    handleEndDate={handleEndDate}
                    HubStartTime={x.HubStartTime}
                    HubEndTime={x.HubEndTime}
                    innerTextColor={brandBlue}
                    IsClose={x.IsClose}
                    label={`${x.HubStartTime} - ${x.HubEndTime}`}
                    minW="150px"
                    // px='5px'
                    bg="#F4FAFF"
                  />
                </HStack>
              );
            })}
          </Box>
          <Box>
            <HStack justifyContent={"space-between"} py="7px">
              <Text
                textTransform={"uppercase"}
                color={textColorSecondary_2}
                fontSize={"14px"}
                fontWeight="semibold"
              >
                Amenities
              </Text>
            </HStack>
            <Flex
              flexWrap={"wrap"}
              justifyContent={"flex-start"}
              alignItems="center"
              py="7px"
              // px="20px"
            >
              {state.Amenities.map((item: any, index: number) => {
                return (
                  <Chip
                    onClick={() => addAminities(index)}
                    key={ index}
                    cursor="pointer"
                    innerTextColor={"white"}
                    label={item.amenitiesChoice}
                    px="10px"
                    m="2px"
                    bg={item.IsSelected ? brandBlue : textColorSecondary}
                    _hover={{ bg: brandBlue }}
                  />
                );
              })}
            </Flex>
          </Box>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="14px"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Business Documents (7 max)
          </FormLabel>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="14px"
            fontWeight="light"
            color={textColorSecondary}
            mb="2px"
          >
            Any additional business documents that can be sent to provide
            verification that your hub facility is authentic and exists can be
            uploaded here. The more you can provide, the faster the verification
            process will be.
          </FormLabel>

          <Flex
            py="20px"
            flexWrap={"wrap"}
            justifyContent={"flex-start"}
            alignItems="center"
          >
            {Object.keys(state.Photos).map((key: any) => {
              return (
                <Box key={key} maxW={"60px"} mr="10px" position={"relative"}>
                  <Icon
                    position={"absolute"}
                    top={"-5px"}
                    right={"-2px"}
                    fontWeight="bold"
                    color={"red"}
                    _hover={{ cursor: "pointer" }}
                    as={RxCross1}
                    onClick={() => handleRemoveUpload(key)}
                  />
                  <Icon
                    color={"#FF7763"}
                    _hover={{ cursor: "pointer" }}
                    boxSize={"50px"}
                    as={BsFileEarmarkTextFill}
                  />
                  <Text maxW={"60px"} noOfLines={1}>
                    {state.Photos[key].name}
                  </Text>
                </Box>
              );
            })}
          </Flex>
          <Button
            bg={buttonColorBrand}
            onClick={handleClickDocuments}
            fontSize="14px"
            variant="brand"
            fontWeight="500"
            w="60%"
            h="40px"
            mt={"0px"}
            borderRadius={8}
          >
            Attach
          </Button>
          <input
            accept="*"
            type="file"
            value={""}
            multiple
            maxLength={7}
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
          />
        </Box>
        <Box w={"35%"}>
          <Box>
            <HStack py="7px">
              <Text
                color={textColorSecondary_2}
                fontWeight="600"
                fontSize={"16px"}
              >
                Host Guidelines (optional)
              </Text>
            </HStack>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="14px"
              fontWeight="600"
              color={textColor}
              mb="8px"
            >
              Occupancy
            </FormLabel>
            <Input
              onChange={(e) => updateState(e.target.value, "Occupancy")}
              value={state.Occupancy === 0 ? "" : state.Occupancy}
              isRequired={true}
              variant="auth"
              fontSize="14px"
              ms={{ base: "0px", md: "0px" }}
              type="number"
              borderRadius={8}
              mb="24px"
              fontWeight="600"
              placeholder="Total Occupancy"
              size="lg"
            />

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="14px"
              fontWeight="600"
              color={textColor}
              mb="8px"
            >
              Health Protocols
            </FormLabel>
            <Input
              onChange={(e) => updateState(e.target.value, "CovidRules")}
              value={state.CovidRules}
              isRequired={true}
              variant="auth"
              fontSize="14px"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              borderRadius={8}
              mb="24px"
              fontWeight="600"
              placeholder="Mask up!"
              size="lg"
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="14px"
              fontWeight="600"
              color={textColor}
              mb="8px"
            >
              Cleaning Protocols
            </FormLabel>
            <Input
              onChange={(e) => updateState(e.target.value, "CleaningProtocol")}
              value={state.CleaningProtocol}
              isRequired={true}
              variant="auth"
              fontSize="14px"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              borderRadius={8}
              mb="24px"
              fontWeight="600"
              placeholder="Any cleaning rules?"
              size="lg"
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="14px"
              fontWeight="600"
              color={textColor}
              mb="8px"
            >
              Parking
            </FormLabel>
            <Input
              onChange={(e) => updateState(e.target.value, "Parking")}
              value={state.Parking}
              isRequired={true}
              variant="auth"
              fontSize="14px"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              borderRadius={8}
              mb="24px"
              fontWeight="600"
              placeholder="Park properly..."
              size="lg"
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="14px"
              fontWeight="600"
              color={textColor}
              mb="8px"
            >
              Any Additional Rules
            </FormLabel>
            <Input
              onChange={(e) =>
                updateState(e.target.value, "AnyAdditionalRules")
              }
              value={state.AnyAdditionalRules}
              isRequired={true}
              variant="auth"
              fontSize="14px"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              borderRadius={8}
              mb="24px"
              fontWeight="600"
              placeholder="Get specific!"
              size="lg"
            />
          </Box>
        </Box>
      </Flex>
      <Flex mt={"20px"} justifyContent={"flex-end"}>
        <Button
          isLoading={isLoading}
          loadingText="Saving..."
          onClick={saveLocation}
          fontSize="14px"
          variant="brand"
          fontWeight="600"
          float={"right"}
          w="30%"
          h="40px"
          mb="24px"
          borderRadius={8}
          bg={buttonColorBrand}
        >
          Add
        </Button>
      </Flex>
    </Card>
  );
}
