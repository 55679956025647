import { Icon } from "@chakra-ui/react";
import { MdLock } from "react-icons/md";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Admin Imports
// import MainDashboard from "views/admin/default";
// import NFTMarketplace from "views/admin/marketplace";
// import Profile from "views/admin/profile";
// import Account from "views/admin/account";
import EventPlanner from "views/admin/eventPlanner";
// import Business from "views/admin/accountSettings/components/Business";
// import Documents from "views/admin/accountSettings/components/Documents";
// import Information from "views/admin/accountSettings/components/Information";
// import Locations from "views/admin/accountSettings/components/OtherLocations";
import AccountSettings from "views/admin/accountSettings";

import BracketPortal from "views/admin/bracketPortal";
import Analytics from "views/admin/analytics";
import EditProfile from "views/admin/EditProfile";

import AdminUsersList from "views/admin/AdminUsersList";
import EventsList from "views/admin/EventsList";
import HubsList from "views/admin/HubsList";
import UsersList from "views/admin/UsersList";
import ViewHubDetails from "views/admin/ViewHubDetails";
import EventDetails from "views/admin/EventDetails";
import Roles from "views/admin/Roles";
import TicketingSystem from "views/admin/TicketingSystem";
import AddParticipants from "views/admin/AddParticipants";
import { FORMS_ID } from "utilities/constants";

// import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import PublicBrackets from "views/auth/PublicBrackets";

import SignUpCentered from "views/auth/signUp";
import CodeVerification from "views/auth/CodeVerification";
import ForgotPassword from "views/auth/ForgotPassword";
import ResetPassword from "views/auth/ResetPassword";
import HubProfile from "views/auth/HubProfile";

export const authRoutes = [
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignUpCentered,
  },

  {
    name: "CodeVerification",
    layout: "/auth",
    path: "/CodeVerification",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: CodeVerification,
  },
  {
    name: "ForgotPassword",
    layout: "/auth",
    path: "/ForgotPassword",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgotPassword,
  },
  {
    name: "ResetPassword",
    layout: "/auth",
    path: "/ResetPassword",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ResetPassword,
  },
  {
    name: "HubProfile",
    layout: "/auth",
    path: "/HubProfile",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: HubProfile,
  },
  {
    name: "PublicBrackets",
    layout: "/auth",
    path: "/brackets",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: PublicBrackets,
  },
  
];

export const adminRoutes = [
  {
    path: "/admin/Analytics",
    component: Analytics,
    key: FORMS_ID.Analytics,
  },
  {
    path: "/admin/Profile",
    component: EventPlanner,
    key: FORMS_ID.Profile,
  },

  {
    path: "/admin/Settings",
    component: AccountSettings,
    key: FORMS_ID.Settings,
  },
  {
    path: "/admin/BracketPortal",
    component: BracketPortal,
    key: FORMS_ID.Settings,
  },
  {
    path: "/admin/BracketPortal",
    component: BracketPortal,
    key: FORMS_ID.BracketPortal,
  },
  {
    path: "/admin/editProfile",
    component: EditProfile,
    key: FORMS_ID.EditProfile,
  },
  {
    path: "/admin/data-tables",
    component: AdminUsersList,
    key: FORMS_ID.Admins,
  },
  {
    path: "/admin/Event",
    component: EventsList,
    key: FORMS_ID.Event,
  },
  {
    path: "/admin/Event",
    component: EventsList,
    key: FORMS_ID.Event,
  },
  {
    path: "/admin/HubsList",
    component: HubsList,
    key: FORMS_ID.HubsList,
  },
  {
    path: "/admin/Users",
    component: UsersList,
    key: FORMS_ID.Users,
  },
  {
    path: "/admin/ViewHubDetails",
    component: ViewHubDetails,
    key: FORMS_ID.HubsList,
  },
  {
    path: "/admin/EventDetails",
    component: EventDetails,
    key: FORMS_ID.Event,
  },
  {
    path: "/admin/UsersRoles",
    component: Roles,
    key: FORMS_ID.UsersRoles,
  },
  {
    path: "/admin/TicketingSystem",
    component: TicketingSystem,
    key: FORMS_ID.TicketingSystem,
  },
  {
    path: "/admin/AddParticipants",
    component: AddParticipants,
    key: FORMS_ID.Admins,
  },
];
const PageNotExists = () => {
  return (
    <h1
      style={{
        position: "absolute",
        top: 200,
        left: "20%",
        fontWeight: "bold",
        fontSize: "50px",
      }}
    >
      404 not found!
      <br /> Page does not exist or you don't have specific rights
    </h1>
  );
};
function AppRoutes() {
  const { menuList } = useSelector((state: any) => state.AdminsTableLists);

  const getRoutes = (): any => {
    return adminRoutes.map((route: any) => {
      if (menuList.includes(route.key)) {
        return (
          <Route
            path={route.path}
            component={route.component}
            key={route.key}
          />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <Switch>
      {/* <Route path="/admin/Analytics" exact component={Analytics} />
      <Route path="/admin/Profile" exact component={EventPlanner} />
      <Route path="/admin/s" exact component={Locations} />
      <Route path="/admin/Settings" exact component={AccountSettings} />
      <Route path="/admin/BracketPortal" exact component={BracketPortal} />
      <Route path="/admin/editProfile" exact component={EditProfile} />
      <Route path="/admin/data-tables" exact component={AdminUsersList} />
      <Route path="/admin/Event" exact component={EventsList} />
      <Route path="/admin/HubsList" exact component={HubsList} />
      <Route path="/admin/Users" exact component={UsersList} />
      <Route path="/admin/ViewHubDetails" exact component={ViewHubDetails} />
      <Route path="/admin/EventDetails" exact component={EventDetails} />
      <Route path="/admin/UsersRoles" exact component={Roles} />
      <Route path="/admin/TicketingSystem" exact component={TicketingSystem} />
      <Route path="/admin/AddParticipants" exact component={AddParticipants} /> */}
      {getRoutes()}
      <Route path={`/*`} component={PageNotExists} />
    </Switch>
  );
}

export default AppRoutes;
// export default routes;
