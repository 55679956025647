import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  Image,
  useToast,
  Text,
  chakra,
  Box,
  ModalFooter,
  ModalBody,
  Heading,
  useColorModeValue,
  HStack,
  Icon,
  Button,
  Avatar,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Textarea,
} from "@chakra-ui/react";
import { HiOutlineCamera } from "react-icons/hi";
import { hubAdminUrl_table } from "utilities/constants";
import { RxCross1 } from "react-icons/rx";
import { BsFileEarmarkTextFill } from "react-icons/bs";

import { post } from "redux/services/api";
import { eventRootUrl } from "utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import pubg_banner2 from "assets/img/global/pubg_banner2.png";

import logoWite from "assets/img/global/logoWite.png";
import EventStepper_1 from "assets/img/global/EventStepper_1.png";
// import EventStepper_1 from "assets/img/global/EventStepper_1.png";
import { getProfile } from "redux/slices/Auth";

import { AppDispatch } from "redux/store";
import { getEventList } from "redux/slices/Events";

interface Props {
  onOpen: Function;
  isOpen: boolean;
  onClose: Function;
  refetch: Function;
  [x: string]: any;
  ishub: boolean;
  id?: string;
}

export default function ApproveHub(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const { next, handleSteps, parantState, ishub, id, refetch } = props;
  console.log({ id });
  const textColor = useColorModeValue("black.50", "white");
  const textColorBrand = useColorModeValue("blue.100", "white");
  const buttonColorBrand = "blue.200";
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const toast = useToast();
  const dispatch: AppDispatch = useDispatch();
  const [preview, setPreview] = useState<any>(null);
  const [isReview, setIsReview] = React.useState(false);
  const [loaing, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose, editState, ...rest } = props;
  const [steps, setStep] = React.useState(5);
  const hiddenFileInputBanner = React.useRef(null);
  const [previewCover, setPreviewCover] = useState<any>(null);

  let initialState: any = {};
  const [state, setState] = useState({
    feedbackComment: "",
    document: null,
    locationId: id,
    userId: id,
  });

  useEffect(() => {
    setState((prvState) => ({
      ...prvState,
      locationId: id,
      userId: id,
    }));
  }, [id]);
  const handleChangeBanner = (event: any) => {
    const fileUploaded = event.target.files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(fileUploaded);

    setState((prvState) => ({
      ...prvState,
      document: fileUploaded,
    }));
  };
  const handleClickBanner = () => {
    hiddenFileInputBanner.current.click();
  };

  const updateState = (val: string, prop: string) => {
    setState((prvState) => ({ ...prvState, [prop]: val }));
  };
  const checks = (): boolean => {
    return true;
  };
  const finish = async () => {
    if (checks()) {
      setIsLoading(true);
      let payload: any = { ...state };
      console.log({ payload });
      var myHeaders = new Headers();
      var formdata = new FormData();

      myHeaders.append("accept", "*/*");
      for (var key in payload) {
        if (payload[key] != "") {
          formdata.append(key, payload[key]);
        }
      }

      let obj = {
        url: ishub
          ? `${hubAdminUrl_table}api/v1/user-profile/decline-admin-hub-profile`
          : `${hubAdminUrl_table}api/v1/user-profile/decline-hub-location`,
        data: formdata,
      };
      let result: any = await post(obj);
      setIsLoading(false);
      console.log({ result });
      if (result.status === false) {
        toast.closeAll()
        toast({
          title: "Error",
          description: result.errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        refetch();
        onClose();
        toast.closeAll()
        toast({
          title: "Success",
          description: result.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  return (
    <Modal
    isCentered
      {...rest}
      // closeOnOverlayClick={false}
      scrollBehavior="inside"
      size={"4xl"}
      isOpen={isOpen}
      onClose={() => {
        setState(initialState);
        onClose();
        setStep(1);
      }}
    >
      <ModalOverlay />
      <ModalContent borderRadius="14px" position={"relative"} p={0}>
        <ModalHeader h={"80px"} p="0px" borderRadius="14px">
          <Box
            borderTopRadius="14px"
            // borderBottomRadius="16px"
            justifyContent={"space-between"}
            bg="#1C2A5B"
            alignItems="center"
            py="20px"
            px="50px"
          >
            <Text color={"white"} fontSize="24px" fontWeight={"600"} mt="10px">
              Reason for Rejection
            </Text>
          </Box>
        </ModalHeader>
        <ModalBody
          overflowY="auto"
          css={{
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#1C2A5B",
              borderRadius: "24px",
            },
          }}
          position={"relative"}
          p="35px"
        >
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={"black"}
            mb="8px"
          >
            Upload Documents
          </FormLabel>
          {state.document && (
            <Box maxW={"60px"} mr="10px" position={"relative"}>
              <Icon
                position={"absolute"}
                top={"-5px"}
                right={"-2px"}
                fontWeight="bold"
                color={"red"}
                _hover={{ cursor: "pointer" }}
                as={RxCross1}
                onClick={() =>
                  setState((prvState) => ({ ...prvState, document: null }))
                }
              />
              <Icon
                color={"#FF7763"}
                _hover={{ cursor: "pointer" }}
                boxSize={"50px"}
                as={BsFileEarmarkTextFill}
              />
            </Box>
          )}
          <Text noOfLines={1}>{state.document?.name}</Text>
          <Flex border="1px solid #CECECE" borderRadius="5px" p="10px">
            <Button
              h="25px"
              isLoading={loaing}
              loadingText="Loading..."
              onClick={handleClickBanner}
              fontSize="12"
              variant="brand"
              fontWeight="500"
              bg="white"
              borderRadius={8}
              border={`1px solid  white`}
              color={"#298BE2"}
              _hover={{ bg: "white" }}
              _active={{ bg: "white" }}
              _focus={{ bg: "white" }}
              borderColor={"#E2E2E2"}
            >
              Choose a file
            </Button>
            <input
              accept="image/*"
              type="file"
              value={""}
              ref={hiddenFileInputBanner}
              onChange={handleChangeBanner}
              style={{ display: "none" }}
            />
          </Flex>

          <Flex
            zIndex="2"
            direction="column"
            w="100%"
            background="transparent"
            borderRadius="15px"
          >
            <FormControl mt={10}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={"black"}
                mb="8px"
              >
                Write a reason of rejection
              </FormLabel>
              <Textarea
                onChange={(e) => updateState(e.target.value, "feedbackComment")}
                placeholder="Type Here..."
                value={state.feedbackComment}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                borderRadius={8}
                mb="24px"
                fontWeight="500"
                size="lg"
                minHeight={"200px"}
                border={`1px solid `}
                borderColor={"gray.400"}
              />
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter px="50px">
          <Flex w="full" justifyContent={"space-between"} alignItems="center">
            <Button
              isLoading={loaing}
              loadingText="Loading..."
              onClick={() => onClose()}
              fontSize="16px"
              variant="brand"
              fontWeight="700"
              w="276px"
              h="50"
              color={"black"}
              bg="transparent"
              border={`1px solid  black`}
              borderRadius={8}
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
            >
              Cancel
            </Button>

            <Button
              fontSize="16px"
              variant="brand"
              fontWeight="700"
              isLoading={isLoading}
              loadingText="Updating..."
              onClick={finish}
              w="276px"
              h="50"
              borderRadius={8}
              bg={buttonColorBrand}
            >
              Submit
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
