import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post, get } from "../services/api";
// import { ProductInterface } from "../../utilities/Interface";
import { rootUrl, hubRootUrl, eventRootUrl } from "../../utilities/constants";
import { app, db, auth } from "firebaseConfig";
import { environment } from "utilities/constants";
import OneSignal from "react-onesignal";
import axios from "axios";
import { store } from "redux/store";

interface AuthState {
  loading: boolean;
  userId: string;
  userName: string;
  email: string;
  phoneNumer: string;
  token: string;
  refreshToken: string;
  isAuthenticated: boolean;
  allUsers: Array<any>;
  title: string;
  location: string;
  description: string;
  website: string;
  games: Array<any>;
  logo: string;
  coverImage: string;
  locations: Array<any>;
  aminitiesList: Array<any>;
  selectedLocation: any;
  AuthError: any;
  role: string;
  userStastics: any;
  isHubApproved: any;
  rejectDocument: any;
  rejectReason: any;
  // currentServerTime: any;
  // expires: any;
}

const createFirebaseLog = async (userId: string, name: string) => {
  let Id = userId.toString();

  let latitude = 0;
  let longitude = 0;
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    });
  } else {
    console.log("Geolocation is not supported by this browser.");
  }
  let permission = OneSignal.Notifications.permission;

  const res = await axios.get("https://api.ipify.org?format=json");
  const ipAddress = res.data.ip;
  const collectionName = "userLogs" + environment;
  let idDate = new Date().toISOString();
  console.log({ Id, name, collectionName, permission, idDate });
  const messagesCol: any = db
    .collection(collectionName)
    .doc(Id)
    .collection(Id)
    .doc(idDate);
  try {
    await messagesCol.set({
      id:idDate,
      platform: "web",
      ip: ipAddress,
      deviceId: "",
      deviceName: "",
      isPhysicalDevice: true,
      isDeviceRooted: false,
      isWifiConnected: true,
      username: name,
      loginType: "form",
      logType:"logout",
      latLong: latitude + "," + longitude,
      appVersion: "",
      createdAt: Date.now(),
      permission: permission ? ["notification"] : [],
    });
  } catch (err: any) {
    console.log({ err });
  }
};
export const Authenticate = createAsyncThunk(
  "auth/token",
  async (data: object) => {
    try {
      let payload = {
        data: data,
        url: `${rootUrl}api/v1/token/access-token`,
      };
      const response: any = await post(payload);
      console.log({ response });
      if (response.data.status) {
        return response.data;
      } else {
        throw response.data.errorMessage;
      }
    } catch (err: any) {
      //console.log({ errorz: err.response?.data?.error_description });
      console.log({ errorz: err });

      if (err) {
        throw err;
      } else {
        throw err;
      }
    }
  }
);

export const getProfile = createAsyncThunk("auth/getProfile", async () => {
  try {
    let url = `${rootUrl}api/v1/account/get-profile`;

    const response: any = await get(url);

    return response.data;
  } catch (err: any) {
    console.log({ errorz: err });
    if (err) {
      throw err;
    } else {
      throw err;
    }
  }
});
export const getAllUsers = createAsyncThunk("auth/getAllUsers", async () => {
  try {
    let url = `${rootUrl}api/v1/user/get-users`;

    const response: any = await get(url);

    return response.data;
  } catch (err: any) {
    console.log({ errorz: err });
    if (err) {
      throw err;
    } else {
      throw err;
    }
  }
});
export const getAllLocations = createAsyncThunk(
  "auth/getAllLocations",
  async () => {
    try {
      let url = `${hubRootUrl}api/v1/profile/profile-detail?IsLoggedInUser=true`;
      const response: any = await get(url);

      return response.data;
    } catch (err: any) {
      console.log({ errorz: err });
      if (err) {
        throw err;
      } else {
        throw err;
      }
    }
  }
);
export const getAminitiesList = createAsyncThunk(
  "auth/getAminitiesList",
  async () => {
    try {
      let url = `${eventRootUrl}api/v1/inperson-event/myplace-amenities`;
      const response: any = await get(url);

      return response.data;
    } catch (err: any) {
      console.log({ errorz: err });
      if (err) {
        throw err;
      } else {
        throw err;
      }
    }
  }
);
const initialState: AuthState = {
  loading: false,
  userId: "",
  userName: "",
  email: "",
  phoneNumer: "",
  token: "",
  refreshToken: "",
  isAuthenticated: false,
  allUsers: [],
  location: "",
  description: "",
  website: "",
  games: [],
  logo: "",
  coverImage: "",
  title: "",
  locations: [],
  aminitiesList: [],
  selectedLocation: null,
  AuthError: null,
  role: "",
  userStastics: null,
  isHubApproved: null,
  rejectDocument: null,
  rejectReason: null,

  // currentServerTime: null,
  // expires: null,
};

const AuthSlice: any = createSlice({
  name: "AuthSlice",
  initialState: initialState,
  reducers: {
    clearResults(state, action: any) {
    
     
     
      state.AuthError = null;
      state.loading = false;
    },
    UpdateRefreshToken(state, action: any) {
      // console.log({UpdateRefreshToken:action.payload})
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
    },
    onChangeSelectedLocation(state, action: any) {
      console.log({ loc: action.payload });
      state.selectedLocation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(Authenticate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Authenticate.fulfilled, (state, action: any) => {
      console.log("fulfilled");
      state.loading = false;
      state.userId = action.payload.response?.userId;
      state.userName = action.payload.response?.userName;
      state.email = action.payload.response?.email;
      state.phoneNumer = action.payload.response?.phoneNumer;
      state.token = action.payload.response?.token;
      state.refreshToken = action.payload.response?.refreshToken;
      state.role = action.payload.response?.role;
      state.isAuthenticated = true;
      createFirebaseLog(
        action.payload.response?.userId,
        action.payload.response?.userName
      );
      // state.currentServerTime = action.payload.response?.currentServerTime;
      // state.expires = action.payload.response?.expires;
      //
    });
    builder.addCase(Authenticate.rejected, (state, error: any) => {
      console.log({ error: error.error.message });
      state.AuthError = error.error.message;
      state.loading = false;
    });

    builder.addCase(getAllUsers.pending, (state) => {});
    builder.addCase(getAllUsers.fulfilled, (state, action: any) => {
      state.allUsers = action.payload.response.getUsers;
    });
    builder.addCase(getAllUsers.rejected, (state, error: any) => {});

    builder.addCase(getProfile.pending, (state) => {});
    builder.addCase(getProfile.fulfilled, (state, action: any) => {
      state.location = action.payload.response.location;
      state.description = action.payload.response.description;
      state.website = action.payload.response.website;
      state.logo = action.payload.response.logo;
      state.coverImage = action.payload.response.coverImage;
      state.title = action.payload.response.title;
      state.userStastics = action.payload.response.userStastics;
      state.isHubApproved = action.payload.response.isHubApproved;
      state.rejectDocument = action.payload.response.rejectDocument;
      state.rejectReason = action.payload.response.rejectReason;
    });
    builder.addCase(getProfile.rejected, (state, error: any) => {});

    builder.addCase(getAllLocations.pending, (state) => {});
    builder.addCase(getAllLocations.fulfilled, (state, action: any) => {
      state.locations = action.payload.response;
      console.log({ selectedLocation: state.selectedLocation });
      // state.selectedLocation = action.payload?.response[0];
    });
    builder.addCase(getAllLocations.rejected, (state, error: any) => {});
    builder.addCase(getAminitiesList.pending, (state) => {});
    builder.addCase(getAminitiesList.fulfilled, (state, action: any) => {
      state.aminitiesList = action.payload.response.amenities;
    });
    builder.addCase(getAminitiesList.rejected, (state, error: any) => {});
  },
});
export const { clearResults, onChangeSelectedLocation, UpdateRefreshToken } =
  AuthSlice.actions;
export default AuthSlice.reducer;
