import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post, get } from "../services/api";
// import { ProductInterface } from "../../utilities/Interface";
import { eventRootUrl, gameRootUrl } from "../../utilities/constants";
import moment from "moment";

interface EventState {
  loading: boolean;
  gameList: Array<any>;
  eventList: Array<any>;
  loadingEventList: boolean;
}

export const getEventList = createAsyncThunk(
  "event/getEventList",
  async (userId: string) => {
    try {
      let url = `${eventRootUrl}api/v1/challonge/tournament-details-list?GameId=-1&hostUserId=${userId}`;

      const response: any = await get(url);

      return response.data;
    } catch (err: any) {
      console.log({ errorz: err });
      if (err) {
        throw err;
      } else {
        throw err;
      }
    }
  }
);

export const getGameList = createAsyncThunk("event/getGameList", async () => {
  try {
    let url = `${gameRootUrl}/api/v1/game/game-list?Page=1&PageSize=5&PageGameSize=10`;

    const response: any = await get(url);

    return response.data;
  } catch (err: any) {
    console.log({ errorz: err });
    if (err) {
      throw err;
    } else {
      throw err;
    }
  }
});

const initialState: EventState = {
  loading: false,
  gameList: [],
  eventList: [],
  loadingEventList: false,
};

const EventsSlice = createSlice({
  name: "EventsSlice",
  initialState: initialState,
  reducers: {
    clearResults() {},
  },
  extraReducers: (builder) => {
    builder.addCase(getGameList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGameList.fulfilled, (state, action: any) => {
      state.loading = false;

      // let arr: any = [];
      let gameList = action.payload.response.results;
      // gameList = gameList.forEach((data: any) => {
      //   data.gameDetails.forEach((details: any) => arr.push(details));
      // });
      state.gameList = gameList;
    });
    builder.addCase(getGameList.rejected, (state, error: any) => {
      state.loading = false;
    });
    builder.addCase(getEventList.pending, (state) => {
      state.loadingEventList = true;
    });
    builder.addCase(getEventList.fulfilled, (state, action: any) => {
      state.loadingEventList = false;
      let list = action.payload.response;

      state.eventList = list;
    });
    builder.addCase(getEventList.rejected, (state, error: any) => {
      state.loadingEventList = false;
    });
  },
});
export const { clearResults } = EventsSlice.actions;
export default EventsSlice.reducer;
