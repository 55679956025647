// import * as authe from "../app/modules/Auth/_redux/authRedux";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { UpdateRefreshToken, clearResults } from "redux/slices/Auth";
import axios from "axios";
import { getJwtToken } from "utilities";
import { notification } from "antd";
import { store } from "../store";
import { rootUrl } from "../../utilities/constants";

const REFRESH_TOKEN_URL = `${rootUrl}api/v1/token/refresh`;
const instance = axios.create({
  headers: {
    platform: "web",
    appVersion: "0",
  },
});
const UNAUTHORIZED = 401;
instance.interceptors.request.use(
  (config) => {
    let { token, refreshToken } = getJwtToken();
    const userjwtDecode = token ? jwtDecode(token) : 0;
    let expDateTime = moment(userjwtDecode.exp * 1000);
    let currentDateTime = moment.now();
    const isExpired = expDateTime.diff(currentDateTime, "minutes") < 2;
    // console.log({token,expDateTime,currentDateTime,userjwtDecode, isExpired, diff:expDateTime.diff(currentDateTime, "minutes")})
    if (token && !isExpired) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    }
    var headers = new Headers();
    headers.append("accept", "*/*");
    headers.append("Content-Type", "application/json");
    if (
      isExpired &&
      config.url.split("/")[config.url.split("/").length - 1] !== "access-token"
    ) {
      return new Promise((resolve) => {
        var raw = JSON.stringify({
          token: token,
          refreshToken: refreshToken,
        });
        const res = fetch(REFRESH_TOKEN_URL, {
          method: "POST",
          headers: headers,
          body: raw,
          redirect: "follow",
        });
        res
          .then((r) => r.json())
          .then((result) => {
            console.log({ result });
            config.headers.Authorization = `Bearer ${result.response.token}`;
            store.dispatch(UpdateRefreshToken(result.response));
          })
          .then(() => {
            resolve(config);
          })
          .catch(() => {
            localStorage.clear();
            store.dispatch(clearResults());
          });
      });
    }
    return config;
  },
  (err) => Promise.reject(err)
);
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response?.status;
    if (error?.response?.headers["content-type"] == "text/html") {
      notification.error({ message: "Html type error thrown" });
    } else {
      if (status === UNAUTHORIZED) {
        localStorage.clear();
        store.dispatch(clearResults());
      } else if (!error.response) {
        notification.error({ message: "Network Error" });
        // localStorage.clear();
        // store.dispatch(clearResults());
      }
      return Promise.reject(error);
      //   try {
      //     const { status } = error?.response;
      //     if (status === UNAUTHORIZED) {
      //       localStorage.clear();
      //       store.dispatch(clearResults());
      //     }
      //     return Promise.reject(error);
      //   } catch (err) {
      //     return Promise.reject(error);
      //   }
    }
  }
);

export default instance;
