import { useEffect, memo, useState } from "react";
import { HiPlus } from "react-icons/hi";
import "../../../../../assets/css/App.css";
import { mode } from "@chakra-ui/theme-tools";

import {
  FormErrorMessage,
  ModalBody,
  Heading,
  useColorModeValue,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Button,
  Box,
  Input,
  ModalFooter,
  Icon,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import moment from "moment";

import { BsPlus } from "react-icons/bs";
interface Props {
  next: Function;
  handleBack: () => void;
  parantState: any;
  isReview:boolean
}

function Step_6(props: Props) {
  const { next, handleBack, parantState,isReview } = props;

  const textColor = useColorModeValue("black.50", "white");
  const textColorBrand = useColorModeValue("blue.100", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [selected, setSelected] = useState<any>("minutes");

  const buttonColorBrand = "blue.200";

  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const [show, setShow] = useState({
    addDayBtn: true,
    addDateAndTime: false,
    showSelectedDateTime: false,
  });
  const [state, setState] = useState({
    ...parantState,
    start_Time: "",
    end_Time: "",
    startDate: "",
    endDate: "",
    checkInAccessWeightage: "30",
  });
  const [invalidState, setInvalidState] = useState({
    startDateInvalid: false,
    endDateInvalid: false,
    start_TimeInvalid: false,
    end_TimeInvalid: false,
    checkTimeEquality: false,
    checkSameTime: false,
  });
  useEffect(() => {
    if (parantState.start_Time) {
      setState(() => ({ ...parantState }));
      setShow((prvState) => ({
        ...prvState,
        addDayBtn: false,
        addDateAndTime: false,
        showSelectedDateTime: true,
      }));
    }
  }, [parantState]);
  const updateState = (val: any, prop: string, invalidProp?: string) => {
    setState((prvState: any) => ({ ...prvState, [prop]: val }));
    if (invalidProp) {
      setInvalidState((prvState: any) => ({
        ...prvState,
        [invalidProp]: false,
      }));
    }
  };

  const timeEqualityFunction = (star_time:any ,end_time:any) =>{
    const startTime = moment(star_time, "HHmmss");
      const endTime = moment(end_time, "HHmmss");
  
      if (!startTime.isSameOrBefore(endTime))  {
        setInvalidState((prvState) => ({
          ...prvState,
          checkTimeEquality: true,
        }))
        return false;
      }else if(startTime.isSame(endTime)){
        setInvalidState((prvState) => ({
          ...prvState,
          checkSameTime: true,
        }))
        return false;
      }
      return true
  }

  const checks = () => {
    if (state.startDate === "") {
      setInvalidState((prvState) => ({
        ...prvState,
        startDateInvalid: true,
      }));
      return false;
    } else if (state.start_Time === "") {
      setInvalidState((prvState) => ({
        ...prvState,
        start_TimeInvalid: true,
      }));
      return false;
    } else if (state.end_Time === "") {
      setInvalidState((prvState) => ({
        ...prvState,
        end_TimeInvalid: true,
      }));
      return false;
    } else if (state.start_Time != "" && state.end_Time != ""){
      return timeEqualityFunction(state.start_Time, state.end_Time)
    }
    return true;
  };
  const handleNext = () => {
    console.log({ state });
    if (checks()) {
      let newState = {
        ...state,
        checkInAccessWeightage: state.checkInAccessWeightage + " " + selected,
      };
      console.log({ newState });
      next(newState);
    }
  };

  const handleBackFunction = () => {
    if(isReview){
      handleBack();
      return
    }
    if (show.addDayBtn) {
      handleBack();
    } else if (show.addDateAndTime) {
      setShow((prvState) => ({
        ...prvState,
        addDayBtn: true,
        addDateAndTime: false,
      }));
    } else if (show.showSelectedDateTime) {
      setShow((prvState) => ({
        ...prvState,
        addDayBtn: false,
        addDateAndTime: true,
        showSelectedDateTime: false,
      }));
    }
  };
  const handleNextFunction = () => {
    console.log(show.addDateAndTime);
    if (show.addDateAndTime) {
      if (checks()) {
        setShow((prvState) => ({
          ...prvState,
          addDayBtn: false,
          addDateAndTime: false,
          showSelectedDateTime: true,
        }));
      }
    } else {
      handleNext();
    }
  };
  return (
    <>
      <ModalBody
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
            
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#D2D2D2",
            borderRadius: "24px",
          },
        }}
        position={"relative"}
        py="20px"
        px="50px"
      >
        {show.addDateAndTime && (
          <FormControl minH={"476px"} borderColor={borderColor}>
            <Flex justifyContent={"space-between"}>
              <FormControl isInvalid={invalidState.startDateInvalid}>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Start Date<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  borderColor={invalidState.startDateInvalid ? "red" : null}
                  onChange={(e) =>
                    updateState(e.target.value, "startDate", "startDateInvalid")
                  }
                  value={state.startDate}
                  isRequired={true}
                  variant="auth"
                  placeholder="Start Date"
                  fontSize="sm"
                  type="date"
                  maxW={"100%"}
                  borderRadius={8}
                  // mb="10px"
                  fontWeight="500"
                  size="lg"
                />
                {invalidState.startDateInvalid && (
                  <FormErrorMessage> Date is required.</FormErrorMessage>
                )}
              </FormControl>

              {/* <FormControl isInvalid={invalidState.endDateInvalid}>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  End Date<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  borderColor={invalidState.endDateInvalid ? "red" : null}
                  onChange={(e) =>
                    updateState(e.target.value, "endDate", "endDateInvalid")
                  }
                  value={state.endDate}
                  isRequired={true}
                  variant="auth"
                  placeholder="Start Date"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="date"
                  maxW={"100%"}
                  borderRadius={8}
                  // mb="10px"
                  fontWeight="500"
                  size="lg"
                />
                {invalidState.endDateInvalid && (
                  <FormErrorMessage> End Date is required.</FormErrorMessage>
                )}
              </FormControl> */}
            </Flex>

            <Flex
              justifyContent={"space-between"}
              alignItems={"baseline"}
              mt="10px"
            >
              <FormControl isInvalid={
                  invalidState.start_TimeInvalid || 
                  invalidState.checkSameTime
                  }>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Start Time<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  borderColor={invalidState.start_TimeInvalid ? "red" : null}
                  onChange={(e) => {
                    updateState(
                      e.target.value,
                      "start_Time",
                      "start_TimeInvalid"
                    );
                    setInvalidState((prvState) => ({
                      ...prvState,
                      checkTimeEquality: false,
                      checkSameTime:false,
                    }))
                  }}
                  value={state.start_Time}
                  isRequired={true}
                  variant="auth"
                  placeholder="PUBG Championship"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="time"
                  borderRadius={8}
                  maxW={"95%"}
                  fontWeight="500"
                  size="lg"
                />
                {(invalidState.start_TimeInvalid && !invalidState.checkTimeEquality && !invalidState.checkSameTime) && (
                  <FormErrorMessage> Start time is required.</FormErrorMessage>
                )}
                {(invalidState.checkSameTime && !invalidState.start_TimeInvalid && !invalidState.checkTimeEquality) && (
                  <FormErrorMessage>Start time cannot be equal to end time.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={
                invalidState.end_TimeInvalid ||
                invalidState.checkTimeEquality
                }>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  End Time<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  borderColor={invalidState.end_TimeInvalid ? "red" : null}
                  onChange={(e) => {
                    updateState(e.target.value, "end_Time", "end_TimeInvalid");
                    setInvalidState((prvState) => ({
                      ...prvState,
                      checkTimeEquality: false,
                      checkSameTime:false,
                    }))
                  }}
                  value={state.end_Time}
                  isRequired={true}
                  variant="auth"
                  placeholder="PUBG Championship"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="time"
                  borderRadius={8}
                  maxW={"100%"}
                  fontWeight="500"
                  size="lg"
                />
                {invalidState.end_TimeInvalid && (
                  <FormErrorMessage> End time is required.</FormErrorMessage>
                )}
                {(invalidState.checkTimeEquality && !invalidState.start_TimeInvalid && !invalidState.checkSameTime) && (
                  <FormErrorMessage>End time should not be less then start time.</FormErrorMessage>
                )}
              </FormControl>
            </Flex>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
              mt="8px"
            >
              Check-In access<Text color={brandStars}>* {""}</Text>( before
              event start time )
            </FormLabel>
            <Flex mt="10px" maxW="100%">
              <FormControl w={"80px"} isInvalid={false}>
                <NumberInput
                  onChange={(e) => updateState(e, "checkInAccessWeightage")}
                  value={state.checkInAccessWeightage}
                  w={"80px"}
                  height="40px"
                  borderRadius={"16px"}
                >
                  <NumberInputField borderRadius={"16px"} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <Select
                height="40px"
                borderRadius={"16px"}
                onChange={(e) => setSelected(e.target.value)}
                value={selected}
                p="0px"
                w={"120px"}
                ml="7px"
              >
                <option value="minutes">minutes</option>
                <option value="hours">hours</option>
                <option value="days">days</option>
              </Select>
            </Flex>
          </FormControl>
        )}

        {show.addDayBtn && (
          <>
          <Heading color={textColor} w="full" fontWeight={"600"} fontSize="24px">
          Date & Time
          </Heading>
          <Flex
            justifyContent={"center"}
            minH={"476px"}
            flexDir="column"
            alignItems="center"
          >
            <Button
              onClick={() =>
                setShow((prvState) => ({
                  ...prvState,
                  addDayBtn: false,
                  addDateAndTime: true,
                }))
              }
              fontSize="sm"
              variant="brand"
              fontWeight="700"
              w="160px"
              h="50"
              bg="transparent"
              borderRadius={"100px"}
              border={`0.45px solid #E2E2E2`}
              color={"#298BE2"}
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
              borderColor={"black"}
              leftIcon={<Icon boxSize={"25px"} as={BsPlus} />}
            >
              Add Day
            </Button>
            <Text mt="15px" color={"#9299B0"}>
              Add days and time for your event
            </Text>
          </Flex>
          </>
        )}

        {show.showSelectedDateTime && (
          <Box
            minH={"476px"}
            cursor="pointer"
            onClick={() => {
              setShow((prvState) => ({
                ...prvState,
                addDayBtn: false,
                addDateAndTime: true,
                showSelectedDateTime: false,
              }));
            }}
          >
            <Heading color={textColor} fontSize="24px" fontWeight={"600"}>
              Date and Time.
            </Heading>
            <Flex w="full" mt="24px">
              <Card
                px="20px"
                py="5px"
                shadow={"none"}
                border="1px solid #ECF1F4"
              >
                <Text>
                  {" "}
                  Day 1-
                  {moment(state.startDate, "YYYY-MM-DD HH:mm:ss").format(
                    "dddd"
                  )}
                </Text>
                <Text>
                  {moment(state.startDate).format("DD/MM/YYYY")},{" "}
                  {moment(state.start_Time, "HHmmss").format("hh:mm a")} -{" "}
                  {moment(state.end_Time, "HHmmss").format("hh:mm a")}
                </Text>
              </Card>
            </Flex>
          </Box>
        )}
      </ModalBody>
      <ModalFooter px="50px">
        <Flex w="full" justifyContent={"space-between"}>
          <Button
            onClick={handleBackFunction}
            fontSize="sm"
            variant="brand"
            fontWeight="700"
            w="276px"
            h="50"
            bg="transparent"
            borderRadius={8}
            border={`1px solid `}
            color={"black"}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
            borderColor={"black"}
          >
            Back
          </Button>

          {!show.addDayBtn && (
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="276px"
              h="50"
              onClick={() => handleNextFunction()}
              borderRadius={8}
              bg={buttonColorBrand}
            >
              Continue
            </Button>
          )}
        </Flex>
      </ModalFooter>
    </>
  );
}
export default memo(Step_6);
