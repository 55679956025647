import React, { useMemo, memo } from "react";
import { HiOutlineGlobe, HiOutlineChevronRight } from "react-icons/hi";
import { IconType } from "react-icons";
import {
  useDisclosure,
  Avatar,
  Flex,
  Text,
  HStack,
  Icon,
  Box,
  Button,
  
} from "@chakra-ui/react";

import pubgAvtar from "assets/img/global/pubgAvtar.png";

import Card from "components/card/Card";
interface Props {
  cardBg: string;
  heading: string;
  handleNext?: () => void;
  iconName: IconType;
  [x: string]: any;
  btn_1_txt: string;
  btn_2_txt: string;
}
  function EventCard2(props: Props) {
  const textColorSecondary = "gray.400";
  const { cardBg, heading,   iconName, btn_1_txt, btn_2_txt,handleNext, ...rest } =
    props;

  return (
    <Card
      w="48%"
      border={"1px solid #E3E4E5"}
      boxShadow={"xl"}
      bg={cardBg}
      p="10px"
      mt="20px"
      borderRadius={"8px"}
      {...rest}
    >
      <Flex py='10px' alignItems={"center"}>
        <HStack w="100%">
          <Box>
            <Avatar size="md" name="Kola Tioluwani" src={pubgAvtar} />{" "}
          </Box>
          <Box>
            <Text fontSize={"15px"}>{heading}</Text>
          </Box>
        </HStack>
      </Flex>
      <HStack justifyContent="space-between">
        <Button
        onClick={handleNext}
        border='1px solid #F0F3F6'
          maxH="32px"
          bg="white"
          color={"black"}
          fontWeight="regular"
          fontSize="14px"
          minW="100px"
          borderRadius={"8px"}
        >
          {btn_1_txt}
        </Button>
        <Button
        border='1px solid #F0F3F6'
          bg="white"
          maxH="32px"
          color={"black"}
          fontWeight="regular"
          fontSize="14px"
          minW="100px"
          borderRadius={"8px"}
        >
          {btn_2_txt}
        </Button>
      </HStack>
    </Card>
  );
}
export default memo(EventCard2);
