/* eslint-disable */

import React, { memo, useState, useMemo } from "react";
import Countdown from "react-countdown";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  chakra,
  PinInput,
  PinInputField,
  HStack,
  useToast,
} from "@chakra-ui/react";
// Custom components
import { rootUrl } from "utilities/constants";
import { get } from "redux/services/api";
function millisToMinutesAndSeconds(millis: any) {
  var minutes = Math.floor(millis / 60000);
  var seconds: any = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}
function step_3(props: { next: () => void; parantState: any }) {
  const { next, parantState } = props;
  // Chakra color mode
  const toast = useToast();

  const textColor = useColorModeValue("black.50", "white");
  const textColorSecondary = "#707997";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("#19BAEE", "#19BAEE");
  const buttonColorBrand = useColorModeValue("blue.200", "blue.200");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [show, setShow] = React.useState(false);
  const [countDown, setCountDown] = React.useState(true);

  const [countdownkey, setcountdownkey] = React.useState(
    Math.random().toString()
  );

  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    pinCode: "",
  });

  const [invalidState, setInvalidState] = useState({
    pinCodeInvalid: false,
  });
  const updateState = (val: string, prop: string) => {
    setState((prvState) => ({ ...prvState, [prop]: val }));
  };
  const checks = () => {
    if (state.pinCode === "" && state.pinCode.length < 6) {
      setInvalidState((prvState) => ({ ...prvState, pinCodeInvalid: true }));
      return false;
    }

    return true;
  };
  const submit = async () => {
    if (checks()) {
      setIsLoading(true);
      let url = `${rootUrl}api/v1/account/validate-accesscode-for-signup?username=${parantState.EmailAddress}&accessCode=${state.pinCode}&IsAppleLogin=false&accountType=hub`;
      let result: any = await get(url);
      setIsLoading(false);
      console.log({ result });
      if (result.data.status == true) {
        next();
        toast.closeAll()
        toast({
          title: "Varified",
          description: result.data.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast.closeAll()
        toast({
          title: "Failed",
          description: "Validation Faild",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  const resend = async () => {
    setcountdownkey(Math.random.toString());
    setCountDown(true);
    let url = `${rootUrl}api/v1/account/send-accesscode-for-signup?username=${parantState.EmailAddress}&IsSms=true&IsEmail=true&IsAppleLogin=false&accountType=hub`;
    let result: any = await get(url);
    if (result.data.status == true) {
      toast.closeAll()
      toast({
        title: "Sent",
        description: "Verification code sent",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <Flex
      maxW={{ base: "100%", md: "max-content" }}
      w="100%"
      mx={{ base: "auto", lg: "0px" }}
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      mb={{ base: "30px", md: "60px" }}
      px={{ base: "25px", md: "0px" }}
      mt="24px"
      flexDirection="column"
    >
      <Box me="auto">
        <Heading color={textColor} fontSize="36px" w={400} mb="10px">
        Enter Security Code{" "}
        </Heading>
      </Box>
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "420px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <FormControl mt={5}>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="16px"
            fontWeight="400"
            color={textColorSecondary}
            mb="8px"
          >
            Please enter the 6-digits verification code that was sent to your
            email. This code is valid for 30 minutes.
          </FormLabel>
          <HStack w="100%" justifyContent={"space-between"} mt="20px">
            <PinInput
              value={state.pinCode}
              onChange={(e) => updateState(e, "pinCode")}
              size={"lg"}
              placeholder=""
              variant="outline"
              focusBorderColor={textColorBrand}
            >
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
        </FormControl>
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          maxW="100%"
          mt="30px"
        >
          <Button
            onClick={resend}
            cursor={"pointer"}
            disabled={countDown}
            color={countDown ? textColorSecondary : textColorBrand}
            as="span"
            bg={"transparent"}
            _hover={{ bg: "transparent" }}
            m="0px"
            p="0"
            mr="5px"
            fontWeight="500"
          >
            <Text textDecorationLine={"underline"}>
              Didn’t Received The Code?
            </Text>
          </Button>
          <>
            {useMemo(() => {
             return <Countdown
                key={countdownkey}
                date={Date.now() + 180000}
                intervalDelay={1000}
                precision={0}
                onComplete={() => setCountDown(false)}
                renderer={(props: any) => (
                  <div>{millisToMinutesAndSeconds(props.total)}</div>
                )}
              />;
            }, [countdownkey])}
          </>
        </Flex>
        <Button
          isLoading={isLoading}
          loadingText="Verifying..."
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="100%"
          // float={'right'}
          display={'flex'}
          justifySelf='flex-end'
          alignSelf={'flex-end'}
          h="50"
          mt="50px"
          
          mb="24px"
          borderRadius={"12px"}
          bg={buttonColorBrand}
          onClick={submit}
        >
          Verify
        </Button>
      </Flex>
    </Flex>
  );
}

export default memo(step_3);
