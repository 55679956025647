// Chakra imports
import { Text, useDisclosure, chakra } from "@chakra-ui/react";
import Card from "components/card/Card";
import ViewHubRejection from "../components/ViewHubRejection";
import { useDispatch, useSelector } from "react-redux";

export default function ProfileApproved() {
  // Chakra Color Mode
  const textColorSecondary = "gray.200";
  const textColorSecondary_2 = "gray.300";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isHubApproved, rejectDocument, rejectReason } = useSelector(
    (state: any) => state.Auth
  );
  return (
    <Card borderRadius={"10px"}>
      <ViewHubRejection onOpen={onOpen} onClose={onClose} isOpen={isOpen} />
      <Text fontSize={"14px"}>Profile Approval Status</Text>
      <Text fontSize={"14px"} mt="10px" 
   
      color={isHubApproved==="Rejected"? "#FF5247": isHubApproved==="Pending"? "#F5901A": "#31D0AA"}

      >
        {isHubApproved + " "}
        {isHubApproved === "Rejected" && (
          <chakra.span color={"#298BE2"} cursor="pointer" onClick={onOpen}>
            (View Detail)
          </chakra.span>
        )}
      </Text>
    </Card>
  );
}
