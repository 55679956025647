import React, { useState, useCallback, useEffect } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  GridItem,
  useColorModeValue,
  SimpleGrid,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import Banner from "views/admin/ViewHubDetails/components/Banner";
import ProfileApproved from "views/admin/ViewHubDetails/components/ProfileApproved";
import AboutMyHub from "views/admin/ViewHubDetails/components/AboutMyHub";
import PhotosAndVideos from "views/admin/ViewHubDetails/components/PhotosAndVideos";
import { get } from "redux/services/api";
import { hubRootUrl } from "utilities/constants";
import ViewLocation from "views/admin/ViewHubDetails/components/ViewLocation";
import FullCalendar from "views/admin/ViewHubDetails/components/FullCalendar";
import ReviewDocuments from "views/admin/ViewHubDetails/components/ReviewDocuments";
import LocationApproval from "views/admin/ViewHubDetails/components/LocationApproval";
import { Spin } from "antd";
import { useHubDetails } from "./hooks";
export default function Marketplace(props: any) {
  // Chakra Color Mode
  const [tabIndex, setTabIndex] = useState(0);
  const [hubId, sethubId] = useState<string>("");
  const { data, isLoading, refetch } = useHubDetails(hubId);
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [state, setState] = useState({
    hubLocations: [],
    attachments:[],
    hubDocuments:[]
  });
  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();
  const [selectedLocation, setselectedLocation] = useState({
    locationId: null,
    hubTimings: [],
    hubAmenities: [],
    attachments: [],
    hostGuidelinesDetails: [],
  });

  useEffect(() => {
    if (data?.data.response) {
      setState(data.data.response);
      let location = data.data.response?.hubLocations[0] ?? selectedLocation;
      setselectedLocation(location);
    }
  }, [data]);
  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const hub_Id = query.get("hubId");
    sethubId(hub_Id);
  }, []);
  const handleTabsChange = useCallback(
    (index: number) => {
      setTabIndex(index);
    },
    [tabIndex]
  );

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Spin spinning={isLoading} tip="Loading...">
        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem colSpan={12} borderRadius="10px">
            <Banner
              state={state}
              onOpenDrawer={onOpenDrawer}
              handleTabsChange={handleTabsChange}
              tabIndex={tabIndex}
              selectedLocation={selectedLocation}
            />
            {/* <Box w="full" mt="20px">
              <Select
                value={selectedLocation?.locationId}
                onChange={onSelectLocation}
                placeholder="Select Location"
              >
                {state?.hubLocations.map((loc: any) => {
                  return <option value={loc.locationId}>{loc.address}</option>;
                })}
              </Select>
            </Box> */}
            {/* <ViewLocation
              selectedLocation={selectedLocation}
              isOpen={isOpenDrawer}
              onOpen={onOpenDrawer}
              onClose={onCloseDrawer}
            /> */}
            <Grid templateColumns="repeat(12, 1fr)" gap={4} mt="20px">
              <GridItem colSpan={3}>
                <ProfileApproved
                  hubId={hubId}
                  refetch={refetch}
                  state={state}
                />
                <LocationApproval
                  refetch={refetch}
                  state={state}
                  hubLocations={state?.hubLocations}
                  selectedLocation={selectedLocation}
                />
               
                <AboutMyHub state={state} />
                {/* <PhotosAndVideos /> */}
              </GridItem>
              <GridItem colSpan={9} borderRadius="10px">
                <FullCalendar />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </Spin>
    </Box>
  );
}
