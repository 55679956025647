// import react, { useEffect, useState } from "react";
// import { Box, SimpleGrid } from "@chakra-ui/react";
// import ComplexTable from "./components/ComplexTable";
// import tableDataComplex from "./variables/tableDataComplex";
// import { useDispatch, useSelector } from "react-redux";
// import { getAdminsList } from "redux/slices/AdminsTableLists";
// export default function Settings() {

//   const dispatch: any = useDispatch();
//   const { adminsListloading, adminsList } = useSelector(
//     (state: any) => state.AdminsTableLists
//   );
//   const [tableData, setTableData] = useState([]);
//   const [pagination, setPagination] = useState({
//     pageIndex: 0,
//     pageSize: 10,
//     pageCount: -1, // -1 allows the table to calculate the page count for us via instance.getPageCount()
//   });
//   useEffect(() => {
//     let payload = { PageNumber: 1, PageSize: 100, Flag: 0 };
//     dispatch(getAdminsList(payload));
//   }, []);
//   useEffect(() => {
//     if (adminsList) {
//       setTableData(adminsList);
//     }
//     console.log({ adminsList });
//   }, [adminsList]);
//   return (
//     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       <SimpleGrid
//         mb="20px"
//         columns={{ sm: 1, md: 1 }}
//         spacing={{ base: "20px", xl: "20px" }}
//       >
//         <ComplexTable
//           tableData={tableData}
//           usersListloading={adminsListloading}
//           pagination={pagination}
//           setPagination={setPagination}
//         />
//       </SimpleGrid>
//     </Box>
//   );
// }
import { Box, SimpleGrid ,Flex} from "@chakra-ui/react";
import react, { useEffect, useState } from "react";
import ComplexTable from "./components/ComplexTable";
import tableDataComplex from "./variables/tableDataComplex";
import { useDispatch, useSelector } from "react-redux";
import { getEventList } from "redux/slices/AdminsTableLists";
import { useAdmin } from "./hooks";
import { Table, Pagination } from "antd";
const DEFAULT_PAGE_SIZE = 10;
export default function EventsList() {
  const [filters, setFilters] = useState({
    page: 1,
    per_page: DEFAULT_PAGE_SIZE,
  });

  const { data, isLoading, isSuccess, isError, error, isFetching } = useAdmin({
    PageNumber: filters.page,
    PageSize: filters.per_page,
  });

 
  const userTransactions = data?.response?.userList || [];
  const totalTransactions = data?.response?.totalCount || 0;
  // const currentPage = data?.data?.current_page || 0;
  const handlePageChange = (page: number, size: number) => {
    console.log({ page, size });
    setFilters({ ...filters, page, per_page: size });
  };

  const handleTableChange = (
    pagination: any,
    tableFilters: any,
    sorter: any
  ) => {
    setFilters({
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Title",
    //   dataIndex: "userName",
    //   key: "userName",
    // },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <div>
          <Table
            rowKey={(record:any) => record.id}
            columns={columns}
            dataSource={userTransactions}
            onChange={handleTableChange}
            pagination={false}
            loading={isLoading || isFetching}
          />
          {totalTransactions > 0 ? (
            <Flex justifyContent={"flex-end"} mt="10px">
              <Pagination
                // showLessItems
                defaultPageSize={DEFAULT_PAGE_SIZE}
                total={totalTransactions}
                showSizeChanger={true}
                current={filters.page}
                onChange={handlePageChange}
                pageSizeOptions={[10, 20, 30, 50]}
                pageSize={filters.per_page}
              />
            </Flex>
          ) : null}
        </div>
      </SimpleGrid>
    </Box>
  );
}
