import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, post } from "../services/api";
// import { ProductInterface } from "../../utilities/Interface";
import { hubAdminUrl } from "../../utilities/constants";

interface ProductsState {
  loading: boolean;
  TicketsList: Array<any>;
}
export const getTicketsList = createAsyncThunk(
  "get-tickets",
  async (data: any) => {
    let payload = {
      data: data,
      url: `${hubAdminUrl}/api/v1/ticket/get-tickets`,
    };
    try {
      const response = await post(payload);
      return response.data.response;
    } catch (err: any) {
      console.log({ errorz: err.message });
      throw err;
    }
  }
);

const initialState: ProductsState = {
  loading: false,
  TicketsList: [],
};

const Ticketing = createSlice({
  name: "Ticketing",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTicketsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTicketsList.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TicketsList = action.payload;

    });
    builder.addCase(getTicketsList.rejected, (state, error: any) => {
      state.loading = false;
    });
  },
});

export default Ticketing.reducer;
