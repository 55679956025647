import {
  Box,
  SimpleGrid,
  Flex,
  Text,
  Button,
  Icon,
  HStack,
  Avatar,
  Image,
  AvatarGroup,
  Badge,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import react, { useEffect, useState } from "react";
import ComplexTable from "./components/ComplexTable";
import tableDataComplex from "./variables/tableDataComplex";
import { useDispatch, useSelector } from "react-redux";
import { getEventList } from "redux/slices/AdminsTableLists";
import { useEvent, useDashboard } from "./hooks";
import { useHistory, useLocation } from "react-router-dom";
import Card from "components/card/Card";
import { BiMessageAltDots } from "react-icons/bi";
import { Table, Pagination, Input, Spin } from "antd";
import { HiDotsVertical } from "react-icons/hi";
import avatar_New from "assets/img/global/avatar_New.png";
import pubg_banner from "assets/img/global/pubg_banner.png";
import closeMnue from "assets/img/closeMnue.png";
import openMenu from "assets/img/openMenu.png";
import { useAdmin } from "../HubsList/hooks";
import { format } from 'date-fns';
const { Search } = Input;

const DEFAULT_PAGE_SIZE = 10;
export default function EventsList() {
  const { userId, userName, logo } =
  useSelector((state: any) => state.Auth);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const [openHubProfile, setopenHubProfile] = useState(false);
  const { data: DashboardData, isLoading: DashboardLoading } = useDashboard();
  const [search, setSearch] = useState({
    page: 1,
    per_page: 100,
    search: "",
  });
  const [filters, setFilters] = useState({
    page: 1,
    per_page: DEFAULT_PAGE_SIZE,
    search: "",
  });
  const [statsArr, setstatsArr] = useState([]);
  const [filtersHub, setFiltersHub] = useState({
    page: 1,
    per_page: 50,
    search: "",
    Status: 0,
  });
  const onSearchHub = (value: string) => {
    const name = value?.toString() || "";

    setFiltersHub({
      ...filtersHub,
      search: name,
      page: 1,
    });
  };
  const {
    data: hubData,
    isLoading: hubLoading,
    isSuccess: hubIsSuccess,
    isError: hubisError,
    error: huberror,
    isFetching: hubisFetching,
  } = useAdmin({
    PageNumber: filtersHub.page,
    PageSize: filtersHub.per_page,
    search: filtersHub.search,
    Status: filtersHub.Status,
  });
  const { data: searchData, isLoading: searching } = useAdmin({
    PageNumber: search.page,
    PageSize: search.per_page,
    search: search.search,
    Status: filtersHub.Status,
  });
  const { data, isLoading, isSuccess, isError, error, isFetching } = useEvent({
    PageNumber: filters.page,
    PageSize: filters.per_page,
    search: filters.search,
  });

  const [tableData, setTableData] = useState([]);
  const userTransactions = data?.response?.superAdminEventInfos || [];
  const totalTransactions = data?.response?.totalCount || 0;
  // const currentPage = data?.data?.current_page || 0;
  const handlePageChange = (page: number, size: number) => {
    console.log({ page, size });
    setFilters({ ...filters, page, per_page: size });
  };

  const handleTableChange = (
    pagination: any,
    tableFilters: any,
    sorter: any
  ) => {
    setFilters({
      ...filters,
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
    });
  };
  const navigate = (info: any) => {
    console.log({ info });
    history.push({
      pathname: "/admin/BracketPortal",
      state: { eventDetail: info },
      search: `?eventId=${info.id}`,
    });
  };
  const columns = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
      width: "300px",
      render: (text: any, record: any) => (
        <Flex
          cursor={"pointer"}
          justifyContent="flex-start"
          alignItems={"center"}
          onClick={() => navigate(record)}
          // maxW="190px"
        >
          <Image src={record.coverPath} w="87px" h="52px" />
          <Text
            // w="50px"
            // maxW="100px"
            noOfLines={1}
            ml="20px"
            color="#298BE2"
            fontWeight={"500"}
            fontSize="12px"
            textDecorationLine={"underline"}
          >
            {record.eventName}
          </Text>
        </Flex>
      ),
    },

    {
      title: "Event Type",
      dataIndex: "eventType",
      key: "eventType",
    },
    {
      title: "Timings",
      dataIndex: "timings",
      key: "timings",
      // width: "150px",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text: any, record: any) => {
        const formattedDate = format(new Date(record.date), 'MMMM d, yyyy');
        return (
          <Text>
            {formattedDate}
          </Text>
        );
      },
    },

    {
      title: "Participants",
      dataIndex: "participants",
      key: "participants",

      render: (text: any, record: any) => (
        <AvatarGroup colorScheme="orange" size="sm" max={4}>
          {record.participants?.map((url: string) => {
            return <Avatar name="" src={url} />;
          })}
        </AvatarGroup>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => {
        return (
          <Badge colorScheme={getStatusColor(record.status)}>
            {record.status}
          </Badge>
        );
      },
    },
  ];
  const getStatusColor = (status: string) => {
    if (status === "Open") {
      return "red";
    } else if (status == "Closed") {
      return "green";
    } else {
      return "blue";
    }
  };
  const onSearch = (value: string) => {
    const name = value?.toString() || "";
    setFilters({
      ...filters,
      search: name,
      page: 1,
    });
  };

  const handlePressKey = (e:any) => {
    const name = e?.target.value.toString() || "";
    if (e.key === 'Enter') {
      setFilters({
        ...filters,
        search: name,
        page: 1,
      });
    }
  }
  useEffect(() => {
    if (DashboardData) {
      console.log({ DashboardData });
      let arr = [
        {
          heading: "Total Users",
          stats: DashboardData.response.totalUsers,
          textColor: "#7FB341",
          bgColor: "#EEFFEF",
        },
        {
          heading: "Active Users",
          stats: DashboardData.response.verifiedUsers,
          textColor: "#5B59A7",
          bgColor: "#F2F0FEA1",
        },
        {
          heading: "Non-Active Users",
          stats: DashboardData.response.unverifiedUsers,
          textColor: "#14466C",
          bgColor: "#EBF3FFC2",
        },
        {
          heading: "Total Games",
          stats: DashboardData.response.totalGames,
          textColor: "#FF611D",
          bgColor: "#FEF1EBD4",
        },
        {
          heading: "Total Events",
          stats: DashboardData.response.totalEvents,
          textColor: "#FF1D1D",
          bgColor: "#FF1D1D14",
        },
      ];
      setstatsArr(arr);
    }
  }, [DashboardData]);

  const navigateHub = (hubId: any) => {
    console.log({ hubId });
    history.push({
      pathname: "/admin/ViewHubDetails",
      search: `?hubId=${hubId.userId}`,
    });
  };
  return (
    <Box
      pl={{ sm: "10px", xl: "0px" }}
      pt={{ base: "130px", md: "80px", xl: "80px" }}
    >
      <Flex w="full" justifyContent={"space-between"} alignItems="flex-start">
        <SimpleGrid
          w={{ sm: "90%", xl: "70%" }}
          // w="70%"
          mb="20px"
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}
          // maxH='100vh' overflow={'auto'}
          // css={{
          //   "&::-webkit-scrollbar": {
          //     width: "8px",
          //   },
          //   "&::-webkit-scrollbar-track": {
          //     width: "6px",

          //   },
          //   "&::-webkit-scrollbar-thumb": {
          //     background: "#1C2A5B",
          //     borderRadius: "24px",
          //   },
          // }}
        >
          <Text
            color={"#1C2A5B"}
            fontSize={{ sm: "20px", xl: "32px" }}
            fontWeight={"700"}
          >
            Welcome To Dashboard {userName} !
          </Text>
          <Flex justifyContent={"space-between"} mt="24px">
            {statsArr.map((x: any) => {
              console.log({ x });
              return (
                <Flex
                  key={x.heading}
                  direction={"column"}
                  bg={x.bgColor}
                  h={{ sm: "70px", xl: "106px" }}
                  w={{ sm: "148px", xl: "195px" }}
                  borderRadius={"17px"}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Text
                    color={x.textColor}
                    fontWeight={"700"}
                    fontSize={{ sm: "18px", xl: "24px" }}
                  >
                    {x.stats}
                  </Text>
                  <Text
                    color={x.textColor}
                    fontWeight={"500"}
                    fontSize={{ sm: "12px", xl: "16px" }}
                  >
                    {x.heading}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
          <Card>
            <Flex mb="20px" justifyContent={"flex-end"} mt="10px">
              <Search
                placeholder="Search entities"
                onSearch={onSearch}
                // onPressEnter={(e:any) => onSearch(e.target.value)}
                onKeyPress={(e:any)=>handlePressKey(e)}
                allowClear
                style={{ width: 300 }}
              />
            </Flex>
            <div>
              <Table
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={userTransactions}
                onChange={handleTableChange}
                pagination={false}
                loading={isLoading}
              />
              {totalTransactions > 0 ? (
                <Flex justifyContent={"flex-end"} mt="10px">
                  <Pagination
                    // showLessItems
                    defaultPageSize={DEFAULT_PAGE_SIZE}
                    total={totalTransactions}
                    showSizeChanger={true}
                    current={filters.page}
                    onChange={handlePageChange}
                    pageSizeOptions={[10, 20, 30, 50]}
                    pageSize={filters.per_page}
                  />
                </Flex>
              ) : null}
            </div>
          </Card>
        </SimpleGrid>

        <SimpleGrid
          display={{ sm: "none", xl: "block" }}
          w="28%"
          mb="20px"
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}
          maxH="100vh"
          overflow={"auto"}
          css={{
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#1C2A5B",
              borderRadius: "24px",
            },
          }}
        >
          <Spin spinning={hubLoading}>
            <Card minH="100vh">
              <Text color={"#374151"} fontSize="24px" fontWeight={"600"}>
                Hub Profiles
              </Text>
              <Search
                placeholder="Search entities"
                onSearch={onSearchHub}
                allowClear
                style={{ width: "100%", marginTop: 20, height: "45px" }}
              />
              <Flex
                flexWrap={"wrap"}
                justifyContent={"flex-start"}
                alignItems="flex-start"
                py="7px"
              >
                {[
                  { label: "All Profiles", Status: 0 },
                  { label: "Approved", Status: 1 },
                  { label: "Rejected", Status: 2 },
                  { label: "Pending", Status: 3 },
                ].map((x: any) => {
                  return (
                    <Button
                      onClick={() =>
                        setFiltersHub({
                          ...filtersHub,
                          Status: x.Status,
                        })
                      }
                      key={x.Status}
                      w="80px"
                      fontSize="11px"
                      variant="brand"
                      fontWeight="normal"
                      mr="5px"
                      mt="5px"
                      h="30px"
                      bg={
                        x.Status === filtersHub.Status ? "blue.200" : "#EAEAEA"
                      }
                      color={
                        x.Status === filtersHub.Status ? "white" : "#33312E"
                      }
                      _hover={{ bg: x.label === "Approved" ? "#31D0AA1A" : 
                      (x.label === "Rejected" ? "#ff1d1d2b" : (x.label === "Pending" ? "#f7f9b9c2" : "blue.200"))
                      , color: x.label === "Approved" ? "#31D0AA" : 
                      (x.label === "Rejected" ? "#ff1d1d" : (x.label === "Pending" ? "#929010" : "white")) }}
                      borderRadius="20px"
                    >
                      {x.label}
                    </Button>
                  );
                })}
              </Flex>
              <Box mt="20px">
                {hubData?.response?.userList.map((x: any) => {
                  return (
                    <Flex
                      key={x.userId}
                      mt="12px"
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <HStack
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                      >
                        <Box>
                          <Avatar src={x.logo ?? avatar_New} size="md" />
                        </Box>
                        <Box ml="10px">
                          <Text
                            w={"100px"}
                            noOfLines={1}
                            color={"#000000"}
                            fontWeight={"600"}
                            fontSize={"14px"}
                          >
                            {x.name}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack>
                        <Box>
                        {/* <Button
                          onClick={() => null}
                          w="80px"
                          fontSize="11px"
                          variant="brand"
                          fontWeight="normal"
                          mr="5px"
                          mt="5px"
                          h="30px"
                          bg={`
                          ${x.status==="Approved" ? "#31D0AA1A" : 
                            x.status ==="Pending" ? "#EBF3FFC2": "#FF1D1D14"}`}
                          color={`
                          ${x.status==="Approved" ? "#31D0AA" : 
                            x.status ==="Pending" ? "#14466C": "#FF1D1D"}`}
                          // _hover={{ bg: "#31D0AA1A", color: "#31D0AA" }}
                          _hover={{ bg: "inherit", color: "inherit" }} 
                          borderRadius="20px"
                        >
                          {x.status}
                        </Button> */}
                        <div
                          style={{
                            width: "80px",
                            fontSize: "11px",
                            fontWeight: "normal",
                            marginRight: "5px",
                            marginTop: "5px",
                            height: "30px",
                            backgroundColor: x.status === "Approved" ? "#31D0AA1A" : 
                              x.status === "Pending" ? "#f7f9b9c2" : "#FF1D1D14",
                            color: x.status === "Approved" ? "#31D0AA" : 
                              x.status === "Pending" ? "#929010" : "#FF1D1D",
                            borderRadius: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {x.status}
                        </div>
                        </Box>
                      </HStack>
                      <HStack
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                      >
                        <Text
                          onClick={() => navigateHub(x)}
                          cursor={"pointer"}
                          textDecorationLine={"underline"}
                          fontSize="14px"
                          color={"#298BE2"}
                          fontWeight="600"
                        >
                          View Profile
                        </Text>
                      </HStack>
                    </Flex>
                  );
                })}
              </Box>
            </Card>
          </Spin>
        </SimpleGrid>

        <Card
          display={{ sm: "block", xl: "none" }}
          p="0px"
          w="85px"
          h="100vh"
          position="fixed"
          borderLeftRadius={"24px"}
          borderRightRadius="0px"
          right="0px"
        >
          <Flex justifyContent="center" alignItems={"flex-end"}>
            <Image
              cursor={"pointer"}
              onClick={() => {
                onOpen();
              }}
              mt="20px"
              src={openHubProfile == false ? openMenu : closeMnue}
              alt="closeMnue"
              w={"36px"}
              h={"36px"}
            />
          </Flex>
          <Text
            textAlign={"center"}
            mt="10px"
            color={"#000000"}
            fontWeight={"500"}
            fontSize={"10px"}
          >
            Hub Profiles
          </Text>
        </Card>
        <Drawer size={"md"} isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader> Hub Profiles</DrawerHeader>
            <Spin spinning={hubLoading}>
              <DrawerBody>
                <Search
                  placeholder="Search entities"
                  onSearch={onSearch}
                  allowClear
                  style={{ width: "100%", marginTop: 20, height: "45px" }}
                />
                <Flex
                  flexWrap={"wrap"}
                  justifyContent={"flex-start"}
                  alignItems="flex-start"
                  py="7px"
                >
                  {[
                    { label: "All Profiles", Status: 0 },
                    { label: "Approved", Status: 1 },
                    { label: "Rejected", Status: 2 },
                    { label: "Pending", Status: 3 },
                  ].map((x: any) => {
                    return (
                      <Button
                        onClick={() =>
                          setFiltersHub({
                            ...filtersHub,
                            Status: x.Status,
                          })
                        }
                        key={x.Status}
                        w="80px"
                        fontSize="11px"
                        variant="brand"
                        fontWeight="normal"
                        mr="5px"
                        mt="5px"
                        h="30px"
                        bg={
                          x.Status === filtersHub.Status
                            ? "blue.200"
                            : "#EAEAEA"
                        }
                        color={
                          x.Status === filtersHub.Status ? "white" : "#33312E"
                        }
                        _hover={{ bg: "blue.200", color: "white" }}
                        borderRadius="20px"
                      >
                        {x.label}
                      </Button>
                    );
                  })}
                </Flex>
                <Box mt="20px">
                  {hubData?.response?.userList.map((x: any) => {
                    return (
                      <Flex
                        key={x.userId}
                        mt="12px"
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <HStack
                          alignItems={"center"}
                          justifyContent={"flex-start"}
                        >
                          <Box>
                            <Avatar src={x.logo ?? avatar_New} size="md" />
                          </Box>
                          <Box ml="10px">
                            <Text
                              maxW={"100px"}
                              noOfLines={1}
                              color={"#000000"}
                              fontWeight={"600"}
                              fontSize={"14px"}
                            >
                              {x.name}
                            </Text>
                          </Box>
                          <Button
                            onClick={() => null}
                            w="80px"
                            fontSize="11px"
                            variant="brand"
                            fontWeight="normal"
                            mr="5px"
                            mt="5px"
                            h="30px"
                            bg={"#31D0AA1A"}
                            color={"#31D0AA"}
                            _hover={{ bg: "#31D0AA1A", color: "#31D0AA" }}
                            borderRadius="20px"
                          >
                            {x.status}
                          </Button>
                        </HStack>
                        <HStack
                          alignItems={"center"}
                          justifyContent={"flex-start"}
                        >
                          <Text
                            onClick={() => navigateHub(x)}
                            cursor={"pointer"}
                            textDecorationLine={"underline"}
                            fontSize="14px"
                            color={"#298BE2"}
                            fontWeight="600"
                          >
                            View Profile
                          </Text>
                        </HStack>
                      </Flex>
                    );
                  })}
                </Box>
              </DrawerBody>
            </Spin>
            <DrawerFooter></DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  );
}
