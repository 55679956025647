/* eslint-disable */
import { useDispatch, useSelector } from "react-redux";
import React, { memo, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  chakra,
  FormErrorMessage,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  HStack,
  Select,
} from "@chakra-ui/react";

// Custom components
import { useForm } from "react-hook-form";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { RxCross1 } from "react-icons/rx";
// import { FaFile } from "react-icons/fa";
import { BsFileEarmarkTextFill } from "react-icons/bs";
const documentDropDown = [
  {
    label: "Business Document EIN",
    value: "Business Document EIN",
  },
  {
    label: "Retail Address Confirmation",
    value: "Retail Address Confirmation",
  },
  {
    label: "License/Passport",
    value: "License/Passport",
  },
  {
    label: "Retail Safety Document",
    value: "Retail Safety Document",
  },
  {
    label: "Social Pages",
    value: "Social Pages",
  },
];
function PrivacyPoloicy_Term(props: any) {
  const { privacyPolicy, termsConditions } = useSelector(
    (state: any) => state.AdminsTableLists
  );
  const { data, isOpen, onOpen, onClose, modalTitle } = props;
  console.log({ modalTitle });
  return (
    <>
      <Modal
        isCentered
        size={"6xl"}
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {" "}
            <div
              dangerouslySetInnerHTML={{
                __html:
                  modalTitle === "Terms of Service"
                    ? termsConditions
                    : privacyPolicy,
              }}
            />
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function step_1(props: {
  next: Function;
  erorrMessage: string;
  isLoading: boolean;
}) {
  const {
    handleSubmit,
    register,
    setError,
    getValues,
    control,
    formState: { errors, isSubmitting },
  } = useForm();
  const { next, erorrMessage, isLoading } = props;
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Chakra color mode
  const textColor = useColorModeValue("black.50", "white");
  const textColorSecondary = "#7C7C7C";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("blue.100", "blue.100");
  const buttonColorBrand = useColorModeValue("blue.200", "blue.200");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [checkedItems, setCheckedItems] = React.useState(false);
  const [modalTitle, setModalTitle] = useState("Terms of Service");
  const [show, setShow] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [state, setState] = useState<any>({
    LastName: "",
    CompanyName: "",
    MobileNumber: "",
    BusinessEINNumber: "",
    UserName: "",
    SSNNumber: "",
    EmailAddress: "",
    // Documents: {},
    DocumentsList: [],
    FirstName: "",
    ConfirmPassword: "",
    Password: "",
    Expiration: null,
  });
  const stateRef = useRef(null);
  stateRef.current = state;
  const [invalidState, setInvalidState] = useState({
    LastNameInvalid: false,
    CompanyNameInvalid: false,
    MobileNumberInvalid: false,
    IsHubInvalid: false,
    BusinessEINNumberInvalid: false,
    UserNameInvalid: false,
    SSNNumberInvalid: false,
    EmailAddressInvalid: false,
    DocumentsInvalid: false,
    FirstNameInvalid: false,
    ConfirmPasswordInvalid: false,
    PasswordInvalid: false,
    termCondInvalid: false,
    expirationInvalid: false,
    docTypeInvalid: false,
    docInvalid: false,
  });

  const handleClick = () => setShow(!show);

  const updateState = (val: string, prop: string, invalidProp?: string) => {
    setState((prvState: any) => ({ ...prvState, [prop]: val }));
    setInvalidState((prvState: any) => ({ ...prvState, [invalidProp]: false }));
  };
  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const checks = () => {
    if (state.FirstName === "") {
      setInvalidState((prvState) => ({ ...prvState, FirstNameInvalid: true }));
      return false;
    } else if (state.LastName === "") {
      setInvalidState((prvState) => ({ ...prvState, LastNameInvalid: true }));
      return false;
    } else if (
      state.EmailAddress === "" ||
      !validateEmail(state.EmailAddress)
    ) {
      setInvalidState((prvState) => ({
        ...prvState,
        EmailAddressInvalid: true,
      }));
      return false;
    } else if (state.UserName === "") {
      setInvalidState((prvState) => ({ ...prvState, UserNameInvalid: true }));
      return false;
    } else if (state.Password.length < 6) {
      setInvalidState((prvState) => ({ ...prvState, PasswordInvalid: true }));
      return false;
    } else if (state.ConfirmPassword != state.Password) {
      setInvalidState((prvState) => ({
        ...prvState,
        ConfirmPasswordInvalid: true,
      }));
      return false;
    } else if (state.CompanyName === "") {
      setInvalidState((prvState) => ({
        ...prvState,
        CompanyNameInvalid: true,
      }));
      return false;
    } else if (!checkedItems) {
      setInvalidState((prvState) => ({
        ...prvState,
        termCondInvalid: true,
      }));
      return false;
    }

    return true;
  };
  // const createAccount = () => {
  //   if (checks()) next(state);
  // };

  const createAccount = () => {
    if (checks()) {
      next({ ...state });
    }
  };

  const handleClickDocuments = () => {
   if (selectedDoc === null || selectedDoc === "") {
      setInvalidState((prvState) => ({
        ...prvState,
        docTypeInvalid: true,
      }));
      return;
    } else if (state.Expiration === null || state.Expiration === "" ) {
      setInvalidState((prvState) => ({
        ...prvState,
        expirationInvalid: true,
      }));
      return;
    }  
    hiddenFileInput.current.click();
  };
  const handleChange = (event: any) => {
    let files = event.target.files;
    let newFile: any = {};
    Object.keys(files).map((key) => {
      newFile = files[key];
    });
    let updatedFile: any = {
      Files: newFile,
      Expiration: state.Expiration,
      DocumentType: selectedDoc,
    };
    let arr = [...state.DocumentsList];
    let index = arr.findIndex((x: any) => x.DocumentType === selectedDoc);
    if (index > -1) {
      arr[index] = updatedFile;
    } else {
      arr.push(updatedFile);
    }

    console.log({ arr, files });
    // Object.keys(files).map((key) => {
    //   updatedFile[files[key]["name"]] = files[key];
    // });
    // updatedFile = { ...updatedFile, ...stateRef.current.Documents };
    let size = arr.length;
    // let size = Object.keys(updatedFile).length;
    if (size > 6) {
      
      toast({
        title: "Warning",
        description: "Only maximum 7 files accepted.",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      event.preventDefault();
    
      return;
    }
    toast({
      title: "",
      description: "Uploaded Successfully",
      status: "success",
      duration: 1000,
      isClosable: true,
    });
    setState((prvState: any) => ({
      ...prvState,
      Expiration: "",
      DocumentsList: arr,
    }));
    setSelectedDoc("");
  };
  const handleRemoveUpload = (i: number) => {
    const fileUploaded = [...state.DocumentsList];
    fileUploaded.splice(i, 1);

    setState((prvState: any) => ({
      ...prvState,
      DocumentsList: fileUploaded,
    }));
  };
  const openTersmModal = (title: string) => {
    setModalTitle(title);
    onOpen();
  };
  return (
    <Flex
      maxW={{ base: "100%", md: "max-content" }}
      w="100%"
      mx={{ base: "auto", lg: "0px" }}
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      mb={{ base: "30px", md: "60px" }}
      px={{ base: "25px", md: "0px" }}
      mt="24px"
      flexDirection="column"
    >
      <PrivacyPoloicy_Term
        data={""}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        modalTitle={modalTitle}
      />

      <Flex
        zIndex="2"
        direction="column"
        // w={{ base: "100%", md: "420px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
        w="540px"
        bg="white"
      >
        <HStack justifyContent={"space-between"} w="100%">
          <FormControl isInvalid={invalidState.FirstNameInvalid}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              First Name<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              borderColor={invalidState.FirstNameInvalid ? "red" : null}
              onChange={(e) =>
                updateState(e.target.value, "FirstName", "FirstNameInvalid")
              }
              value={state.FirstName}
              isRequired={true}
              placeholder="Enter Your First Name"
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              borderRadius={8}
              fontWeight="500"
              w="258px"
              h={"46px"}
              size="lg"
            />
            {invalidState.FirstNameInvalid && (
              <FormErrorMessage>FirstName is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={invalidState.LastNameInvalid}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Last Name<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              borderColor={invalidState.LastNameInvalid ? "red" : null}
              onChange={(e) =>
                updateState(e.target.value, "LastName", "LastNameInvalid")
              }
              value={state.LastName}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              placeholder="Enter Your Last Name"
              type="text"
              borderRadius={8}
              fontWeight="500"
              size="lg"
              w="258px"
              h={"46px"}
            />
            {invalidState.LastNameInvalid && (
              <FormErrorMessage>Last Name is required.</FormErrorMessage>
            )}
          </FormControl>
        </HStack>

        <HStack mt="24px" justifyContent={"space-between"} w="100%">
          <FormControl isInvalid={invalidState.EmailAddressInvalid}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              borderColor={invalidState.EmailAddressInvalid ? "red" : null}
              onChange={(e) =>
                updateState(
                  e.target.value,
                  "EmailAddress",
                  "EmailAddressInvalid"
                )
              }
              placeholder="Enter E-mail Address"
              value={state.EmailAddress}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              borderRadius={8}
              fontWeight="500"
              size="lg"
              w="258px"
              h={"46px"}
            />
            {invalidState.EmailAddressInvalid && (
              <FormErrorMessage>Invalid Email</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={invalidState.UserNameInvalid}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              User Name<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              borderColor={invalidState.UserNameInvalid ? "red" : null}
              onChange={(e) =>
                updateState(e.target.value, "UserName", "UserNameInvalid")
              }
              value={state.UserName}
              isRequired={true}
              variant="auth"
              placeholder="Enter User Name"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              borderRadius={8}
              fontWeight="500"
              size="lg"
              w="258px"
              h={"46px"}
            />
            {invalidState.UserNameInvalid && (
              <FormErrorMessage>User Name is required.</FormErrorMessage>
            )}
          </FormControl>
        </HStack>

        <HStack mt="24px" justifyContent={"space-between"} w="100%">
          <FormControl isInvalid={invalidState.PasswordInvalid}>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                borderColor={invalidState.PasswordInvalid ? "red" : null}
                onChange={(e) =>
                  updateState(e.target.value, "Password", "PasswordInvalid")
                }
                value={state.Password}
                borderRadius={8}
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 6 characters"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                w="258px"
                h={"46px"}
              />

              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  borderColor={
                    invalidState.BusinessEINNumberInvalid ? "red" : null
                  }
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {invalidState.PasswordInvalid && (
              <FormErrorMessage>
                Password must contain at least 6 characters .
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={invalidState.ConfirmPasswordInvalid}>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Confirm Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                borderColor={invalidState.ConfirmPasswordInvalid ? "red" : null}
                onChange={(e) =>
                  updateState(
                    e.target.value,
                    "ConfirmPassword",
                    "ConfirmPasswordInvalid"
                  )
                }
                value={state.ConfirmPassword}
                borderRadius={8}
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 6 characters"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                w="258px"
                h={"46px"}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {invalidState.ConfirmPasswordInvalid && (
              <FormErrorMessage>Password does not match</FormErrorMessage>
            )}
          </FormControl>
        </HStack>
        <HStack mt="24px" justifyContent={"space-between"} w="100%">
          <FormControl isInvalid={invalidState.CompanyNameInvalid}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Company Name<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              borderColor={invalidState.CompanyNameInvalid ? "red" : null}
              onChange={(e) =>
                updateState(e.target.value, "CompanyName", "CompanyNameInvalid")
              }
              value={state.CompanyName}
              isRequired={true}
              variant="auth"
              placeholder="Enter Company Name"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              borderRadius={8}
              fontWeight="500"
              size="lg"
              w="258px"
              h={"46px"}
            />
            {invalidState.CompanyNameInvalid && (
              <FormErrorMessage>Company Name is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={invalidState.BusinessEINNumberInvalid}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Business EIN Number
            </FormLabel>
            <Input
              onChange={(e) =>
                updateState(
                  e.target.value,
                  "BusinessEINNumber",
                  "BusinessEINNumberInvalid"
                )
              }
              placeholder="e.g. #09123102"
              value={state.BusinessEINNumber}
              borderColor={invalidState.BusinessEINNumberInvalid ? "red" : null}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="number"
              borderRadius={8}
              fontWeight="500"
              size="lg"
            />
            {invalidState.BusinessEINNumberInvalid && (
              <FormErrorMessage>
                Business EIN Number is required.
              </FormErrorMessage>
            )}
          </FormControl>
        </HStack>
        <FormLabel
          mt="24px"
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Business Documents (7 max)
        </FormLabel>
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="light"
          color={textColorSecondary}
          mb="8px"
        >
          Any additional business documents that can be sent to provide
          verification that your hub facility is authentic and exists can be
          uploaded here. The more you can provide, the faster the verification
          process will be.
        </FormLabel>
        <Flex
          // py="20px"
          flexWrap={"wrap"}
          justifyContent={"flex-start"}
          alignItems="center"
        >
          {state.DocumentsList.map((key: any, i: number) => {
            console.log({ key });
            return (
              <Box key={i} maxW={"100px"} mr="10px" position={"relative"}>
                <Icon
                  position={"absolute"}
                  top={"-5px"}
                  right={"-2px"}
                  fontWeight="bold"
                  color={"red"}
                  _hover={{ cursor: "pointer" }}
                  as={RxCross1}
                  onClick={() => handleRemoveUpload(i)}
                />
                <Icon
                  color={"#FF7763"}
                  _hover={{ cursor: "pointer" }}
                  boxSize={"50px"}
                  as={BsFileEarmarkTextFill}
                />
                <Text maxW={"100px"} noOfLines={1}>
                  {key.Files.name}
                </Text>
                <Text maxW={"100px"} noOfLines={1}>
                  {key.Expiration}
                </Text>
              </Box>
            );
          })}
        </Flex>
        <Flex mt="26px" justifyContent={"space-between"}>
          <FormControl w="258px" isInvalid={invalidState.docTypeInvalid}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Document Type<Text color={brandStars}>*</Text>
            </FormLabel>

            <Select
              // onChange={setSelectedDoc}
              onChange={(e) => {
                setSelectedDoc(e.target.value);
                setInvalidState((prvState) => ({
                  ...prvState,
                  docTypeInvalid: false,
                }));
              }}
              value={selectedDoc}
              w="258px"
              h={"46px"}
              variant="auth"
              borderRadius={"8px"}
              placeholder="Select option"
            >
              {documentDropDown.map((x: any) => {
                return (
                  <option key={x.value} value={x.value}>
                    {x.label}
                  </option>
                );
              })}
            </Select>
            {invalidState.docTypeInvalid && (
              <FormErrorMessage>Please select Document Type.</FormErrorMessage>
            )}
          </FormControl>

          <FormControl w="258px" isInvalid={invalidState.expirationInvalid}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Expiration Date<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              onChange={(e) => {
                updateState(e.target.value, "Expiration");
                setInvalidState((prvState) => ({
                  ...prvState,
                  expirationInvalid: false,
                }));
              }}
              value={state.Expiration}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="date"
              borderRadius={8}
              placeholder="Name"
              fontWeight="500"
              w="258px"
              h={"46px"}
              size="lg"
            />
            {invalidState.expirationInvalid && (
              <FormErrorMessage>
                Please select expiration date.
              </FormErrorMessage>
            )}
          </FormControl>
        </Flex>
        <Button
          onClick={handleClickDocuments}
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="100%"
          h="40px"
          mt="24px"
          borderRadius={8}
        >
          Attach
        </Button>
        <input
          accept="*"
          type="file"
          value={""}
          maxLength={7}
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
        <Box mt="20px">
          <Text color={"red"}>{erorrMessage}</Text>
        </Box>
        <FormControl
          alignItems="center"
          isInvalid={invalidState.termCondInvalid}
        >
          <Flex align="center" mb="10px">
            <Checkbox
              isChecked={checkedItems}
              onChange={(e) => {
                setCheckedItems(e.target.checked);
                setInvalidState((prvState) => ({
                  ...prvState,
                  termCondInvalid: false,
                }));
              }}
              id="remember-login"
              colorScheme="brandScheme"
              me="10px"
            />

            <FormLabel
              htmlFor="remember-login"
              mb="0"
              fontWeight="normal"
              color={textColorSecondary}
              fontSize="sm"
            >
              I agree to all the Term of
              <chakra.span
                onClick={() => openTersmModal("Terms of Service")}
                cursor="pointer"
                color={buttonColorBrand}
              >
                {" "}
                conditions{" "}
              </chakra.span>{" "}
              &{" "}
              <chakra.span
                onClick={() => openTersmModal("Privacy Policy")}
                cursor="pointer"
                color={buttonColorBrand}
              >
                {" "}
                Privacy Policy{" "}
              </chakra.span>{" "}
            </FormLabel>
          </Flex>
          {invalidState.termCondInvalid && (
            <FormErrorMessage>
              You must be agree to term & conditions
            </FormErrorMessage>
          )}
        </FormControl>

        <Button
          isLoading={isLoading}
          loadingText="Creating Account.."
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="100%"
          h="50"
          my="10px"
          borderRadius={8}
          bg={buttonColorBrand}
          onClick={createAccount}
        >
          Create Account
        </Button>

        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxW="100%"
          mt="0px"
          w="full"
        >
          <Text
            color={textColorDetails}
            textAlign="center"
            fontWeight="400"
            fontSize="14px"
          >
            Already have an account?{" "}
            <NavLink to="/auth/sign-in">
              <Text
                color={buttonColorBrand}
                as="span"
                ms="5px"
                fontWeight="500"
              >
                Log In
              </Text>
            </NavLink>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default memo(step_1);
