/* eslint-disable */

import React, { useCallback, useEffect, useState } from "react";
import { rootUrl } from "utilities/constants";
import { useToast, Box, Text, Flex, HStack, Image } from "@chakra-ui/react";
// Custom components

import { useDispatch, useSelector } from "react-redux";
import Card from "components/card/Card";
import {
  getPrivacyPolicy,
  getTermsConditions,
} from "redux/slices/AdminsTableLists";
import logo from "assets/img/global/logo.png";
import newlogo from "assets/img/global/16_PNG-logo.png";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import step_1 from "assets/img/global/step_1.png";
import step_2 from "assets/img/global/step_2.png";
import step_3 from "assets/img/global/step_3.png";
import Step_1 from "./step_1";
import Step_2 from "./step_2";

import Step_3 from "./step_3";
import Step_4 from "./step_4";
import { get } from "redux/services/api";
import { useHistory, useLocation } from "react-router-dom";

function SignUp() {
  const history = useHistory();

  const dispatch: any = useDispatch();
  const { userId, userName, email, phoneNumer, token } = useSelector(
    (state: any) => state.Auth
  );

  const toast = useToast();
  const [steps, setStep] = useState(1);
  const [erorrMessage, SetErorrMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    LastName: "",
    CompanyName: "",
    MobileNumber: "",
    IsHub: true,
    BusinessEINNumber: "",
    UserName: "",
    SSNNumber: "",
    EmailAddress: "",
    // Documents: "",
    FirstName: "",
    ConfirmPassword: "",
    Password: "",
    userId:0
  });
  useEffect(() => {
    dispatch(getPrivacyPolicy());
    dispatch(getTermsConditions());
  }, []);
  const handleNext: any = useCallback(
    (updatedState: any) => {
      setState((prvState) => ({ ...prvState, ...updatedState }));
      if (steps === 1) {
        // console.log({updated:{ ...state,...updatedState,}});
        setState((prvState) => ({ ...prvState, ...updatedState }));
        createAccount({ ...state, ...updatedState });
      } else if (steps === 3) {
        // setStep(steps + 1);
        history.push({
          pathname: "/auth/HubProfile",
          state: {
            userName: state.UserName,
            password: state.Password,
            userId:state.userId
          },
        });
      } else if (steps === 4) {
        // getAccessToken();
        console.log({});
      } else {
        history.push("/auth/HubProfile", {
          userName: state.UserName,
          password: state.Password,
          userId:state.userId
        });
      }
    },
    [steps,state]
  );

  const createAccount = (payload: any) => {
    setIsLoading(true);
    var myHeaders = new Headers();
    var formdata = new FormData();
    myHeaders.append("accept", "*/*");
    for (var key in payload) {
      if (key != "DocumentsList") {
        formdata.append(key, payload[key]);
      }
    }

    let files = payload?.DocumentsList;
    // if (files) {
    //   for (const file of files) {
    //     // formdata.append("Documents", file);
    //     formdata.append("Documents" + `[${i}]` + `[${key}]`, x[key]);
    //   }
    // }
    files.forEach((x: any, i: number) => {
      Object.keys(x).map((key) => {
        formdata.append("Documents" + `[${i}]` + `.${key}`, x[key]);
      });
    });
    // console.log({ type: typeof payload.Documents.file });
    var requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${rootUrl}api/v1/account/hub-signup`, requestOptions)
      .then((response) => response.text())
      .then((result: any) => {
        setIsLoading(false);
        result = JSON.parse(result);
        if (result.status === false) {
          SetErorrMessage(result.errorMessage);
        } else if (result.status === true) {
          toast.closeAll()
          toast({
            title: "Account created.",
            description: "We've created your account for you.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setStep(3);
          setState((prvState) => ({
            ...prvState,
            userId: result.response.newId,
          }));
          sendVarificationCode(payload);
        } else {
          SetErorrMessage(result?.errorMessage);
        }
        console.log({ result });
      })
      .catch((error) => console.log("error", error));
  };
  const sendVarificationCode = async (payload: any) => {
    let url = `${rootUrl}api/v1/account/send-accesscode-for-signup?username=${payload.UserName}&IsSms=true&IsEmail=true&IsAppleLogin=false&accountType=hub`;
    await get(url);
  };
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Box>
        <Box w={"full"} height="80px">
          <Box px={{ "2xl": "120px" }} maxW="1440px" m="auto">
            <Image src={newlogo} alt="logo" w={"107px"} h={"50px"} />
          </Box>
        </Box>
        <Card
          borderRadius={"10px"}
          w="782px"
          minH="100px"
          p={0}
          // boxShadow={"base"}
          border="2px solid #ECF1F4"
          // borderRadius={'20px'}
          m="auto"
        >
          <Flex justifyContent="space-between" alignItems={"center"}>
            <Flex
              borderLeftRadius={"20px"}
              borderTopRightRadius="300px"
              borderBottomRightRadius={"10px"}
              justifyContent="flex-start"
              alignItems={"center"}
              w="380px"
              minH="100px"
              px="20px"
              bg="linear-gradient(180deg, #1E75FF 27.38%, #19BAEE 53.23%)"
            >
              <Text color={"white"} fontSize={"18px"}>
                Take the first step in becoming a Hub
              </Text>
            </Flex>
            <Flex
              justifyContent="flex-start"
              alignItems={"center"}
              w="400px"
              maxH="100px"
            >
              <HStack
                justifyContent={"space-between"}
                w="100%"
                position={"relative"}
              >
                <Box
                  w="44%"
                  top={"13px"}
                  left="29%"
                  h={"1.5px"}
                  bg={steps !== 3 ? "#555F84" : "#298BE2"}
                  position={"absolute"}
                />
                <Flex
                  justifyContent="center"
                  flexDirection={"column"}
                  alignItems={"center"}
                  w="50%"
                  // border={"1px solid"}
                >
                  <Flex
                    w="26px"
                    h="26px"
                    borderRadius={"26px"}
                    bg="#F7F7F7"
                    justifyContent="center"
                    alignItems={"center"}
                    border={"1px solid"}
                    borderColor={steps === 3 ? "#555F84" : "#298BE2"}
                  >
                    <Text
                      fontSize={"16px"}
                      fontWeight="semibold"
                      color={steps === 3 ? "#555F84" : "#298BE2"}
                    >
                      1
                    </Text>
                  </Flex>
                  <Text
                    fontSize={"16px"}
                    fontWeight="semibold"
                    color={steps === 3 ? "#555F84" : "#298BE2"}
                    mt="8px"
                  >
                    Personal Information
                  </Text>
                </Flex>
                <Flex
                  flexDirection={"column"}
                  justifyContent="center"
                  alignItems={"center"}
                  w="50%"
                  // border={"1px solid"}
                >
                  <Flex
                    w="26px"
                    h="26px"
                    borderRadius={"26px"}
                    justifyContent="center"
                    alignItems={"center"}
                    border={"1px solid"}
                    borderColor={steps !== 3 ? "#555F84" : "#298BE2"}
                    bg="#F7F7F7"
                  >
                    <Text
                      fontSize={"16px"}
                      fontWeight="semibold"
                      color={steps !== 3 ? "#555F84" : "#298BE2"}
                    >
                      2
                    </Text>
                  </Flex>
                  <Text
                    fontSize={"16px"}
                    fontWeight="semibold"
                    color={steps !== 3 ? "#555F84" : "#298BE2"}
                    mt="8px"
                  >
                    Security Code
                  </Text>
                </Flex>
              </HStack>
            </Flex>
          </Flex>
        </Card>
        <Flex
          justifyContent="center"
          alignItems={"center"}
          m="auto"
          w="100%"
          // bg={"red.50"}
        >
          {steps === 1 && (
            <Step_1
              next={handleNext}
              isLoading={isLoading}
              erorrMessage={erorrMessage}
            />
          )}

          {steps === 3 && <Step_3 parantState={state} next={handleNext} />}
          {/* {steps === 4 && <Step_4 next={handleNext} />} */}
        </Flex>
      </Box>
    </DefaultAuth>
  );
}

export default SignUp;
