// Chakra imports
import {
  Text,
  Flex,
  Button,
  useDisclosure,
  useToast,
  chakra,
} from "@chakra-ui/react";
import React, { useState, useCallback, useEffect } from "react";
import ReviewDocuments from "views/admin/ViewHubDetails/components/ReviewDocuments";

import Card from "components/card/Card";
import EditProfile from "views/admin/ViewHubDetails/components/ApproveHub";
import Confirmation from "./Confirmation";
import { useMutationApproveHub } from "../hooks";

export default function ProfileApproved({ hubId, state, refetch }: any) {
  const toast = useToast();

  const { mutateAsync, isLoading, isSuccess } = useMutationApproveHub();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDoc,
    onOpen: onOpenDoc,
    onClose: onCloseDoc,
  } = useDisclosure();

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenConfirmation,
    onClose: onCloseConfirmation,
  } = useDisclosure();

  const approveConfirm = async () => {
    await mutateAsync(hubId);
  };

  useEffect(() => {
    console.log({ isSuccess });
    if (isSuccess === true) {
      onCloseConfirmation();
      refetch();
      toast.closeAll();
      toast({
        title: "Hub Approved",
        description: "Approved Successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [isSuccess]);
  return (
    <Card border={"1px solid #ECF1F4"} borderRadius={"10px"}>
      <Confirmation
        isLoading={isLoading}
        isOpen={isOpenDelete}
        onOpen={onOpenConfirmation}
        onClose={onCloseConfirmation}
        confirm={approveConfirm}
      />
      <Text fontSize={"14px"} fontWeight="500">
        Profile Status :{" "}
        <chakra.span
          fontSize={"16px"}
          fontWeight="bold"
          // color={state?.status === "Approved" ? "#31D0AA" : "red"}
          color={
            state?.status === "Rejected"
              ? "#FF5247"
              : state?.status === "Pending"
              ? "#F5901A"
              : "#31D0AA"
          }
        >
          {state?.status}
        </chakra.span>
      </Text>
      <Text
        onClick={onOpenDoc}
        fontSize={"14px"}
        mt="16px"
        cursor={"pointer"}
        color="#298BE2"
        textDecorationLine={"underline"}
        fontWeight="400"
      >
        Review Documents
      </Text>
      <ReviewDocuments
        onOpen={onOpenDoc}
        onClose={onCloseDoc}
        isOpen={isOpenDoc}
        ishub={true}
        id={hubId}
        hubDocuments={state?.hubDocuments}
        refetch={refetch}
      />
      <EditProfile
        refetch={refetch}
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        ishub={true}
        id={hubId}
      />
      {state?.status != "Approved" && (
        <Flex mt="18px" justifyContent={"flex-start"} alignItems={"flex-start"}>
          <Button
            onClick={onOpen}
            fontSize="12px"
            variant="outline"
            borderColor="white"
            color="white"
            fontWeight="700"
            w="69px"
            h="24px"
            borderRadius={"100px"}
            _hover={{ bg: "#FF5247" }}
            _active={{ bg: "#FF5247" }}
            bg="#FF5247"
          >
            Decline{" "}
          </Button>
          <Button
            onClick={onOpenConfirmation}
            fontSize="12px"
            variant="outline"
            borderColor="white"
            color="white"
            fontWeight="700"
            w="69px"
            h="24px"
            borderRadius={"100px"}
            _hover={{ bg: "#298BE2" }}
            _active={{ bg: "#298BE2" }}
            bg="#298BE2"
            ml="20px"
          >
            Approve
          </Button>
        </Flex>
      )}
    </Card>
  );
}
