import React, { memo, Fragment } from "react";
import { HiOutlineGlobe, HiOutlineChevronRight } from "react-icons/hi";
import { IconType } from "react-icons";
import { Flex, Text, HStack, Icon, Box, Image } from "@chakra-ui/react";

import Card from "components/card/Card";
interface Props {
  cardBg: string;
  heading: string;
  text: string;
  iconName?: IconType;
  handleNext?: () => void;
  src?: string;
  isImage?: boolean;
  [x: string]: any;
}
function EventCard(props: Props) {
  const textColorSecondary = "gray.400";

  const { cardBg, heading, text, iconName, isImage, src, handleNext, ...rest } =
    props;

  return (
    <Card
      cursor={"pointer"}
      onClick={handleNext}
      w="48%"
      border={"1px solid #E3E4E5"}
      boxShadow={"xl"}
      bg={cardBg}
      p="20px"
      mt="20px"
      borderRadius={"8px"}
      {...rest}
    >
      <Flex pb="0px" alignItems={"center"}>
        <HStack w="100%">
          <Flex
            maxW={"56px"}
            maxH={"56px"}
            borderRadius={"56px"}
            bg={"#F0F4F8"}
            padding="15px"
            justifyContent={"center"}
            alignItems="center"
          >
            {isImage ? (
              <Image src={src} w='50px' h='50px' objectFit='contain' />
            ) : (
              <Icon
                as={iconName}
                color={"#707997"}
                cursor="pointer"
                boxSize={"30px"}
                // size={"sm"}
                //    p='5px'
                borderRadius={"5px"}
              />
            )}
          </Flex>
          <Box>
            <Text fontSize={"14px"} color="#1C2A5B" fontWeight={"600"}>
              {heading}
            </Text>
            <Text fontSize={"12px"} color={textColorSecondary}>
              {text}
            </Text>
          </Box>
        </HStack>
        <Icon
          as={HiOutlineChevronRight}
          color={"#707997"}
          cursor="pointer"
          boxSize={"30px"}
          borderRadius={"5px"}
        />
      </Flex>
    </Card>
  );
}
export default memo(EventCard);
