import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  Image,
  useToast,
  Text,
  chakra,
  Box,
  ModalFooter,
  ModalBody,
  Heading,
  useColorModeValue,
  HStack,
  Icon,
  Button,
  Avatar,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Textarea,
} from "@chakra-ui/react";
import { HiOutlineCamera } from "react-icons/hi";
import { rootUrl } from "utilities/constants";
import { BsFileEarmarkMinusFill } from "react-icons/bs";

import { post } from "redux/services/api";
import { eventRootUrl } from "utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import pubg_banner2 from "assets/img/global/pubg_banner2.png";

import logoWite from "assets/img/global/logoWite.png";
import EventStepper_1 from "assets/img/global/EventStepper_1.png";
// import EventStepper_1 from "assets/img/global/EventStepper_1.png";
import { getProfile } from "redux/slices/Auth";

import { AppDispatch } from "redux/store";
import { getEventList } from "redux/slices/Events";

interface Props {
  onOpen: Function;
  isOpen: boolean;
  onClose: Function;
  [x: string]: any;
}

export default function EditProfile(props: Props) {
  const { rejectDocument, rejectReason } = useSelector(
    (state: any) => state.Auth
  );

  const { isOpen, onOpen, onClose, editState, ...rest } = props;

  return (
    <Modal
    isCentered
      {...rest}
      // closeOnOverlayClick={false}
      scrollBehavior="inside"
      size={"4xl"}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent borderRadius="14px" position={"relative"} p={0}>
        <ModalHeader h={"80px"} p="0px" borderRadius="14px">
          <Box
            borderTopRadius="14px"
            // borderBottomRadius="16px"
            justifyContent={"space-between"}
            bg="#1C2A5B"
            alignItems="center"
            py="20px"
            px="50px"
          >
            <Text color={"white"} fontSize="24px" fontWeight={"600"} mt="10px">
              Reason for Rejection
            </Text>
          </Box>
        </ModalHeader>
        <ModalBody
          overflowY="auto"
          css={{
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#1C2A5B",
              borderRadius: "24px",
            },
          }}
          position={"relative"}
          p="35px"
        >
          <Flex border="0px solid #CECECE" borderRadius="5px" p="10px">
            <Text color={"#090909"} fontSize="12px">
              {rejectReason}
            </Text>
          </Flex>
          <Text fontWeight={"600"} mt="40px" color={"#090909"} fontSize="14px">
            Document
          </Text>

          <Flex
            mt="18px"
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
          >
            {rejectDocument && (
              <Box
                cursor={"pointer"}
                onClick={() =>
                  window.open(rejectDocument, "_blank", "noopener,noreferrer")
                }
                bg="#ECF1F4"
                p="8px"
                borderRadius="4px"
              >
                <BsFileEarmarkMinusFill size={"40px"} color="#1C2A5B" />
              </Box>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter px="50px">
          <Flex w="full" justifyContent={"space-between"} alignItems="center">
            <Button
               
              loadingText="Loading..."
              onClick={() => onClose()}
              fontSize="16px"
              variant="brand"
              fontWeight="700"
              w="276px"
              h="50"
              color={"black"}
              bg="transparent"
              border={`1px solid  black`}
              borderRadius={8}
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
            >
              Close
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
