import React, { Component, useState } from "react";
import * as ReactDOM from "react-dom/client";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import { Text, Menu } from '@mantine/core';
import Menu from "./EventMenu";
import { Icon, Text,Image ,Flex} from "@chakra-ui/react";
import avatar from "assets/img/global/avatar.png";

import {
  MdOutlineMoreHoriz,
  MdOutlinePerson,
  MdOutlineCardTravel,
  MdOutlineLightbulb,
  MdOutlineSettings,
} from "react-icons/md";
const localizer = momentLocalizer(moment);

export function EventWrapper(props: any) {
  const [showMenu, setShowMenu] = useState(false);
  // console.log({ props, children: props.children });
  return (
    <div
      onClick={(e) => {
        // alert()
        setShowMenu(!showMenu);
        // e.stopPropagation();
      }}
      style={{
        // position: 'absolute',

        top: `${props?.style?.top}%`,
        width: "100%",
        display: "flex",
        height: 50,
      }}
    >
      <div
        onClick={(e) => {
          // alert()
          // e.stopPropagation();
        }}
      >
        {props.children}
      </div>
      <div
        aria-disabled={true}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{
          right: 0,
          position: "absolute",
          // width: "20%",
          backgroundColor: "red",
          // height: 50,
          top: `${props?.style?.top}%`,
          zIndex: 100,
        }}
      >
        {/* <Menu
          showMenu={showMenu}
          event={props.event}
          onEditEvent={props.onEditEvent}
        /> */}
      </div>
    </div>
  );
}

export function Event(props: any) {
  console.log({props:props.event})
  return (
    <>
    <Image src={props?.event?.coverPath?? avatar} w='20px' h='20px' borderRadius={'20px'}/>
      <Text  noOfLines={2} mt='2px' fontSize="14px">
        {props.title}
      </Text>
    </>
  );
}

// class App extends Component {
//   constructor() {
//     super();

//     const now = new Date();
//     const events = [
//       {
//         id: 0,
//         title: 'All Day Event',
//         allDay: false,
//         start: now,
//         end: now,
//       },
//     ];
//     this.state = {
//       name: 'React',
//       events,
//       eventTop: 0,
//     };
//   }

//   render() {
//     return (
//       <div>
//         <p>Tool tip</p>
//         <div style={{ height: '500pt' }}>
//           <Calendar
//             onSelectEvent={this.onEvent}
//             tooltipAccessor={null}
//             components={{
//               event: Event,
//               eventWrapper: EventWrapper,
//             }}
//             events={this.state.events}
//             view={'week'}
//             startAccessor="start"
//             endAccessor="end"
//             defaultDate={moment().toDate()}
//             localizer={localizer}
//           />
//         </div>
//       </div>
//     );
//   }
// }

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
