import React, { useState, memo, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import { post, put } from "redux/services/api";
import { eventRootUrl } from "utilities/constants";

function EventBracketConfirmation(props: any) {
  const toast = useToast();

  const { isOpen, onOpen, onClose, number_of_participants, getEventDetail,onChangeNavigation } =
    props;
  const [isLoading, setIsloading] = useState(false);
  const { challonge_Tournament_Id, id, next, urlKey, tournament_Type } =
    props.state;

  const confirm = async () => {
    console.log({ challonge_Tournament_Id });
    setIsloading(true);
    let url = `${eventRootUrl}api/v1/challonge/start-tournament`;

    let payload = {
      data: {
        tournament: {
          tournament_Id: challonge_Tournament_Id,
          number_of_participants: 0,
        },
      },
      url: url,
    };

    try {
      const response: any = await post(payload);
      console.log({response})
      if (response.data.status === true) {
        console.log({response:response.data})
        getEventDetail();
        console.log({response:response.data})
        onClose();
        console.log({response:response.data})
        setIsloading(false);
        console.log({response:response.data})
        toast.closeAll()
        
        toast({
          title: "Success",
          description: "Tournament Started Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onChangeNavigation(0)
        // next(number_of_participants);
      } else {
        toast.closeAll()
        toast({
          title: "Error",
          description: "Server Error",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (err) {
      console.log({err})
      toast({
        title: "Error",
        description: "Server Error",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const startGroupStage = async () => {
    setIsloading(true);
    let url = `${eventRootUrl}api/v1/challonge/start-group-stage`;

    let payload = {
      data: {
        tournamentId: challonge_Tournament_Id,
        tournamentUrl: urlKey,
      },
      url: url,
    };

    try {
      const response: any = await put(payload);
      if (response.data.status === true) {
        getEventDetail();
        onClose();
        setIsloading(false);
        toast.closeAll()
        toast({
          title: "Success",
          description: "Group stage started successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onChangeNavigation(0)
      } else {
        toast.closeAll()
        toast({
          title: "Error",
          description: "Server Error",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (err) {
      console.log({ err });
      toast.closeAll()
      toast({
        title: "Error",
        description: "Server Error",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const closeRegistration = async () => {
    setIsloading(true);

    let url = `${eventRootUrl}api/v1/challonge/add-participants`;

    let payload = {
      data: {
        tournamentId: id.toString(),
        challonge_tournament_Id: challonge_Tournament_Id,
      },
      url: url,
    };
    const response: any = await post(payload);
    console.log({ response });
    if (response.data.status === true) {
      
      // setLoadingCloseRegistration(false);

      if (tournament_Type === "two stage tournament") {
        startGroupStage();
      } else {
        confirm();
      }
    } else {
      toast.closeAll()
      toast({
        title: "Error",
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setIsloading(false);
  };
  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal isCentered size={"3xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Confirm event setup?</ModalHeader>
          <ModalBody
            overflowY="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#1C2A5B",
                borderRadius: "24px",
              },
            }}
            position={"relative"}
            bg="#F5F5F5"
          >
            <Text color="#5D6689" fontSize={"20px"} fontWeight="semibold">
              Once confirmed, the following cannot be changed:
            </Text>
            <Text fontSize={"18px"} color="#5D6689" fontWeight="semibold">
              - Event setup <br />
              - Check-In
              <br />
              - Bracket type
              <br />
              - Round time
              <br />
              - Player placement
              <br />
              - Player additions
              <br />
              - Moderators
              <br />
              - Moderator permissions
              <br />
              - Notifications
              <br />- Winning distribution
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={isLoading}
              loadingText="Starting Event..."
              bg="#31D0AA"
              _hover={{ bg: "#31D0AA" }}
              _focus={{ bg: "#31D0AA" }}
              _active={{ bg: "#31D0AA" }}
              w="full"
              borderRadius={"4px"}
              onClick={closeRegistration}
              variant="brand"
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default memo(EventBracketConfirmation);
