import { memo, useCallback, useEffect, useState } from "react";
import { SearchBar } from "../SearchBox";
import {
  ModalBody,
  Heading,
  useColorModeValue,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Button,
  Box,
  Avatar,
  HStack,
  Image,
  Icon,
  useToast,
  ModalFooter,
  Spinner,
} from "@chakra-ui/react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiSelectMultiple } from "react-icons/bi";

import { gameRootUrl } from "utilities/constants";
import { get } from "redux/services/api";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";
// import { SearchBar } from "components/navbar/searchBar/SearchBar";
interface Props {
  next: Function;
  handleBack: () => void;
  parantState: any;
  isReview: boolean;
}

// var dataSet: any = {};
function Step_7(props: Props) {
  const toast = useToast();

  const { gameList } = useSelector((state: any) => state.Events);
  const { next, handleBack, parantState, isReview } = props;
  const textColor = useColorModeValue("black.50", "white");
  const buttonColorBrand = "blue.200";
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const [selectedGame, setselectedGame] = useState({
    apiGameId: null,
    background_image: "",
    name: "",
  });
  const [swiperRef, setSwiperRef] = useState(null);
  const [pageNumber, setPageNumber] = useState(2);
  const [searchPageNumber, setSearchPageNumber] = useState(2);
  const [gameCount, setGameCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [gameListArr, setGameListArr] = useState([]);
  const [search, setsearch] = useState("");
  const [searchArr, setsearchArr] = useState([]);
  const [AddGame, setAddGame] = useState(true);
  const [showGame, setshowGame] = useState(false);
  const [dataSet, setDataSet] = useState<any>({});
  const [showSelectedGame, setShowSelectedGame] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [state, setState] = useState({
    ...parantState,
  });

  useEffect(() => {
    if (gameList.length) {
      console.log({ gameList });
      setGameListArr(gameList);
    }
  }, []);

  useEffect(() => {
    if (parantState.selectedGame) {
      setselectedGame(parantState.selectedGame);
      setShowSelectedGame(true);
      setAddGame(false);
    }
  }, [parantState]);

  const checks = () => {
    if (selectedGame === null) {
      toast({
        title: "Info",
        description: "Please Select Game",
        status: "info",
        duration: 9000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };
  const handleNext = () => {
    if (checks()) {
      next(selectedGame);
    }
  };

  const paginate = useCallback(async () => {
    setIsLoading(true);
    try {
      let url = `${gameRootUrl}/api/v1/game/game-list?Page=${pageNumber}&PageSize=5&PageGameSize=10`;
      const response: any = await get(url);

      if (response.data.statusCode === 200) {
        setPageNumber(pageNumber + 1);
        let arr = [...gameListArr, ...response.data.response.results];
        setIsLoading(false);
        setGameListArr(arr);
      } else {
        setIsLoading(false);
        setGameCount(-1);
      }
    } catch (err: any) {
      console.log({ errorz: err });
      if (err) {
        throw err;
      } else {
        throw err;
      }
    }
  }, [pageNumber, gameListArr]);

  const onSearch = async () => {
    if (searchArr.length) {
      setsearchArr([]);
      setsearch("");
      setGameCount(0);
      return;
    }
    setIsSearching(true);
    try {
      let url = `${gameRootUrl}/api/v1/game/genre-list-by-id?Page=1&PageSize=100&Search=${search}`;
      const response: any = await get(url);
      if (response.data.statusCode === 200) {
        let arr = [...response.data.response.results];
        setsearchArr(arr);
        setIsSearching(false);
      } else {
        setIsSearching(false);
      }
    } catch (err: any) {
      console.log({ errorz: err });
      if (err) {
        throw err;
      } else {
        throw err;
      }
    }
  };

  const handlePressKey = async (e: any) => {
    if (e.key === 'Enter') {
    if (searchArr.length) {
      setsearchArr([]);
      setsearch("");
      setGameCount(0);
      return;
    }
    setIsSearching(true);
    try {
      let url = `${gameRootUrl}/api/v1/game/genre-list-by-id?Page=1&PageSize=100&Search=${search}`;
      const response: any = await get(url);
      if (response.data.statusCode === 200) {
        let arr = [...response.data.response.results];
        setsearchArr(arr);
        setIsSearching(false);
      } else {
        setIsSearching(false);
      }
    } catch (err: any) {
      console.log({ errorz: err });
      toast({
        title: "Info",
        description:  err,
        status: "info",
        duration: 9000,
        isClosable: true,
      })
      if (err) {
        toast({
          title: "Info",
          description:  err,
          status: "info",
          duration: 9000,
          isClosable: true,
        })
      } else {
        throw err;
      }
    }
  }
  };

  const paginateSearch = useCallback(async () => {
    setIsSearching(true);
    setIsLoading(true);
    try {
      let url = `${gameRootUrl}/api/v1/game/genre-list-by-id?Page=${searchPageNumber}&PageSize=100&Search=${search}`;
      const response: any = await get(url);
      if (response.data.statusCode === 200) {
        let arr = [...searchArr, ...response.data.response.results];
        setSearchPageNumber(searchPageNumber + 1);
        setsearchArr(arr);
        setIsSearching(false);
        setIsLoading(false);
      } else {
        setIsSearching(false);
        setIsLoading(false);
        setGameCount(-1);
      }
    } catch (err: any) {
      console.log({ errorz: err });
      if (err) {
        throw err;
      } else {
        throw err;
      }
    }
  }, [searchPageNumber, searchArr]);
  const handleBackFunction = () => {
    if (isReview) {
      handleBack();
      return;
    }
    if (AddGame === true) {
      handleBack();
      setshowGame(false);
      setAddGame(false);
    } else if (showGame === true) {
      setshowGame(false);
      setAddGame(true);
      setselectedGame({ apiGameId: null, background_image: "", name: "" });
    } else if (showSelectedGame === true) {
      setshowGame(true);
      setAddGame(false);
      setShowSelectedGame(false);
    }
  };
  const handleNextFunction = () => {
    if (showGame === true) {
      if (checks()) {
        setshowGame(false);
        setAddGame(false);
        setShowSelectedGame(true);
      }
    } else {
      handleNext();
    }
  };

  const paginateRow = useCallback(
    async (rowInndex: number, GenreId: number) => {
      let dataSetObg = { ...dataSet };
      let gameListArrCopy = [...gameListArr];
      let gameListArrCopy2 = [...gameListArr];

      console.log({ rowInndex, GenreId });
      if (gameListArr[rowInndex].gameDetails.length < 10) {
        console.log("paginateRowReturn");
        return;
      }
      let propertyName = rowInndex;
      dataSetObg[propertyName] = {
        pageNumber: dataSetObg[rowInndex]
          ? dataSetObg[rowInndex].pageNumber + 1
          : 2,
        loading: true,
      };
      setDataSet(dataSetObg);
      console.log("dataSet", dataSetObg);
      try {
        let url = `${gameRootUrl}/api/v1/game/genre-list-by-id?GenreId=${GenreId}&Page=${
          dataSetObg[`${rowInndex}`].pageNumber
        }&PageSize=10`;
        const response: any = await get(url);
        if (response.data.statusCode === 200) {
          let arr = [
            ...gameListArr[rowInndex].gameDetails,
            ...response.data.response.results,
          ];
          let updatedArr = (gameListArrCopy[rowInndex] = {
            ...gameListArrCopy[rowInndex],
            gameDetails: arr,
          });
          gameListArrCopy2[rowInndex] = updatedArr;
          console.log({ gameListArrCopy2 });
          dataSetObg[propertyName] = {
            pageNumber: dataSetObg[rowInndex]
              ? dataSetObg[rowInndex].pageNumber
              : 2,
            loading: false,
          };
          setDataSet(dataSetObg);
          setGameListArr(gameListArrCopy2);
        } else {
          dataSetObg[propertyName] = {
            pageNumber: dataSetObg[rowInndex]
              ? dataSetObg[rowInndex].pageNumber
              : 2,
            loading: false,
          };
          setDataSet(dataSetObg);
        }
      } catch (err: any) {
        console.log({ errorz: err });
        if (err) {
          throw err;
        } else {
          throw err;
        }
      }
    },
    [dataSet, gameListArr]
  );
  return (
    <>
      <ModalBody
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            width: "8px",

            border: "1px solid",
            borderRadius: "24px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#1C2A5B",
            borderRadius: "24px",
          },
        }}
        position={"relative"}
        py="20px"
        px="50px"
        // minH={"500px"}
      >
        <Box>
          {showSelectedGame === true && (
            <Box
              position={"relative"}
              minW="94px"
              w="94px"
              h="122px"
              cursor={"pointer"}
              mr="10px"
              mt="10px"
              onClick={() => {
                setshowGame(true);
                setShowSelectedGame(false);
              }}
            >
              <Image
                borderRadius={"4px"}
                objectFit="cover"
                w="94px"
                h="122px"
                src={selectedGame.background_image}
              />
              <Icon
                as={BiSelectMultiple}
                color={"green"}
                width="30px"
                height="30px"
                top={"1px"}
                right="1px"
                zIndex={1}
                position={"absolute"}
              />
              <Box
                bottom={"10px"}
                left="0px"
                zIndex={1}
                position={"absolute"}
                w="full"
                bg="rgb(0, 0, 0,0.6) "
              >
                <Text
                  textAlign={"center"}
                  noOfLines={1}
                  color={"white"}
                  fontSize="12px"
                  fontWeight={"500"}
                >
                  {selectedGame.name}
                </Text>
              </Box>
            </Box>
          )}
          {AddGame === true && (
            <>
              <Heading
                color={textColor}
                fontSize="24px"
                fontWeight={"600"}
                mb="10px"
              >
                Games
              </Heading>
              <Flex
                border="1px solid #707997"
                borderStyle={"dashed"}
                justifyContent={"center"}
                alignItems="center"
                cursor={"pointer"}
                mt="40px"
                bg="#F9F9F9"
                onClick={() => {
                  setAddGame(false);
                  setshowGame(true);
                }}
                // borderColor={"gray"}
              >
                <Flex
                  flexDir={"column"}
                  justifyContent={"center"}
                  alignItems="center"
                  p="50px"
                >
                  <PlusSquareIcon color={"gray.700"} w="25px" h="25px" />
                  <Text mt="10px">Add game</Text>
                </Flex>
              </Flex>
            </>
          )}

          {showGame === true && (
            <>
              <Flex justifyContent={"space-between"}>
                <Heading
                  color={textColor}
                  fontSize="24px"
                  fontWeight={"600"}
                  mb="10px"
                >
                  Day 1-
                  {moment(state.startDate, "YYYY-MM-DD HH:mm:ss").format(
                    "dddd"
                  )}
                </Heading>
                <SearchBar
                  showClear={searchArr.length ? true : false}
                  onSearch={onSearch}
                  isLoading={isSearching}
                  onChange={(e: any) => {
                    setsearch(e.target.value);
                    setsearchArr([]);
                  }}
                  onKeyPress={(e:any)=>handlePressKey(e)}
                  value={search}
                  w="50%"
                  mr="20px"
                />
              </Flex>

              <Box maxH={"400px"} position={"relative"}>
                {searchArr.length === 0 &&
                  gameListArr.map((data: any, i: number) => {
                    return (
                      <Box
                        mt="24px"
                        key={i}
                        // overflow='scroll'
                        position={"relative"}
                      >
                        <Heading
                          color={textColor}
                          fontSize="16px"
                          fontWeight={"600"}
                          mb="10px"
                        >
                          {data.name}
                        </Heading>

                        <Swiper
                          // style={{ width:"90%" }}
                          onSwiper={setSwiperRef}
                          slidesPerView={6}
                          centeredSlides={false}
                          spaceBetween={0}
                          // pagination={{
                          //   type: "fraction",
                          // }}
                          onReachEnd={() => {
                            // console.log("onReachEnd");
                            paginateRow(i, data.apiGameCategoryId);
                          }}
                          navigation={true}
                          modules={[Pagination, Navigation]}
                          className="mySwiper"
                        >
                          {data.gameDetails.map((innerGame: any, i: number) => {
                            return (
                              <SwiperSlide>
                                <Box
                                  onClick={() => setselectedGame(innerGame)}
                                  key={i}
                                  position={"relative"}
                                  minW="94px"
                                  w="94px"
                                  h="122px"
                                  cursor={"pointer"}
                                  borderRadius={"4px"}

                                  // mr="10px"
                                >
                                  <Image
                                    borderRadius={"4px"}
                                    objectFit="cover"
                                    w="94px"
                                    h="122px"
                                    src={innerGame.background_image}
                                    opacity={
                                      selectedGame.apiGameId ===
                                      innerGame.apiGameId
                                        ? "0.4"
                                        : "1"
                                    }
                                  />
                                  <Box
                                    width="30px"
                                    height="30px"
                                    top={"1px"}
                                    right="1px"
                                    zIndex={1}
                                    position={"absolute"}
                                    bg="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #161616 100%)"
                                    borderRadius={"30px"}
                                  >
                                    <Icon
                                      as={
                                        selectedGame.apiGameId ===
                                        innerGame.apiGameId
                                          ? BiSelectMultiple
                                          : AiOutlinePlusCircle
                                      }
                                      color={
                                        selectedGame.apiGameId ===
                                        innerGame.apiGameId
                                          ? "#7CFC00"
                                          : "white"
                                      }
                                      width="30px"
                                      height="30px"
                                    />
                                  </Box>
                                  <Box
                                    bottom={"0px"}
                                    left="0px"
                                    zIndex={1}
                                    position={"absolute"}
                                    display="flex"
                                    justifyContent={"center"}
                                    w="full"
                                    // h="51px"
                                    bg="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #161616 100%)"
                                    // bg='red'
                                  >
                                    <Text
                                      textAlign={"center"}
                                      noOfLines={1}
                                      color={"white"}
                                      fontSize="12px"
                                      fontWeight={"500"}
                                    >
                                      {innerGame.name.trim()}
                                    </Text>
                                  </Box>
                                </Box>
                              </SwiperSlide>
                            );
                          })}
                          {dataSet[i]?.loading === true && (
                            <>
                              <SwiperSlide>
                                <Box
                                  display={"flex"}
                                  justifyContent="center"
                                  alignItems={"center"}
                                  position={"relative"}
                                  minW="94px"
                                  w="94px"
                                  h="122px"
                                  cursor={"pointer"}
                                  // mr="10px"
                                >
                                  <Spinner />
                                </Box>
                              </SwiperSlide>
                              <SwiperSlide>
                                <Box
                                  display={"flex"}
                                  justifyContent="center"
                                  alignItems={"center"}
                                  position={"relative"}
                                  minW="94px"
                                  w="94px"
                                  h="122px"
                                  cursor={"pointer"}
                                  // mr="10px"
                                >
                                  <Spinner />
                                </Box>
                              </SwiperSlide>
                            </>
                          )}
                        </Swiper>
                      </Box>
                    );
                  })}
                {searchArr.length > 0 && (
                  <Flex
                    mt="6px"
                    overflowY="auto"
                    justifyContent={"flex-start"}
                    alignItems="center"
                    flexWrap={"wrap"}
                    py="20px"
                    css={{
                      "&::-webkit-scrollbar": {
                        width: "2px",
                      },
                      "&::-webkit-scrollbar-track": {
                        width: "6px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        background: "#D2D2D2",
                        borderRadius: "24px",
                      },
                    }}
                  >
                    {searchArr.map((innerGame: any, i: number) => {
                      return (
                        <Box
                          onClick={() => setselectedGame(innerGame)}
                          key={i}
                          position={"relative"}
                          minW="94px"
                          w="94px"
                          h="122px"
                          cursor={"pointer"}
                          mr="10px"
                          mt="10px"
                        >
                          <Image
                            borderRadius={"4px"}
                            objectFit="cover"
                            w="94px"
                            h="122px"
                            src={innerGame.background_image}
                            opacity={
                              selectedGame.apiGameId === innerGame.apiGameId
                                ? "0.4"
                                : "1"
                            }
                          />
                          <Box
                            width="30px"
                            height="30px"
                            top={"1px"}
                            right="1px"
                            zIndex={1}
                            position={"absolute"}
                            bg="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #161616 100%)"
                            borderRadius={"30px"}
                          >
                            <Icon
                              as={
                                selectedGame.apiGameId === innerGame.apiGameId
                                  ? BiSelectMultiple
                                  : AiOutlinePlusCircle
                              }
                              color={
                                selectedGame.apiGameId === innerGame.apiGameId
                                  ? "#7CFC00"
                                  : "white"
                              }
                              width="30px"
                              height="30px"
                            />
                          </Box>
                          <Box
                            bottom={"10px"}
                            left="0px"
                            zIndex={1}
                            position={"absolute"}
                            w="full"
                            bg="rgb(0, 0, 0,0.6) "
                          >
                            <Text
                              textAlign={"center"}
                              noOfLines={1}
                              color={"white"}
                              fontSize="12px"
                              fontWeight={"500"}
                            >
                              {innerGame.name}
                            </Text>
                          </Box>
                        </Box>
                      );
                    })}
                  </Flex>
                )}
                <Flex
                  w={"full"}
                  justifyContent="center"
                  alignItems={"center"}
                  p="20px"
                >
                  <Button
                    disabled={gameCount < 0}
                    isLoading={isLoading}
                    m="auto"
                    onClick={searchArr.length === 0 ? paginate : paginateSearch}
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="150px"
                    h="30"
                    bg="transparent"
                    borderRadius={8}
                    border={`1px solid `}
                    color={buttonColorBrand}
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    _focus={{ bg: "transparent" }}
                    borderColor={buttonColorBrand}
                  >
                    Load More
                  </Button>
                </Flex>
              </Box>
            </>
          )}
        </Box>
      </ModalBody>
      <ModalFooter px="50px">
        <Flex w="full" justifyContent={"space-between"}>
          <Button
            onClick={handleBackFunction}
            fontSize="sm"
            variant="brand"
            fontWeight="700"
            w="276px"
            h="50"
            bg="transparent"
            borderRadius={8}
            border={`1px solid `}
            color={"black"}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
            borderColor={"black"}
          >
            Back
          </Button>

          {selectedGame.apiGameId && (
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="276px"
              h="50"
              onClick={() => handleNextFunction()}
              borderRadius={8}
              bg={buttonColorBrand}
            >
              Continue
            </Button>
          )}
        </Flex>
      </ModalFooter>
    </>
  );
}
export default memo(Step_7);
