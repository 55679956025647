// Chakra imports
import {
  Text,
  Flex,
  Button,
  useDisclosure,
  HStack,
  Box,
} from "@chakra-ui/react";
import React, { useMemo, useEffect, useState } from "react";

import Card from "components/card/Card";
import LocationView from "views/admin/ViewHubDetails/components/LocationView";

export default function LocationApprovel(props: {
  selectedLocation: any;
  hubLocations: any;
  refetch: any;
  state: any;
}) {
  const { hubLocations, refetch } = props;
  // Chakra Color Mode
  const textColorSecondary = "gray.200";
  const textColorSecondary_2 = "gray.300";
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const [selectedLocation, setSelectedLocation] = useState(null);
  return (
    <Card border={"1px solid #ECF1F4"} mt="10px" p="0px" borderRadius={"10px"}>
      <Text p="20px" fontSize={"14px"} fontWeight="500">
        Location Approval{" "}
      </Text>
      <Box
        px="20px"
        py='10px'
        maxH={"150px"}
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#1C2A5B",
            borderRadius: "24px",
          },
        }}
      >
        <LocationView
          refetch={refetch}
          selectedLocation={selectedLocation}
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
        />
        <Box>
          {hubLocations.map((x: any, i:number) => {
            return (
              <HStack
              key={i}
                cursor={"pointer"}
                onClick={() => {
                  onOpen();
                  setSelectedLocation(x);
                }}
                mt={i===0?"0px":"16px"}
                justifyContent={"space-between"}
              >
                <Text color={"#298BE2"} fontSize="12px">
                  {x.address}
                </Text>
                <Text color={x.status==="Rejected"? "#FF5247": x.status==="Pending"? "#F5901A": "#31D0AA"} fontSize="12px">
                  {x.status}
                </Text>
              </HStack>
            );
          })}
        </Box>
      </Box>
    </Card>
  );
}
