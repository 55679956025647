import React, { useMemo, useEffect, useState } from "react";
import {
  Calendar,
  Views,
  dateFnsLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Grid } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
import { getEventList } from "redux/slices/Events";
import moment from "moment";
import { EventWrapper, Event } from "./EventWraper";
import Menu from "components/menu/MainMenu";
import { useHistory, useLocation } from "react-router-dom";

var newDateObj = moment(new Date("2023-05-29")).add(30, "m").toDate();
const locales = {
  "en-US": enUS,
};
const events = [
  {
    id: 0,
    title: "Some Event",
    start: new Date(),
    end: newDateObj,
  },
  // {
  //   id: 1,
  //   title: "Some Event",
  //   start: new Date( ),
  //   end: new Date( ),
  // },
  {
    id: 4,
    title: "Some Event",
    start: new Date(2023, 3, 28, 3, 0, 0),
    end: new Date(2023, 3, 28, 5, 0, 0),
  },
];
interface Props {
  onOpen?: Function;
  onEditEvent?: Function;
}

export default function Timeslots(props: Props) {
  const history = useHistory();

  const { onOpen, onEditEvent } = props;
  const dispatch: AppDispatch = useDispatch();
  const defaultDate = useMemo(() => new Date(), []);
  const { eventList } = useSelector((state: any) => state.Events);
  const { userId, userName } = useSelector((state: any) => state.Auth);
  const [eventListArr, setEventListArr] = useState([]);
  // useEffect(() => {
  //   dispatch(getEventList(userId));
  //   console.log({
  //     start: new Date(2023, 4, 12, 0, 0, 0),
  //     end: new Date(2023, 4, 12, 0, 0, 0),
  //   });
  // }, [userId]);

  useEffect(() => {
    if (eventList?.length) {
      let list = eventList.map((x: any) => {
        return {
          ...x,
          id: x.id,
          title: x.tournament_Name,
          start: moment(x.startDate + " " + x.start_Time).toDate(),
          end: moment(x.startDate + " " + x.end_Time).toDate(),
        };
      });
      console.log({ list });
      setEventListArr(list);
      console.log({ eventList, events });
    }
  }, [eventList]);
  const localizer = momentLocalizer(moment);
  const eventRenderProps = (
    event: any,
    start: any,
    end: any,
    isSelected: any
  ) => {
    let result = {
      style: {
        backgroundColor: "#FFA8A2",
        color: "black",
        borderRadius: 0,
        border: "0px",
        borderLeft: "4px solid #1E75FF",
        minHeight: "50px",
      },
    };
    // Code to conditionally add 'className' or 'style' to the result
    return result; // {className?: String, style?: Object}
  };
  const navigateToEventDetail = (e: any) => {
    console.log({ e });
    history.push({
      pathname: "/admin/BracketPortal",
      state: { eventDetail: e },
      search: `?eventId=${e.id}`,
    });

    // history.push({
    //   pathname: "/admin/ViewHubDetails",
    //   search: `?hubId=${hubId.row.original.userId}`,
    // });
  };

  return (
    <Grid bg="white" h={"1800px"} p="20px" borderRadius={"10px"}>
      <Calendar
        selectable={true}
        onSelectSlot={(SlotInfo) => null}
        // onSelecting={()=>onOpen()}
        startAccessor="start"
        endAccessor="end"
        // onKeyPressEvent={alert}
        onSelectEvent={(e) => navigateToEventDetail(e)}
        // onSelecting={alert}
        eventPropGetter={eventRenderProps}
        components={{
          event: Event,
          eventWrapper: (props: any) =>
            EventWrapper({ ...props, onEditEvent: onEditEvent }),
        }}
        defaultDate={defaultDate}
        defaultView={Views.WEEK}
        events={eventListArr.length ? eventListArr : events}
        // events={[]}

        localizer={localizer}
        step={60}
        timeslots={1}
      />
    </Grid>
  );
}
