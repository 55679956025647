/* eslint-disable */

import React, { memo, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  useToast,
  Text,
  useColorModeValue,
  HStack,
  FormErrorMessage,
  Spinner,
} from "@chakra-ui/react";
// Custom components
import { useDispatch, useSelector } from "react-redux";
import { get, post } from "redux/services/api";

import {
  AsyncCreatableSelect,
  AsyncSelect,
  CreatableSelect,
  Select,
} from "chakra-react-select";
function index(props: { next: () => void }) {
  const toast = useToast();
  const dispatch: any = useDispatch();
  const { loading } = useSelector((state: any) => state.Auth);
  const { usersListloading, usersList, userRolesList } = useSelector(
    (state: any) => state.AdminsTableLists
  );
  const { next } = props;
  // Chakra color mode
  const textColor = useColorModeValue("black.50", "white");
  const [isLoadingUsers, setIsloadingUsers] = useState(false);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);

  const buttonColorBrand = useColorModeValue("blue.200", "blue.200");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [userDropDown, setUserDropDown] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [selectedUsers, setSelectedUsers] = useState<any>(null);
  const [env, setEnv] = useState<any>(null);
  const [invalidState, setInvalidState] = useState({
    users: false,
    event: false,
    env: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (usersList) {
      let arr = usersList.map((x: any) => {
        return {
          label: x.gamerTag,
          value: x.userId,
        };
      });
      setUserDropDown(arr);
    }
  }, [usersList]);

  const checks = (): boolean => {
    if (env === null) {
      setInvalidState((prvState) => ({
        ...prvState,
        env: true,
      }));
      return false;
    } else if (selectedEvent === null) {
      setInvalidState((prvState) => ({
        ...prvState,
        event: true,
      }));
      return false;
    } else if (selectedUsers === null) {
      setInvalidState((prvState) => ({
        ...prvState,
        users: true,
      }));
      return false;
    }
    return true;
  };
  const finish = async () => {
    if (checks()) {
      setIsLoading(true);

      let payload = {
        url: `https://eventapi${env.value}.gridgamers.com/api/v1/challonge/register-tournament-participants`,
        data: {
          tournamentId: selectedEvent.value.toString(),
          userId: selectedUsers.value.toString(),
        },
      };
      try {
        let response = await post(payload);
        // setSelectedEvent(null);
        setSelectedUsers([]);
        if (response.data.statusCode === 200) {
          setIsLoading(false);
          toast.closeAll()
          toast({
            title: response.data.message,
            description: response.data.errorMessage,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          setIsLoading(false);
          toast.closeAll()
          toast({
            title: response.data.message,
            description: response.data.errorMessage,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (err) {
        console.log({ err });
        setIsLoading(false);
        toast.closeAll()
        toast({
          title: "Server error",
          description: "Server Error",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  const getUsers = async (env: string) => {
    setIsloadingUsers(true);
    let url = `https://authapi${env}.gridgamers.com/api/v1/user/get-users`;
    try {
      const response: any = await get(url);
      setIsloadingUsers(false);
      if (response.data.status === true) {
        console.log({ data: response.data.response.getUsers });
        let arr = response.data.response.getUsers.map((x: any) => {
          return {
            label: x.userName ?? x.title,
            value: x.userId,
          };
        });
        setUserDropDown(arr);
      }
    } catch (err) {
      setIsloadingUsers(false);
    }
  };
  const getEventList = async (env: string) => {
    setIsLoadingEvents(true);
    let url = `https://eventapi${env}.gridgamers.com/api/v1/challonge/tournament-summary-list?PageNumber=0&PageSize=100`;
    try {
      const response: any = await get(url);
      setIsLoadingEvents(false);
      if (response.data.status === true) {
        console.log({ data: response.data.response.tournaments });
        let arr = response.data.response.tournaments.map((x: any) => {
          return {
            label: x.name,
            value: x.tournamentId,
          };
        });
        setEventList(arr);
      }
    } catch (err) {
      setIsLoadingEvents(false);
    }
  };
  const onchangeEnv = (x: any) => {
    setInvalidState((prvState) => ({
      env: false,
      users: false,
      event: false,
    }));
    getEventList(x.value);
    getUsers(x.value);
    setEnv(x);
  };
  return (
    <Flex
      maxW={{ base: "100%", md: "max-content" }}
      w="100%"
      mx={{ base: "auto", lg: "0px" }}
      me="auto"
      h="max-content"
      alignItems="start"
      justifyContent="center"
      mt={{ base: "40px", md: "10vh" }}
      flexDirection="column"
      bg={"white"}
      p="50px"
    >
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "420px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <FormControl mt={10} isInvalid={invalidState.env}>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Environment<Text color={brandStars}>*</Text>
          </FormLabel>
          <Select
            isInvalid={invalidState.env}
            colorScheme="blue.200"
            options={[
              { label: "Development", value: ".dev" },
              { label: "Staging", value: ".stage" },
              { label: "Production", value: "" },
            ]}
            onChange={onchangeEnv}
            value={env}
            placeholder="Select Environment"
          />
          {invalidState.env && (
            <FormErrorMessage>Please select Environment.</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={invalidState.event}>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Event<Text color={brandStars}>*</Text>
          </FormLabel>
          <Select
            isDisabled={eventList.length === 0 || isLoadingEvents}
            isInvalid={invalidState.event}
            isLoading={isLoadingEvents}
            colorScheme="blue"
            options={eventList}
            onChange={setSelectedEvent}
            value={selectedEvent}
          />
          {invalidState.event && (
            <FormErrorMessage>Please select Event.</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={invalidState.users}>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Users<Text color={brandStars}>*</Text>
          </FormLabel>
          <Select
            isDisabled={userDropDown.length === 0 || isLoadingUsers}
            isLoading={isLoadingUsers}
            isInvalid={invalidState.users}
            // isMulti
            colorScheme="blue"
            options={userDropDown}
            onChange={setSelectedUsers}
            value={selectedUsers}
          />
          {invalidState.users && (
            <FormErrorMessage>Please select user.</FormErrorMessage>
          )}
        </FormControl>
        <HStack mt="20px">
          <Button
            isLoading={isLoading || loading}
            loadingText="Finisging..."
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            // w="30%"
            h="50"
            borderRadius={8}
            bg={buttonColorBrand}
            onClick={finish}
          >
            Add Participants
          </Button>
        </HStack>
      </Flex>
    </Flex>
  );
}

export default index;
