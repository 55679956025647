import { useQuery, useMutation, useQueryClient } from "react-query";
import { post, put, get } from "../../../redux/services/api";
import { hubAdminUrl_table } from "../../../utilities/constants";
// const queryClient = useQueryClient()

interface payload {
  PageSize: number;
  PageNumber: number;
}
const fetchAdminList = async (payload: payload) => {
  const { PageNumber, PageSize } = payload;
  const { data } = await get(
    // `${hubAdminUrl_table}/v1/Complain?PageSize=${PageSize}&PageNumber=${PageNumber}&LocationId=${locationId}`
    `${hubAdminUrl_table}api/v1/user/get-admin-users-list?PageNumber=${PageNumber}&PageSize=${PageSize}&Flag=${1}`
  );
  return data;
};

export const useAdmin = (payload: payload) => {
    const { PageNumber, PageSize } = payload;
  return useQuery({
    queryKey: ["fetchAdminList", PageNumber,PageSize],
    queryFn: () => fetchAdminList(payload),
    refetchOnWindowFocus: false,
  });
};

// export default useComplain
