import React, { useState, useCallback, useEffect } from "react";
// Chakra imports
import {
  Flex,
  Text,
  Input,
  Textarea,
  useColorModeValue,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { get } from "redux/services/api";
import { hubAdminUrl } from "utilities/constants";
import { post } from "redux/services/api";
import { hubRootUrl } from "utilities/constants";
import { useDispatch, useSelector } from "react-redux";

export default function Information() {
  const toast = useToast();

  const { userId } = useSelector((state: any) => state.Auth);
  const { privacyPolicy, termsConditions } = useSelector(
    (state: any) => state.AdminsTableLists
  );

  const textColor = useColorModeValue("black.50", "white");
  const buttonColorBrand = useColorModeValue("#19BAEE", "white");
  const buttonColor = useColorModeValue("#F0F4F8", "white");

  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("#19BAEE", "white");
  const [ticketTypesArr, setTicketTypesArr] = useState([]);
  const [loading, setloading] = useState(false);

  const [selectedticketTypes, setSelectedTicketTypes] = useState({
    answerId: null,
  });
  const [text, setText] = useState("");
  useEffect(() => {
    getTicketTypes();
     
  }, [ ]);

  const getTicketTypes = async () => {
    let url = `${hubAdminUrl}/api/v1/setting/get-list-of-support-and-feedback?subCatagoryId=14`;
    const response: any = await get(url);
    if (response.data.status === true) {
      console.log({ response: response.data.response.supports });
      setTicketTypesArr(response.data.response.supports[0].supportSubMenuList);
    }
  };

  const send = async () => {
    setloading(true);
    let formdata = new FormData();
    formdata.append("TicketDescription", text);
    formdata.append("CreatedById", userId);
    formdata.append("TicketTypeId", selectedticketTypes.answerId);
    formdata.append("Attachment", null);

    let payload = {
      url: `${hubAdminUrl}/api/v1/ticket/createticket`,
      data: formdata,
    };
    let response = await post(payload);
    setloading(false);
    if (response.data.statusCode === 200) {
      setText("")
      setSelectedTicketTypes({
        answerId: null,
      })
      toast.closeAll()
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast.closeAll()
      toast({
        title: response.data.message,
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <Card shadow={"lg"} minW='936px' borderRadius={"8px"} p="40px">
      <Text fontSize={"36px"} fontWeight="bold" color="#1C2A5B">
        Information Settings
      </Text>
      <Tabs>
        <TabList
          color={textColorSecondary}
          display={"flex"}
          justifyContent="space-between"
        >
          <Tab
            _selected={{ color: textColorBrand, borderColor: textColorBrand }}
          >
            Support & Feedack
          </Tab>
          <Tab
            _selected={{ color: textColorBrand, borderColor: textColorBrand }}
          >
            Terms & Conditions
          </Tab>
          <Tab
            _selected={{ color: textColorBrand, borderColor: textColorBrand }}
          >
            Privacy Policy
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Text fontSize={"20px"} fontWeight="700" textAlign={"left"}>
              Tell us what this is about
            </Text>
            <Flex py="10px" justifyContent={"space-between"} flexWrap="wrap">
              {ticketTypesArr.map((data, i) => {
                return (
                  <Button
                    onClick={() => setSelectedTicketTypes(data)}
                    key={i}
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="48%"
                    h="50"
                    mb="12px"
                    borderRadius={8}
                    color={
                      selectedticketTypes.answerId === data.answerId
                      ? "white"
                      : "black"
                    }
                    bg={
                      selectedticketTypes.answerId === data.answerId
                        ? buttonColorBrand
                        : buttonColor
                    }
                    _active={{ bg: buttonColorBrand, color:"white" }}
                    _hover={{ bg: buttonColorBrand, color:"white" }}
                    _focus={{ bg: buttonColorBrand, color:"white" }}
                  >
                    {data.answerChoice}
                  </Button>
                );
              })}
            </Flex>

            <Textarea
              onChange={(e: any) => setText(e.target.value)}
              value={text}
              borderRadius={8}
              isRequired={true}
              fontSize="sm"
              placeholder="Description..."
              mb="24px"
              size="lg"
              minHeight={"200px"}
              border={`1px solid `}
              borderColor={textColorSecondary}
            />
            {/* <Flex justifyContent={"flex-end"}> */}
              <Button
                isLoading={loading}
                onClick={send}
                m="auto"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="28%"
                h="50"
                float={'right'}
                borderRadius={8}
                bg={"#298BE2"}
              >
                Send
              </Button>
            {/* </Flex> */}
          </TabPanel>
          <TabPanel>
            <div dangerouslySetInnerHTML={{ __html: termsConditions }} />
          </TabPanel>
          <TabPanel>
          <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
}
